<template>
  <div>
    <svg viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0)">
        <path
          d="M0.5 0.5L13.6 13.6"
          stroke="#000C2E"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M13.6 0.5L0.5 13.6"
          stroke="#000C2E"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="14.1" height="14.1" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </div>
</template>

<script>
export default {
  name: "AppSvgClose"
};
</script>

<style lang="scss" scoped>
rect {
  fill: var(--link-active-color);
}
</style>
<template>
  <div class="svg-stage">
    <svg
      version="1.1"
      id="Illustration-front"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 1621.3 650.8"
      style="enable-background:new 0 0 1621.3 650.8;"
      xml:space="preserve"
    >
      <g id="KK-1-6_1_" class="hover-obj keid kejd" @click="$store.commit('setModal', '1.6')">
        <g id="_x31_-6_x5F_doer_x5F_2_1_">
          <polygon class="st0" points="659.8,341.8 634.7,336.7 636.6,420.7 661.6,428.4 		" />
          <g id="_x31_-6_x5F_doer_x5F_2">
            <polygon class="st0" points="642.1,349.4 654.5,352.2 654.9,373.9 642.6,370.8 			" />
            <polygon
              class="st0"
              points="642.1,349.4 642.6,370.8 654.9,373.9 654.9,374.6 640.8,370.9 640.3,349 			"
            />
            <path
              class="st0"
              d="M657.6,387.1h-0.5c-0.3,0-0.6-0.2-0.8-0.5c-0.1,0-0.1,0-0.2,0c0,0-0.1,0-0.1,0l-4.2-1
				c-0.3-0.1-0.6-0.4-0.6-0.8c0-0.5,0.3-0.9,0.7-0.8c0.3,0,4.2,1,4.2,1c0.2-0.5,0.6-0.8,1-0.8h0.5c0.6,0,1.1,0.7,1.1,1.5
				S658.3,387.1,657.6,387.1z"
            />
          </g>
        </g>
        <g id="_x31_-6_x5F_doer_x5F_1">
          <path
            class="st0"
            d="M1030.3,385.7l-2.9,106.4l27.8-13.2l2.9-102L1030.3,385.7z M1055.2,434.2l-4.3,1.7l0,0
			c-0.1,0.1-0.3,0.1-0.5,0.1c-0.6,0-1.1-0.5-1.1-1.1c0-0.5,0.3-0.9,0.8-1.1l0,0l2.6-1c0,0,0-0.1,0-0.1c0-0.8,0.5-1.5,1.2-1.5
			c0.5,0,0.9,0.4,1.1,1c0.5,0.1,0.9,0.5,0.9,1.1C1055.9,433.6,1055.6,434,1055.2,434.2L1055.2,434.2z"
          />
          <polygon class="st0" points="1050.4,393.2 1049.6,420.6 1035.3,426 1036,398.1 		" />
          <polygon class="st0" points="1049.6,420.6 1051,421.1 1035.3,427 1035.3,426 		" />
          <polygon class="st0" points="1051,421.1 1051.8,392.8 1050.4,393.2 1049.6,420.6 		" />
        </g>
      </g>
      <g id="KK-1-2_1_" class="hover-obj keid kejd" @click="$store.commit('setModal', '1.2')">
        <g id="_x31_-2_x5F_tag">
          <polygon class="st0" points="977.8,254.2 973.4,241.9 432.7,215.7 437.1,222.1 		" />
          <polygon class="st0" points="973.4,241.9 977.8,254.2 1164.8,31.2 1165.3,18.2 		" />
          <path
            class="st0"
            d="M1165.3,18.2l-0.5,13l96,196.1c0,0,2.5-5,0.4-11.6C1257.7,208.2,1165.3,18.2,1165.3,18.2z"
          />
          <polygon
            class="st0"
            points="1260.8,227.3 1222.7,226.2 1223.7,205.3 1144.4,55.6 1164.8,31.2 		"
          />
          <path
            class="st0"
            d="M1028.8,36.5l8.3,12c0,0,0,0,0,0c0.1,0.1,0.1,0.1,0.1,0.2c0,0.4-1.6,0.9-3.9,1.4l-0.8,43.8
			c0,4.1-7.5,10-14.5,10h-1.9c-1.8,0-3.2-1.4-3.1-3.2l1.2-50.2c-2.6-0.4-4.3-0.9-4.3-1.3c0-0.1,0.1-0.1,0.1-0.2c0,0,0,0,0,0
			l7.8-11.1L841.5,61.6l6.8,9.8c0.1,0,0.1,0.1,0.1,0.2c0,0.3-1.3,0.8-3.3,1.1l-0.6,36.3c0,3.4-6.2,8.3-12,8.3h-1.6
			c-1.5,0-2.6-1.2-2.6-2.7l1-41.6c-2.2-0.3-3.5-0.7-3.5-1.1c0-0.1,0.1-0.1,0.1-0.2l6.4-9L701.1,80.4l2.6,3.8h0c0,0,0.1,0.1,0.1,0.1
			c0,0.2-1,0.6-2.5,0.9l-0.5,28.1c0,2.6-4.8,6.4-9.3,6.4h-1.2c-1.1,0-2-0.9-2-2.1l0.8-32.2c-1.7-0.2-2.7-0.6-2.7-0.8
			c0,0,0-0.1,0.1-0.1l0,0l1.7-2.4l-38,5.1l0.4,14.7l-23.3,16.8l-16.3,1.4l-0.7-23.7L432.7,215.7l540.8,26.2l191.9-223.7L1028.8,36.5
			z"
          />
        </g>
        <g id="_x31_-2_x5F_facade_x5F_2">
          <polygon class="st0" points="976.5,256.4 976.6,254.2 474.9,224.4 475,225.9 		" />
          <polygon class="st0" points="538.1,250.2 542,251.8 541.9,247.7 		" />
          <polygon class="st0" points="487.1,245 491.8,247 491.6,242.1 		" />
          <polygon class="st0" points="949.9,505.5 949.9,505.5 949.9,505.5 		" />
          <polygon class="st0" points="488.9,324.1 495.6,326.9 495.3,319.5 		" />
          <polygon class="st0" points="540.8,336 545.6,338 545.4,332.6 		" />
          <path
            class="st0"
            d="M975.9,261.9l-7.7-0.5l-3.4,7.9l-0.2,8.4l-4,216l-0.1,3c0,1-0.1,1.9-0.4,2.9c-0.2,0.8-0.5,1.9-0.7,2.6
			c-2.2,5.9-5.7,8.7-5.7,8.7c0,0,0,0,0.1-0.1c-0.4,0.4-0.9,0.7-1.6,0.7c-1.6,0-3.2-1.5-3.2-3.3c0-1,0.3-2,0.8-2.6l0,0l2.4-3.7
			c0.5-0.8,0.9-1.7,1.2-2.7l0.2-0.7c0.3-1,0.4-2,0.4-3v-1.7l3.5-216.3c0,0,0,0,0,0l0,0l0.1-8.5l-3.3-8.5l-477.5-31.6l-1.5,4l0.5,8.1
			l14.3-9l15.5,0.9l-3.3,2.1l3.8,0.1l0.8,18.1l1.5,0.6c0.1-0.8,0.3-1.4,0.6-1.4c0.3,0,0.6,1,0.6,2.2c0,1.2-0.3,2.2-0.6,2.2
			c-0.3,0-0.5-0.6-0.6-1.6l-1.4-0.6l1,22.5l-15.3-2.1l-1-26.8l-5.9-2.5l-7.7,5l-1.9-0.2l3.3,66.1l15-10.5l15.3,3l-2.8,2l2.9,0.5
			l0.9,21.5l1.7,0.7c0.1-0.8,0.3-1.4,0.6-1.4c0.3,0,0.6,1,0.6,2.2s-0.3,2.2-0.6,2.2c-0.3,0-0.5-0.7-0.6-1.7l-1.6-0.7l0.7,18.4
			l-15.1-4l-0.8-20.7l-7.8-3.3L482,329l-1.9-0.5l2.2,44.7l53.6,16.5l8.1-1.4l1.9,0.7v2.9l1.2,0.4v-5.6l13.1,4v3l1-0.2l2,0.6l0.1,4.2
			l73,22.4l-1.9-84l25.1,5.1l1.8,86.6l84.4,25.9l9.1-3l4,1.3v4.4l1.2,0.4v-8.2l30.5,9.3v4.3l1.1-0.4l4.5,1.5v6l63,19.3l8.5-2.8
			l4.2,1.2v4.4l1.2,0.4v-8.2l30.5,9.3v4.3l1.1-0.4l4.5,1.5v6l61.9,18.9l5-261.7C976.2,261.9,976.1,261.9,975.9,261.9z M511.9,253.9
			l28.2-18.9l18.7,1.1l-3,2l3.5,0.2l0.7,21l2.3,1c0.1-0.8,0.3-1.4,0.6-1.4c0.3,0,0.6,1,0.6,2.2c0,1.2-0.3,2.2-0.6,2.2
			c-0.3,0-0.4-0.7-0.5-1.7l-2.3-1l0.8,23.4l-17.8-2.3l-1.1-28.6l-5.1-2.1l-6.9,4.7L511.9,253.9z M565.7,349.1
			c-0.3,0-0.5-0.7-0.6-1.7l-1.9-0.8l0.7,20l-17.6-4.6l-0.8-22.8l-5.8-2.4l-6.1,4.6l-17.8-4.4l28.5-21.2l18.4,3.7l-2.9,2.2l2.5,0.5
			l0.8,23.2l1.9,0.8c0.1-0.8,0.3-1.4,0.6-1.4c0.3,0,0.6,1,0.6,2.2S566,349.1,565.7,349.1z M626.7,327.5l-0.1,1.1h-7.1l-2.7-9.3
			l0.5-5.9l3.6,0.1c0.8-1.1,1.9-1.8,3.1-1.8h1.4c2.7,0,4.8,3.6,4.8,8C630.2,323.5,628.8,326.6,626.7,327.5z M654.3,322.7
			c0,0-1.3,0.2-3.2,0.4c0,0,0.1,2.3-2.4,2.3c-2.5,0-3.3-2-3.3-2l0,0h0c-2.4,0-4.7-0.3-6.2-1.1c-0.2-5.2,4-10.2,9.7-9.8
			C655,315.6,654.3,322.7,654.3,322.7z M674.9,389.5l-0.8,0.7l-6.6-1.8l-0.2-12.1l0.9-0.7l6.8,1.5L674.9,389.5z M682.9,374.5
			l-15.6-3.7l-0.5-16.8l1.1-0.5l15.7,3.4L684,374L682.9,374.5z M759.9,251.2l32.8,2l-3.3,2.8l4.6,0.3l0.2,26.4l3.5,1.4
			c0.1-0.8,0.3-1.4,0.6-1.4c0.3,0,0.6,1,0.6,2.2c0,1.2-0.3,2.2-0.6,2.2c-0.3,0-0.5-0.7-0.6-1.7l-3.4-1.4l0.2,33.7l-31.9-4.3
			l-0.3-34.1l-0.9,0.8l-32.4-3.2L759.9,251.2z M798.4,402.2c-0.3,0-0.5-0.7-0.6-1.7l-3.1-1.3l0.2,28.2l-31.4-8.3l-0.2-24.3l-0.8,0.8
			l-32-7.7l31.7-29.8l31.7,6.4l-2.9,2.9l3.4,0.4l0.2,22.8l0.1,7.3l3.1,1.3c0.1-0.8,0.3-1.4,0.6-1.4c0.3,0,0.6,1,0.6,2.2
			S798.8,402.2,798.4,402.2z M914.7,425.8c-0.3,0-0.5-0.7-0.6-1.7l-3.1-1.3l-0.4,34.8l-40-10.6l0.2-25.7l-32.4-8l32.1-34.4l40.5,8.2
			l-2.9,3.3l3.1,0.4l-0.3,25.7l-0.1,5l3.1,1.3c0.1-0.8,0.3-1.4,0.6-1.4c0.3,0,0.6,1,0.6,2.2C915.3,424.8,915.1,425.8,914.7,425.8z
			M916.8,297.6c-0.3,0-0.5-0.7-0.6-1.7l-3.9-1.6l-0.4,38.6l-40.5-5.3v-37.4l-32.9-3.2l31.3-29.1l41.7,2.5l-3.4,3.3l4.5,0.1
			l-0.3,29.3l3.9,1.6c0.1-0.8,0.3-1.4,0.6-1.4c0.3,0,0.6,1,0.6,2.2C917.4,296.6,917.2,297.6,916.8,297.6z"
          />
        </g>
        <g id="_x31_-2_x5F_facade_x5F_1">
          <polygon class="st0" points="1059.2,283 1066.1,280.2 1059.5,275.5 		" />
          <polygon class="st0" points="1175,367.8 1188.1,362.3 1175.7,353.6 		" />
          <path
            class="st0"
            d="M1144.4,55.6L977.8,254.2l-1.2-0.1l0,2.3l0.2,0v0l3.1,0.2c0,2.9-1.2,5.3-3.2,5.3c0,0-0.1,0-0.1,0l-4.9,256.7
			l55.8-26.5l2.8-106.4l27.8-8.7l-2.9,102l158.5-75.2l10.2-198.4L1144.4,55.6z M1077.2,288l-10-7.1l-8,3.3l-1.4,38.9l-26,5.3
			l0.9-33.3l-2.3,1c-0.1,1-0.3,1.7-0.6,1.7c-0.3,0-0.6-1-0.6-2.2c0-1.2,0.3-2.2,0.6-2.2c0.3,0,0.5,0.6,0.6,1.4l2.4-1l0.9-33.1
			l4.2-0.4l-4.1-2.9l26.8-2.7l42.5,29.3L1077.2,288z M1196.7,368.4l-7.7-5.5l-14,5.9l-1,21.3l-16.3,6.6l0.8-21l-1.9,0.8
			c-0.1,0.8-0.3,1.5-0.5,1.5c-0.3,0-0.5-0.8-0.5-1.9c0-1,0.2-1.9,0.5-1.9c0.2,0,0.4,0.5,0.5,1.2l2-0.8l1.2-30.1l2.3-0.7l-2.1-1.5
			l17.3-5.2l35.8,24.7L1196.7,368.4z M1202.1,269.3l-8.5-5.3l0,0l-13.6,5.7l0,0.9l-1.3,27.9l-16.7,3.3l1.2-24.6l0-0.3l-2.4,1
			c-0.1,0.8-0.3,1.5-0.5,1.5c-0.3,0-0.5-0.8-0.5-1.9s0.2-1.9,0.5-1.9c0.2,0,0.4,0.5,0.5,1.2l2.5-1l1.3-27.6l3.5-0.2l-4-2.5l17.7-1.6
			l36.5,22.7L1202.1,269.3z"
          />
          <polygon class="st0" points="1180.3,263.6 1180.1,268.7 1192.7,263.4 1180.7,255.9 		" />
        </g>
      </g>
      <g id="KK-1-17" class="hover-obj keid kejd" @click="$store.commit('setModal', '1.17')">
        <g id="_x31_-17_x5F_vej">
          <polyline
            class="st1"
            points="3.6,348.4 152,330.6 156.5,334.5 156.8,337.1 157.8,337 159.2,337.8 159.7,342.5 160.9,342.4 
			160.3,336.8 164.9,336.2 166.3,337 166.7,341.7 167.9,341.6 167.4,336 171.9,335.4 173.3,336.2 173.8,340.9 175,340.8 
			174.4,335.2 179,334.7 180.3,335.5 180.8,340.1 182,340 181.5,334.4 186,333.9 187.4,334.7 187.8,339.3 189,339.2 188.5,333.6 
			193.1,333.1 194.4,333.9 194.9,338.5 196.1,338.4 195.5,332.8 200.1,332.3 201.5,333.1 203.5,354.6 267.1,347.2 265,324.8 
			269.4,324.3 270.8,325.1 270.9,326.3 271.6,326.2 272.1,326.4 271.9,324 276.3,323.4 277.6,324.2 278,328.1 279.2,327.9 
			278.7,323.1 283.2,322.6 284.5,323.4 284.9,327.4 286.1,327.2 285.6,322.3 290,321.8 291.4,322.5 291.8,326.7 292.9,326.5 
			292.5,321.4 296.2,321 295.4,310.8 294,310 293.9,309 293,309.1 1.8,343.8 		"
          />
          <g>
            <polygon class="st1" points="252,333 248.9,331.1 210.3,335.6 213.4,337.6 			" />
          </g>
          <polygon class="st1" points="194.8,337.4 194.9,338.5 196.1,338.4 196,337.2 		" />
          <polygon class="st1" points="187.7,338.2 187.8,339.3 189,339.2 188.9,338 		" />
          <polygon class="st1" points="180.7,339 181.9,338.8 182,340 180.8,340.1 		" />
          <polygon class="st1" points="173.6,339.7 173.8,340.9 175,340.8 174.8,339.6 		" />
          <polygon class="st1" points="166.6,340.5 166.7,341.7 167.9,341.6 167.8,340.4 		" />
          <polygon class="st1" points="201.8,336.6 202,338.8 205.7,338.4 202.6,336.5 		" />
          <polygon class="st1" points="265.4,329.4 265.6,331.7 259.7,332.4 256.6,330.4 		" />
          <polygon class="st1" points="195.3,342.4 196.5,342.3 197.7,355.3 196.5,355.5 		" />
          <polygon class="st1" points="188.2,343.2 189.4,343.1 190.7,356.2 189.5,356.3 		" />
          <polygon class="st1" points="181.2,344.1 182.4,343.9 183.6,357 182.4,357.2 		" />
          <polygon class="st1" points="174.1,344.9 175.3,344.8 176.6,357.9 175.4,358 		" />
          <polygon class="st1" points="169.5,358.6 168.3,345.6 167.1,345.7 168.3,358.8 		" />
          <polygon class="st1" points="160.1,346.5 161.3,346.4 162.5,359.4 161.3,359.6 		" />
        </g>
        <g id="_x31_-17_x5F_fortov_1_">
          <g id="_x31_-17_x5F_fortov">
            <polygon class="st1" points="162.8,374.7 161.3,359.6 162.5,359.5 164,374.6 			" />
            <polygon class="st1" points="169.8,373.9 171,373.7 169.6,358.6 168.4,358.8 			" />
            <line class="st1" x1="168.6" y1="361.3" x2="169.9" y2="362.1" />
            <polygon class="st1" points="175.4,358 176.6,357.9 178,372.9 176.8,373 			" />
            <line class="st1" x1="176.2" y1="366.3" x2="177.5" y2="367.1" />
            <polygon class="st1" points="183.9,372.2 185.1,372 183.6,357 182.4,357.2 			" />
            <line class="st1" x1="183.8" y1="371.3" x2="185" y2="372.1" />
            <polygon class="st1" points="189.5,356.3 190.7,356.2 192.1,371.2 190.9,371.3 			" />
            <line class="st1" x1="189.6" y1="357.4" x2="190.9" y2="358.3" />
            <polygon class="st1" points="196.5,355.5 197.7,355.3 199.2,370.4 198,370.5 			" />
            <line class="st1" x1="197.2" y1="362.4" x2="198.5" y2="363.3" />
            <polygon class="st1" points="203.6,354.7 205,369.7 268.6,362.1 267.1,347.2 			" />
            <line class="st1" x1="204.8" y1="367.4" x2="207.6" y2="369.3" />
            <line class="st1" x1="211.1" y1="353.9" x2="230.4" y2="366.6" />
            <line class="st1" x1="232.2" y1="351.3" x2="252.5" y2="364" />
            <line class="st1" x1="254.1" y1="348.9" x2="268.1" y2="357.6" />
          </g>
        </g>
      </g>
      <g id="KK-1-16" class="hover-obj keid kejd" @click="$store.commit('setModal', '1.16')">
        <g id="havelaage">
          <g>
            <g>
              <polygon class="st0" points="269.9,362.9 268.6,362.1 265,324.8 266.3,325.6 			" />
              <polygon
                class="st0"
                points="270.8,325.1 269.4,324.3 265,324.8 268.6,362.1 269.9,362.9 266.3,325.6 			"
              />
              <polygon
                class="st0"
                points="270.9,326.3 270.8,325.1 266.3,325.6 269.9,362.9 271.7,362.7 268.3,326.6 			"
              />
            </g>
            <polygon class="st0" points="284.9,327.4 286.1,327.2 286.4,331.2 285.3,331.3 		" />
            <polygon class="st0" points="291.8,326.7 292.9,326.5 293.3,330.4 292.1,330.5 		" />
            <polygon class="st0" points="278,328.1 278.2,329.8 279.4,330.1 279.2,328 		" />
            <g>
              <polygon
                class="st0"
                points="271.9,324 272.1,326.4 273.4,326.8 273.2,324.7 277.6,324.2 276.3,323.4 			"
              />
              <polygon
                class="st0"
                points="275,329.6 276.9,329.4 278.2,329.8 277.6,324.2 273.2,324.7 273.4,326.8 274.8,327.2 			"
              />
              <polygon
                class="st0"
                points="280.1,323.9 280.9,332.7 280.3,332.8 280.2,330.3 279.4,330.1 278.7,323.1 283.2,322.6 284.5,323.4 
							"
              />
              <polygon
                class="st0"
                points="282.3,332.6 280.9,332.7 280.1,323.9 284.5,323.4 285.5,333.5 			"
              />
              <polygon
                class="st0"
                points="291.4,322.6 290,321.8 285.6,322.3 286.9,335.8 287.6,335.8 288.2,335.9 286.9,323.1 			"
              />
              <polygon
                class="st0"
                points="293,338.9 291.1,339.1 290.9,336.7 288.2,335.9 286.9,323.1 291.4,322.6 			"
              />
              <polygon
                class="st0"
                points="292.5,321.4 296.9,320.9 298.2,321.7 293.8,322.2 295.5,339.6 294.2,339.2 			"
              />
              <polygon
                class="st0"
                points="298.2,359.5 301.8,359 298.2,321.7 293.8,322.2 295.5,339.6 296.5,339.8 			"
              />
            </g>
          </g>
          <g>
            <polygon class="st0" points="200.6,371.9 199.2,371.1 195.6,332.8 196.9,333.6 		" />
            <polygon class="st0" points="201.5,333.1 205.2,371.4 200.6,371.9 196.9,333.6 		" />
            <polygon
              class="st0"
              points="201.5,333.1 200.1,332.3 195.6,332.8 199.2,371.1 200.6,371.9 196.9,333.6 		"
            />
          </g>
          <g>
            <polygon class="st0" points="193.6,372.7 192.2,371.9 188.5,333.6 189.9,334.4 		" />
            <polygon class="st0" points="194.4,333.9 198.1,372.2 193.6,372.7 189.9,334.4 		" />
            <polygon
              class="st0"
              points="194.4,333.9 193.1,333.1 188.5,333.6 192.2,371.9 193.6,372.7 189.9,334.4 		"
            />
          </g>
          <g>
            <polygon class="st0" points="186.5,373.5 185.1,372.7 181.5,334.4 182.9,335.2 		" />
            <polygon class="st0" points="187.4,334.7 191.1,372.9 186.5,373.5 182.9,335.2 		" />
            <polygon
              class="st0"
              points="187.4,334.7 186,333.9 181.5,334.4 185.1,372.7 186.5,373.5 182.9,335.2 		"
            />
          </g>
          <g>
            <polygon class="st0" points="179.5,374.3 178.1,373.5 174.4,335.2 175.8,336 		" />
            <polygon class="st0" points="180.4,335.5 184,373.7 179.5,374.3 175.8,336 		" />
            <polygon
              class="st0"
              points="180.4,335.5 179,334.7 174.4,335.2 178.1,373.5 179.5,374.3 175.8,336 		"
            />
          </g>
          <g>
            <polygon class="st0" points="172.4,375.1 171,374.3 167.4,336 168.8,336.8 		" />
            <polygon class="st0" points="173.3,336.3 177,374.5 172.4,375.1 168.8,336.8 		" />
            <polygon
              class="st0"
              points="173.3,336.3 171.9,335.4 167.4,336 171,374.3 172.4,375.1 168.8,336.8 		"
            />
          </g>
          <g>
            <polygon class="st0" points="165.4,375.9 164,375 160.3,336.8 161.7,337.6 		" />
            <polygon class="st0" points="166.3,337 169.9,375.3 165.4,375.9 161.7,337.6 		" />
            <polygon
              class="st0"
              points="166.3,337 164.9,336.2 160.3,336.8 164,375 165.4,375.9 161.7,337.6 		"
            />
          </g>
          <g>
            <polygon class="st0" points="160.6,376.4 160.6,376.4 156.8,337.1 156.9,338.1 		" />
            <polygon class="st0" points="159.2,337.8 162.9,376.1 160.6,376.4 156.9,338.1 		" />
            <polygon
              class="st0"
              points="159.2,337.8 157.8,337 156.8,337.1 160.6,376.4 160.6,376.4 156.9,338.1 		"
            />
          </g>
          <polygon class="st0" points="159.7,342.5 160.9,342.4 161.3,346.4 160.1,346.6 	" />
          <polygon class="st0" points="166.7,341.7 167.9,341.6 168.3,345.6 167.1,345.7 	" />
          <polygon class="st0" points="173.8,340.9 175,340.8 175.3,344.8 174.1,344.9 	" />
          <polygon class="st0" points="180.8,340.1 182,340 182.4,343.9 181.2,344.1 	" />
          <polygon class="st0" points="187.8,339.4 189,339.2 189.4,343.1 188.2,343.2 	" />
          <polygon class="st0" points="196.1,338.4 196.5,342.3 195.3,342.4 194.9,338.6 	" />
          <polyline class="st0" points="279,332.9 283.1,333.9 285.5,333.5 282.3,332.6 279,332.9 	" />
          <polygon class="st0" points="290.9,336.7 288.5,337.1 284.4,336.1 287.6,335.8 	" />
          <polygon
            class="st0"
            points="269.4,324.3 270.8,325.1 266.3,325.6 269.9,362.9 268.6,362.1 265,324.8 	"
          />
          <polyline
            class="st0"
            points="277.8,330.7 281,369.8 282.5,369.5 279,332.9 280.3,332.8 280.2,330.3 277.8,330.7 	"
          />
          <polyline
            class="st0"
            points="273.7,329.7 277.8,330.7 281,369.8 277.2,367.5 277.1,366.3 273.7,329.7 	"
          />
          <polygon class="st0" points="282.6,370.7 286.4,373 283.1,333.9 279,332.9 	" />
          <polygon
            class="st0"
            points="285.5,333.5 285.7,336 284.4,336.1 287.8,372.6 286.4,373 283.1,333.9 	"
          />
          <polygon class="st0" points="287.9,373.8 291.8,376.2 288.5,337.1 284.4,336.1 	" />
          <polygon
            class="st0"
            points="293.2,375.8 291.8,376.2 288.5,337.1 290.9,336.7 291.1,339.1 289.8,339.3 	"
          />
          <polygon class="st0" points="297.1,379.3 293.3,377 289.8,339.3 293.9,340.3 	" />
          <polygon class="st0" points="293,338.9 289.8,339.3 293.9,340.3 296.5,339.8 	" />
          <polyline class="st0" points="296.5,339.8 293,338.9 289.8,339.3 	" />
          <polygon class="st0" points="297.1,379.3 299.9,378.7 296.5,339.8 293.9,340.3 	" />
          <polygon class="st0" points="271.6,326.2 268.3,326.6 272.4,327.6 274.8,327.2 	" />
          <polygon
            class="st0"
            points="274.8,327.2 275,329.6 273.7,329.7 277.1,366.3 275.7,366.6 272.4,327.6 	"
          />
          <polygon
            class="st0"
            points="268.3,326.6 272.4,327.6 275.7,366.6 271.9,364.3 271.7,362.7 	"
          />
          <polygon class="st0" points="276.9,329.4 273.7,329.7 277.8,330.7 280.2,330.3 	" />
        </g>
        <g>
          <polygon class="st0" points="1401.1,431.8 1403.9,372.2 1216.3,351.2 1213.6,403.8 		" />
          <line class="st0" x1="1393.1" y1="371.3" x2="1390.1" y2="430.1" />
          <line class="st0" x1="1229.4" y1="352.4" x2="1226.5" y2="405.7" />
          <polygon
            class="st0"
            points="1406.3,370.4 1403.3,432.7 1400.9,434.9 1401.1,431.8 1403.9,372.2 1216.3,351.2 1219.4,349.4 		"
          />
          <line class="st0" x1="1403.9" y1="372.2" x2="1406.3" y2="370.4" />
          <line class="st0" x1="1393.1" y1="371.3" x2="1395.7" y2="369.3" />
          <line class="st0" x1="1229.4" y1="352.6" x2="1232.3" y2="350.8" />
        </g>
        <line class="st0" x1="1229.2" y1="356.5" x2="1392.9" y2="374.8" />
        <line class="st0" x1="1229" y1="360.1" x2="1392.7" y2="378.5" />
        <line class="st0" x1="1228.8" y1="363.7" x2="1392.4" y2="382.5" />
        <line class="st0" x1="1228.6" y1="367.2" x2="1392.2" y2="387" />
        <line class="st0" x1="1228.4" y1="370.8" x2="1392" y2="391.3" />
        <line class="st0" x1="1228.2" y1="374.4" x2="1391.6" y2="395.7" />
        <line class="st0" x1="1228" y1="377.9" x2="1391.4" y2="400.2" />
        <line class="st0" x1="1227.8" y1="381.5" x2="1391.2" y2="404.8" />
        <line class="st0" x1="1227.6" y1="385" x2="1391.2" y2="409" />
        <line class="st0" x1="1227.4" y1="388.6" x2="1391" y2="412.8" />
        <line class="st0" x1="1227.2" y1="392.2" x2="1390.8" y2="416.5" />
        <line class="st0" x1="1227" y1="395.7" x2="1390.6" y2="420.3" />
        <line class="st0" x1="1226.8" y1="399.3" x2="1390.4" y2="424.3" />
        <line class="st0" x1="1226.6" y1="402.8" x2="1390.2" y2="428.5" />
        <polygon
          class="st0"
          points="1299.9,360.6 1304,358.9 1315.2,360.1 1311.6,361.8 1308.3,417.9 1296.6,416.2 	"
        />
        <line class="st0" x1="1299.9" y1="360.6" x2="1311.6" y2="361.8" />
      </g>
      <g id="KK-1-15" class="hover-obj keid kejd" @click="$store.commit('setModal', '1.15')">
        <g id="_x31_-15_x5F_halvmur_x5F_2">
          <polygon class="st0" points="1618.9,360.4 1611.7,429.9 1614.4,421.1 1621.2,354.4 		" />
          <path
            class="st2"
            d="M1590.6,385.9c-0.6-2.8-5-3.7-9.3-3.7c-3.5,0-5.2-1.6-6.9-3.2c0.1,0,0.3,0.1,0.4,0.1c0.2,0,0.3,0.1,0.5,0.1
			c0.3,0.1,0.7,0.1,1,0.1c0.1-0.2,0.1-0.6,0.1-1.1c0-0.4,0-0.8,0.1-1.2c0-0.4,0.1-0.8,0.1-1.2c0,0.2,0,0,0-0.6c0-0.4,0-0.7,0-0.8
			c0-0.1-0.7,0-1.8,0.1c-0.5,0-0.8,0.1-1,0.1c-0.5,0-0.8,0-1,0.1c-0.6,0-1.3,0.1-2.1,0.2c-0.5,0.1-1.2,0.1-2.1,0.2
			c-0.4,0-0.7,0.1-1,0.1c-0.3,0-0.7,0.1-1,0.1l-1.5,0.2c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0-0.3,0c-0.2,0.2-0.3,0.5-0.4,1
			c-1.2-0.2-2.5-0.4-3.9-0.6c0,0,0-0.1,0-0.1c0-0.1,0-0.2,0-0.3c0-0.4,0-0.5,0-0.6l0-1c0-0.4,0-0.8,0-1.3c0-1-0.1-1.7-0.2-2.1
			c-0.1-0.3-0.4-0.4-0.8-0.4c-0.5,0.1-0.8,0.1-1,0.1c-1,0.2-2,0.3-3,0.5c-0.5,0.1-1,0.2-1.5,0.3s-1,0.2-1.5,0.3
			c-0.9,0.2-1.8,0.4-2.9,0.6c-1.2,0.3-2.2,0.5-2.8,0.8c-0.3,0.2-0.5,0.4-0.5,0.7c0,0,0,0,0,0c-2.9-0.5-5.2-0.9-6.6-1.2
			c-6.4-1.4-7,4.6-11.5,1.4c-2.1-1.5-8-2.2-14.3-2.6l-2.9-4.1l-0.2-0.2c0-0.1-0.1-0.1-0.1-0.2c-0.2-0.3-0.5-0.3-0.8,0
			c-0.2,0.2-0.8,0.8-1.6,1.8c-0.7,0.9-1.3,1.5-1.5,1.9c-0.1,0.1-0.2,0.3-0.4,0.5c-1,0-2.1-0.1-3.1-0.1c0-0.4,0-0.8-0.1-1.1
			c0-0.2,0-0.4,0-0.6c0-0.2,0-0.4,0-0.6c0-0.2,0-0.4,0-0.5c0-0.2,0-0.4,0-0.6c0,0,0-0.4-0.1-1.1c0-0.5-0.1-0.9-0.1-1.1
			c-0.2,0-0.9,0.1-2,0.3c-0.6,0.1-1,0.2-1.4,0.3c-0.4,0.1-0.6,0.1-0.7,0.2c-0.4,0.1-0.8,0.2-1.4,0.3s-1.1,0.3-1.7,0.4
			c-1.2,0.3-2.2,0.6-3,0.8c-0.5,0.2-0.8,0.3-0.9,0.4c-0.2,0.2-0.3,0.6-0.3,1.1c0,0.2,0,0.5,0,0.9c0,0.1,0,0.2,0,0.3
			c-4.3,0.2-4.8,2.3-5.9,2.7c-1.3,0.4-2.6-0.4-4.9-0.4c-2.2,0-3.8,3.8-4.9,6c-1.1,2.2,1.1,3.8,5.2,7.8c2.9,2.8,9,3.2,14.5,3.8
			c0.1,0.4,0.1,0.9,0.2,1.2l0.1,0.7l0.1,0.7c0.1,0.5,0.2,0.9,0.3,1.4c0.1,0.5,0.1,1,0.3,1.3c0.2,0.4,0.5,0.5,1,0.4
			c0.4-0.1,0.8-0.3,1.2-0.4c0.4-0.2,0.8-0.3,1.2-0.5c0.2-0.1,0.4-0.2,0.6-0.2c0.2-0.1,0.4-0.1,0.6-0.2c0.2-0.1,0.4-0.1,0.6-0.2
			c0.2-0.1,0.4-0.1,0.6-0.2c0.8-0.3,1.4-0.5,1.8-0.7c0.4-0.1,0.9-0.3,1.6-0.6c1.4,1,2.8,2.3,4.1,3.6c0,0.1,0,0.1,0,0.2
			c0,0.2,0,0.4-0.1,0.6c-0.1,0.5-0.1,0.9-0.1,1.3c0,0.4,0.2,0.6,0.7,0.4c0.4-0.1,0.9-0.2,1.5-0.3c0.1,0,0.1,0,0.2,0
			c2.7,2.8,5.2,5.4,7.4,5.7c0.9,0.1,1.7-0.2,2.4-0.7c0,0,0,0.1,0,0.1c0.1,0.8,0.2,1.4,0.2,1.6c0,0.1,0,0.3,0,0.4
			c0,0.1,0,0.3,0.1,0.4c0,0.5,0.1,0.7,0.3,0.7c0,0,0.5-0.1,1.4-0.4c0.1,0,0.2-0.1,0.3-0.1c0.1,0,0.2-0.1,0.4-0.1
			c0.3-0.1,0.5-0.2,0.7-0.2c0.3-0.1,0.6-0.2,0.8-0.3c0.2-0.1,0.4-0.1,0.6-0.2c0.1,0,0.6-0.2,1.5-0.5c0.9-0.3,1.3-0.3,1.4-0.1
			c0,0.1,0,0.5-0.1,1.3c-0.1,0.7-0.1,1.2-0.1,1.5c0.2,0,0.6,0,1.2-0.1c0.3,0,0.6-0.1,0.8-0.1c0.2,0,0.4,0,0.5-0.1
			c0.6,0,1.2-0.1,1.8-0.1c0.6,0,1.3-0.1,1.9-0.2c1.3-0.1,2.5-0.3,3.6-0.4c1.2-0.2,2.3-0.4,3.3-0.7c1-0.3,2-0.7,3.1-1.1
			c0.5-0.2,1.2-0.6,1.9-0.9c0.3-0.2,0.5-0.4,0.7-0.6c1.9-0.4,3.7-0.7,5.4-0.9c4.9-0.7,5.3,1.9,10.8,3.9c5.6,2.1,13.3-7.4,18.1-5.4
			c5.8,3.1,14-2.3,15.9-7.2C1600,388.8,1591.3,388.7,1590.6,385.9z"
          />
          <path
            class="st0"
            d="M1495.7,343.6c-1.2,2-0.9,1.2-6.2,5.1c-0.1,0.1-0.2,0.1-0.3,0.2l129.7,11.5l2.3-6L1495.7,343.6z"
          />
          <path
            class="st0"
            d="M1364.7,332.3l-146.9-12.7l-0.3,5.2l148.8,13.2C1365.5,335.7,1365,333.6,1364.7,332.3z"
          />
          <g>
            <path
              class="st0"
              d="M1438.9,359.7c-1.3,0-3.6,0.6-8-1.5c-1.4-0.7-2.8-1.6-4.1-2.6c0.6,6.3,1.2,12.7,1.6,18.1
				c3.2-3.6,7.5-9.1,11.2-14C1439.3,359.8,1439.1,359.7,1438.9,359.7z"
            />
            <path
              class="st0"
              d="M1489.2,348.9c-5.6,4-9.7,5.5-11.4,5.7c-1.8,0.2-4.3,2.1-8.2,3.9c-3.9,1.8-8.6,2.2-8.6,2.2s-3.3-0.4-4.5,0.1
				c-2.5,0.9-4.4,0.9-8.3,0.6c-2.1-0.2-4.2-0.6-5.9-1c-8.4,12.6-12.7,28.3-12.7,28.3c0.1,4.9,0.1,11.8,0.1,17.7l182.2,23.5l7.2-69.5
				L1489.2,348.9z M1598,393.6c-2,4.9-10.2,10.2-15.9,7.2c-4.8-2-12.5,7.4-18.1,5.4c-5.6-2.1-6-4.6-10.8-3.9
				c-1.7,0.2-3.5,0.5-5.4,0.9c-0.2,0.2-0.5,0.4-0.7,0.6c-0.7,0.4-1.3,0.7-1.9,0.9c-1.1,0.5-2.1,0.9-3.1,1.1c-1,0.3-2.1,0.5-3.3,0.7
				c-1.2,0.2-2.4,0.3-3.6,0.4c-0.6,0.1-1.3,0.1-1.9,0.2c-0.6,0-1.2,0.1-1.8,0.1c-0.1,0-0.3,0-0.5,0.1c-0.2,0-0.5,0-0.8,0.1
				c-0.6,0.1-1,0.1-1.2,0.1c0-0.3,0-0.8,0.1-1.5c0.1-0.8,0.1-1.2,0.1-1.3c0-0.2-0.5-0.2-1.4,0.1c-0.9,0.3-1.4,0.5-1.5,0.5
				c-0.2,0-0.3,0.1-0.6,0.2c-0.2,0.1-0.5,0.2-0.8,0.3c-0.2,0.1-0.4,0.1-0.7,0.2c-0.1,0-0.2,0.1-0.4,0.1c-0.1,0-0.2,0.1-0.3,0.1
				c-0.9,0.3-1.4,0.4-1.4,0.4c-0.1,0-0.2-0.2-0.3-0.7c0-0.1,0-0.2-0.1-0.4c0-0.1,0-0.3,0-0.4c-0.1-0.3-0.1-0.8-0.2-1.6
				c0,0,0-0.1,0-0.1c-0.7,0.5-1.5,0.8-2.4,0.7c-2.1-0.3-4.6-2.9-7.4-5.7c-0.1,0-0.1,0-0.2,0c-0.6,0.1-1.1,0.2-1.5,0.3
				c-0.5,0.1-0.7,0-0.7-0.4c0-0.4,0-0.8,0.1-1.3c0-0.2,0-0.4,0.1-0.6c0-0.1,0-0.1,0-0.2c-1.3-1.3-2.7-2.6-4.1-3.6
				c-0.7,0.3-1.2,0.4-1.6,0.6c-0.4,0.1-1,0.4-1.8,0.7c-0.2,0.1-0.4,0.2-0.6,0.2c-0.2,0.1-0.4,0.1-0.6,0.2c-0.2,0.1-0.4,0.2-0.6,0.2
				c-0.2,0.1-0.4,0.1-0.6,0.2c-0.4,0.1-0.8,0.3-1.2,0.5c-0.4,0.2-0.8,0.3-1.2,0.4c-0.5,0.2-0.9,0.1-1-0.4c-0.1-0.3-0.2-0.8-0.3-1.3
				c-0.1-0.4-0.2-0.9-0.3-1.4l-0.1-0.7l-0.1-0.7c-0.1-0.4-0.1-0.8-0.2-1.2c-5.5-0.5-11.7-1-14.5-3.8c-4.1-4.1-6.3-5.7-5.2-7.8
				c1.1-2.2,2.7-6,4.9-6c2.2,0,3.6,0.8,4.9,0.4c1.1-0.4,1.6-2.5,5.9-2.7c0-0.1,0-0.2,0-0.3c0-0.4,0-0.7,0-0.9c0-0.5,0.1-0.9,0.3-1.1
				c0.1-0.1,0.4-0.2,0.9-0.4c0.8-0.3,1.8-0.5,3-0.8c0.6-0.1,1.2-0.3,1.7-0.4s1-0.2,1.4-0.3c0.1,0,0.4-0.1,0.7-0.2
				c0.4-0.1,0.8-0.2,1.4-0.3c1.1-0.3,1.8-0.4,2-0.3c0,0.2,0.1,0.6,0.1,1.1c0,0.7,0.1,1.1,0.1,1.1c0,0.2,0,0.4,0,0.6
				c0,0.2,0,0.4,0,0.5c0,0.2,0,0.4,0,0.6c0,0.2,0,0.4,0,0.6c0,0.3,0,0.7,0.1,1.1c1,0,2,0.1,3.1,0.1c0.1-0.2,0.3-0.3,0.4-0.5
				c0.3-0.4,0.8-1,1.5-1.9c0.8-1,1.3-1.6,1.6-1.8c0.3-0.3,0.6-0.3,0.8,0c0,0.1,0.1,0.1,0.1,0.2l0.2,0.2l2.9,4.1
				c6.4,0.4,12.2,1.2,14.3,2.6c4.5,3.2,5.1-2.8,11.5-1.4c1.4,0.3,3.7,0.7,6.6,1.2c0,0,0,0,0,0c0-0.3,0.2-0.5,0.5-0.7
				c0.6-0.3,1.5-0.6,2.8-0.8c1.1-0.2,2-0.4,2.9-0.6c0.5-0.1,1-0.2,1.5-0.3s1-0.2,1.5-0.3c1-0.2,2-0.4,3-0.5c0.2,0,0.6-0.1,1-0.1
				c0.4,0,0.7,0.1,0.8,0.4c0.2,0.4,0.2,1.1,0.2,2.1c0,0.5,0,0.9,0,1.3l0,1c0,0,0,0.2,0,0.6c0,0.1,0,0.2,0,0.3c0,0,0,0.1,0,0.1
				c1.3,0.2,2.6,0.4,3.9,0.6c0.1-0.5,0.3-0.9,0.4-1c0.1,0,0.2,0,0.3,0c0.1,0,0.2,0,0.3,0l1.5-0.2c0.3,0,0.7-0.1,1-0.1
				c0.3,0,0.7-0.1,1-0.1c0.9,0,1.6-0.1,2.1-0.2c0.8-0.1,1.5-0.1,2.1-0.2c0.2,0,0.6,0,1-0.1c0.2,0,0.6-0.1,1-0.1
				c1.2-0.1,1.8-0.1,1.8-0.1c0,0.1,0,0.3,0,0.8c0,0.5,0,0.7,0,0.6c0,0.4-0.1,0.8-0.1,1.2c-0.1,0.3-0.1,0.7-0.1,1.2
				c0,0.6-0.1,0.9-0.1,1.1c-0.3,0-0.6-0.1-1-0.1c-0.2,0-0.3-0.1-0.5-0.1c-0.2,0-0.3-0.1-0.4-0.1c1.7,1.6,3.4,3.2,6.9,3.2
				c4.3,0,8.7,0.9,9.3,3.7C1591.3,388.7,1600,388.8,1598,393.6z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M1420.3,349.2c-1.3-1.3-3.2-2.9-3.9-3.1c-3-0.7-5.6-0.6-7.6-0.2l11.7,8.4
				C1420.4,352.6,1420.4,350.9,1420.3,349.2z"
            />
            <path
              class="st0"
              d="M1404.8,347C1404.8,347.1,1404.7,347.1,1404.8,347c-0.1,0-5.5,2.9-9.7,3.8c-4.2,0.8-3.1,1.1-6.6,2.3
				c-3.1,1-4.2,1.8-5.9,1.6c-10.4-1.8-13.5-8.5-15.3-13.7c-0.4-1-0.7-2-1-3l-148.7-13.2l-1.2,26.3l3.1-1.8l186.9,21l-1.6,32.8
				l14,1.8c1.2-12.8,2.7-31.4,2.1-43.1C1416.9,357.3,1409.3,350.8,1404.8,347z"
            />
          </g>
        </g>
        <g id="_x31_-15_x5F_halvmur_x5F_1">
          <polygon
            class="st0"
            points="474.5,292.1 475.8,319.3 467,325.4 476.2,327.6 476.7,336.6 368.7,350.1 353.3,344.6 341.9,353.5 
			303.7,358.3 299.6,312.7 		"
          />
          <polygon
            class="st0"
            points="303.7,358.3 301.6,356.4 298.2,321.7 296.9,320.9 296.2,321 295.4,310.8 299.1,312.8 299.6,312.7 		
			"
          />
          <polygon class="st0" points="294,310 299.1,312.8 298.8,310 293.8,307.3 		" />
          <polygon class="st0" points="293.8,307.3 474.2,285.7 474.4,289.9 298.8,310 		" />
          <polygon class="st0" points="474.5,292.1 474.4,289.9 298.8,310 299.1,312.8 		" />
          <g>
            <polygon class="st0" points="331.8,321.1 319.5,322.5 319.9,326.7 332.2,325.3 			" />
            <polygon class="st0" points="358.4,322.4 346.1,323.9 346.6,328.1 358.8,326.7 			" />
            <polygon class="st0" points="332.8,321 333.2,325.3 345,323.9 344.6,319.7 			" />
          </g>
          <g>
            <polygon class="st0" points="409.3,340.8 397,342.3 397.4,346.5 409.7,345 			" />
            <polygon class="st0" points="383.6,339.4 384,343.6 395.8,342.3 395.4,338 			" />
          </g>
          <g>
            <polygon class="st0" points="452.4,326.9 451.9,322.3 439.6,323.8 440.1,328.3 			" />
            <polygon class="st0" points="453.8,326.9 454.2,331.1 465.9,329.7 465.5,325.5 			" />
          </g>
          <g>
            <g>
              <polygon class="st0" points="421,308.8 408.8,310.2 409.2,314.4 421.4,313 				" />
            </g>
            <polygon class="st0" points="422,304.3 422.5,308.8 434.2,307.4 433.8,302.9 			" />
          </g>
        </g>
        <g id="_x31_-15_x5F_terasse_2_">
          <polygon class="st1" points="971.5,518.6 971.4,523.6 1225.9,601 1225.9,595.3 		" />
          <polygon
            class="st1"
            points="1400.9,434.9 1401.1,431.8 1213.6,403.7 971.5,518.6 1225.9,595.3 1401.1,431.8 1225.9,595.3 
			1225.9,601 		"
          />
          <line class="st1" x1="1210.6" y1="590.6" x2="1210.6" y2="596.3" />
          <line class="st1" x1="1390.1" y1="430.1" x2="1210.6" y2="590.6" />
          <line class="st1" x1="988.1" y1="523.6" x2="988.1" y2="528.7" />
          <line class="st1" x1="1110.2" y1="462.4" x2="988.1" y2="523.6" />
          <line class="st1" x1="1224" y1="405.3" x2="1190.9" y2="421.9" />
          <line class="st1" x1="1007.7" y1="529.5" x2="1007.7" y2="534.7" />
          <line class="st1" x1="1110.2" y1="475.4" x2="1007.7" y2="529.5" />
          <line class="st1" x1="1123.5" y1="468.3" x2="1114" y2="473.4" />
          <line class="st1" x1="1128.1" y1="465.9" x2="1127.3" y2="466.3" />
          <line class="st1" x1="1238.7" y1="407.5" x2="1206.3" y2="424.6" />
          <line class="st1" x1="1028.9" y1="535.9" x2="1028.9" y2="541.1" />
          <line class="st1" x1="1124.7" y1="481.7" x2="1028.9" y2="535.9" />
          <line class="st1" x1="1150.6" y1="467" x2="1127.3" y2="480.2" />
          <line class="st1" x1="1165.4" y1="458.6" x2="1154.4" y2="464.9" />
          <line class="st1" x1="1252.2" y1="409.5" x2="1221" y2="427.2" />
          <line class="st1" x1="1050.1" y1="542.3" x2="1050.1" y2="547.5" />
          <line class="st1" x1="1150.6" y1="481" x2="1050.1" y2="542.3" />
          <line class="st1" x1="1180" y1="463.1" x2="1154.4" y2="478.7" />
          <line class="st1" x1="1264.8" y1="411.4" x2="1234.9" y2="429.6" />
          <line class="st1" x1="1192.8" y1="585.3" x2="1192.8" y2="590.9" />
          <line class="st1" x1="1375.1" y1="427.9" x2="1192.8" y2="585.3" />
          <line class="st1" x1="1175.7" y1="580.1" x2="1175.7" y2="585.7" />
          <line class="st1" x1="1360.4" y1="425.7" x2="1175.7" y2="580.1" />
          <line class="st1" x1="1071.4" y1="548.7" x2="1071.4" y2="554" />
          <line class="st1" x1="1180" y1="478.2" x2="1071.4" y2="548.7" />
          <line class="st1" x1="1222.2" y1="450.7" x2="1183.7" y2="475.7" />
          <line class="st1" x1="1279.3" y1="413.6" x2="1226" y2="448.3" />
          <line class="st1" x1="1092.4" y1="555" x2="1092.4" y2="560.4" />
          <line class="st1" x1="1181.3" y1="493.3" x2="1092.4" y2="555" />
          <line class="st1" x1="1203.4" y1="477.9" x2="1183.7" y2="491.6" />
          <line class="st1" x1="1293.1" y1="415.6" x2="1250.4" y2="445.3" />
          <line class="st1" x1="1115" y1="561.8" x2="1115" y2="567.3" />
          <line class="st1" x1="1208.2" y1="491.9" x2="1115" y2="561.8" />
          <line class="st1" x1="1218.3" y1="484.3" x2="1212" y2="489.1" />
          <line class="st1" x1="1307" y1="417.8" x2="1266.2" y2="448.4" />
          <line class="st1" x1="1137" y1="568.5" x2="1137" y2="574" />
          <line class="st1" x1="1221.5" y1="501.6" x2="1137" y2="568.5" />
          <line class="st1" x1="1258.8" y1="472.1" x2="1225.3" y2="498.6" />
          <line class="st1" x1="1324.2" y1="420.3" x2="1262.8" y2="468.9" />
          <line class="st1" x1="1159" y1="575.1" x2="1159" y2="580.6" />
          <line class="st1" x1="1341.2" y1="422.8" x2="1159" y2="575.1" />
        </g>
        <polyline
          id="_x31_-15_x5F_sti"
          class="st0"
          points="862.6,582.8 550.7,456.8 661.6,428.4 636.6,420.7 473.4,457.8 862.6,635.2 	
		"
        />
      </g>
      <g id="KK-1-14" class="hover-obj lejer" @click="$store.commit('setModal', '1.14')">
        <g id="_x31_-14_x5F_legeredskaber">
          <polygon class="st0" points="177.5,447.9 177.5,450.2 179.6,452.3 179.5,450.1 		" />
          <polygon class="st0" points="179.6,452.3 212.7,447.4 212.5,445.2 179.5,450.1 		" />
          <polygon class="st0" points="177.5,447.9 210.4,443.3 212.5,445.2 179.5,450.1 		" />
          <line class="st1" x1="176.3" y1="396.6" x2="179.2" y2="448.1" />
          <line class="st1" x1="180.8" y1="449.2" x2="176.4" y2="396.6" />
          <line class="st1" x1="203.9" y1="393" x2="209.2" y2="444" />
          <line class="st1" x1="211" y1="444.9" x2="203.9" y2="393" />
          <path
            class="st0"
            d="M243.8,384.8l-108.2,13.7c-0.2,0-0.4,0.8-0.3,1.7c0,0.9,0.3,1.7,0.5,1.7l108.2-14c0.2,0,0.4-0.5,0.3-1.4
			C244.3,385.5,244,384.8,243.8,384.8z"
          />

          <ellipse
            transform="matrix(0.9989 -4.738662e-02 4.738662e-02 0.9989 -18.8097 6.877)"
            class="st0"
            cx="135.6"
            cy="400.2"
            rx="0.4"
            ry="1.7"
          />
          <path
            class="st0"
            d="M136.5,401.8l-3.8,87.6c0,0.3,0.9,0.5,2.1,0.5c1.2,0,2.1-0.2,2.1-0.5l2.9-88h-0.1L136.5,401.8z"
          />
          <path
            class="st0"
            d="M243.9,387.9l-3.2,0.4l23.7,78.9c0,0.3,0.9,0.5,2.1,0.5c1.2,0,2.1-0.2,2.1-0.5L243.9,387.9z"
          />
          <path
            class="st0"
            d="M240.6,388.3l-0.3,0l12.8,70.4c0,0.3,0.8,0.5,1.9,0.5c1,0,1.9-0.2,1.9-0.5l-9.6-48.3L240.6,388.3z"
          />
          <path
            class="st0"
            d="M136.5,401.8l2.4-0.3l0,0L136.5,401.8l-10.1,77.3c0,0.3,0.8,0.5,1.9,0.5c1,0,1.9-0.2,1.9-0.5l4.8-42.5
			L136.5,401.8z"
          />
        </g>
        <g id="_x31_-14_x5F_borde-og-baenke_1_">
          <polygon class="st0" points="1208.2,481.9 1208.2,498.5 1210.6,499 1210.6,482.4 		" />
          <polygon class="st0" points="1221.5,485.1 1221.5,501.7 1223.9,502.3 1223.9,485.7 		" />
          <g>
            <polygon class="st0" points="1261.1,459.7 1261.1,473.5 1262.8,472.2 1262.8,458.4 			" />
            <polygon class="st0" points="1258.8,461.5 1258.8,472.9 1261.1,473.5 1261.1,459.7 			" />
          </g>
          <polygon class="st0" points="1223.9,485.7 1223.9,502.3 1225.3,501.1 1225.3,486 		" />
          <polygon class="st0" points="1210.6,482.4 1210.6,499 1212,497.9 1212,482.8 		" />
          <polygon class="st0" points="1110.2,461.7 1110.2,478.3 1112.6,478.8 1112.6,462.2 		" />
          <polygon class="st0" points="1123.5,464.8 1123.5,481.4 1125.9,482 1125.9,465.4 		" />
          <polygon class="st0" points="1125.9,465.4 1125.9,482 1127.3,481.2 1127.3,465.7 		" />
          <polygon class="st0" points="1112.6,462.2 1112.6,478.8 1114,478.1 1114,462.6 		" />
          <polygon class="st0" points="1150.6,455.3 1150.6,486.8 1152.9,487.3 1152.9,455.8 		" />
          <polygon class="st0" points="1152.9,455.8 1152.9,487.3 1154.4,486.3 1154.4,456.1 		" />
          <polygon class="st0" points="1180,461.9 1180,493 1182.3,493.5 1182.3,462.4 		" />
          <polygon class="st0" points="1182.3,462.4 1182.3,493.5 1183.7,492.9 1183.7,462.7 		" />
          <polygon class="st0" points="1222.2,437.7 1222.2,468.8 1224.5,469.3 1224.5,438.2 		" />
          <polygon class="st0" points="1224.5,438.2 1224.5,469.3 1226,468.8 1226,438.5 		" />
          <polygon class="st0" points="1129.2,462.1 1129.1,466.1 1108.3,461.2 1108.6,457.6 		" />
          <polygon
            class="st0"
            points="1145.3,454.1 1135.1,451.8 1135.3,446.9 1165.7,432.2 1162.8,431.6 1108.6,457.6 1129.2,462.1 		"
          />
          <polygon
            class="st0"
            points="1145.3,454.1 1145.3,454.1 1129.2,462.1 1129.1,466.1 1150.1,455.2 		"
          />
          <polygon class="st0" points="1196.8,460.1 1196.6,465.7 1135.1,451.8 1135.3,446.9 		" />
          <polygon class="st0" points="1196.8,460.1 1243.2,431.1 1243,435.6 1196.6,465.7 		" />
          <polygon class="st0" points="1243.2,431.1 1196.8,460.1 1135.3,446.9 1187.9,421.4 		" />
          <polygon class="st0" points="1203.4,476.2 1203.4,480.6 1226.7,486.3 1226.7,481.9 		" />
          <polygon class="st0" points="1226.7,481.9 1269.4,448.9 1269.4,453.3 1226.7,486.3 		" />
          <polygon class="st0" points="1203.4,476.2 1226.7,481.9 1269.4,448.9 1248.8,445 		" />
        </g>
      </g>
      <g id="KK-1-13" class="hover-obj lejer" @click="$store.commit('setModal', '1.13')">
        <g id="_x31_-13_x5F_sandkasse">
          <polygon class="st0" points="188.4,545.9 309.1,519.5 283.4,498.7 171.7,520.7 		" />
          <polygon
            class="st0"
            points="171.7,520.7 171.7,524 188.9,549.8 309.1,522.8 309.1,519.5 188.4,545.9 		"
          />
          <polygon class="st0" points="193.4,548.8 198.5,556.3 299.7,533.1 299.1,525.1 		" />
          <line class="st0" x1="198.5" y1="556.3" x2="197.8" y2="547.8" />
          <polygon class="st0" points="203.7,538.3 190.4,520.5 272.7,504.1 290.6,519.5 		" />
          <polygon class="st0" points="272.7,504.1 273.4,514.3 281.5,521.5 290.6,519.5 		" />
          <polygon class="st0" points="190.4,520.5 272.7,504.1 273.4,514.3 197.5,530 		" />
          <polygon class="st0" points="197.5,530 203.7,538.3 281.5,521.5 273.4,514.3 		" />
          <path
            class="st0"
            d="M195.3,527.1c1.6-1.6,7-2.6,10.5-2s11.1,1.5,17.8-2c3.8-2,13.3-8.8,19.6-8.8c6.3,0,15.7,0.7,19,0
			c3.4-0.7,6.8-4.1,10.5-4.1c3,0,8.9,1.7,8.9,5.2c0,3.2,3.6,5.3,3.6,5.3l-81.6,17.7L195.3,527.1z"
          />
        </g>
        <g id="_x31_-13_x5F_faldunderlag">
          <g>
            <path
              class="st0"
              d="M266.9,461.9l1.6,5.2c0,0.3-0.9,0.5-2.1,0.5c-1.2,0-2.1-0.2-2.1-0.5l-2.8-9.2l-5.6-4.1l1,5
				c0,0.3-0.8,0.5-1.9,0.5c-1,0-1.9-0.2-1.9-0.5l-1.5-8.1l-4.4-3.2l-109.6,16.5l-0.8,25.4c0,0.3-0.9,0.5-2.1,0.5
				c-1.2,0-2.1-0.2-2.1-0.5l1.1-24.8l-1.9,0.3l-1.6,14.2c0,0.3-0.8,0.5-1.9,0.5c-1,0-1.9-0.2-1.9-0.5l1.8-13.7l-13.1,2l24.4,40
				l150.7-28.2L266.9,461.9z"
            />
            <line class="st0" x1="132" y1="495.1" x2="275.7" y2="468.4" />
            <line class="st0" x1="137.2" y1="477.3" x2="252.9" y2="457.4" />
            <line class="st0" x1="130.2" y1="478.5" x2="133.1" y2="478" />
            <line class="st0" x1="122.6" y1="479.8" x2="126.4" y2="479.1" />
            <line class="st0" x1="137.7" y1="465" x2="163.1" y2="502.9" />
            <line class="st0" x1="229.8" y1="450" x2="269" y2="483.1" />
            <line class="st0" x1="208" y1="453.3" x2="242.1" y2="488.1" />
            <line class="st0" x1="184.9" y1="456.8" x2="213.9" y2="493.4" />
            <line class="st0" x1="162" y1="460.3" x2="188.4" y2="498.2" />
            <g>
              <polygon class="st0" points="133.7,464.5 133.7,463.7 131.9,464 131.8,464.8 				" />
              <polygon
                class="st0"
                points="251.7,450.6 251.2,448.1 248.8,446.4 137.7,463.1 137.7,463.9 247.3,447.4 				"
              />
              <polygon
                class="st0"
                points="266,459.1 266.9,461.9 290.2,479.2 139.4,507.3 115,467.4 128.2,465.4 128.3,464.6 112,467 
					137.6,509 293.9,479.8 				"
              />
              <polygon class="st0" points="260.7,455.2 255.5,451.3 256,453.8 261.6,458 				" />
            </g>
          </g>
        </g>
        <g id="_x31_-13_x5F_legehus_1_">
          <polygon class="st0" points="312.9,387.7 343.8,400.4 347.5,449.5 316.4,431.2 		" />
          <polygon
            class="st0"
            points="304.2,382.6 304.8,384.3 355.7,405.2 406.1,365.4 449.9,383.6 450.4,382.2 406.1,363.4 355.2,403.5 
					"
          />
          <polygon class="st0" points="353.3,344.6 406.1,363.4 355.2,403.5 304.2,382.6 		" />
          <polygon
            class="st0"
            points="435.3,377.5 437.4,411.4 434.3,411.7 435.9,430.9 409.7,435.4 407,393.1 380.6,396.2 383.6,439.8 
			375.4,441.1 375.4,440.8 349,426.6 345.8,427.3 343.8,400.4 355.7,405.2 406.1,365.4 		"
          />
          <polygon
            class="st0"
            points="347.2,445.9 373.5,461.3 468,443.1 468,446.9 373.4,465 347.5,449.5 		"
          />
          <polygon class="st0" points="383.6,439.8 380.6,396.2 407,393.1 409.7,435.4 		" />
          <polygon class="st0" points="345.8,427.3 349,426.6 375.4,440.8 372.3,441.3 		" />
          <polygon class="st0" points="346,430.5 345.8,427.3 372.3,441.3 372.5,444.9 		" />
          <polygon class="st0" points="351.8,433.7 352,437.2 362.6,443.3 367.9,442.4 		" />
          <polygon class="st0" points="375.4,440.8 372.3,441.3 372.5,444.9 375.6,444.4 		" />
          <polygon class="st0" points="373.5,461.3 372.5,444.9 375.6,444.4 376.4,460.7 		" />
          <polygon class="st0" points="369.8,443.5 372.5,444.9 373.5,461.3 370.9,459.8 		" />
          <polygon class="st0" points="346,430.5 347.2,445.9 349.7,447.4 348.8,432 		" />
          <polygon class="st0" points="351.8,433.7 352,437.2 349.1,438.1 348.8,432 		" />
          <polygon class="st0" points="349.7,447.4 352.7,446.8 352.4,442.9 349.3,441.2 		" />
          <polygon class="st0" points="352,437.2 370.1,447.6 370.3,450.3 349.1,438.1 		" />
          <polygon class="st0" points="349.3,441.2 349.1,438.1 370.3,450.3 370.5,453.5 		" />
          <polygon class="st0" points="352.6,445 355.1,444.5 352.4,442.9 		" />
          <polygon
            class="st0"
            points="349.7,447.4 352.7,446.8 352.6,445 355.1,444.5 370.5,453.5 370.9,459.8 		"
          />
          <polygon class="st0" points="362.6,443.3 367.9,442.4 369.8,443.5 370.1,447.6 		" />
          <polygon
            class="st0"
            points="375.4,441.1 376.4,460.7 465,443.7 462.2,442.6 462.1,440.7 441.6,431.7 438.5,432.4 435.9,430.9 		
			"
          />
          <polygon class="st0" points="437.3,416.4 434.6,415.2 435.9,430.9 438.5,432.4 		" />
          <polygon class="st0" points="440.7,422.2 437.8,423.1 437.3,416.4 440.3,417.7 		" />
          <polygon class="st0" points="438.5,432.4 441.6,431.7 441.2,427.6 438,426.1 		" />
          <polygon class="st0" points="461.7,427.1 464.5,428.4 465,443.7 462.2,442.6 		" />
          <polygon class="st0" points="464.3,425.1 465,443.7 468,443.1 467.4,424.6 		" />
          <polygon class="st0" points="434.3,411.7 464.3,425.1 467.4,424.6 437.4,411.4 		" />
          <polygon class="st0" points="434.6,415.2 464.5,428.4 464.3,425.1 434.3,411.7 		" />
          <polygon class="st0" points="440.7,422.2 461.8,431.4 461.9,433.7 437.8,423.1 		" />
          <polygon class="st0" points="438,426.1 462,436.9 461.9,433.7 437.8,423.1 		" />
          <polygon class="st0" points="402.5,418.4 402.6,419.2 405.9,418.7 405.8,417.9 		" />
          <path
            class="st0"
            d="M394.3,408.8c-1.8-0.2-3.3-1.8-3.5-3.9c-0.3-2.4,1.2-4.5,3.3-4.7s4,1.5,4.3,3.9s-1.2,4.5-3.3,4.7
			C394.8,408.8,394.6,408.8,394.3,408.8"
          />
          <g>
            <path
              class="st0"
              d="M398.4,404.1c-0.3-2.4-2.2-4.1-4.3-3.9c0,0,0,0,0,0c1.8,0.2,3.3,1.8,3.5,3.9c0.3,2.4-1.2,4.5-3.3,4.7
				c0,0,0,0,0,0c0.3,0,0.5,0,0.8,0C397.2,408.6,398.7,406.5,398.4,404.1z"
            />
          </g>
        </g>
      </g>
      <g id="KK-1-11" class="hover-obj keid kejd" @click="$store.commit('setModal', '1.11')">
        <g id="_x31_-11_x5F_ventilationshaette_x5F_3">
          <path
            class="st0"
            d="M837.2,70.5c5.8,0,10.5,0.3,11.2,0.9l-10.9-15.8L826,71.8C826.8,71.2,831.5,70.5,837.2,70.5z"
          />
          <path
            class="st0"
            d="M848.5,71.6c0,0.7-6.1,2-11.3,2c-6.2,0-11.3-0.9-11.3-1.6s5.1-1.5,11.3-1.5C843.4,70.5,848.5,70.9,848.5,71.6
			z"
          />
          <path
            class="st0"
            d="M829.4,72.4c0,0,2.1-1,7.9-1s7.9,1.2,7.9,1.2l-0.7,36.5c0,3.4-6.2,8.3-12,8.3H831c-1.5,0-2.6-1.2-2.6-2.7
			L829.4,72.4z"
          />
        </g>
        <g id="_x31_-11_x5F_ventilationshaette_x5F_2">
          <path
            class="st0"
            d="M695.1,83.6c4.5,0,8.1,0.3,8.7,0.7L695.3,72l-8.9,12.5C687.1,84.1,690.7,83.6,695.1,83.6z"
          />
          <path
            class="st0"
            d="M703.9,84.4c0,0.5-4.7,1.5-8.8,1.5c-4.8,0-8.8-0.7-8.8-1.2c0-0.5,3.9-1.1,8.8-1.1S703.9,83.9,703.9,84.4z"
          />
          <path
            class="st0"
            d="M689.1,85c0,0,1.6-0.8,6.2-0.8s6.1,0.9,6.1,0.9l-0.5,28.3c0,2.6-4.8,6.4-9.3,6.4h-1.2c-1.1,0-2-0.9-2-2.1
			L689.1,85z"
          />
        </g>
        <g id="_x31_-11_x5F_ventilationshaette_x5F_1">
          <path
            class="st0"
            d="M1023.7,47.4c6.9,0,12.6,0.4,13.5,1.1l-13.2-19L1010.2,49C1011.2,48.2,1016.8,47.4,1023.7,47.4z"
          />
          <path
            class="st0"
            d="M1037.4,48.7c0,0.8-7.3,2.4-13.6,2.4c-7.5,0-13.6-1.1-13.6-1.9c0-0.8,6.1-1.8,13.6-1.8
			S1037.4,47.9,1037.4,48.7z"
          />
          <path
            class="st0"
            d="M1014.4,49.6c0,0,2.5-1.2,9.6-1.2s9.5,1.4,9.5,1.4l-0.8,44c0,4.1-7.5,10-14.5,10h-1.9c-1.8,0-3.2-1.4-3.1-3.2
			L1014.4,49.6z"
          />
        </g>
      </g>
      <g id="KK-1-10" class="hover-obj keid kejd" @click="$store.commit('setModal', '1.10')">
        <polygon class="st0" points="609.4,71.2 625.9,68.5 627.2,118.8 610.9,120.2 	" />
        <polygon class="st0" points="627.2,118.8 650.6,101.9 649.7,71.3 625.9,68.5 	" />
      </g>
      <g id="KK-1-8" class="hover-obj keid kejd" @click="$store.commit('setModal', '1.8')">
        <path
          class="st0"
          d="M654.4,322.7c0,0,0.7-7.1-5.4-10.3c-5.7-0.3-9.9,4.6-9.7,9.8C643.4,324.4,654.4,322.7,654.4,322.7z"
        />
        <path
          class="st0"
          d="M651.3,323.1c-1.6,0.2-3.7,0.3-5.8,0.3l0,0c0,0,0.8,2,3.3,2C651.3,325.3,651.3,323.1,651.3,323.1L651.3,323.1z
		"
        />
      </g>
      <g id="KK-1-7_1_" class="hover-obj lejer" @click="$store.commit('setModal', '1.7')">
        <g id="_x31_-7_x5F_kodesystem">
          <polygon
            class="st0"
            points="667.3,376.2 668.2,375.6 675,377.1 674.9,389.5 674.1,390.1 674.1,377.7 		"
          />
          <path
            class="st0"
            d="M667.3,376.2l0.2,12.1l6.6,1.8v-12.4L667.3,376.2z M669.4,387.5c-0.5,0-1-0.5-1-1.2s0.4-1.2,1-1.2
			s1,0.5,1,1.2S670,387.5,669.4,387.5z M669.4,384.1c-0.5,0-1-0.5-1-1.2c0-0.7,0.4-1.2,1-1.2s1,0.5,1,1.2
			C670.4,383.6,670,384.1,669.4,384.1z M672.2,388.3c-0.5,0-1-0.5-1-1.2s0.4-1.2,1-1.2c0.5,0,1,0.5,1,1.2S672.7,388.3,672.2,388.3z
			M672.2,384.8c-0.5,0-1-0.5-1-1.2s0.4-1.2,1-1.2c0.5,0,1,0.5,1,1.2S672.7,384.8,672.2,384.8z M668.2,377.5v3.1l4.9,1.1v-3.2
			L668.2,377.5z"
          />
          <ellipse class="st0" cx="669.4" cy="386.3" rx="1" ry="1.2" />
          <ellipse class="st0" cx="672.2" cy="387.1" rx="1" ry="1.2" />
          <ellipse class="st0" cx="669.4" cy="382.9" rx="1" ry="1.2" />
          <ellipse class="st0" cx="672.2" cy="383.7" rx="1" ry="1.2" />
        </g>
        <g id="_x31_-7-doerhaandtag_x5F_1">
          <path
            class="st0"
            d="M657.6,384.1h-0.5c-0.4,0-0.8,0.3-1,0.8c0.4,0,0.7,0.4,0.7,0.8c0,0.4-0.2,0.7-0.5,0.8
			c0.2,0.3,0.5,0.5,0.8,0.5h0.5c0.6,0,1.1-0.7,1.1-1.5S658.3,384.1,657.6,384.1z"
          />
          <path
            class="st0"
            d="M657.1,384.6c-0.3,0-0.5,0.2-0.6,0.5c0.2,0.1,0.4,0.4,0.4,0.7c0,0.2-0.1,0.4-0.2,0.6c0.1,0.1,0.3,0.3,0.5,0.3
			c0.4,0,0.8-0.5,0.8-1C657.9,385.1,657.5,384.6,657.1,384.6z"
          />
          <path
            class="st0"
            d="M655.5,385.8c0-0.5,0.3-0.8,0.7-0.8c0.1,0,0.1,0,0.2,0c0,0,0,0,0,0l-4.2-1c0,0-0.1,0-0.1,0
			c-0.4,0-0.7,0.4-0.7,0.8c0,0.4,0.3,0.7,0.6,0.8l0,0l4.2,1l0,0C655.7,386.5,655.5,386.2,655.5,385.8z"
          />
          <path
            class="st0"
            d="M655.5,385.7c0,0.4,0.2,0.9,0.7,0.9c0.4,0,0.7-0.4,0.7-0.8c0-0.2-0.1-0.9-0.7-0.9
			C655.5,385,655.5,385.7,655.5,385.7z"
          />
        </g>
        <g id="_x31_-7-doerhaandtag_x5F_2">
          <circle class="st0" cx="1050.4" cy="434.8" r="1.1" />
          <path
            class="st0"
            d="M1055.9,433.1c0-0.6-0.5-1.1-1.1-1.1c-0.1,0-0.3,0-0.4,0.1l-4.4,1.7l0,0c0.1,0,0.2-0.1,0.3-0.1
			c0.6,0,1.1,0.5,1.1,1.1c0,0.4-0.3,0.8-0.6,1l0,0l4.3-1.7l0,0C1055.6,434,1055.9,433.6,1055.9,433.1z"
          />
          <path
            class="st0"
            d="M1054.4,432.1c0.1,0,0.2-0.1,0.4-0.1c0.1,0,0.2,0,0.2,0c-0.2-0.6-0.6-1-1.1-1c-0.7,0-1.2,0.7-1.2,1.5
			c0,0,0,0.1,0,0.1L1054.4,432.1z"
          />
        </g>
        <g id="_x31_-7_x5F_overvaagning">
          <g>
            <polygon class="st0" points="620,322.1 619.5,328.6 626.6,328.6 627.1,322.3 			" />
            <polygon class="st0" points="619.5,328.6 616.8,319.3 617.3,313.4 620,322.1 			" />
            <polygon class="st0" points="627.1,322.3 620,322.1 617.3,313.4 624.3,313.6 			" />
          </g>
          <circle class="st0" cx="623.5" cy="325.4" r="1.4" />
          <g>
            <path
              class="st0"
              d="M627.1,322.3l-0.3,4.2c1.3-1.4,2.2-3.9,2.2-6.7c0-4.4-2.2-8-4.8-8c-1.2,0-2.2,0.7-3.1,1.8l3.2,0.1
				L627.1,322.3z"
            />
          </g>
          <path
            class="st0"
            d="M625.5,311.7h-1.4c2.7,0,4.8,3.6,4.8,8c0,2.8-0.9,5.3-2.2,6.7l-0.1,1.1c2.1-0.9,3.7-4,3.7-7.8
			C630.4,315.3,628.2,311.7,625.5,311.7z"
          />
        </g>
      </g>
      <g id="KK-1-1" class="hover-obj keid kejd" @click="$store.commit('setModal', '1.1')">
        <g id="_x31_-1_x5F_nedloebsroer_x5F_2">
          <g>
            <path
              class="st0"
              d="M954.5,260.4l3.3,8.5l-3.7,224.7v1.7c0,1-0.1,2-0.4,3l-0.2,0.7c-0.3,1-0.7,1.9-1.2,2.7l-2.4,3.7l0,0
				c0.4-0.5,1-0.8,1.8-0.8c1.7,0,3.1,1.5,3.1,3.4c0,1-0.3,2.1-0.9,2.7l0,0c0,0,3.5-2.8,5.7-8.7c0.2-0.7,0.5-1.7,0.7-2.6
				c0.2-0.9,0.3-1.9,0.4-2.9l0.1-3l4.2-224.4l3.4-7.9L954.5,260.4z"
            />
            <path
              class="st0"
              d="M954.8,508.1c0,1.9-0.8,3.3-2.5,3.3c-1.6,0-3.2-1.5-3.2-3.3s0.9-3.4,2.6-3.4
				C953.4,504.7,954.8,506.2,954.8,508.1z"
            />
          </g>
          <path class="st0" d="M957.8,268.9c0.9,0.3,2.2,0.5,3.6,0.6c1.4,0.1,2.6-0.1,3.6-0.2" />
          <path class="st0" d="M957.6,277.4c0.8,0.3,2,0.7,3.5,0.7c1.6,0.1,2.8-0.2,3.7-0.4" />
        </g>
        <g id="_x31_-1_x5F_nedloebsroer_x5F_1">
          <g>
            <ellipse
              transform="matrix(0.9782 -0.2075 0.2075 0.9782 -65.3679 106.5413)"
              class="st0"
              cx="475.2"
              cy="364.9"
              rx="1"
              ry="1.9"
            />
            <g>
              <path
                class="st0"
                d="M474.6,363.1L474.6,363.1C474.6,363.1,474.6,363.1,474.6,363.1L474.6,363.1z"
              />
              <g>
                <polygon
                  class="st0"
                  points="472.2,243.3 475.9,241 475.5,232.8 477,228.8 469.8,228.3 471.7,232.8 					"
                />
                <g>
                  <polygon
                    class="st0"
                    points="479.6,316.6 476.3,250.6 472.5,250.2 475.8,319.3 						"
                  />
                  <path
                    class="st0"
                    d="M476.2,327.6l1.4,29.2v2.2c0,0.4-0.1,0.8-0.2,1.2c-0.2,0.7-0.7,1.2-1.2,1.7l-1.6,1.2
							c0.1,0,0.1-0.1,0.2-0.1c0.5-0.1,1.1,0.6,1.3,1.7c0.2,0.9,0,1.7-0.3,2l1.3-0.7c0.3-0.2,0.6-0.4,0.9-0.6l2.7-2.8
							c0.3-0.3,0.5-0.7,0.7-1.1l0.1-0.3c0.2-0.6,0.3-1.3,0.3-1.9l-1.5-30.6L476.2,327.6z"
                  />
                </g>
              </g>
            </g>
          </g>
          <path class="st0" d="M471.7,232.8c0,0,1.5,0.6,3.7,0" />
          <path class="st0" d="M471.9,236.8c0,0,1.2,0.6,3.7,0" />
        </g>
        <g id="_x31_-1_x5F_tagrende_x5F_2">
          <path
            class="st0"
            d="M976.6,261.9c2.1,0,3.2-2.4,3.2-5.3l-7.2-0.4C972.7,259.1,973.6,261.9,976.6,261.9z"
          />
          <path
            class="st0"
            d="M972.7,256.2l4.1,0.2l0,0l-540.6-32.9c0,2.9,0.9,2.8,2.9,2.8L976,261.9C973.4,261.5,972.7,258.9,972.7,256.2z
			"
          />
        </g>
        <g id="_x31_-1_x5F_tagrende_x5F_1">
          <polygon class="st0" points="1222.5,232.1 1258.4,233.2 1258.4,228.8 1222.7,227.8 		" />
          <path
            class="st0"
            d="M1258.4,233.2c1.7-0.1,2.6-2,2.5-4.4l-5.8-0.1C1255.1,231,1255.9,233.3,1258.4,233.2z"
          />
        </g>
      </g>
      <g id="KK-1-4" class="hover-obj keid kejd" @click="$store.commit('setModal', '1.4')">
        <g id="_x31_-4_x5F_markise_x5F_11">
          <polygon class="st1" points="1066.1,280.2 1029.8,295.1 1029.8,296.4 1067.2,280.9 		" />
          <polygon class="st1" points="1033.8,257.4 1077.2,288 1103.1,284 1060.6,254.8 		" />
          <path
            class="st1"
            d="M1029.8,296.4l0-1.2l0.6-0.3c-0.1-0.8-0.3-1.4-0.6-1.4c-0.3,0-0.6,1-0.6,2.2c0,1.2,0.3,2.2,0.6,2.2
			c0.3,0,0.5-0.7,0.6-1.7L1029.8,296.4z"
          />
        </g>
        <g id="_x31_-4_x5F_markise_x5F_10">
          <polygon class="st1" points="1163.9,245.4 1202.1,269.3 1218.2,266.5 1181.7,243.8 		" />
          <polygon class="st1" points="1192.7,263.4 1160.2,277 1160.2,278 1193.6,264 		" />
          <path
            class="st1"
            d="M1160.2,278V277l0.5-0.2c-0.1-0.7-0.3-1.2-0.5-1.2c-0.3,0-0.5,0.8-0.5,1.9s0.2,1.9,0.5,1.9
			c0.3,0,0.5-0.6,0.5-1.5L1160.2,278z"
          />
        </g>
        <g id="_x31_-4_x5F_markise_x5F_9">
          <polygon class="st1" points="1159.8,342.4 1196.7,368.4 1212.9,361.9 1177.1,337.2 		" />
          <polygon class="st1" points="1188.1,362.3 1156,375.7 1156,376.8 1189,363 		" />
          <path
            class="st1"
            d="M1156,376.8v-1.1l0.5-0.2c-0.1-0.7-0.3-1.2-0.5-1.2c-0.3,0-0.5,0.8-0.5,1.9s0.2,1.9,0.5,1.9
			c0.3,0,0.5-0.6,0.5-1.5L1156,376.8z"
          />
        </g>
        <g id="_x31_-4_x5F_markise_x5F_8">
          <polygon class="st1" points="911.2,387.2 879.5,423.5 838.5,413.4 870.7,379 		" />
          <polygon class="st1" points="888.3,413.4 914.8,424.4 914.8,423.1 889,412.5 		" />
          <path
            class="st1"
            d="M914.8,421.4c-0.3,0-0.5,0.6-0.6,1.4l0.6,0.2v1.2l-0.6-0.3c0.1,1,0.3,1.7,0.6,1.7c0.3,0,0.6-1,0.6-2.2
			C915.4,422.4,915.1,421.4,914.8,421.4z"
          />
        </g>
        <g id="_x31_-4_x5F_markise_x5F_7">
          <polygon class="st1" points="911.6,260.3 880.5,291 838.6,286.9 869.9,257.8 		" />
          <polygon class="st1" points="887.3,284.3 916.9,296.1 916.9,294.9 888.2,283.4 		" />
          <path
            class="st1"
            d="M916.9,293.2c-0.3,0-0.5,0.6-0.6,1.4l0.6,0.2v1.2l-0.6-0.2c0.1,1,0.3,1.7,0.6,1.7c0.3,0,0.6-1,0.6-2.2
			C917.5,294.2,917.2,293.2,916.9,293.2z"
          />
        </g>
        <g id="_x31_-4_x5F_markise_x5F_6">
          <polygon class="st1" points="792.8,253.2 761.3,279.9 729,276.7 759.9,251.2 		" />
          <polygon class="st1" points="768.7,273.6 798.3,285.5 798.3,284.3 769.7,272.8 		" />
          <path
            class="st1"
            d="M798.3,282.6c-0.3,0-0.5,0.6-0.6,1.4l0.6,0.2v1.2l-0.6-0.2c0.1,1,0.3,1.7,0.6,1.7c0.3,0,0.6-1,0.6-2.2
			S798.6,282.6,798.3,282.6z"
          />
        </g>
        <g id="_x31_-4_x5F_markise_x5F_5">
          <polygon class="st1" points="794,364.5 762.5,395.5 730.5,387.8 762.3,358.1 		" />
          <polygon class="st1" points="769.5,388.6 798.5,400.7 798.5,399.5 770.4,387.8 		" />
          <path
            class="st1"
            d="M798.5,397.8c-0.3,0-0.5,0.6-0.6,1.4l0.6,0.2v1.2l-0.6-0.3c0.1,1,0.3,1.7,0.6,1.7c0.3,0,0.6-1,0.6-2.2
			C799.1,398.8,798.8,397.8,798.5,397.8z"
          />
        </g>
        <g id="_x31_-4_x5F_markise_x5F_4">
          <polygon class="st1" points="544.4,315.8 562.8,319.5 533.7,341.4 515.9,337 		" />
          <polygon class="st1" points="540.8,336 539.8,336.8 565.8,347.7 565.8,346.4 		" />
          <path
            class="st1"
            d="M565.8,344.7c-0.3,0-0.5,0.6-0.6,1.4l0.6,0.2v1.2l-0.6-0.3c0.1,1,0.3,1.7,0.6,1.7c0.3,0,0.6-1,0.6-2.2
			C566.4,345.7,566.1,344.7,565.8,344.7z"
          />
        </g>
        <g id="_x31_-4_x5F_markise_x5F_3">
          <polygon class="st1" points="540.1,235 558.8,236.1 530.1,255.7 511.9,253.9 		" />
          <polygon class="st1" points="538.1,250.2 537,251 563,261.8 563,260.6 		" />
          <path
            class="st1"
            d="M563,258.9c-0.3,0-0.5,0.6-0.6,1.4l0.6,0.2v1.2l-0.6-0.3c0.1,1,0.3,1.7,0.6,1.7c0.3,0,0.6-1,0.6-2.2
			S563.3,258.9,563,258.9z"
          />
        </g>
        <g id="_x31_-4_x5F_markise_x5F_2">
          <polygon class="st1" points="505.6,233 478,250.8 462.8,249.2 490,232 		" />
          <polygon class="st1" points="486.9,245 485.8,245.8 509,255.4 509,254.2 		" />
          <path
            class="st1"
            d="M509,252.5c-0.3,0-0.5,0.5-0.6,1.3l0,0.2l0.6,0.2v1.2l-0.6-0.3l0,0.1c0.1,1,0.3,1.7,0.6,1.7
			c0.3,0,0.6-1,0.6-2.2C509.6,253.5,509.3,252.5,509,252.5z"
          />
        </g>
        <g id="_x31_-4_x5F_markise_x5F_1">
          <polygon class="st1" points="488.9,324.1 487.8,324.9 513,335.4 513,334.2 		" />
          <polygon class="st1" points="509.8,309.2 482,329 467,325.4 494.5,306.2 		" />
          <path
            class="st1"
            d="M513,332.5c-0.3,0-0.5,0.6-0.6,1.4l0.6,0.2v1.2l-0.6-0.3c0.1,1,0.3,1.7,0.6,1.7c0.3,0,0.6-1,0.6-2.2
			S513.4,332.5,513,332.5z"
          />
        </g>
      </g>
      <g id="KK-1-3" class="hover-obj keid kejd" @click="$store.commit('setModal', '1.3')">
        <g id="_x31_-3_x5F_lyskasse_x5F_3">
          <g>
            <polygon
              class="st0"
              points="563.4,394.2 563.6,398.3 553,400.1 534.2,394.2 534.2,390.1 552.9,396 			"
            />
            <polygon
              class="st0"
              points="534.2,390.1 544.1,388.4 546.1,389.1 540.4,390.2 554.7,394.8 561.4,393.6 563.4,394.2 552.9,396 
							"
            />
            <polygon class="st0" points="546.1,389.1 546.1,392 540.4,390.2 			" />
          </g>
          <line class="st0" x1="553" y1="400.1" x2="552.9" y2="396" />
        </g>
        <g id="_x31_-3_x5F_lyskasse_x5F_2">
          <polygon
            class="st0"
            points="909.6,504.8 909.6,498.8 898.3,503 857,489.9 857,495.8 898.3,508.9 		"
          />
          <polygon class="st0" points="872.2,487.5 872.2,492 865.6,489.9 		" />
          <polygon
            class="st0"
            points="898.3,503 909.6,498.8 905.1,497.3 898,500 865.6,489.9 872.2,487.5 868,486.3 857,489.9 		"
          />
          <line class="st0" x1="898.3" y1="503" x2="898.3" y2="508.9" />
        </g>
        <g id="_x31_-3_x5F_lyskasse_x5F_1">
          <polygon
            class="st0"
            points="796.5,469.8 796.5,463.8 785.2,468 743.9,454.9 743.9,460.8 785.2,474 		"
          />
          <polygon class="st0" points="759.1,452.6 759.1,457 752.5,454.9 		" />
          <polygon
            class="st0"
            points="785.2,468 796.5,463.8 792,462.3 784.9,465 752.5,454.9 759.1,452.6 755.1,451.3 743.9,454.9 		"
          />
          <line class="st0" x1="785.2" y1="468" x2="785.2" y2="474" />
        </g>
      </g>
      <g id="KK-A1-1-23" class="hover-obj keid kejd" @click="$store.commit('setModal', 'a-kloak')">
        <path
          class="st0"
          d="M470.1,372l-13.6,3.3l16,6.2l14.7-3.7L470.1,372z M458.7,375.3l11.7-2.5l13.5,4.6l-11.5,2.9L458.7,375.3z"
        />
        <g id="_x33_-3_x5F_kloak_x5F_3">
          <path
            class="st0"
            d="M802.1,503c0.2,4.8-10.5,9.1-23.9,9.6c-13.4,0.5-23.6-3-23.7-7.8c-0.2-4.8,9.7-9.1,23.1-9.6
			C790.9,494.7,801.9,498.2,802.1,503z"
          />
          <path
            class="st0"
            d="M798.1,503.1c0.1,4-8.8,7.6-19.9,8s-19.6-2.5-19.8-6.5c-0.1-4,8.1-7.6,19.3-8S797.9,499.1,798.1,503.1z"
          />
          <line class="st0" x1="777.6" y1="496.6" x2="778.1" y2="511.1" />
          <line class="st0" x1="758.3" y1="504.6" x2="798.1" y2="503.1" />
          <line class="st0" x1="789.3" y1="497.5" x2="766.7" y2="510.2" />
          <line class="st0" x1="766.2" y1="498.4" x2="789.7" y2="509.4" />
        </g>
        <g id="_x33_-3_x5F_kloak_x5F_2">
          <polygon class="st0" points="924.4,525.4 940.6,518.1 962.4,525 946.5,533 		" />
          <path
            class="st0"
            d="M940.6,518.1l-16.2,7.3l22.1,7.6l15.9-8L940.6,518.1z M946,531.8l-18.6-6.4l13.6-6.1l18.3,5.8L946,531.8z"
          />
          <polygon class="st0" points="941,519.3 927.4,525.5 946,531.8 959.4,525.1 		" />
          <g>
            <polygon class="st0" points="945.8,520.8 932.1,527.1 935.4,528.2 948.5,521.7 			" />
            <polygon class="st0" points="941,519.3 927.4,525.5 929.7,526.2 943.3,520 			" />
            <polygon class="st0" points="951.4,522.6 938.4,529.2 940.9,530.1 954,523.4 			" />
            <polygon class="st0" points="943.5,531 946,531.8 959.4,525.1 956.7,524.3 			" />
          </g>
          <line class="st0" x1="941" y1="527.9" x2="941" y2="530.1" />
          <line class="st0" x1="941" y1="523" x2="941" y2="525.4" />
          <line class="st0" x1="941" y1="519.3" x2="941" y2="521.1" />
        </g>
        <g id="_x33_-3_x5F_kloak_x5F_1">
          <polygon class="st0" points="470.4,372.9 458.7,375.3 472.4,380.4 483.9,377.5 		" />
          <g>
            <polygon class="st0" points="473.9,374.1 462.1,376.6 464.6,377.5 475.9,374.7 			" />
            <polygon class="st0" points="470.4,372.9 458.7,375.3 460.4,375.9 472,373.4 			" />
            <polygon class="st0" points="478,375.5 466.8,378.3 468.6,379 479.9,376.1 			" />
            <polygon class="st0" points="470.6,379.7 472.4,380.4 483.9,377.5 482,376.8 			" />
          </g>
          <line class="st0" x1="470.4" y1="377.4" x2="470.4" y2="378.5" />
          <line class="st0" x1="470.4" y1="374.8" x2="470.4" y2="376.1" />
          <line class="st0" x1="470.4" y1="372.9" x2="470.4" y2="373.8" />
        </g>
      </g>
      <g id="KK-A1-1-3" class="hover-obj lejer" @click="$store.commit('setModal', 'a-haek')">
        <g>
          <path
            class="st3"
            d="M12.2,405.2c-0.7-0.6-0.7-0.6-1.4-1.2c-0.7-0.6,0.1-1.5-0.6-2.1c-0.7-0.6-0.7-0.6-1.4-1.2
			c-0.7-0.6-0.3-1-1-1.6c-0.7-0.6-1.1-0.2-1.8-0.8s-1.2-0.2-1.6-1.1c-0.4-0.8-0.5-0.9-0.6-1.9c-0.1-0.9-0.4-0.9-0.4-1.8
			c-0.1-0.9,1.1-1,1.1-2c-0.1-0.9,0-0.9,0-1.9c-0.1-0.9-0.8-0.9-0.8-1.8c-0.1-0.9-0.9-0.9-1-1.8c-0.1-0.9,1.9-1.1,1.8-2
			c-0.1-0.9-1.6-0.8-1.7-1.7c-0.1-0.9,0.2-1,0.2-1.9c-0.1-0.9-0.6-0.9-0.7-1.8c-0.1-0.9,0.3-1,0.2-1.9c-0.1-0.9,0.8-1,0.7-1.9
			c-0.1-0.9-0.3-0.9-0.4-1.8c-0.1-0.9-0.3-0.9-0.4-1.8c-0.1-0.9-0.4-0.9-0.4-1.8c-0.1-0.9,0.8-1,0.8-1.9c-0.1-0.9-0.4-0.9-0.5-1.8
			c-0.1-0.9-0.2-0.9-0.3-1.8c-0.1-0.9,0-0.9-0.1-1.9c-0.1-0.9-1-0.9-1.1-1.8c-0.1-0.9,0-0.9-0.1-1.9c-0.1-0.9,1-1,0.9-2
			c-0.1-0.9-0.3-0.9-0.4-1.8c-0.1-0.9-0.2-0.9-0.3-1.9s-0.4-0.9-0.5-1.8c-0.1-1,0.8-1.9,1.7-2c0.9-0.1,0.8-0.8,1.8-0.9
			c0.9-0.1,1,0.2,1.9,0.1c0.9-0.1,1.1,1.2,2,1.1c0.9-0.1,0.9-0.7,1.8-0.8c0.9-0.1,0.8-1.2,1.7-1.3c0.9-0.1,0.9-0.3,1.8-0.4
			c0.9-0.1,1,0.2,1.9,0.1c0.9-0.1,1.1,1.3,2,1.2c0.9-0.1,0.9-0.7,1.8-0.8c0.9-0.1,0.9-0.2,1.9-0.4c0.9-0.1,1,0.7,2,0.6
			c0.9-0.1,0.9-0.2,1.9-0.3c0.9-0.1,0.8-1.3,1.7-1.4c0.9-0.1,1,0.5,1.9,0.4c0.9-0.1,0.9,0,1.9-0.1c0.9-0.1,1,0.6,2,0.5
			c0.9-0.1,0.7-1.7,1.7-1.8c0.9-0.1,1,0.7,2,0.6c0.9-0.1,0.8-1.2,1.7-1.3c0.9-0.1,1.1,1.6,2.1,1.5c0.9-0.1,1,0.1,1.9-0.1
			c0.9-0.1,0.8-1,1.8-1.1c0.9-0.1,0.9-0.8,1.8-0.9c0.9-0.1,0.9-0.2,1.9-0.3c0.9-0.1,1.1,1.1,2,1c0.9-0.1,0.9-0.2,1.9-0.3
			c0.9-0.1,0.8-1.4,1.7-1.5c0.9-0.1,1.1,1.4,2.1,1.3c0.9-0.1,0.9,0,1.9-0.1c0.9-0.1,0.8-1.4,1.7-1.5c0.9-0.1,1,0.7,2,0.6
			c0.9-0.1,1,0.3,1.9,0.2c0.9-0.1,0.9-0.7,1.8-0.8c0.9-0.1,1,0.1,1.9,0c0.9-0.1,0.9-0.5,1.8-0.6c0.9-0.1,1,0.4,1.9,0.3
			c0.9-0.1,0.9-0.2,1.9-0.3c0.9-0.1,0.8-1.3,1.7-1.4c0.9-0.1,1,0.7,2,0.6c0.9-0.1,1,0.2,1.9,0.1c0.9-0.1,0.9-0.2,1.9-0.3
			c0.9-0.1,1,0.1,1.9,0c0.9-0.1,1,0.3,1.9,0.2c0.9-0.1,0.9-0.2,1.9-0.3c0.9-0.1,0.8-1.5,1.7-1.6c0.9-0.1,1,0.1,1.9,0
			c0.9-0.1,0.9-0.1,1.9-0.2c0.9-0.1,1,0.5,1.9,0.3c0.9-0.1,1,0.7,2,0.6c0.9-0.1,0.8-1.4,1.7-1.6c0.9-0.1,1,0.8,2,0.7
			c0.9-0.1,1,0,1.9-0.1c0.9-0.1,0.8-1.3,1.7-1.5c0.9-0.1,1,0.8,2,0.7c0.9-0.1,1,0,1.9-0.1c0.9-0.1,0.8-1.6,1.7-1.7
			c0.9-0.1,1.1,1.4,2.1,1.3c0.9-0.1,0.9-0.1,1.9-0.2c0.9-0.1,0.8-0.9,1.8-1c0.9-0.1,0.9-0.1,1.9-0.3c0.9-0.1,0.9-0.2,1.9-0.3
			c0.9-0.1,0.9-0.5,1.8-0.7c0.9-0.1,1.1,1.4,2.1,1.3c0.9-0.1,0.9-0.3,1.9-0.4c0.9-0.1,0.8-0.9,1.8-1.1c0.9-0.1,1,0.8,2,0.6
			c0.9-0.1,0.9-0.8,1.8-0.9c0.9-0.1,1,0.2,1.9,0.1c0.9-0.1,1,0.4,1.9,0.3c0.9-0.1,0.8-1.4,1.7-1.5c0.9-0.1,1.1,1,2,0.9
			c0.9-0.1,1,0,1.9-0.1c0.9-0.1,0.8-1.6,1.7-1.7c0.9-0.1,0.9-0.3,1.9-0.4c0.9-0.1,0.9-0.1,1.9-0.2c0.9-0.1,1,0.6,2,0.5
			c0.9-0.1,0.8-0.9,1.8-1c0.9-0.1,1.1,0.8,2,0.7c0.9-0.1,1,0.2,1.9,0s1.2-1.5,2.1-1.2c0.9,0.3,1,0.8,1.8,1.4
			c0.8,0.6-0.3,1.9,0.4,2.5c0.8,0.6,1.3,0,2,0.7"
          />
          <path
            class="st1"
            d="M12.2,405.2c-0.7-0.6-0.7-0.6-1.4-1.2c-0.7-0.6,0.1-1.5-0.6-2.1c-0.7-0.6-0.7-0.6-1.4-1.2
			c-0.7-0.6-0.3-1-1-1.6c-0.7-0.6-1.1-0.2-1.8-0.8s-1.2-0.2-1.6-1.1c-0.4-0.8-0.5-0.9-0.6-1.9c-0.1-0.9-0.4-0.9-0.4-1.8
			c-0.1-0.9,1.1-1,1.1-2c-0.1-0.9,0-0.9,0-1.9c-0.1-0.9-0.8-0.9-0.8-1.8c-0.1-0.9-0.9-0.9-1-1.8c-0.1-0.9,1.9-1.1,1.8-2
			c-0.1-0.9-1.6-0.8-1.7-1.7c-0.1-0.9,0.2-1,0.2-1.9c-0.1-0.9-0.6-0.9-0.7-1.8c-0.1-0.9,0.3-1,0.2-1.9c-0.1-0.9,0.8-1,0.7-1.9
			c-0.1-0.9-0.3-0.9-0.4-1.8c-0.1-0.9-0.3-0.9-0.4-1.8c-0.1-0.9-0.4-0.9-0.4-1.8c-0.1-0.9,0.8-1,0.8-1.9c-0.1-0.9-0.4-0.9-0.5-1.8
			c-0.1-0.9-0.2-0.9-0.3-1.8c-0.1-0.9,0-0.9-0.1-1.9c-0.1-0.9-1-0.9-1.1-1.8c-0.1-0.9,0-0.9-0.1-1.9c-0.1-0.9,1-1,0.9-2
			c-0.1-0.9-0.3-0.9-0.4-1.8c-0.1-0.9-0.2-0.9-0.3-1.9s-0.4-0.9-0.5-1.8c-0.1-1,0.8-1.9,1.7-2c0.9-0.1,0.8-0.8,1.8-0.9
			c0.9-0.1,1,0.2,1.9,0.1c0.9-0.1,1.1,1.2,2,1.1c0.9-0.1,0.9-0.7,1.8-0.8c0.9-0.1,0.8-1.2,1.7-1.3c0.9-0.1,0.9-0.3,1.8-0.4
			c0.9-0.1,1,0.2,1.9,0.1c0.9-0.1,1.1,1.3,2,1.2c0.9-0.1,0.9-0.7,1.8-0.8c0.9-0.1,0.9-0.2,1.9-0.4c0.9-0.1,1,0.7,2,0.6
			c0.9-0.1,0.9-0.2,1.9-0.3c0.9-0.1,0.8-1.3,1.7-1.4c0.9-0.1,1,0.5,1.9,0.4c0.9-0.1,0.9,0,1.9-0.1c0.9-0.1,1,0.6,2,0.5
			c0.9-0.1,0.7-1.7,1.7-1.8c0.9-0.1,1,0.7,2,0.6c0.9-0.1,0.8-1.2,1.7-1.3c0.9-0.1,1.1,1.6,2.1,1.5c0.9-0.1,1,0.1,1.9-0.1
			c0.9-0.1,0.8-1,1.8-1.1c0.9-0.1,0.9-0.8,1.8-0.9c0.9-0.1,0.9-0.2,1.9-0.3c0.9-0.1,1.1,1.1,2,1c0.9-0.1,0.9-0.2,1.9-0.3
			c0.9-0.1,0.8-1.4,1.7-1.5c0.9-0.1,1.1,1.4,2.1,1.3c0.9-0.1,0.9,0,1.9-0.1c0.9-0.1,0.8-1.4,1.7-1.5c0.9-0.1,1,0.7,2,0.6
			c0.9-0.1,1,0.3,1.9,0.2c0.9-0.1,0.9-0.7,1.8-0.8c0.9-0.1,1,0.1,1.9,0c0.9-0.1,0.9-0.5,1.8-0.6c0.9-0.1,1,0.4,1.9,0.3
			c0.9-0.1,0.9-0.2,1.9-0.3c0.9-0.1,0.8-1.3,1.7-1.4c0.9-0.1,1,0.7,2,0.6c0.9-0.1,1,0.2,1.9,0.1c0.9-0.1,0.9-0.2,1.9-0.3
			c0.9-0.1,1,0.1,1.9,0c0.9-0.1,1,0.3,1.9,0.2c0.9-0.1,0.9-0.2,1.9-0.3c0.9-0.1,0.8-1.5,1.7-1.6c0.9-0.1,1,0.1,1.9,0
			c0.9-0.1,0.9-0.1,1.9-0.2c0.9-0.1,1,0.5,1.9,0.3c0.9-0.1,1,0.7,2,0.6c0.9-0.1,0.8-1.4,1.7-1.6c0.9-0.1,1,0.8,2,0.7
			c0.9-0.1,1,0,1.9-0.1c0.9-0.1,0.8-1.3,1.7-1.5c0.9-0.1,1,0.8,2,0.7c0.9-0.1,1,0,1.9-0.1c0.9-0.1,0.8-1.6,1.7-1.7
			c0.9-0.1,1.1,1.4,2.1,1.3c0.9-0.1,0.9-0.1,1.9-0.2c0.9-0.1,0.8-0.9,1.8-1c0.9-0.1,0.9-0.1,1.9-0.3c0.9-0.1,0.9-0.2,1.9-0.3
			c0.9-0.1,0.9-0.5,1.8-0.7c0.9-0.1,1.1,1.4,2.1,1.3c0.9-0.1,0.9-0.3,1.9-0.4c0.9-0.1,0.8-0.9,1.8-1.1c0.9-0.1,1,0.8,2,0.6
			c0.9-0.1,0.9-0.8,1.8-0.9c0.9-0.1,1,0.2,1.9,0.1c0.9-0.1,1,0.4,1.9,0.3c0.9-0.1,0.8-1.4,1.7-1.5c0.9-0.1,1.1,1,2,0.9
			c0.9-0.1,1,0,1.9-0.1c0.9-0.1,0.8-1.6,1.7-1.7c0.9-0.1,0.9-0.3,1.9-0.4c0.9-0.1,0.9-0.1,1.9-0.2c0.9-0.1,1,0.6,2,0.5
			c0.9-0.1,0.8-0.9,1.8-1c0.9-0.1,1.1,0.8,2,0.7c0.9-0.1,1,0.2,1.9,0s1.2-1.5,2.1-1.2c0.9,0.3,1,0.8,1.8,1.4
			c0.8,0.6-0.3,1.9,0.4,2.5c0.8,0.6,1.3,0,2,0.7"
          />
        </g>
        <g>
          <path
            class="st3"
            d="M12.1,404.9c0.7,0.6,0.4,0.1,1.4,0c0.9-0.1,0.9-0.7,1.8-0.8c0.9-0.1,0.9,0,1.9-0.2c0.9-0.1,1.1,0.7,2,0.6
			c0.9-0.1,0.8-1.2,1.7-1.3c0.9-0.1,1.2,1.7,2.1,1.6c0.9-0.1,0.9-0.4,1.8-0.6c0.9-0.1,0.9-0.4,1.8-0.5c0.9-0.1,0.9-0.4,1.8-0.6
			c0.9-0.1,0.9-0.4,1.8-0.5c0.9-0.1,1.1,0.8,2,0.7c0.9-0.1,0.8-1.4,1.7-1.5c0.9-0.1,1.1,0.8,2,0.7c0.9-0.1,0.8-0.8,1.8-1
			c0.9-0.1,1,0.1,1.9,0c0.9-0.1,1,0.4,1.9,0.3c0.9-0.1,0.8-1.1,1.7-1.3c0.9-0.1,0.9-0.4,1.8-0.5c0.9-0.1,0.9-0.4,1.8-0.5
			c0.9-0.1,1,0.6,2,0.5c0.9-0.1,1,0.2,1.9,0.1c0.9-0.1,0.9-0.2,1.9-0.3c0.9-0.1,1,0,1.9-0.1c0.9-0.1,0.8-1.1,1.7-1.3
			c0.9-0.1,1.1,0.9,2,0.8c0.9-0.1,0.8-1,1.8-1.1c0.9-0.1,1.1,0.8,2,0.7c0.9-0.1,0.8-1.3,1.7-1.4c0.9-0.1,1.2,1.4,2.1,1.3
			c0.9-0.1,0.9-0.5,1.8-0.6c0.9-0.1,0.8-0.9,1.8-1c0.9-0.1,0.8-0.8,1.8-0.9c0.9-0.1,1.1,0.7,2,0.6c0.9-0.1,0.9-0.1,1.9-0.2
			c0.9-0.1,1,0.2,1.9,0c0.9-0.1,0.9-0.7,1.8-0.8c0.9-0.1,1.1,0.8,2,0.7c0.9-0.1,0.8-1.4,1.7-1.5c0.9-0.1,1.1,1,2,0.8
			c0.9-0.1,0.8-1.4,1.7-1.6c0.9-0.1,1.1,1.1,2,0.9c0.9-0.1,0.7-1.6,1.7-1.8c0.9-0.1,1,0.1,1.9,0c0.9-0.1,1.1,1.2,2.1,1
			c0.9-0.1,0.8-0.9,1.8-1c0.9-0.1,0.9-0.2,1.9-0.4c0.9-0.1,1.1,0.9,2,0.8c0.9-0.1,0.8-1,1.7-1.2c0.9-0.1,1,0,1.9-0.1
			c0.9-0.1,1,0.5,2,0.3c0.9-0.1,1,0,1.9-0.1c0.9-0.1,1,0,1.9-0.2c0.9-0.1,0.8-1,1.7-1.1c0.9-0.1,0.8-0.8,1.8-1
			c0.9-0.1,1.1,0.9,2,0.7c0.9-0.1,0.8-1.4,1.7-1.5c0.9-0.1,1,0.6,2,0.4c0.9-0.1,1,0.3,1.9,0.2c0.9-0.1,0.9-0.3,1.8-0.5
			c0.9-0.1,0.9-0.1,1.9-0.2c0.9-0.1,0.9-0.3,1.8-0.5c0.9-0.1,1,0.1,1.9,0c0.9-0.1,0.8-1.1,1.7-1.2c0.9-0.1,0.9-0.2,1.9-0.3
			c0.9-0.1,1,0,1.9-0.1c0.9-0.1,0.9-0.2,1.9-0.3c0.9-0.1,1.1,1.3,2.1,1.1c0.9-0.1,0.7-1.6,1.7-1.8c0.9-0.1,1,0.4,2,0.3
			c0.9-0.1,1,0.2,1.9,0.1c0.9-0.1,1,0.2,1.9,0.1c0.9-0.1,0.7-1.4,1.7-1.6c0.9-0.1,1.2,1.6,2.1,1.5c0.9-0.1,0.7-1.5,1.7-1.6
			c0.9-0.1,1.1,1,2,0.9c0.9-0.1,0.9-0.4,1.8-0.5c0.9-0.1,0.8-1,1.8-1.1c0.9-0.1,1.1,1,2,0.8c0.9-0.1,0.8-1,1.8-1.2
			c0.9-0.1,1.4,0.7,2,0c0.6-0.7,0.2-1,0.1-2c-0.1-0.9-0.2-0.9-0.3-1.9c-0.1-1-1-0.9-1-1.8c-0.1-1-0.4-0.9-0.5-1.9
			c-0.1-0.9,1.3-1.1,1.2-2c-0.1-0.9-1-0.9-1.1-1.8c-0.1-0.9,0.7-1,0.6-2c-0.1-1-1.2-0.8-1.3-1.8c-0.1-1,1.2-1.1,1.1-2
			c-0.1-1-1.6-0.8-1.7-1.8c-0.1-1,0.9-1,0.8-2c-0.1-1-0.8-0.9-0.9-1.8c-0.1-1-0.2-0.9-0.3-1.9c-0.1-1-0.3-0.9-0.4-1.9
			c-0.1-1,0.5-1,0.5-2c-0.1-1-0.5-0.9-0.6-1.9c-0.1-1,0.9-1.1,0.9-2c-0.1-1-1.3-0.8-1.4-1.8c-0.1-1-0.1-0.9-0.2-1.9
			c-0.1-1,1.3-1.1,1.2-2c-0.1-1-0.9-0.9-1-1.8c-0.1-1,0.3-1,0.2-1.9c-0.1-1-0.2-0.9-0.3-1.9c-0.1-1-0.8-0.9-0.8-1.8
			c-0.1-1-0.3-0.9-0.4-1.9c-0.1-1,1.1-0.9,0.4-1.5c-0.7-0.6-0.5,0-1.4,0.1c-0.9,0.1-1-0.2-1.9-0.1c-0.9,0.1-1-0.5-1.9-0.4
			c-0.9,0.1-0.9,0.3-1.9,0.4c-0.9,0.1-0.9,0.5-1.8,0.6c-0.9,0.1-0.9,0.6-1.8,0.7c-0.9,0.1-1.1-1.1-2-1c-0.9,0.1-1,0-1.9,0.1
			c-0.9,0.1-0.9,0.7-1.8,0.8c-0.9,0.1-1-0.4-1.9-0.3c-0.9,0.1-0.9,0.4-1.8,0.5c-0.9,0.1-0.9,0.5-1.8,0.6c-0.9,0.1-0.9,0.1-1.9,0.2
			c-0.9,0.1-1-0.6-2-0.5c-0.9,0.1-0.7,1.7-1.7,1.8c-0.9,0.1-1,0-1.9,0.1c-0.9,0.1-1.1-1.1-2-1c-0.9,0.1-0.8,1.6-1.7,1.7
			c-0.9,0.1-1-0.7-2-0.6c-0.9,0.1-0.9,0.3-1.9,0.4c-0.9,0.1-0.9,0.5-1.8,0.6c-0.9,0.1-1.1-1-2-0.8c-0.9,0.1-0.8,1.5-1.7,1.6
			c-0.9,0.1-1.1-1.6-2.1-1.5c-0.9,0.1-0.8,1.4-1.7,1.5c-0.9,0.1-1-0.7-2-0.5c-0.9,0.1-1-0.2-1.9-0.1c-0.9,0.1-0.9,0.3-1.9,0.4
			c-0.9,0.1-0.9,0.1-1.9,0.2c-0.9,0.1-0.9,0.7-1.8,0.8c-0.9,0.1-0.9,0.3-1.9,0.4c-0.9,0.1-1-0.8-2-0.7c-0.9,0.1-0.8,1.7-1.7,1.8
			c-0.9,0.1-1-0.3-1.9-0.2c-0.9,0.1-0.9,0.3-1.8,0.4c-0.9,0.1-1-0.3-1.9-0.2c-0.9,0.1-1-0.3-1.9-0.2c-0.9,0.1-0.9,0.7-1.8,0.8
			c-0.9,0.1-1.1-1.2-2-1c-0.9,0.1-0.8,1.3-1.7,1.4c-0.9,0.1-0.9,0.3-1.9,0.4c-0.9,0.1-1.1-0.9-2-0.8c-0.9,0.1-0.8,1.4-1.7,1.5
			c-0.9,0.1-1-0.4-1.9-0.3c-0.9,0.1-0.9,0.7-1.8,0.8c-0.9,0.1-1-0.5-1.9-0.4c-0.9,0.1-0.9,0.5-1.8,0.6c-0.9,0.1-1.1-1.2-2-1
			c-0.9,0.1-0.9,0.5-1.8,0.6c-0.9,0.1-1-0.5-2-0.4c-0.9,0.1-0.9,0.3-1.9,0.4c-0.9,0.1-0.9,0.8-1.8,0.9c-0.9,0.1-0.9,0.5-1.8,0.7
			c-0.9,0.1-1-0.7-2-0.6c-0.9,0.1-1-0.1-1.9,0c-0.9,0.1-0.8,1.2-1.8,1.3c-0.9,0.1-1-0.5-1.9-0.4c-0.9,0.1-1-0.5-2-0.4
			c-0.9,0.1-0.9,0.2-1.9,0.3c-0.9,0.1-0.8,1.3-1.7,1.4c-0.9,0.1-0.9,0.3-1.9,0.5c-0.9,0.1-1.1-0.9-2-0.8c-0.9,0.1-0.8,1.2-1.7,1.4
			c-0.9,0.1-1-0.8-2-0.7c-0.9,0.1-1-0.6-2-0.5c-0.9,0.1-0.8,1.3-1.7,1.4c-0.9,0.1-1.1-0.9-2-0.8c-0.9,0.1-0.7,1.8-1.7,1.9
			c-0.9,0.1-1.1-1-2-0.9c-0.9,0.1-0.9,0.3-1.9,0.4c-0.9,0.1-1-0.7-2-0.6c-0.9,0.1-0.8,1.2-1.8,1.3c-0.9,0.1-0.9,0.6-1.8,0.7
			c-0.9,0.1-1-0.4-1.9-0.3c-0.9,0.1-0.9,0.6-1.8,0.8c-0.9,0.1-1-0.4-1.9-0.3c-0.9,0.1-0.9,0.3-1.9,0.4c-0.9,0.1-1-0.1-1.9,0
			c-0.9,0.1-0.9,1-1.8,1.1c-1,0.1-1.9,0-1.9,0.9c0.1,0.9,0.9,0.9,1,1.8c0.1,0.9-0.1,1-0.1,1.9c0.1,0.9-0.4,1-0.3,1.9
			c0.1,0.9-0.2,1-0.1,1.9c0.1,0.9-0.5,1-0.4,2c0.1,0.9,1.9,0.8,2,1.7c0.1,0.9-1.3,1.1-1.2,2c0.1,0.9,0.1,0.9,0.2,1.9
			c0.1,0.9,1.1,0.8,1.2,1.8c0.1,0.9,0,1,0.1,1.9c0.1,0.9,0,1,0.1,1.9c0.1,0.9-0.7,1-0.6,2c0.1,0.9,0,1,0.1,1.9
			c0.1,0.9,0.6,0.9,0.7,1.8c0.1,0.9,0.2,0.9,0.3,1.9c0.1,0.9-0.1,1,0,1.9c0.1,0.9-0.4,1-0.3,1.9c0.1,0.9,0.6,0.9,0.7,1.8
			c0.1,0.9,1,0.9,1.1,1.8c0.1,0.9-0.1,1,0,1.9c0.1,0.9,0.1,0.9,0.2,1.9c0.1,0.9,0.2,0.9,0.3,1.9c0.1,0.9-0.5,1-0.4,2
			c0.1,0.9-0.6,1-0.5,2c0.1,0.9,0.1,0.9,0.2,1.9c0.1,1,0.2,0.9,0.2,1.9C10.9,404.4,11.4,404.3,12.1,404.9z"
          />
          <path
            class="st1"
            d="M12.1,404.9c0.7,0.6,0.4,0.1,1.4,0c0.9-0.1,0.9-0.7,1.8-0.8c0.9-0.1,0.9,0,1.9-0.2c0.9-0.1,1.1,0.7,2,0.6
			c0.9-0.1,0.8-1.2,1.7-1.3c0.9-0.1,1.2,1.7,2.1,1.6c0.9-0.1,0.9-0.4,1.8-0.6c0.9-0.1,0.9-0.4,1.8-0.5c0.9-0.1,0.9-0.4,1.8-0.6
			c0.9-0.1,0.9-0.4,1.8-0.5c0.9-0.1,1.1,0.8,2,0.7c0.9-0.1,0.8-1.4,1.7-1.5c0.9-0.1,1.1,0.8,2,0.7c0.9-0.1,0.8-0.8,1.8-1
			c0.9-0.1,1,0.1,1.9,0c0.9-0.1,1,0.4,1.9,0.3c0.9-0.1,0.8-1.1,1.7-1.3c0.9-0.1,0.9-0.4,1.8-0.5c0.9-0.1,0.9-0.4,1.8-0.5
			c0.9-0.1,1,0.6,2,0.5c0.9-0.1,1,0.2,1.9,0.1c0.9-0.1,0.9-0.2,1.9-0.3c0.9-0.1,1,0,1.9-0.1c0.9-0.1,0.8-1.1,1.7-1.3
			c0.9-0.1,1.1,0.9,2,0.8c0.9-0.1,0.8-1,1.8-1.1c0.9-0.1,1.1,0.8,2,0.7c0.9-0.1,0.8-1.3,1.7-1.4c0.9-0.1,1.2,1.4,2.1,1.3
			c0.9-0.1,0.9-0.5,1.8-0.6c0.9-0.1,0.8-0.9,1.8-1c0.9-0.1,0.8-0.8,1.8-0.9c0.9-0.1,1.1,0.7,2,0.6c0.9-0.1,0.9-0.1,1.9-0.2
			c0.9-0.1,1,0.2,1.9,0c0.9-0.1,0.9-0.7,1.8-0.8c0.9-0.1,1.1,0.8,2,0.7c0.9-0.1,0.8-1.4,1.7-1.5c0.9-0.1,1.1,1,2,0.8
			c0.9-0.1,0.8-1.4,1.7-1.6c0.9-0.1,1.1,1.1,2,0.9c0.9-0.1,0.7-1.6,1.7-1.8c0.9-0.1,1,0.1,1.9,0c0.9-0.1,1.1,1.2,2.1,1
			c0.9-0.1,0.8-0.9,1.8-1c0.9-0.1,0.9-0.2,1.9-0.4c0.9-0.1,1.1,0.9,2,0.8c0.9-0.1,0.8-1,1.7-1.2c0.9-0.1,1,0,1.9-0.1
			c0.9-0.1,1,0.5,2,0.3c0.9-0.1,1,0,1.9-0.1c0.9-0.1,1,0,1.9-0.2c0.9-0.1,0.8-1,1.7-1.1c0.9-0.1,0.8-0.8,1.8-1
			c0.9-0.1,1.1,0.9,2,0.7c0.9-0.1,0.8-1.4,1.7-1.5c0.9-0.1,1,0.6,2,0.4c0.9-0.1,1,0.3,1.9,0.2c0.9-0.1,0.9-0.3,1.8-0.5
			c0.9-0.1,0.9-0.1,1.9-0.2c0.9-0.1,0.9-0.3,1.8-0.5c0.9-0.1,1,0.1,1.9,0c0.9-0.1,0.8-1.1,1.7-1.2c0.9-0.1,0.9-0.2,1.9-0.3
			c0.9-0.1,1,0,1.9-0.1c0.9-0.1,0.9-0.2,1.9-0.3c0.9-0.1,1.1,1.3,2.1,1.1c0.9-0.1,0.7-1.6,1.7-1.8c0.9-0.1,1,0.4,2,0.3
			c0.9-0.1,1,0.2,1.9,0.1c0.9-0.1,1,0.2,1.9,0.1c0.9-0.1,0.7-1.4,1.7-1.6c0.9-0.1,1.2,1.6,2.1,1.5c0.9-0.1,0.7-1.5,1.7-1.6
			c0.9-0.1,1.1,1,2,0.9c0.9-0.1,0.9-0.4,1.8-0.5c0.9-0.1,0.8-1,1.8-1.1c0.9-0.1,1.1,1,2,0.8c0.9-0.1,0.8-1,1.8-1.2
			c0.9-0.1,1.4,0.7,2,0c0.6-0.7,0.2-1,0.1-2c-0.1-0.9-0.2-0.9-0.3-1.9c-0.1-1-1-0.9-1-1.8c-0.1-1-0.4-0.9-0.5-1.9
			c-0.1-0.9,1.3-1.1,1.2-2c-0.1-0.9-1-0.9-1.1-1.8c-0.1-0.9,0.7-1,0.6-2c-0.1-1-1.2-0.8-1.3-1.8c-0.1-1,1.2-1.1,1.1-2
			c-0.1-1-1.6-0.8-1.7-1.8c-0.1-1,0.9-1,0.8-2c-0.1-1-0.8-0.9-0.9-1.8c-0.1-1-0.2-0.9-0.3-1.9c-0.1-1-0.3-0.9-0.4-1.9
			c-0.1-1,0.5-1,0.5-2c-0.1-1-0.5-0.9-0.6-1.9c-0.1-1,0.9-1.1,0.9-2c-0.1-1-1.3-0.8-1.4-1.8c-0.1-1-0.1-0.9-0.2-1.9
			c-0.1-1,1.3-1.1,1.2-2c-0.1-1-0.9-0.9-1-1.8c-0.1-1,0.3-1,0.2-1.9c-0.1-1-0.2-0.9-0.3-1.9c-0.1-1-0.8-0.9-0.8-1.8
			c-0.1-1-0.3-0.9-0.4-1.9c-0.1-1,1.1-0.9,0.4-1.5c-0.7-0.6-0.5,0-1.4,0.1c-0.9,0.1-1-0.2-1.9-0.1c-0.9,0.1-1-0.5-1.9-0.4
			c-0.9,0.1-0.9,0.3-1.9,0.4c-0.9,0.1-0.9,0.5-1.8,0.6c-0.9,0.1-0.9,0.6-1.8,0.7c-0.9,0.1-1.1-1.1-2-1c-0.9,0.1-1,0-1.9,0.1
			c-0.9,0.1-0.9,0.7-1.8,0.8c-0.9,0.1-1-0.4-1.9-0.3c-0.9,0.1-0.9,0.4-1.8,0.5c-0.9,0.1-0.9,0.5-1.8,0.6c-0.9,0.1-0.9,0.1-1.9,0.2
			c-0.9,0.1-1-0.6-2-0.5c-0.9,0.1-0.7,1.7-1.7,1.8c-0.9,0.1-1,0-1.9,0.1c-0.9,0.1-1.1-1.1-2-1c-0.9,0.1-0.8,1.6-1.7,1.7
			c-0.9,0.1-1-0.7-2-0.6c-0.9,0.1-0.9,0.3-1.9,0.4c-0.9,0.1-0.9,0.5-1.8,0.6c-0.9,0.1-1.1-1-2-0.8c-0.9,0.1-0.8,1.5-1.7,1.6
			c-0.9,0.1-1.1-1.6-2.1-1.5c-0.9,0.1-0.8,1.4-1.7,1.5c-0.9,0.1-1-0.7-2-0.5c-0.9,0.1-1-0.2-1.9-0.1c-0.9,0.1-0.9,0.3-1.9,0.4
			c-0.9,0.1-0.9,0.1-1.9,0.2c-0.9,0.1-0.9,0.7-1.8,0.8c-0.9,0.1-0.9,0.3-1.9,0.4c-0.9,0.1-1-0.8-2-0.7c-0.9,0.1-0.8,1.7-1.7,1.8
			c-0.9,0.1-1-0.3-1.9-0.2c-0.9,0.1-0.9,0.3-1.8,0.4c-0.9,0.1-1-0.3-1.9-0.2c-0.9,0.1-1-0.3-1.9-0.2c-0.9,0.1-0.9,0.7-1.8,0.8
			c-0.9,0.1-1.1-1.2-2-1c-0.9,0.1-0.8,1.3-1.7,1.4c-0.9,0.1-0.9,0.3-1.9,0.4c-0.9,0.1-1.1-0.9-2-0.8c-0.9,0.1-0.8,1.4-1.7,1.5
			c-0.9,0.1-1-0.4-1.9-0.3c-0.9,0.1-0.9,0.7-1.8,0.8c-0.9,0.1-1-0.5-1.9-0.4c-0.9,0.1-0.9,0.5-1.8,0.6c-0.9,0.1-1.1-1.2-2-1
			c-0.9,0.1-0.9,0.5-1.8,0.6c-0.9,0.1-1-0.5-2-0.4c-0.9,0.1-0.9,0.3-1.9,0.4c-0.9,0.1-0.9,0.8-1.8,0.9c-0.9,0.1-0.9,0.5-1.8,0.7
			c-0.9,0.1-1-0.7-2-0.6c-0.9,0.1-1-0.1-1.9,0c-0.9,0.1-0.8,1.2-1.8,1.3c-0.9,0.1-1-0.5-1.9-0.4c-0.9,0.1-1-0.5-2-0.4
			c-0.9,0.1-0.9,0.2-1.9,0.3c-0.9,0.1-0.8,1.3-1.7,1.4c-0.9,0.1-0.9,0.3-1.9,0.5c-0.9,0.1-1.1-0.9-2-0.8c-0.9,0.1-0.8,1.2-1.7,1.4
			c-0.9,0.1-1-0.8-2-0.7c-0.9,0.1-1-0.6-2-0.5c-0.9,0.1-0.8,1.3-1.7,1.4c-0.9,0.1-1.1-0.9-2-0.8c-0.9,0.1-0.7,1.8-1.7,1.9
			c-0.9,0.1-1.1-1-2-0.9c-0.9,0.1-0.9,0.3-1.9,0.4c-0.9,0.1-1-0.7-2-0.6c-0.9,0.1-0.8,1.2-1.8,1.3c-0.9,0.1-0.9,0.6-1.8,0.7
			c-0.9,0.1-1-0.4-1.9-0.3c-0.9,0.1-0.9,0.6-1.8,0.8c-0.9,0.1-1-0.4-1.9-0.3c-0.9,0.1-0.9,0.3-1.9,0.4c-0.9,0.1-1-0.1-1.9,0
			c-0.9,0.1-0.9,1-1.8,1.1c-1,0.1-1.9,0-1.9,0.9c0.1,0.9,0.9,0.9,1,1.8c0.1,0.9-0.1,1-0.1,1.9c0.1,0.9-0.4,1-0.3,1.9
			c0.1,0.9-0.2,1-0.1,1.9c0.1,0.9-0.5,1-0.4,2c0.1,0.9,1.9,0.8,2,1.7c0.1,0.9-1.3,1.1-1.2,2c0.1,0.9,0.1,0.9,0.2,1.9
			c0.1,0.9,1.1,0.8,1.2,1.8c0.1,0.9,0,1,0.1,1.9c0.1,0.9,0,1,0.1,1.9c0.1,0.9-0.7,1-0.6,2c0.1,0.9,0,1,0.1,1.9
			c0.1,0.9,0.6,0.9,0.7,1.8c0.1,0.9,0.2,0.9,0.3,1.9c0.1,0.9-0.1,1,0,1.9c0.1,0.9-0.4,1-0.3,1.9c0.1,0.9,0.6,0.9,0.7,1.8
			c0.1,0.9,1,0.9,1.1,1.8c0.1,0.9-0.1,1,0,1.9c0.1,0.9,0.1,0.9,0.2,1.9c0.1,0.9,0.2,0.9,0.3,1.9c0.1,0.9-0.5,1-0.4,2
			c0.1,0.9-0.6,1-0.5,2c0.1,0.9,0.1,0.9,0.2,1.9c0.1,1,0.2,0.9,0.2,1.9C10.9,404.4,11.4,404.3,12.1,404.9z"
          />
        </g>
        <g>
          <g>
            <g>
              <path class="st3" d="M127.4,355.2c1.2-0.7,1.4-1.3,1.9,0.2" />
              <path class="st1" d="M127.4,355.2c1.2-0.7,1.4-1.3,1.9,0.2" />
            </g>
            <g>
              <path class="st3" d="M114.7,366.6c1.2-0.7,2.3-1.7,2.8-0.3" />
              <path class="st1" d="M114.7,366.6c1.2-0.7,2.3-1.7,2.8-0.3" />
            </g>
            <g>
              <path class="st3" d="M103.8,351.3c1.2-0.7,2.2-2.1,2.7-0.7" />
              <path class="st1" d="M103.8,351.3c1.2-0.7,2.2-2.1,2.7-0.7" />
            </g>
            <g>
              <path class="st3" d="M143.3,373.4c1.2-0.7,1-1.6,1.5-0.2" />
              <path class="st1" d="M143.3,373.4c1.2-0.7,1-1.6,1.5-0.2" />
            </g>
            <g>
              <path class="st3" d="M97,386.1c1.2-0.7,1.9-0.1,2.5,1.3" />
              <path class="st1" d="M97,386.1c1.2-0.7,1.9-0.1,2.5,1.3" />
            </g>
            <g>
              <path class="st3" d="M88.1,367c1.2-0.7,2.5-1.2,3,0.2" />
              <path class="st1" d="M88.1,367c1.2-0.7,2.5-1.2,3,0.2" />
            </g>
            <g>
              <path class="st3" d="M64.4,356.5c1.2-0.7,1.3-0.4,1.8,1" />
              <path class="st1" d="M64.4,356.5c1.2-0.7,1.3-0.4,1.8,1" />
            </g>
            <g>
              <path class="st3" d="M65,380c1.2-0.7,2.8-0.6,3.4,0.8" />
              <path class="st1" d="M65,380c1.2-0.7,2.8-0.6,3.4,0.8" />
            </g>
            <g>
              <path class="st3" d="M40.2,395.3c1.2-0.7,0.4-1.5,1-0.1" />
              <path class="st1" d="M40.2,395.3c1.2-0.7,0.4-1.5,1-0.1" />
            </g>
            <g>
              <path class="st3" d="M40.7,372.8c1.2-0.7,1.2-1.4,1.7,0" />
              <path class="st1" d="M40.7,372.8c1.2-0.7,1.2-1.4,1.7,0" />
            </g>
            <g>
              <path class="st3" d="M18.7,386c1.2-0.7,2-0.9,2.5,0.5" />
              <path class="st1" d="M18.7,386c1.2-0.7,2-0.9,2.5,0.5" />
            </g>
            <g>
              <path class="st3" d="M24,359.6c1.2-0.7,1.7-0.3,2.3,1.2" />
              <path class="st1" d="M24,359.6c1.2-0.7,1.7-0.3,2.3,1.2" />
            </g>
            <g>
              <path class="st3" d="M143.8,346.6c1.2-0.7,0.9-1,1.4,0.4" />
              <path class="st1" d="M143.8,346.6c1.2-0.7,0.9-1,1.4,0.4" />
            </g>
          </g>
        </g>
      </g>

      <g id="KK-A1-1-9" class="hover-obj lejer" @click="$store.commit('setModal', 'a-trae')">
        <g>
          <path
            class="st0"
            d="M1450.7,350.5c-3.6-0.8-3.6-0.8-3.6-0.8s-11.9,16.4-18.7,24.1c-1.7-20.5-5.1-53-5.1-53h-4.2
			c0,0,0.5,16.7,1.4,33.5l-17-12.3l-2.3,2c0,0,13.7,11.1,19.7,17.8c0.9,19-3.6,57.1-3.6,57.1c0,1.2,2.7,2.1,6.1,2.2
			c5.5,0.1,6.1-0.7,6.1-2.2c0,0,0.2-19.7,0-30.4C1429.5,388.6,1436.7,362.3,1450.7,350.5z"
          />
          <g>
            <path
              class="st3"
              d="M1443.1,198.2c1.7,0,3.3,0,4.9,0.1c1.7,0.1,3.3,0.4,4.9,0.6c1.7,0.3,3.1,1.2,4.7,1.7c1.6,0.5,3.5,0.2,5,0.8
				c1.5,0.7,2.8,1.9,4.2,2.7c1.4,0.9,2.4,2.2,3.7,3.3c1.2,1,2.3,2.1,3.2,3.5c0.8,1.3,1.6,2.7,2.2,4.2c0.6,1.5,1.6,2.8,2.3,4.3
				c0.6,1.5,0.6,3.3,1.4,4.7c0.8,1.5,2.1,2.5,3.1,3.7c0.8,1,2.2,1.9,3.7,3.1c1.2,0.9,2.1,2.4,3.5,3.5c1.2,1,2.8,1.6,4.1,2.7
				c1.2,1,2,2.7,3.2,3.8c1.2,1.1,2.8,1.9,3.8,3.1c1.1,1.3,2,2.6,2.9,4c0.9,1.4,1.4,3,2,4.5c0.5,1.4,0.7,3,1.1,4.7
				c0.4,1.5,0.7,3.1,1,4.7c0.3,1.6,0.5,3.1,0.7,4.8c0.2,1.6,0.8,3.2,0.9,4.8c0.1,1.6-0.1,3.3-0.1,4.9c0,1.6-0.2,3.3-0.3,4.9
				c-0.1,1.7-0.3,3.3-0.5,4.8c-0.2,1.7-0.1,3.3-0.4,4.9c-0.3,1.7-0.9,3.2-1.3,4.7c-0.5,1.5-0.9,3.1-1.4,4.5
				c-0.6,1.5-0.8,3.1-1.3,4.6c-0.5,1.5-1.5,2.9-1.9,4.4c-0.4,1.6-0.3,3.2-0.5,4.8c-0.2,1.6-0.7,3.2-0.4,4.8c0.2,1.5,1,3,1,4.6
				c0.1,1.6-0.7,3.1-0.8,4.7c-0.1,1.6,0,3.2-0.3,4.7c-0.4,1.5-0.4,3.2-1.1,4.7c-0.6,1.4-2.1,2.4-3.1,3.6c-0.9,1.2-1.3,3-2.6,4.1
				c-1.2,1-2.7,1.9-4.2,2.9c-1.3,0.9-2.8,1.7-4.3,2.6c-1.4,0.8-2.9,1.8-4.4,2.5c-1.5,0.8-3.3,1-4.8,1.7c-1.5,0.7-3,1.6-4.6,2.2
				c-1.6,0.6-3.1,1.6-4.6,2.1c-1.7,0.5-3.4,0.8-5,1.2c-1.8,0.4-3.5,0-5.1,0.1c-1.3,0.1-2.8,0.9-4.5,0.9c-1.4,0-2.9-0.2-4.5-0.2
				c-1.5-0.1-2.9-0.5-4.5-0.6c-1.5-0.1-2.9-0.6-4.4-0.8c-1.5-0.2-3.1,0.1-4.5-0.2c-1.6-0.3-3-1-4.3-1.4c-1.6-0.6-2.7-1.6-3.7-2.4
				c-1.2-1-2.5-1.9-3.6-3.2c-1-1.2-2.2-2.3-3.4-3.4c-1.2-1.1-2.2-2.5-3.8-3.1c-1.4-0.6-3.1-0.5-4.9-0.5c-1.4,0-3,0.3-4.8,0.7
				c-1.4,0.3-2.8,1.1-4.5,1.9c-1.4,0.6-3,1.1-4.6,1.8c-1.5,0.7-3.2,0.8-4.8,1.4c-1.5,0.6-3,1.3-4.6,1.7c-1.6,0.4-3.2,1.4-4.8,1.5
				c-1.7,0-3.3-0.6-4.9-1.2c-1.5-0.5-3.1-1.1-4.4-2.3c-1.2-1-2.4-2.2-3.4-3.7c-0.9-1.2-1.7-2.7-2.4-4.4c-0.7-1.5-1.1-3.1-1.6-4.6
				c-0.5-1.6-1.1-3.1-1.4-4.7c-0.4-1.6-0.7-3.2-0.9-4.8c-0.2-1.6-0.1-3.3-0.1-4.9c0-1.6-0.3-3.3-0.2-4.9c0.1-1.6,0.7-3.2,0.9-4.8
				c0.3-1.6,0.8-3.2,1.2-4.7c0.4-1.6,0.4-3.3,0.9-4.8c0.5-1.6,1.3-3,1.9-4.5c0.6-1.5,1-3.2,1.6-4.6c0.7-1.5,2-2.7,2.7-4.1
				c0.7-1.5,0.8-3.3,1.5-4.7c0.7-1.4,1.4-2.7,1.7-4.3c0.4-1.5,0.4-3,0.5-4.6c0.1-1.5,0.8-3.1,0.7-4.7c0-1.5-0.4-3.1-0.5-4.7
				c-0.1-1.5,0-3.1-0.2-4.7c-0.2-1.5-1-3-1.2-4.6c-0.2-1.5-0.3-3.1-0.5-4.7c-0.2-1.6-0.8-3.1-1-4.6c-0.2-1.6,0.1-3.2,0-4.7
				c-0.1-1.6,0.3-3.2,0.3-4.7c0.1-1.6,0.3-3.1,0.5-4.6c0.2-1.6,0.1-3.2,0.6-4.7c0.5-1.5,1.2-2.9,2.1-4.2c1-1.5,1.7-3.1,2.8-4.3
				c1.2-1.3,2.6-2.3,3.9-3.3c1.4-1.1,2.8-2,4.2-2.8c1.5-0.8,3.1-1.6,4.6-2.1c1.6-0.5,3.4-0.7,5-1.1c1.7-0.4,3.3-0.8,4.9-1.1
				c1.7-0.3,3.5-0.2,5-0.5c1.8-0.3,3.5-0.4,5-1c1.5-0.5,3-1,4.4-1.6c1.4-0.7,2.8-1.5,4.1-2.3c1.4-0.7,2.5-2,3.8-2.8
				c1.4-0.8,3-1,4.4-1.7c1.4-0.7,2.5-2.1,3.9-2.7c1.5-0.6,3.1-0.9,4.6-1.3C1440,198.4,1441.5,198.2,1443.1,198.2z"
            />
            <path
              class="st1"
              d="M1443.1,198.2c1.7,0,3.3,0,4.9,0.1c1.7,0.1,3.3,0.4,4.9,0.6c1.7,0.3,3.1,1.2,4.7,1.7c1.6,0.5,3.5,0.2,5,0.8
				c1.5,0.7,2.8,1.9,4.2,2.7c1.4,0.9,2.4,2.2,3.7,3.3c1.2,1,2.3,2.1,3.2,3.5c0.8,1.3,1.6,2.7,2.2,4.2c0.6,1.5,1.6,2.8,2.3,4.3
				c0.6,1.5,0.6,3.3,1.4,4.7c0.8,1.5,2.1,2.5,3.1,3.7c0.8,1,2.2,1.9,3.7,3.1c1.2,0.9,2.1,2.4,3.5,3.5c1.2,1,2.8,1.6,4.1,2.7
				c1.2,1,2,2.7,3.2,3.8c1.2,1.1,2.8,1.9,3.8,3.1c1.1,1.3,2,2.6,2.9,4c0.9,1.4,1.4,3,2,4.5c0.5,1.4,0.7,3,1.1,4.7
				c0.4,1.5,0.7,3.1,1,4.7c0.3,1.6,0.5,3.1,0.7,4.8c0.2,1.6,0.8,3.2,0.9,4.8c0.1,1.6-0.1,3.3-0.1,4.9c0,1.6-0.2,3.3-0.3,4.9
				c-0.1,1.7-0.3,3.3-0.5,4.8c-0.2,1.7-0.1,3.3-0.4,4.9c-0.3,1.7-0.9,3.2-1.3,4.7c-0.5,1.5-0.9,3.1-1.4,4.5
				c-0.6,1.5-0.8,3.1-1.3,4.6c-0.5,1.5-1.5,2.9-1.9,4.4c-0.4,1.6-0.3,3.2-0.5,4.8c-0.2,1.6-0.7,3.2-0.4,4.8c0.2,1.5,1,3,1,4.6
				c0.1,1.6-0.7,3.1-0.8,4.7c-0.1,1.6,0,3.2-0.3,4.7c-0.4,1.5-0.4,3.2-1.1,4.7c-0.6,1.4-2.1,2.4-3.1,3.6c-0.9,1.2-1.3,3-2.6,4.1
				c-1.2,1-2.7,1.9-4.2,2.9c-1.3,0.9-2.8,1.7-4.3,2.6c-1.4,0.8-2.9,1.8-4.4,2.5c-1.5,0.8-3.3,1-4.8,1.7c-1.5,0.7-3,1.6-4.6,2.2
				c-1.6,0.6-3.1,1.6-4.6,2.1c-1.7,0.5-3.4,0.8-5,1.2c-1.8,0.4-3.5,0-5.1,0.1c-1.3,0.1-2.8,0.9-4.5,0.9c-1.4,0-2.9-0.2-4.5-0.2
				c-1.5-0.1-2.9-0.5-4.5-0.6c-1.5-0.1-2.9-0.6-4.4-0.8c-1.5-0.2-3.1,0.1-4.5-0.2c-1.6-0.3-3-1-4.3-1.4c-1.6-0.6-2.7-1.6-3.7-2.4
				c-1.2-1-2.5-1.9-3.6-3.2c-1-1.2-2.2-2.3-3.4-3.4c-1.2-1.1-2.2-2.5-3.8-3.1c-1.4-0.6-3.1-0.5-4.9-0.5c-1.4,0-3,0.3-4.8,0.7
				c-1.4,0.3-2.8,1.1-4.5,1.9c-1.4,0.6-3,1.1-4.6,1.8c-1.5,0.7-3.2,0.8-4.8,1.4c-1.5,0.6-3,1.3-4.6,1.7c-1.6,0.4-3.2,1.4-4.8,1.5
				c-1.7,0-3.3-0.6-4.9-1.2c-1.5-0.5-3.1-1.1-4.4-2.3c-1.2-1-2.4-2.2-3.4-3.7c-0.9-1.2-1.7-2.7-2.4-4.4c-0.7-1.5-1.1-3.1-1.6-4.6
				c-0.5-1.6-1.1-3.1-1.4-4.7c-0.4-1.6-0.7-3.2-0.9-4.8c-0.2-1.6-0.1-3.3-0.1-4.9c0-1.6-0.3-3.3-0.2-4.9c0.1-1.6,0.7-3.2,0.9-4.8
				c0.3-1.6,0.8-3.2,1.2-4.7c0.4-1.6,0.4-3.3,0.9-4.8c0.5-1.6,1.3-3,1.9-4.5c0.6-1.5,1-3.2,1.6-4.6c0.7-1.5,2-2.7,2.7-4.1
				c0.7-1.5,0.8-3.3,1.5-4.7c0.7-1.4,1.4-2.7,1.7-4.3c0.4-1.5,0.4-3,0.5-4.6c0.1-1.5,0.8-3.1,0.7-4.7c0-1.5-0.4-3.1-0.5-4.7
				c-0.1-1.5,0-3.1-0.2-4.7c-0.2-1.5-1-3-1.2-4.6c-0.2-1.5-0.3-3.1-0.5-4.7c-0.2-1.6-0.8-3.1-1-4.6c-0.2-1.6,0.1-3.2,0-4.7
				c-0.1-1.6,0.3-3.2,0.3-4.7c0.1-1.6,0.3-3.1,0.5-4.6c0.2-1.6,0.1-3.2,0.6-4.7c0.5-1.5,1.2-2.9,2.1-4.2c1-1.5,1.7-3.1,2.8-4.3
				c1.2-1.3,2.6-2.3,3.9-3.3c1.4-1.1,2.8-2,4.2-2.8c1.5-0.8,3.1-1.6,4.6-2.1c1.6-0.5,3.4-0.7,5-1.1c1.7-0.4,3.3-0.8,4.9-1.1
				c1.7-0.3,3.5-0.2,5-0.5c1.8-0.3,3.5-0.4,5-1c1.5-0.5,3-1,4.4-1.6c1.4-0.7,2.8-1.5,4.1-2.3c1.4-0.7,2.5-2,3.8-2.8
				c1.4-0.8,3-1,4.4-1.7c1.4-0.7,2.5-2.1,3.9-2.7c1.5-0.6,3.1-0.9,4.6-1.3C1440,198.4,1441.5,198.2,1443.1,198.2z"
            />
          </g>
        </g>
      </g>
      <g id="KK-A1-1-2" class="hover-obj tmf" @click="$store.commit('setModal', 'a-1.2')">
        <g>
          <path
            class="st0"
            d="M1573.3,377.9c2.2,1.8,3.8,4.2,8.1,4.3c4.3,0,8.7,0.9,9.3,3.7s9.4,2.9,7.4,7.8c-2,4.9-10.2,10.2-15.9,7.2
			c-4.8-2-12.5,7.4-18.1,5.4c-5.6-2.1-6-4.6-10.8-3.9c-4.9,0.7-11,1.9-16.2,3.8s-6.6-10.2-8.9-10.8c-2.3-0.6-4.6,9.3-9.1,8.7
			c-4.5-0.6-10.8-11.6-17-13.2s-17.1-0.7-21.3-4.8c-4.1-4.1-6.3-5.7-5.2-7.8c1.1-2.2,2.7-6,4.9-6c2.2,0,3.6,0.8,4.9,0.4
			c1.3-0.4,1.8-3.3,8.7-2.6s29.2,0.4,33.7,3.6s5.1-2.8,11.5-1.4C1545.7,373.5,1573.3,377.9,1573.3,377.9z"
          />
        </g>
        <g>
          <g>
            <path
              class="st0"
              d="M1514.3,378.4c-0.3,0.1-0.6,0.1-1.1,0.1c-0.4,0-0.8,0.1-1.2,0.1c-0.2,0-0.6,0.1-1.1,0.1
				c-0.5,0.1-0.9,0.1-1.1,0.1c-1,0.1-1.2-0.1-0.8-0.7c0.4-0.5,0.8-0.9,1-1.2c0,0,0.1-0.1,0.2-0.3c0.1-0.1,0.3-0.3,0.4-0.5
				c0.2-0.2,0.4-0.5,0.6-0.7c0.2-0.2,0.4-0.5,0.6-0.7c0.4-0.5,0.8-0.9,1.3-1.4c0-0.1,0.1-0.1,0.2-0.2c0.1-0.1,0.2-0.2,0.3-0.3
				c0.2-0.2,0.3-0.4,0.5-0.6c0.1-0.1,0-0.3-0.1-0.5l-3.6-5l-0.2-0.2c0-0.1-0.1-0.1-0.1-0.2c-0.2-0.3-0.5-0.3-0.8,0
				c-0.2,0.2-0.8,0.8-1.6,1.8c-0.7,0.9-1.3,1.5-1.5,1.9c-0.2,0.2-0.4,0.4-0.6,0.8c-0.2,0.2-0.4,0.5-0.6,0.8
				c-0.1,0.1-0.2,0.2-0.3,0.4c-0.1,0.1-0.2,0.2-0.3,0.4c-0.2,0.2-0.4,0.5-0.6,0.8c-0.3,0.4-0.5,0.6-0.7,0.6
				c-0.1-0.6-0.1-1.3-0.2-2.1c-0.1-0.9-0.1-1.6-0.1-2.3c0-0.2,0-0.4,0-0.6c0-0.2,0-0.4,0-0.6c0-0.2,0-0.4,0-0.5c0-0.2,0-0.4,0-0.6
				c0,0,0-0.4-0.1-1.1c0-0.5-0.1-0.9-0.1-1.1c-0.2,0-0.9,0.1-2,0.3c-0.6,0.1-1,0.2-1.4,0.3c-0.4,0.1-0.6,0.1-0.7,0.2
				c-0.4,0.1-0.8,0.2-1.4,0.3c-0.5,0.1-1.1,0.3-1.7,0.4c-1.2,0.3-2.2,0.6-3,0.8c-0.5,0.2-0.8,0.3-0.9,0.4c-0.2,0.2-0.3,0.6-0.3,1.1
				c0,0.2,0,0.5,0,0.9c0,0.4,0,1,0,1.7c0,1.4,0.1,2.2,0.1,2.3c0.1,0,0.4,0,0.7-0.1c0.3,0,0.5-0.1,0.7-0.1c0.2,0,0.4,0,0.7-0.1
				c0.3-0.1,0.5,0,0.6,0.1c0.2,0.2,0.2,0.7,0.2,1.4c0,0.4,0,0.7-0.1,1c0,0.3,0,0.5,0,0.6c0,0.1,0,0.3,0,0.4c0,0.1,0,0.3,0,0.4
				c0,0.3,0,0.6,0,0.8c-0.1,1.8,0.1,4,0.4,6.6c0.3,2.6,0.6,4.8,0.9,6.5l0.1,0.7l0.1,0.7c0.1,0.5,0.2,0.9,0.3,1.4
				c0.1,0.5,0.1,1,0.3,1.3c0.2,0.4,0.5,0.5,1,0.4c0.4-0.1,0.8-0.3,1.2-0.4c0.4-0.2,0.8-0.3,1.2-0.5c0.2-0.1,0.4-0.2,0.6-0.2
				c0.2-0.1,0.4-0.1,0.6-0.2c0.2-0.1,0.4-0.1,0.6-0.2c0.2-0.1,0.4-0.1,0.6-0.2c0.8-0.3,1.4-0.5,1.8-0.7s1-0.4,1.8-0.7
				c0.9-0.4,1.4-0.7,1.4-0.9c0-0.2-0.3-0.9-0.6-2c-0.2-0.4-0.3-0.8-0.4-1.2c-0.1-0.3-0.3-0.7-0.4-1.3c0-0.1-0.1-0.3-0.1-0.5
				c-0.1-0.2-0.1-0.5-0.2-0.8c-0.2-0.7-0.3-1.1-0.2-1.3c0.4,0,0.5,0,0.6,0l0.6,0c0.1,0,0.2,0,0.3,0c0.1,0,0.2,0,0.3,0
				c0.1,0,0.3,0,0.6,0c0.8,0,1.6,0,2.2,0c0.9,0,1.3,0.4,1.3,1.2c0,0.4,0,0.8-0.1,1.3c-0.1,0.6-0.1,1-0.2,1.3l-0.9,7.5
				c0,0.2-0.1,0.4-0.1,0.6c0,0.2,0,0.4-0.1,0.6c-0.1,0.5-0.1,0.9-0.1,1.3c0,0.4,0.2,0.6,0.7,0.4c0.4-0.1,0.9-0.2,1.5-0.3
				c0.4-0.1,0.9-0.2,1.4-0.3c0.3-0.1,0.6-0.1,0.9-0.2c0.3-0.1,0.6-0.1,1-0.2c0.7-0.1,1.3-0.3,2-0.4c0.1,0,0.5-0.1,0.9-0.2l1-0.2
				c0.1,0,0.3,0,0.4-0.1c0.2,0,0.4-0.1,0.5-0.1c0.4-0.1,0.7-0.2,0.9-0.3c0.2-0.1,0.4-0.4,0.4-0.9c0-0.3,0-0.9,0.2-2
				c0.1-1,0.1-1.5,0.1-1.7c-0.2,0-0.5,0-0.9,0.2c-0.5,0.1-0.8,0.2-0.9,0.2c-0.4,0.1-0.7,0.2-1.1,0.3c-0.4,0.1-0.6,0-0.6-0.3
				c-0.1-0.2,0-0.5,0.1-1c0.1-0.2,0.1-0.4,0.1-0.6c0-0.2,0.1-0.3,0.1-0.5c0-0.3,0.1-0.6,0.2-1.1c0-0.2,0.1-0.4,0.1-0.5
				c0-0.2,0.1-0.4,0.1-0.5l1.5-9.9L1514.3,378.4z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M1522.6,387.8c0.9-0.3,1.7-0.5,2.3-0.6c0,0.1-0.1,0.2-0.1,0.4c0,0.1-0.1,0.2-0.1,0.3c0,0.1,0,0.1,0,0.2
				c-0.1,0.3-0.1,0.5-0.2,0.7c-0.1,0.2-0.1,0.5-0.2,0.7c-0.1,0.7-0.2,1.2-0.3,1.5c-0.1,0.3-0.1,0.6-0.2,0.9
				c-0.1,0.3-0.1,0.7-0.2,1.1c0,0.2-0.1,0.6-0.1,1.1l-0.1,0.6l-0.1,0.5l-0.1,1.1c0,0.2,0,0.6-0.1,1.1c0,0.5,0,0.8,0,1.1
				c0,0.5,0,0.8,0,1.1c0,0.1,0,0.3,0,0.5c0,0.2,0,0.4,0,0.6c0,0.4-0.1,0.7-0.2,1c-0.2,0.3-0.4,0.4-0.7,0.3c-0.2,0-0.5-0.1-1-0.2
				c0,0.1,0,0.6,0.2,1.6c0.1,0.8,0.2,1.4,0.2,1.6c0,0.1,0,0.3,0,0.4c0,0.1,0,0.3,0.1,0.4c0,0.5,0.1,0.7,0.3,0.7c0,0,0.5-0.1,1.4-0.4
				c0.1,0,0.2-0.1,0.3-0.1c0.1,0,0.2-0.1,0.4-0.1c0.3-0.1,0.5-0.2,0.7-0.2c0.3-0.1,0.6-0.2,0.8-0.3c0.2-0.1,0.4-0.1,0.6-0.2
				c0.1,0,0.6-0.2,1.5-0.5c0.9-0.3,1.3-0.3,1.4-0.1c0,0.1,0,0.5-0.1,1.3c-0.1,0.7-0.1,1.2-0.1,1.5c0.2,0,0.6,0,1.2-0.1
				c0.3,0,0.6-0.1,0.8-0.1c0.2,0,0.4,0,0.5-0.1c0.6,0,1.2-0.1,1.8-0.1c0.6,0,1.3-0.1,1.9-0.2c1.3-0.1,2.5-0.3,3.6-0.4
				c1.2-0.2,2.3-0.4,3.3-0.7c1-0.3,2-0.7,3.1-1.1c0.5-0.2,1.2-0.6,1.9-0.9c0.7-0.4,1.2-0.9,1.4-1.5c0.2-0.4,0.2-0.8,0.3-1.2
				c0-0.5,0-0.9,0-1.3c-0.1-1.2-0.5-2.2-1.1-3.1c-0.1-0.2-0.2-0.3-0.2-0.3c-0.1-0.1-0.1-0.2-0.2-0.3l-0.3-0.4c0,0-0.1-0.1-0.1-0.1
				c0,0-0.1-0.1-0.1-0.1c-0.1-0.1-0.1-0.1-0.2-0.2c0,0-0.1-0.1-0.1-0.1l-0.1-0.1c0,0-0.3-0.2-0.8-0.7c-1.2-0.9-2.5-1.6-3.9-2
				c-0.1,0-0.3-0.1-0.4-0.1c-0.2,0-0.3-0.1-0.5-0.1c-0.4-0.1-0.6-0.2-0.8-0.3c-0.4-0.2-0.6-0.3-0.5-0.2c0-0.4,0.1-0.6,0.3-0.8
				c0.2-0.2,0.5-0.3,0.8-0.6c0.1-0.1,0.2-0.1,0.2-0.2c0.1-0.1,0.2-0.1,0.2-0.2c0.6-0.5,1.2-1.1,1.6-1.7c0.5-0.7,0.7-1.6,0.8-2.6
				c0.1-1,0-1.9-0.3-2.7c-0.3-0.8-0.8-1.4-1.5-1.9c-0.7-0.4-1.5-0.8-2.2-1c-1.5-0.4-2.8-0.5-3.8-0.3c-1,0.2-1.7,0.2-2,0.1
				c0-0.3,0.1-0.8,0.3-1.6c0.2-0.8,0.3-1.3,0.3-1.5c-0.2,0.1-0.9,0.4-1.9,1c-0.6,0.3-1,0.6-1.3,0.7c-0.3,0.2-0.5,0.3-0.6,0.4
				c-0.2,0.1-0.3,0.2-0.5,0.3c-0.2,0.1-0.3,0.2-0.5,0.3c-0.4,0.2-0.8,0.4-1,0.5c-0.5,0.3-0.8,0.4-1,0.6c-0.4,0.2-0.6,0.5-0.7,0.8
				c-0.1,0.1-0.1,0.4-0.2,0.6c-0.1,0.3-0.1,0.5-0.2,0.6c-0.1,0.1-0.2,0.1-0.5,0.2c-0.2,0.1-0.4,0.1-0.6,0.2
				c-0.5,0.1-0.9,0.2-1.2,0.3c-0.5,0.1-0.8,0.3-0.9,0.6c-0.1,0.1-0.2,0.3-0.3,0.6c0,0.1-0.1,0.2-0.1,0.3c0,0.1-0.1,0.2-0.1,0.3
				c-0.1,0.3-0.3,0.7-0.5,1.1c-0.2,0.5-0.4,0.8-0.5,1.1c0,0.1-0.1,0.1-0.1,0.2c0,0.1-0.1,0.2-0.1,0.4c-0.1,0.3-0.2,0.5-0.2,0.6
				C1521.1,388.3,1521.7,388.2,1522.6,387.8z M1533.6,386.7c0.4,0.3,0.5,0.4,0.6,0.4c0.3,0.3,0.4,0.8,0.3,1.5
				c-0.1,0.3-0.1,0.6-0.3,0.7c-0.1,0.2-0.3,0.3-0.4,0.5c-0.1,0.1-0.3,0.2-0.5,0.4c-0.3,0.2-0.5,0.3-0.7,0.2c0-0.2,0-0.5,0-0.9
				c0-0.2,0-0.3,0-0.5c0-0.2,0-0.3,0-0.5c0-0.3,0-0.6,0-1c0-0.3,0.1-0.7,0.1-1C1533.1,386.5,1533.4,386.6,1533.6,386.7z
				M1534.3,396.6c0.7,0.2,1,0.6,1,1.2c0,0.4-0.2,0.7-0.5,1c-0.4,0.3-0.7,0.6-0.9,0.7c0,0-0.1,0.1-0.3,0.2c-0.2,0.1-0.3,0.1-0.4,0.1
				c-0.1-0.1-0.1-0.5-0.2-1.2c-0.1-0.8-0.2-1.2-0.2-1.5c0-0.2,0-0.3-0.1-0.5c0-0.1,0-0.1-0.1-0.2c0-0.1,0-0.1,0-0.2
				C1532.9,396.3,1533.5,396.4,1534.3,396.6z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M1546.7,378.8l0.5-0.1c0.1,0,0.2,0,0.5-0.1c0.2,0,0.3,0,0.4,0.1c0.2,0.4,0.4,1,0.6,2
				c0.2,0.9,0.3,1.7,0.5,2.2c0,0.1,0.1,0.3,0.1,0.4c0,0.1,0.1,0.2,0.1,0.4c-0.3,0-0.6,0-0.8-0.1c-0.3-0.1-0.6-0.1-0.9-0.1
				c-0.5,0-0.8,0.3-0.9,0.9c-0.1,0.5-0.2,1.1-0.3,1.7c0,0.7-0.1,1.3-0.1,1.7l0,0.4l0,0.4c0,0.1,0,0.3,0,0.4c0,0.1,0,0.3,0,0.4
				c0,0.3,0.1,0.5,0.3,0.6c0.3,0.1,0.6,0.1,0.8,0.2c0.5,0.1,1.2,0.1,1.9,0.1c0.8,0,1.3,0.1,1.6,0.1c0.1,0.2,0.1,0.4,0.2,0.6
				c0,0.1,0,0.2,0.1,0.3c0,0.1,0,0.2,0.1,0.3c0.1,0.5,0.2,0.9,0.3,1.2c0.1,0.4,0.2,0.7,0.2,1.2c0.1,0.4,0.2,0.9,0.3,1.3
				c0.2,1,0.4,1.8,0.7,2.4c0.2,0.4,0.5,0.6,1,0.5c0.4,0,0.6-0.1,0.7-0.1c0.3,0,0.5-0.1,0.7-0.1l4-0.5c0.5-0.1,0.8-0.3,0.9-0.6
				c0.1-0.6,0.2-1.3,0.2-2.2c0-1,0-1.7,0-2.3c0-0.2,0-0.4,0-0.8c0-0.2,0-0.5,0.1-0.8c0.1,0,0.2,0,0.4,0s0.3,0,0.4,0l0.2,0l0.2,0
				c0.1,0,0.2,0,0.4,0c0.3,0,0.6,0,0.8,0c0.4,0,0.7,0,0.8,0.1c0,0,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2l0,1.4c0,0.4,0.1,0.9,0.1,1.3
				c0,0.5,0.1,0.9,0.1,1.4c0.1,1.1,0.2,2,0.3,2.7c0.1,0.5,0.1,1.1,0.3,1.7c0,0.1,0,0.3,0.1,0.4c0,0.1,0,0.3,0.1,0.4
				c0,0.2,0.1,0.5,0.2,0.8c0.1,0.4,0.2,0.7,0.4,0.7c0.2,0,0.5,0,0.9-0.1c0.3,0,0.7-0.1,1.3-0.2c0.6-0.1,1.4-0.3,2.3-0.4
				c1.9-0.3,2.9-0.5,3.1-0.6c0.3-0.1,0.5-0.3,0.5-0.6c0.1-0.3,0.1-0.6,0.1-1c0-0.6,0-1.3,0.1-2c0-0.2,0-0.3,0-0.5c0-0.2,0-0.3,0-0.5
				c0-0.3,0-0.7,0-1c0-0.2,0-0.3,0-0.5c0-0.2,0-0.3,0-0.5l0-1c0-0.2,0-0.4,0-0.5c0-0.1,0-0.2,0-0.2c0-0.1,0-0.2,0-0.3
				c0-0.2,0-0.3,0-0.5c0-0.2,0-0.4,0-0.5c0.2,0,0.5,0,0.7,0c0.2,0,0.5,0,0.7,0c0.4-0.1,0.7-0.3,0.9-0.6c0-0.1,0.2-0.4,0.5-1.1
				c0.1-0.2,0.2-0.5,0.3-0.6s0.2-0.4,0.2-0.5c0.1-0.2,0.2-0.4,0.4-0.8c0.2-0.4,0.4-0.9,0.7-1.5c0.6-1.2,0.8-2,0.8-2.3
				c-0.2-0.1-0.6-0.2-1.1-0.2c-0.3,0-0.5,0-0.8,0c-0.2,0-0.4,0-0.6,0c-0.2,0-0.4,0-0.6,0c-0.2,0-0.4,0-0.6,0c-0.5,0-0.9,0-1.2-0.1
				l0.1-2c0-0.1,0-0.3,0-0.5c0-0.2,0-0.4,0-0.5c0-0.3,0-0.6,0.1-0.9l0.1-0.7c0-0.4,0.3-0.5,0.9-0.4c0.1,0,0.3,0.1,0.5,0.1
				c0.2,0,0.3,0.1,0.5,0.1c0.3,0.1,0.7,0.1,1,0.1c0.1-0.2,0.1-0.6,0.1-1.1c0-0.4,0-0.8,0.1-1.2c0-0.4,0.1-0.8,0.1-1.2
				c0,0.2,0,0,0-0.6c0-0.4,0-0.7,0-0.8c0-0.1-0.7,0-1.8,0.1c-0.5,0-0.8,0.1-1,0.1c-0.5,0-0.8,0-1,0.1c-0.6,0-1.3,0.1-2.1,0.2
				c-0.5,0.1-1.2,0.1-2.1,0.2c-0.4,0-0.7,0.1-1,0.1c-0.3,0-0.7,0.1-1,0.1l-1.5,0.2c-0.1,0-0.2,0-0.3,0s-0.2,0-0.3,0
				c-0.3,0.3-0.5,1.1-0.6,2.4c-0.2,1.5-0.3,2.5-0.3,2.9c0,0.6-0.1,1,0,1.1c0,0.1,0,0.6-0.1,1.2c0,0.7-0.1,1-0.2,1l0,0l0,0l-0.3,0
				c-0.5,0-1,0-1.5,0c-0.6,0-1.1,0-1.3-0.1c0-0.5,0-1.1,0-1.7c0-0.4,0-1,0-1.7c0-0.2,0-0.4,0-0.6c0-0.3,0-0.5,0-0.6
				c0-0.2,0-0.6,0-1.1c0-0.4,0-0.7,0-1.1c0-0.3,0-0.5,0-0.6c0-0.1,0-0.3,0-0.6c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2,0-0.3
				c0-0.4,0-0.5,0-0.6l0-1c0-0.4,0-0.8,0-1.3c0-1-0.1-1.7-0.2-2.1c-0.1-0.3-0.4-0.4-0.8-0.4c-0.5,0.1-0.8,0.1-1,0.1
				c-1,0.2-2,0.3-3,0.5c-0.5,0.1-1,0.2-1.5,0.3s-1,0.2-1.5,0.3c-0.9,0.2-1.8,0.4-2.9,0.6c-1.2,0.3-2.2,0.5-2.8,0.8
				c-0.3,0.2-0.5,0.4-0.5,0.7c0,0.3,0,0.9,0,1.6c0,0.4,0,0.7,0.1,1c0,0.3,0,0.5,0.1,0.7c0,0.2,0,0.4,0,0.5c0,0.2,0,0.4,0,0.6
				c0,0.4,0,0.8,0.1,1.1C1546.3,378.8,1546.4,378.8,1546.7,378.8z"
            />
          </g>
        </g>
      </g>
      <g id="KK-1-5_2_" class="hover-obj keid kejd" @click="$store.commit('setModal', '1.5')">
        <g id="_x31_-5_x5F_vindue_x5F_14_2_">
          <polygon
            class="st4"
            points="1162,343.9 1164,345.4 1163.5,358.7 1159,360.3 1159.7,344.6 		"
          />
          <polygon
            class="st4"
            points="1167,347.4 1164,345.4 1163.5,358.7 1159,360.3 1159,361.7 1166.5,359.1 		"
          />
          <polygon
            class="st4"
            points="1168.4,348.5 1167,347.4 1166.5,359.1 1159,361.7 1158.9,365.1 1166.3,362.5 1166,371.6 1167.4,371 
			1167.8,362 1175.4,359.3 1175.6,356.1 1168,358.6 		"
          />
          <polygon class="st4" points="1172.1,355.7 1175.6,356.1 1168,358.6 1168,357.1 		" />
          <polygon
            class="st4"
            points="1168.4,348.5 1175.7,353.6 1175.6,356.1 1172.1,355.7 1168,357.1 		"
          />
          <polygon class="st4" points="1167.4,371 1175,367.8 1175.4,359.3 1167.8,362 		" />
          <polygon class="st4" points="1158.9,365.1 1158.5,374.7 1162.9,372.9 1163.3,363.6 		" />
          <polygon class="st4" points="1166,371.6 1162.9,372.9 1163.3,363.6 1166.3,362.5 		" />
          <polygon class="st4" points="1167.3,372 1174.9,368.8 1173.9,390.1 1166.3,393.2 		" />
          <polygon class="st4" points="1165.9,372.6 1167.3,372 1166.3,393.2 1165,393.7 		" />
          <polygon class="st4" points="1162.8,373.9 1165.9,372.6 1165,393.7 1162,393.1 		" />
          <polygon
            class="st4"
            points="1158.4,375.8 1162.8,373.9 1162,393.1 1165,393.7 1157.6,396.8 		"
          />
        </g>
        <g id="_x31_-5_x5F_vindue_x5F_13_3_">
          <polygon class="st4" points="1180.7,255.9 1180.3,263.6 1172.6,264.6 1173.2,251.2 		" />
          <polygon class="st4" points="1176.4,263.4 1180.3,263.6 1172.6,264.6 1172.7,263.9 		" />
          <polygon
            class="st4"
            points="1168.8,248.5 1171.9,250.4 1171.2,264.8 1163.6,265.8 1163.6,265 1168.1,264.4 		"
          />
          <polygon
            class="st4"
            points="1173.2,251.2 1171.9,250.4 1171.2,264.8 1163.6,265.8 1163.4,269.3 1171.1,268.1 1170.9,272.5 
			1172.3,271.9 1172.5,267.9 1180.1,266.8 1180.3,263.6 1172.6,264.6 		"
          />
          <polygon
            class="st4"
            points="1168.8,248.5 1167.9,247.9 1164.4,248.1 1163.6,265 1168.1,264.4 		"
          />
          <polygon class="st4" points="1163.4,269.3 1167.9,268.6 1167.7,273.8 1163.1,275.7 		" />
          <polygon class="st4" points="1170.9,272.5 1167.7,273.8 1167.9,268.6 1171.1,268.1 		" />
          <polygon class="st4" points="1172.5,267.9 1172.3,271.9 1180.1,268.7 1180.1,266.8 		" />
          <polygon class="st4" points="1172.3,273 1180,269.7 1178.6,298.5 1171,300 		" />
          <polygon class="st4" points="1170.8,273.6 1172.3,273 1171,300 1169.7,300.3 		" />
          <polygon class="st4" points="1167.6,274.9 1170.8,273.6 1169.7,300.3 1166.5,299.8 		" />
          <polygon
            class="st4"
            points="1163.1,276.8 1167.6,274.9 1166.5,299.8 1169.7,300.3 1161.9,301.8 		"
          />
        </g>
        <g id="_x31_-5_x5F_vindue_x5F_12_1_">
          <polygon
            class="st4"
            points="1037.8,260.3 1033.7,260.8 1033.1,282.2 1042.4,281 1042.9,263.8 		"
          />
          <polygon
            class="st4"
            points="1046,266 1042.9,263.8 1042.4,281 1033.1,282.2 1033.1,283.1 1045.5,281.5 		"
          />
          <polygon
            class="st4"
            points="1048.2,267.6 1046,266 1045.5,281.5 1033.1,283.1 1032.9,287.3 1045.4,285.6 1045.3,288.7 
			1047.6,287.8 1047.6,285.3 1056.6,284.1 1059.2,283 1059.3,279.7 1047.8,281.2 		"
          />
          <polygon
            class="st4"
            points="1059.5,275.5 1048.2,267.6 1047.8,280.3 1056,279.2 1059.3,279.7 		"
          />
          <polygon class="st4" points="1047.8,281.2 1047.8,280.3 1056,279.2 1059.3,279.7 		" />
          <polygon class="st4" points="1047.6,287.8 1056.6,284.1 1047.6,285.3 		" />
          <polygon class="st4" points="1045.3,288.7 1042.1,290 1042.2,286.1 1045.4,285.6 		" />
          <polygon class="st4" points="1032.9,287.3 1032.8,293.9 1042.1,290 1042.2,286.1 		" />
          <polygon class="st4" points="1045.3,290 1047.5,289 1046.5,325.4 1044.3,325.9 		" />
          <polygon class="st4" points="1042.1,291.3 1045.3,290 1044.3,325.9 1041.1,325.1 		" />
          <polygon
            class="st4"
            points="1032.7,295.2 1042.1,291.3 1041.1,325.1 1044.3,325.9 1031.8,328.4 		"
          />
          <polygon class="st4" points="1047.5,289 1059.2,284.2 1057.8,323.1 1046.5,325.4 		" />
        </g>
        <g id="_x31_-5_x5F_vindue_x5F_11_2_">
          <polygon
            class="st4"
            points="911.4,390.8 908.3,390.4 894.6,406.2 894.6,407.4 911.2,411 		"
          />
          <polygon
            class="st4"
            points="892.7,408.3 894.6,406.2 894.6,407.4 911.2,411 911.2,412.3 		"
          />
          <polygon
            class="st4"
            points="889,412.5 892.7,408.3 911.2,412.3 911.2,416.6 892.2,412.4 892.2,413.8 		"
          />
          <polygon class="st4" points="894.5,414.8 894.5,412.9 892.2,412.4 892.2,413.8 		" />
          <polygon class="st4" points="911.1,421.6 911.2,416.6 894.5,412.9 894.5,414.8 		" />
          <polygon class="st4" points="892.1,415 894.5,416 894.4,451.9 891.8,452.7 		" />
          <polygon class="st4" points="888.8,413.6 892.1,415 891.8,452.7 888.4,451.8 		" />
          <polygon
            class="st4"
            points="870.9,421.4 879.5,423.5 888.3,413.4 888.8,413.6 888.4,451.8 870.7,447.1 		"
          />
          <polygon
            class="st4"
            points="911.1,422.8 894.5,416 894.4,451.9 891.8,452.7 910.7,457.7 		"
          />
        </g>
        <g id="_x31_-5_x5F_vindue_x5F_10_2_">
          <g>
            <polygon class="st1" points="912.4,293.1 912.7,263.8 908.2,263.7 888.2,283.4 			" />
            <polygon
              class="st1"
              points="887.3,284.3 880.5,291 871.5,290.2 871.5,327.6 912,332.9 912.4,294.3 			"
            />
          </g>
          <g>
            <polygon class="st4" points="893.4,288.7 895.7,288.9 895.5,329.9 893.1,330.4 			" />
            <polygon class="st4" points="912.4,290.3 912.4,293.1 903.5,289.5 			" />
            <polygon
              class="st4"
              points="895.7,288.9 899.6,289.2 912.4,294.3 912,332.9 893.1,330.4 895.5,329.9 			"
            />
            <polygon
              class="st4"
              points="887.3,284.3 899.6,289.2 893.4,288.7 893.1,330.4 889.4,329.9 890,288.4 883.7,287.9 			"
            />
            <polygon class="st4" points="890.1,281.6 888.2,283.4 888.7,283.6 890.1,283.7 			" />
            <polygon
              class="st4"
              points="893.5,278.2 890.1,281.6 890.1,283.7 888.7,283.6 903.5,289.5 912.4,290.3 912.5,285.6 893.4,284 
							"
            />
            <polygon
              class="st4"
              points="895.9,275.8 895.8,283.6 912.5,284.9 912.5,285.6 893.4,284 893.5,278.2 			"
            />
            <polygon
              class="st4"
              points="912.7,263.8 908.2,263.7 895.9,275.8 895.8,283.6 912.5,284.9 			"
            />
            <polygon
              class="st4"
              points="883.7,287.9 880.5,291 871.5,290.2 871.5,327.6 889.4,329.9 890,288.4 			"
            />
          </g>
        </g>
        <g id="_x31_-5_x5F_vindue_x5F_9_2_">
          <polygon
            class="st1"
            points="779,264.9 781.6,262.6 781.7,273.9 794.2,274.8 794.2,275.4 779,274.3 		"
          />
          <polygon
            class="st1"
            points="781.6,262.6 789.5,256 794.1,256.3 794.2,274.8 781.7,273.9 		"
          />
          <polygon
            class="st1"
            points="779,264.9 776.3,267.2 776.3,274.1 772,273.8 785.2,279 794.2,279.8 794.2,275.4 779,274.3 		"
          />
          <polygon class="st1" points="770.3,273.1 772,273.8 776.3,274.1 776.3,273.5 		" />
          <polygon class="st1" points="776.3,267.2 769.7,272.8 770.3,273.1 776.3,273.5 		" />
          <polygon class="st1" points="794.2,279.8 794.2,282.6 785.2,279 		" />
          <polygon
            class="st1"
            points="768.7,273.6 781.7,278.9 780.5,278.6 779.1,278.5 779.2,315.5 776.4,315.1 776.3,278.3 764.4,277.3 
					"
          />
          <polygon
            class="st1"
            points="781.7,278.9 780.5,278.6 779.1,278.5 779.2,315.5 781.9,315.1 		"
          />
          <polygon
            class="st1"
            points="794.2,283.9 781.7,278.9 781.9,315.1 779.2,315.5 794.4,317.5 		"
          />
          <polygon
            class="st1"
            points="764.4,277.3 762.3,279.1 762.5,313.2 776.4,315.1 776.3,278.3 		"
          />
        </g>
        <g id="_x31_-5_x5F_vindue_x5F_8_2_">
          <polygon
            class="st1"
            points="782.2,382.9 794.7,385.6 794.5,367.8 791.1,367.4 782.2,376.1 		"
          />
          <polygon
            class="st1"
            points="779.9,378.4 782.2,376.1 782.2,382.9 794.7,385.6 794.7,386.9 779.9,383.6 		"
          />
          <polygon
            class="st1"
            points="777.2,381 779.9,378.4 779.9,383.6 794.7,386.9 794.7,390.6 779.9,387.4 779.9,391.8 777.2,390.6 
			777.2,386.8 772.4,385.8 775.6,382.7 777.2,383 		"
          />
          <polygon class="st1" points="770.4,387.8 772.4,385.8 777.2,386.8 777.2,390.6 		" />
          <polygon class="st1" points="777.2,381 775.6,382.7 777.2,383 		" />
          <polygon class="st1" points="782.3,387.9 782.3,392.7 779.9,391.8 779.9,387.4 		" />
          <polygon class="st1" points="794.7,390.6 794.8,397.9 782.3,392.7 782.3,387.9 		" />
          <polygon
            class="st1"
            points="769.5,388.6 777.2,391.9 777.2,422.7 763.6,419.1 763.3,394.8 		"
          />
          <polygon class="st1" points="779.9,393 777.2,391.9 777.2,422.7 780,423.4 		" />
          <polygon class="st1" points="782.3,394 779.9,393 780,423.4 782.5,422.7 		" />
          <polygon class="st1" points="794.8,399.2 782.3,394 782.5,422.7 780,423.4 795,427.4 		" />
        </g>
        <g id="_x31_-5_x5F_vindue_x5F_7_1_">
          <polygon
            class="st1"
            points="559.9,321.7 562.4,322.1 562.8,335 557.6,333.9 557.3,323.7 		"
          />
          <polygon
            class="st1"
            points="555,325.3 557.3,323.7 557.6,333.9 562.8,335 562.9,336 555.3,334.4 		"
          />
          <polygon
            class="st1"
            points="553.4,326.6 555,325.3 555.3,334.4 562.9,336 563,339 555.4,337.3 555.6,342.2 553.9,341.5 
			553.7,337 545.5,335.2 545.4,332.6 545.8,332.3 553.6,334 		"
          />
          <polygon class="st1" points="546.8,331.5 545.8,332.3 553.6,334 553.6,333 		" />
          <polygon class="st1" points="553.4,326.6 546.8,331.5 553.6,333 		" />
          <polygon class="st1" points="555.6,342.2 555.4,337.3 557.8,337.9 558,343.2 		" />
          <polygon class="st1" points="563.2,345.4 563,339 557.8,337.9 558,343.2 		" />
          <polygon class="st1" points="545.5,335.2 545.6,338 553.9,341.5 553.7,337 		" />
          <polygon class="st1" points="545.6,339.2 553.9,342.7 554.7,364.2 546.4,362 		" />
          <polygon class="st1" points="555.6,343.4 553.9,342.7 554.7,364.2 556.2,364.6 		" />
          <polygon class="st1" points="558,344.4 555.6,343.4 556.2,364.6 558.7,364.1 		" />
          <polygon class="st1" points="563.3,346.6 558,344.4 558.7,364.1 556.2,364.6 564,366.6 		" />
        </g>
        <g id="_x31_-5_x5F_vindue_x5F_6_2_">
          <polygon
            class="st1"
            points="506.1,322.7 505.7,312.1 507,311.2 509.8,311.7 510.3,323.6 		"
          />
          <polygon
            class="st1"
            points="503.4,313.8 503.8,323.1 510.4,324.6 510.3,323.6 506.1,322.7 505.7,312.1 		"
          />
          <polygon
            class="st1"
            points="502.1,314.6 503.4,313.8 503.8,323.1 510.4,324.6 510.5,327.4 503.9,326 504.2,330.5 502.7,329.9 
			502.6,325.7 495.5,324.1 495.4,321.3 502.5,322.8 		"
          />
          <polygon
            class="st1"
            points="495.3,319.5 495.4,321.3 497.9,320.9 502.4,321.9 502.1,314.6 		"
          />
          <polygon class="st1" points="502.5,322.8 502.4,321.9 497.9,320.9 495.4,321.3 		" />
          <polygon class="st1" points="495.5,324.1 495.6,326.9 502.7,329.9 502.6,325.7 		" />
          <polygon class="st1" points="506.3,326.5 506.4,331.4 504.2,330.5 503.9,326 		" />
          <polygon class="st1" points="510.7,333.2 510.5,327.4 506.3,326.5 506.4,331.4 		" />
          <polygon
            class="st1"
            points="510.8,334.5 506.5,332.7 507.2,350.7 505.1,351.2 511.5,352.9 		"
          />
          <polygon class="st1" points="504.2,331.7 506.5,332.7 507.2,350.7 505.1,351.2 		" />
          <polygon class="st1" points="495.7,328.2 502.8,331.1 503.5,350.8 496.5,348.9 		" />
          <polygon class="st1" points="504.2,331.7 502.8,331.1 503.5,350.8 505.1,351.2 		" />
        </g>
        <g id="_x31_-5_x5F_vindue_x5F_5_2_">
          <polygon
            class="st1"
            points="555.8,238.2 553.9,239.5 554.3,252.3 559.7,252.7 559.2,238.4 		"
          />
          <polygon class="st1" points="551.7,241 553.9,239.5 554.3,252.3 552.1,252.1 		" />
          <polygon class="st1" points="550.5,252 550.1,242 541.8,247.7 541.9,251.3 		" />
          <polygon
            class="st1"
            points="551.7,241 550.1,242 550.5,252 541.9,251.3 541.9,251.8 550,255.2 550.6,255.2 550.6,255.4 
			552.2,256.1 552.2,255.3 559.8,255.9 559.7,252.7 552.1,252.1 		"
          />
          <polygon class="st1" points="552.2,255.3 552.2,256.1 554.5,257.1 554.5,255.5 		" />
          <polygon class="st1" points="550.6,256.7 552.3,257.4 553.2,283 551.5,282.8 		" />
          <polygon class="st1" points="542,253.1 546.3,254.9 542,254.6 		" />
          <polygon class="st1" points="542,254.6 546.3,254.9 550.6,256.7 551.5,282.8 543,281.7 		" />
          <polygon class="st1" points="554.5,255.5 554.5,257.1 559.9,259.3 559.8,255.9 		" />
          <polygon class="st1" points="552.3,257.4 554.6,258.3 555.5,282.6 553.2,283 		" />
          <polygon class="st1" points="560,260.6 554.6,258.3 555.5,282.6 553.2,283 560.8,284 		" />
        </g>
        <g id="_x31_-5_x5F_vindue_x5F_4_1_">
          <polygon class="st1" points="502.6,252.8 507,254.6 508,277.2 501.4,276.2 503.6,275.9 		" />
          <g>
            <polygon class="st1" points="498.9,251.2 499.9,276 501.4,276.2 500.3,251.8 			" />
            <polygon
              class="st0"
              points="492.3,247.2 500.3,250.6 500.3,250.6 506.9,251.1 506.7,248.4 500.2,247.9 499.7,236.8 498.4,237.7 
				498.8,247.7 			"
            />
            <polygon class="st1" points="491.7,248.2 491.8,249.9 496.5,250.3 			" />
          </g>
          <polygon
            class="st1"
            points="498.4,237.7 499.7,236.8 500.2,247.9 506.7,248.4 506.9,251.1 500.3,250.6 492.3,247.2 498.8,247.7 
					"
          />
          <polygon class="st1" points="494,246.7 498.7,247.1 498.8,247.7 492.3,247.2 492,247.1 		" />
          <polygon class="st1" points="491.7,248.2 496.5,250.3 491.8,249.9 		" />
          <polygon class="st1" points="500.3,250.6 506.9,251.1 507,253.3 		" />
          <polygon class="st1" points="500.3,251.8 502.6,252.8 503.6,275.9 501.4,276.2 		" />
          <polygon class="st1" points="498.9,251.2 500.3,251.8 501.4,276.2 499.9,276 		" />
          <polygon class="st1" points="491.8,249.9 496.5,250.3 498.9,251.2 499.9,276 492.8,275 		" />
          <polygon class="st1" points="502.5,251.5 502.5,250.8 500.3,250.6 		" />
          <polygon
            class="st1"
            points="501.8,235.4 502.4,247.4 506.7,247.8 506.7,248.4 500.2,247.9 499.7,236.8 		"
          />
          <polygon
            class="st1"
            points="498.7,247.1 498.4,237.7 491.5,242.1 491.7,247 492,247.1 494,246.7 		"
          />
          <polygon
            class="st1"
            points="502.4,235.1 501.8,235.4 502.4,247.4 506.7,247.8 506.2,235.2 		"
          />
        </g>
        <g id="_x31_-5_x5F_vindue_x5F_3_2_">
          <polygon
            class="st1"
            points="903.8,493.4 903.8,497.7 897.9,500 888.6,497.1 888.6,488.8 		"
          />
          <polygon class="st1" points="873.3,484.2 873.3,492.4 885.6,496.2 885.6,487.9 		" />
          <polygon class="st1" points="888.6,488.8 885.6,487.9 885.6,496.2 888.6,497.1 		" />
        </g>
        <g id="_x31_-5_x5F_vindue_x5F_2_2_">
          <polygon
            class="st1"
            points="790.7,458.4 790.7,462.7 784.8,465 775.5,462.1 775.5,453.8 		"
          />
          <polygon class="st1" points="772.5,452.9 772.5,461.2 775.5,462.1 775.5,453.8 		" />
          <polygon class="st1" points="760.2,449.2 760.2,457.4 772.5,461.2 772.5,452.9 		" />
        </g>
        <g id="_x31_-5_x5F_vindue_x5F_1_2_">
          <polygon
            class="st1"
            points="560.3,390.8 560.3,393.8 554.6,394.8 553.6,394.4 553.6,388.8 		"
          />
          <polygon class="st1" points="547.2,386.8 552.2,388.4 552.2,394 547.2,392.4 		" />
          <polygon class="st1" points="553.6,388.8 552.2,388.4 552.2,394 553.6,394.4 		" />
        </g>
      </g>
      <g id="KK-1-12" class="hover-obj keid kejd" @click="$store.commit('setModal', '1.12')">
        <polygon
          class="st0"
          points="1612.1,463.8 1578.5,479.9 1571.8,477 1564.6,574.1 1605.8,537.1 	"
        />
        <polygon class="st0" points="1578.5,477.1 1578.5,479.9 1619.3,460.3 1619.3,457.5 	" />
        <polygon class="st0" points="1498.8,442.1 1578.5,477.1 1578.5,479.9 1498.8,444.9 	" />
        <polygon class="st0" points="1498.8,442.1 1498.8,444.9 1411.4,455.1 1411.4,452.2 	" />
        <polygon class="st0" points="1498.8,442.1 1515.3,437 1590.9,471.1 1578.5,477.1 	" />
        <polygon class="st0" points="1551.6,425.9 1537.5,430.2 1607.2,463.3 1619.3,457.5 	" />
        <polygon class="st0" points="1515.3,437 1537.5,430.2 1607.2,463.3 1590.9,471.1 	" />
        <polygon class="st0" points="1469,435.4 1456.6,439.1 1537.5,430.2 1551.6,425.9 	" />
        <polygon class="st0" points="1498.8,442.1 1515.3,437 1430.1,446.8 1411.4,452.2 	" />
        <polygon class="st0" points="1456.6,439.1 1430.1,446.8 1515.3,437 1537.5,430.2 	" />
        <path
          class="st0"
          d="M1498.8,444.9l-80,9.4l-5.8,88.6l99.4,20.5l4.6-71.3l40.9,6.4l-5,73.3l11.7,2.4l7.3-97.1L1498.8,444.9z M1463.4,502.4l-29.4-5.3l1.7-25.5l29.4,5.3L1463.4,502.4z M1497.1,508.5l-29.4-5.3l1.7-25.5l29.4,5.3L1497.1,508.5z"
        />
        <polygon class="st0" points="1498.8,483 1471.3,478.1 1469.7,502.2 1497.2,507.1 	" />
        <polygon class="st0" points="1469.3,477.7 1471.3,478.1 1469.7,502.2 1467.7,503.2 	" />
        <polygon class="st0" points="1497.1,508.5 1497.2,507.1 1469.7,502.2 1467.7,503.2 	" />
        <polygon class="st0" points="1437.6,472.1 1465,477 1463.5,501.1 1436,496.2 	" />
        <polygon class="st0" points="1463.5,501.1 1463.4,502.4 1434,497.2 1436,496.2 	" />
        <polygon class="st0" points="1435.6,471.7 1437.6,472.1 1436,496.2 1434,497.2 	" />
        <path
          class="st0"
          d="M1517,492.1l-4.6,71.3l40.5,8.4l5-73.3L1517,492.1z M1547,527.3l-23.4-3.2l1.6-20.9l23.3,3.4L1547,527.3z"
        />
        <polygon class="st0" points="1527.2,503.6 1525.6,522.8 1547.2,525.7 1548.5,506.7 	" />
        <polygon class="st0" points="1525.2,503.3 1523.6,524.1 1525.6,522.8 1527.2,503.6 	" />
        <polygon class="st0" points="1523.6,524.1 1525.6,522.8 1547.2,525.7 1547,527.3 	" />
        <polygon class="st0" points="1518.6,532.2 1518.5,533.4 1523.7,534.2 1523.8,532.9 	" />
      </g>
      <g id="KK-1-9" class="hover-obj lejer" @click="$store.commit('setModal', '1.9')">
        <g id="_x31_-9_x5F_skilt_x5F_2">
          <polygon class="st0" points="667.3,370.8 666.9,354 682.6,357.3 683,374.5 		" />
          <polygon
            class="st0"
            points="666.9,354 667.9,353.5 683.6,356.8 684,374 683,374.5 682.6,357.3 		"
          />
          <line class="st0" x1="669.4" y1="360.4" x2="679.5" y2="362.7" />
          <line class="st0" x1="669.5" y1="365.2" x2="679.7" y2="367.8" />
          <line class="st0" x1="672.4" y1="363.2" x2="676.4" y2="364.2" />
        </g>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  name: "AppSvg"
};
</script>


<style lang="scss" scoped>
//class="hover-obj" @click="$store.commit('setModal', '1.10')"
.st0 {
  fill: #ffffff;
  stroke: #000000;
  stroke-width: 0.3;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
.st1 {
  fill: none;
  stroke: #000000;
  stroke-width: 0.3;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
.st2 {
  fill: none;
}
.st3 {
  fill: #ffffff;
}
.st4 {
  fill: none;
  stroke: #1d1d1b;
  stroke-width: 0.3;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}

polygon,
rect,
path,
polyline,
ellipse,
circle {
  pointer-events: all;
}

.hover-obj {
  cursor: pointer;
  &.keid {
    &:hover {
      & polygon,
      rect,
      path,
      polyline,
      ellipse {
        //fill: rgba(0, 100, 117, 0.5);
        fill: #8eb1ba;
        transition: fill 0.1s linear;
      }
    }
  }

  &.kejd {
    &:hover {
      & polygon,
      rect,
      path,
      polyline,
      ellipse {
        //fill: rgba(0, 100, 117, 0.5);
        fill: #8eb1ba;
        transition: fill 0.1s linear;
      }
    }
  }

  &.tmf {
    &:hover {
      & polygon,
      rect,
      path,
      polyline,
      ellipse {
        //  fill: rgba(96, 170, 85, 0.5);
        fill: #b2d5ab;
        transition: fill 0.1s linear;
      }
    }
  }

  &.lejer {
    &:hover {
      & polygon,
      rect,
      path,
      polyline,
      ellipse {
        // fill: rgba(210, 77, 75, 0.5);
        fill: #e9a6a5;
        transition: fill 0.1s linear;
      }
    }
  }
}
</style>
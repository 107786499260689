<template>
  <div
    class="storyblok__outline"
    :data-blok-c="edit"
    :data-blok-uid="blok._uid"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "storyblokClick",
  props: {
    blok: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    edit() {
      let edit = "";
      if (this.blok._editable) {
        edit = "{" + this.blok._editable.split("{")[1].split("}")[0] + "}";
      }
      return edit;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
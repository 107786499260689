<template>
  <div class="bg-white">
    <div class="grid row">
      <h3 class="headline" v-if="blok.headline">{{ blok.headline }}</h3>
      <div class="grid-two-col">
        <component :is="item.component" :blok="item" v-for="item in blok.body" :key="item.id" />
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import downloadBox from "@/components/subComps/downloadBox.vue";
import downloadBoxVideo from "@/components/subComps/downloadBoxVideo.vue";
import textBox from "@/components/subComps/textBox.vue";
export default {
  name: "AppTwoCol",
  props: {
    blok: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    downloadBox,
    downloadBoxVideo,
    textBox,
  },
};
</script>

<style lang="scss" scoped>
.grid-two-col {
  display: grid;
  grid-gap: var(--spacing-md-1) var(--spacing-md-3);
  grid-template-columns: 1fr 1fr;

  :deep(.grid-item) {
    display: flex;
    justify-content: center;
    padding: 3rem 3rem;
    position: relative;
    &:nth-child(1) {
      grid-area: 1 / 1 / 2 / 2;
      background-color: var(--blue-color);
    }
    &:nth-child(2) {
      grid-area: 2 / 1 / 3 / 2;
      background-color: white;
      margin-bottom: 0rem;
      padding: 0;
      @media screen and (max-width: 767px) {
        margin-bottom: 2rem;
      }
    }
    &:nth-child(3) {
      grid-area: 1 / 2 / 2 / 3;
      background-color: var(--blue-color);
    }
    &:nth-child(4) {
      grid-area: 2 / 2 / 3 / 3;
      background-color: white;
      margin-bottom: 0rem;
      padding: 0;
      @media screen and (max-width: 767px) {
        margin-bottom: 2rem;
      }
    }
  }

  :deep(.grid-item__content) {
    width: 100%;
  }

  :deep(.grid-item__image) {
    width: 100%;
    max-width: 310px;
    display: block;
    align-self: center;
  }
}

.headline {
  margin-bottom: 1rem;
}

.bg-white {
  background: #ffffff;
}

@media screen and (max-width: 767px) {
  .grid-two-col {
    display: block;
  }
  .row {
    padding-bottom: calc(var(--spacing-md-3) * 2);
  }

  :deep(.grid-item__content) {
    padding-top: 1rem;
  }
}
</style>

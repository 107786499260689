import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import StoryblokVue from 'storyblok-vue'
import StoryblokClick from "@/components/helper/StoryblokClick.vue";
import AppRichText from "@/components/AppRichText.vue";
import AppImage from "@/components/AppImage.vue";
import AppSpacer from "@/components/AppSpacer.vue";

import AppAccordion from "@/components/AppAccordion.vue";
import AppBlueBox from "@/components/AppBlueBox.vue";
import AppContactBox from "@/components/AppContactBox.vue";
import AppIntro from "@/components/AppIntro.vue";
import AppIntroImage from "@/components/AppIntroImage.vue";
import AppTwoCol from "@/components/AppTwoCol.vue";


const app = createApp(App)
app.use(StoryblokVue)
app.component('AppRichText', AppRichText)
app.component('AppAccordion', AppAccordion)
app.component('AppBlueBox', AppBlueBox)
app.component('AppContactBox', AppContactBox)
app.component('AppImage', AppImage)
app.component('AppSpacer', AppSpacer)


app.component('AppIntro', AppIntro)
app.component('AppIntroImage', AppIntroImage)
app.component('AppTwoCol', AppTwoCol)

app.component('STC', StoryblokClick)
app.use(store)
app.use(router)
app.mount('#kk-app')

<template>
  <div>
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 24 24"
      xml:space="preserve"
    >
      <rect y="10" class="st0" width="24" height="2" />
    </svg>
  </div>
</template>

<script>
export default {
  name: "AppSvgMinus"
};
</script>

<style lang="scss" scoped>
rect {
  fill: var(--link-active-color);
}
</style>
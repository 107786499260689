<template>
  <div>
    <svg width="24" height="24" viewBox="0 0 25 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.3999 8.34686V21H6.8999V14.8775C6.8999 14.0612 7.5999 13.3469 8.3999 13.3469H9.8999C10.6999 13.3469 11.3999 14.0612 11.3999 14.8775V21H21.8999V8.34686"
        stroke="white"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M23.4 9.46906L13.1 1.71402C12.6 1.30586 11.9 1.30586 11.3 1.71402L1 9.46906"
        stroke="white"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16 3.85712V1H20.4V7.22444"
        stroke="white"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18.8999 13.2449H14.3999V17.8368H18.8999V13.2449Z"
        stroke="white"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M1 21H23.4"
        stroke="white"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: "AppSvgHouse"
};
</script>

<style lang="scss" scoped>
rect {
  // fill: var(--link-active-color);
}
</style>
<template>
  <div class="dark-box">
    <div class="grid">
      <h3>{{ blok.title }}</h3>
      <app-rich-text class="h4" v-if="blok.text" :text="blok.text" />
      <a class="btn mega" :href="blok.link">{{ blok.linkText }}</a>
      <h3 class="h4">{{ blok.phoneTitle }}</h3>
      <app-rich-text class="h4" v-if="blok.phoneText" :text="blok.phoneText" />
    </div>
  </div>
</template>

<script>
export default {
  name: "AppContactBox",
  props: {
    blok: {
      type: Object,
      default: () => {}
    }
  }
};
</script>

<style lang="scss" scoped>
.dark-box {
  background-color: #000c2e;
  color: white;
  padding: 6.3rem 0px;
  text-align: center;

  & h3 {
    margin-bottom: 1rem;
  }
  & .btn.mega {
    margin: 1rem 0px 4.5rem 0px;
  }
}

@media screen and (max-width: 767px) {
  .dark-box {
    padding: calc(var(--spacing-md-3) * 2) 0px;
    & .btn.mega {
      width: 100%;
    }
  }
}
</style>
<template>
  <teleport to="body">
    <transition name="fade-in">
      <div v-if="show" :style="'--backdrop:' + backdrop" class="modal-backdrop" @click="closeModal"></div>
    </transition>
    <transition name="modal">
      <div v-if="show" role="dialog" class="modal">
        <div class="modal-inner">
          <div
            class="modal-content animate"
            ref="modal"
            @keydown.esc="$store.commit('closeModal')"
            tabindex="0"
          >
            <div class="modal-content-headline">
              <DefaultSvgClose class="close-handle" @click="$store.commit('closeModal')" />
              <h3>{{headline}}</h3>
            </div>
            <!-- video player -->
            <transition name="video">
              <AppVideoPlayer :link="video" :poster="poster" :subtitle="subtitle" />
            </transition>
          </div>
        </div>
      </div>
    </transition>
  </teleport>
</template>

<script>
import DefaultSvgClose from "@/components/svg/DefaultSvgClose.vue";
import AppVideoPlayer from "@/components/AppVideoPlayer.vue";

export default {
  name: "ModalIntro",
  props: {
    show: {
      type: Boolean,
      default: false
    },
    video: {
      type: String,
      required: true
    },
    subtitle: {
      type: Object,
      required: false
    },
    headline: {
      type: String,
      default: "Intro video"
    },
    poster: {
      type: String,
      default: "Intro video"
    }
  },
  components: { DefaultSvgClose, AppVideoPlayer }
};
</script>

<style lang="scss" scoped>
.fade-in-enter-active,
.fade-in-leave-active {
  --fade-in-time: 0.3s ease;
}
.modal {
  position: fixed;
  width: calc(100%);
  max-height: calc(100% - var(--spacing-md-3) - var(--spacing-md-3));
  display: flex;
  justify-content: center;
  align-items: center;
  top: 50%;
  transform: translateY(-50%);
  margin-top: 0rem;
  left: 0;
  pointer-events: none;
  z-index: 9999;
  margin: auto;
  transition: height 0.25s ease-out;
  &-inner {
    background-color: #fff;
    width: 90%;
    max-width: 960px;
    height: 85vh;
    max-height: 730px;
    position: relative;
    overflow: auto;
    pointer-events: visible;
    color: var(--blue-color);

    @media screen and (max-width: 1023px) {
      height: 100vh;
      width: 100%;
    }
  }
  &-content {
    outline: 0;
    position: relative;

    &-headline {
      padding: var(--spacing-md-2);
      width: 100%;
      box-sizing: border-box;
      border-bottom: 1px solid rgba(0, 12, 46, 0.1);
      margin: 0px;
      display: flex;
      background: white;
      position: sticky;
      top: 0;
      left: 0;
      border-top: 8px solid var(--red-color);
      z-index: 10000;

      & .modal-back {
        display: inline-block;
        margin-right: 3rem;
        align-self: center;
        cursor: pointer;

        @media screen and (max-width: 768px) {
          margin-right: 2rem;
        }

        & span {
          position: relative;
          font-family: var(--font);
          padding: 0.2em;
          padding-left: 2rem;
          &::before {
            content: "";
            position: absolute;
            width: 16px;
            height: 16px;
            left: 0;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='28' height='26' viewBox='0 0 28 26' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M19.9887 25C19.8798 25.0001 19.772 24.9787 19.6714 24.937C19.5708 24.8953 19.4795 24.8341 19.4027 24.757L8.24188 13.5734C8.08689 13.4181 7.99989 13.2076 8 12.9882C8.00011 12.7688 8.08731 12.5584 8.24244 12.4033L19.4033 1.24247C19.4802 1.1656 19.5714 1.10462 19.6719 1.06302C19.7723 1.02141 19.88 1 19.9887 1C20.0974 1 20.205 1.02141 20.3055 1.06302C20.4059 1.10462 20.4972 1.1656 20.574 1.24247C20.6509 1.31934 20.7119 1.4106 20.7535 1.51103C20.7951 1.61147 20.8165 1.71913 20.8165 1.82784C20.8165 1.93655 20.7951 2.0442 20.7535 2.14463C20.7119 2.24507 20.6509 2.33633 20.574 2.41321L9.9979 12.9892L20.5746 23.5874C20.6902 23.7033 20.7689 23.8508 20.8007 24.0113C20.8325 24.1718 20.816 24.3382 20.7533 24.4893C20.6906 24.6405 20.5845 24.7697 20.4484 24.8606C20.3123 24.9515 20.1523 25 19.9887 25Z' fill='%23000C2E'/%3E%3C/svg%3E");
          }

          @media screen and (max-width: 768px) {
            font-size: 0;
            padding-left: 0rem;
          }
        }
      }

      & h3 {
        margin-bottom: 0;
        display: inline-block;
        font-family: var(--font-family-header);
      }

      & .close-handle {
        width: 1rem;
        padding: 1rem;
        position: absolute;
        right: 1rem;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        @media screen and (max-width: 1023px) {
          right: 0rem;
        }
      }
    }
  }
}

@media screen and (max-width: 479px) {
  .modal {
    margin: 0px;
    width: 100%;
    height: calc(100vh - calc(100vh - 100%));
    max-height: unset;
    box-sizing: border-box;
    &-inner {
      height: calc(100vh - calc(100vh - 100%));
      max-height: unset;
      width: 100%;
    }
    &-content {
      width: 100%;
      max-width: unset;
      @media screen and (max-width: 768px) {
        height: 65px;
      }
    }
  }
}
</style>
<style lang="scss">
.close-handle {
  &:hover {
    & svg {
      path {
        stroke: var(--link-active-color);
      }
    }
  }
}
</style>
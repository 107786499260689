<template>
  <div>
    <svg width="28" height="26" viewBox="0 0 28 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M23.241 8.84611C23.2407 8.83685 23.2403 8.82767 23.2397 8.81843C23.2375 8.78306 23.2332 8.74786 23.2265 8.71305L23.2265 8.71251C23.2192 8.6775 23.2097 8.64299 23.198 8.60921C23.1951 8.60074 23.1921 8.59241 23.189 8.58402C23.1772 8.55192 23.1634 8.52057 23.1477 8.49017C23.1468 8.48855 23.1462 8.48685 23.1453 8.48524C23.1282 8.45365 23.1091 8.42321 23.088 8.39411C23.0828 8.38675 23.0774 8.37955 23.0719 8.37234C23.0498 8.34291 23.0258 8.31498 23 8.28875L15.9537 1.24244L15.9529 1.24167C15.927 1.21635 15.8996 1.19273 15.8707 1.17098C15.8631 1.16518 15.8555 1.15953 15.8478 1.15401C15.819 1.13317 15.7888 1.11421 15.7576 1.09725C15.7554 1.09608 15.7531 1.09523 15.7509 1.09408C15.7211 1.07874 15.6904 1.06523 15.6589 1.05362C15.6503 1.05038 15.6417 1.04725 15.6329 1.0443C15.5993 1.03265 15.5649 1.02319 15.5301 1.01597L15.5292 1.01583C15.4944 1.00923 15.4592 1.00486 15.4239 1.00275C15.4148 1.00213 15.4057 1.00172 15.3966 1.0014C15.3872 1.00108 15.3778 1 15.3684 1H5.82781C5.60826 1 5.39772 1.08722 5.24248 1.24246C5.08724 1.3977 5 1.60826 5 1.82781V24.1722C5 24.3917 5.08724 24.6023 5.24248 24.7575C5.39772 24.9128 5.60826 25 5.82781 25H22.4146C22.6342 25 22.8447 24.9128 23 24.7575C23.1552 24.6023 23.2424 24.3917 23.2424 24.1722V8.87408C23.2424 8.8647 23.2414 8.85546 23.241 8.84611ZM16.1962 3.82635L20.4161 8.04627H16.1962V3.82635ZM6.65562 23.3444V2.65562H14.5406V8.87408C14.5406 9.09363 14.6278 9.30418 14.7831 9.45943C14.9383 9.61467 15.1488 9.70188 15.3684 9.70189H21.5868V23.3444H6.65562Z"
        fill="white"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: "AppSvgFile"
};
</script>

<style lang="scss" scoped>
</style>
<template>
  <div>
    <svg viewBox="0 0 30 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <line
        x1="1"
        y1="1"
        x2="29"
        y2="0.999997"
        stroke="#000C2E"
        stroke-width="2"
        stroke-linecap="round"
      />
      <line x1="1" y1="9" x2="29" y2="9" stroke="#000C2E" stroke-width="2" stroke-linecap="round" />
      <line
        x1="1"
        y1="17"
        x2="29"
        y2="17"
        stroke="#000C2E"
        stroke-width="2"
        stroke-linecap="round"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: "AppSvgNav"
};
</script>

<style lang="scss" scoped>
rect {
  fill: var(--link-active-color);
}
</style>
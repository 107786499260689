<template>
  <div class="grid-item">
    <DefaultSvgVideo class="icon" />
    <div class="grid-item__image">
      <a class="image-btn" target="_blank" :href="blok.link">
        <img :src="blok.image" alt />
      </a>
    </div>
  </div>
  <div class="grid-item">
    <div class="grid-item__content">
      <h3>{{ blok.title }}</h3>
      <app-rich-text v-if="blok.text" :text="blok.text" />
      <a class="btn" target="_blank" v-if="blok.link" :href="blok.link">{{ blok.linkText }}</a>
    </div>
  </div>
</template>

<script>
import DefaultSvgVideo from "@/components/svg/DefaultSvgVideo.vue";
export default {
  name: "DownloadBoxVideo",
  components: {
    DefaultSvgVideo,
  },
  props: {
    blok: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style lang="scss" scoped>
.grid-item__image {
  text-align: center;
  box-sizing: border-box;
  position: relative;

  & img {
    width: 100%;
    max-width: 310px;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
  }
}

.icon {
  position: absolute;
  right: 1rem;
  top: 1rem;

  & :deep(svg) {
    width: 1.2rem;
  }
}

.download-wrap {
  & .mega {
    margin-top: 1rem;
  }
  h3 {
    margin: 1rem 0px;
  }
  & .btn {
    margin-top: var(--spacing-md-1);
  }
}

@media screen and (max-width: 767px) {
  .download-wrap {
    & h3 {
      word-break: unset;
    }

    & p {
      margin-bottom: 1rem;
    }

    &:last-child {
      .row {
        padding-bottom: 0px;
      }
    }
  }
}
</style>

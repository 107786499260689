<template>
  <div>
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.6045 15.3163C15.2278 13.814 16.9069 9.57209 15.4045 5.94884C13.9022 2.32558 9.66035 0.646513 6.0371 2.14884C2.41384 3.73954 0.73477 7.9814 2.2371 11.6047C3.73942 15.2279 7.98128 16.907 11.6045 15.3163Z"
        stroke="#000C2E"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.8137 13.8139L19.2928 19.293"
        stroke="#000C2E"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: "AppSvgSearch"
};
</script>

<style lang="scss" scoped>
</style>
<template>
  <div class="grid row">
    <h1>{{ blok.title }}</h1>
    <app-rich-text class="h4" v-if="blok.text" :text="blok.text" />
  </div>
</template>

<script>
export default {
  name: "AppIntro",
  props: {
    blok: {
      type: Object,
      default: () => {}
    }
  }
};
</script>

<style lang="scss" scoped>
.h4 {
  width: 80%;
}

.row {
  padding-top: 4rem;
}

@media screen and (max-width: 1023px) {
  .row {
    padding-top: 8rem;
  }
  .h4 {
    width: 100%;
  }
}
</style>
<template>
  <div class="spinner-wrapper">
    <div class="spinner"></div>
  </div>
</template>

<script>
export default {};
</script>
<style lang="scss" scoped>
.spinner {
  display: block;
  margin: auto;
  height: 3rem;
  width: 3rem;
  border: 6px solid rgba(0, 12, 46, 0.2);
  border-top-color: rgba(0, 12, 46, 1);
  border-radius: 50%;
  animation: rotation 0.6s infinite linear;
  &-wrapper {
    display: flex;
    width: 100%;
    position: absolute;
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}
</style>
<template>
  <app-modal backdrop="grey" :show="$store.state.modalActive" :modalType="$store.state.modalType" />
  <AppModalIntro
    backdrop="grey"
    :show="$store.state.modalIntroActive"
    :video="blok.content.videoIntroLink"
    :headline="blok.content.videoIntroHeadline"
    :subtitle="blok.content.subtitle"
    :poster="blok.content.videoPoster"
  />

  <s-t-c :blok="blok.content" class="intro-front">
    <div class="grid row">
      <div class="text">
        <h1>{{ blok.content.title }}</h1>
        <!-- <pre>{{blok.content.bodyEnergiMerge}}</pre>-->
        <app-rich-text
          v-if="blok.content.text"
          class="desktop-no-tablet"
          :text="blok.content.text"
        />
        <app-rich-text
          v-if="blok.content.textMobile"
          class="tablet-mobile-only"
          :text="blok.content.textMobile"
        />
        <p class="desktop-no-tablet">
          <a v-on:click="scrollToElem('maintainceAcco')">
            <span>
              Se videoer
              <span class="arrowDown">
                <DefaultSvgArrowDown />
              </span>
            </span>
          </a>
        </p>
      </div>
      <div class="search" id="search">
        <div class="input-wrapper">
          <input
            type="text"
            autocomplete="off"
            :placeholder="blok.content.searchText"
            id="input-search"
            v-model="orderBy"
            @keydown="setFocus"
          />
          <DefaultSvgSearch class="search-icon" />
        </div>
        <div v-if="orderBy.length > 0" class="search-group">
          <div class="search-item-kategori">
            <h5>Energirigtig drift (videoer)</h5>
            <div class="search-item-kategori-inner energi">
              <template :ref="focus" v-for="(item, index) in filtered" :key="item.id">
                <div
                  v-if="item.component == 'appFrontEnergi'"
                  :class="['search-item', { focus: index === focus }]"
                >
                  <!-- ENERGI -->
                  <div @click="$store.commit('setModal', item), (orderBy = '')">
                    <button
                      @click="$store.commit('setModal', item), (orderBy = '')"
                      @keydown.enter="
                $store.commit('setModal', item), (orderBy = '')
              "
                      class="search-link reset-btn"
                      :class="{'energi': item.component == 'appFrontEnergi'}"
                      v-html="highlightSearch(item.title)"
                    ></button>
                  </div>
                </div>
              </template>
            </div>
          </div>
          <div class="search-item-kategori">
            <h5>Ansvar for vedligehold</h5>
            <div class="search-item-kategori-inner ansvar">
              <template :ref="focus" v-for="(item, index) in filtered" :key="item.id">
                <div
                  v-if="item.component != 'appFrontEnergi'"
                  :class="['search-item', { focus: index === focus }]"
                >
                  <!-- ENERGI -->
                  <div @click="$store.commit('setModal', item.id), (orderBy = '')">
                    <button
                      @click="$store.commit('setModal', item.id), (orderBy = '')"
                      @keydown.enter="
                $store.commit('setModal', item.id), (orderBy = '')
              "
                      class="search-link reset-btn"
                      :class="{'energi': item.component == 'appFrontEnergi'}"
                      v-html="highlightSearch(item.title)"
                    ></button>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
        <div class="intro-video-wrap">
          <h5>{{blok.content.introVideoHeadline}}</h5>
          <app-rich-text
            v-if="blok.content.introVideoText"
            class="small"
            :text="blok.content.introVideoText"
          />
          <div class="video-link" v-if="blok.content.videoIntroLink">
            <button class="white-bg" @click="$store.commit('setModalIntro')">
              <span>Se video</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </s-t-c>

  <div class="illustation-wrapper">
    <div class="grid row">
      <AppSvgEnergi></AppSvgEnergi>
    </div>
  </div>
  <AppFrontEnergiList id="maintainceAcco" :title="blok.content.maintenanceTitle" />
</template>
<script>
/* eslint-disable vue/no-unused-components */
import AppSvgEnergi from "@/components/svg/AppSvgEnergi.vue";
import DefaultSvgSearch from "@/components/svg/DefaultSvgSearch.vue";
import DefaultSvgArrowDown from "@/components/svg/DefaultSvgArrowDown.vue";
import DefaultSvgHouseOut from "@/components/svg/DefaultSvgHouseOut.vue";
import DefaultSvgHouseIn from "@/components/svg/DefaultSvgHouseIn.vue";
import AppFrontEnergiList from "@/components/AppFrontEnergiList.vue";
import AppModal from "@/components/AppModal.vue";
import AppModalIntro from "@/components/AppModalIntro.vue";

import StoryblokClient from "storyblok-js-client";
import RichTextResolver from "storyblok-js-client/source/richTextResolver";
import richtextSchema from "../helpers/richtextSchema.js";

const token = "6gCwVvrksgLUgxapPToc0wtt";
const Storyblok = new StoryblokClient({
  accessToken: token,
  cache: {
    clear: "auto",
    type: "memory"
  }
});
Storyblok.richTextResolver = new RichTextResolver(richtextSchema);

export default {
  name: "Energirigtig drift",
  props: {
    blok: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      frontActive: true,
      orderBy: "",
      illuHovered: true,
      focus: null,
      choosen: ""
    };
  },
  components: {
    AppSvgEnergi,
    AppModal,
    AppModalIntro,
    AppFrontEnergiList,
    DefaultSvgSearch,
    DefaultSvgArrowDown,
    DefaultSvgHouseOut,
    DefaultSvgHouseIn
  },
  computed: {
    getData() {
      return this.$store.getters.mergeSearchData;
    },
    filtered() {
      return this.getData.filter(item => {
        let tempVal = item.title.toLowerCase();
        if (item.text) {
          tempVal =
            tempVal +
            " , " +
            Storyblok.richTextResolver.render(item.text).toLowerCase();
        }
        if (item.search) {
          tempVal = tempVal + "," + item.search.toLowerCase();
        }
        if (item.videos) {
          item.videos.forEach(video => {
            for (let i = 0; i < video.chapters.length; i++) {
              tempVal = tempVal + "," + video.chapters[i].title.toLowerCase();
            }

            if (video.videoDescription.content[0]) {
              if (
                video.videoDescription.content[0].content === undefined ||
                video.videoDescription.content[0].content === null
              ) {
                return;
              } else {
                tempVal =
                  tempVal +
                  "," +
                  video.videoDescription.content[0].content[0].text.toLowerCase();
                console.log(video.videoDescription.content[0].content[0].text);
              }
            }
            if (video.videoTitle) {
              tempVal = tempVal + "," + video.videoTitle.toLowerCase();
            }
          });
        }
        return tempVal.includes(this.orderBy.toLowerCase());
      });
    }
  },
  methods: {
    scrollToElem(e) {
      var scroll = document.getElementById(e);
      scroll.scrollIntoView();
    },
    highlightSearch(e) {
      var regEx = new RegExp(this.orderBy, "ig");
      var toLower = e.replace(regEx, "<b>" + this.orderBy + "</b>");
      return toLower.toLowerCase();
    },
    setFocus(event) {
      if (this.filtered.length < this.focus) {
        this.focus = 0;
      }
      switch (event.keyCode) {
        case 38:
          event.preventDefault();
          if (this.focus === null) {
            this.focus = 0;
          } else if (this.focus > 0) {
            this.focus--;
          }
          break;
        case 40:
          event.preventDefault();
          if (this.focus === null) {
            this.focus = 0;
          } else if (this.focus < this.filtered.length - 1) {
            this.focus++;
          }
          break;
        case 13:
          // run modal function
          this.setModal(this.filtered[this.focus].id);
          break;
      }
    },
    setModal(data) {
      this.$store.commit("setModal", data);
      this.orderBy = "";
      this.focus = 0;
    }
  }
};
</script>

<style lang="scss" scoped>
@keyframes arrowDown {
  0% {
    top: 3px;
  }

  65% {
    top: 8px;
  }
  100% {
    top: 3px;
  }
}

.intro-front {
  & .row {
    padding-top: 4rem;
    padding-bottom: 0px;

    & .text {
      width: calc(100% - 330px);
      display: inline-block;
      box-sizing: border-box;
      padding-right: var(--spacing-md-2);

      & span {
        font-family: var(--font-family-semi-header);
        padding: 0.5rem 0px;
        cursor: pointer;
        & .arrowDown {
          display: inline-block;
          margin-left: 5px;
          top: 3px;
          position: relative;
        }
        &:hover {
          & .arrowDown {
            animation-duration: 1s;
            animation-name: arrowDown;
            animation-iteration-count: infinite;
          }
        }
      }
    }
    & .search {
      width: 330px;
      display: inline-block;
      font-size: 1rem;
      vertical-align: top;
      margin-top: 4rem;
      & .search-item {
        &.focus {
          background: rgba(0, 12, 46, 0.07) !important;
        }
        &:empty {
          display: none;
        }

        &-kategori {
          margin: 1rem 0;
          &-inner {
            &.ansvar {
              &:empty {
                font-size: 10px;
                &:before {
                  content: "Ingen søgeresultater";
                  position: relative;
                  width: 100%;
                  padding: 1rem 1.5rem;
                }
              }
            }
            &.energi {
              &:empty {
                font-size: 10px;
                &:before {
                  content: "Ingen søgeresultater";
                  position: relative;
                  width: 100%;
                  padding: 1rem 1.5rem;
                }
              }
            }
          }
          & h5 {
            padding: 1rem;
            margin-bottom: 0;
          }
        }
      }

      &-link {
        position: relative;
        &.energi::after {
          content: "";
          position: absolute;
          width: 16px;
          height: 16px;
          margin-top: 1px;
          margin-left: 8px;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='18' height='17' viewBox='0 0 18 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_796:1087)'%3E%3Cpath d='M9.00004 16.0711C4.74638 16.0711 1.28577 12.6105 1.28577 8.35685C1.28577 4.1032 4.74638 0.642578 9.00004 0.642578C13.2537 0.642578 16.7143 4.1032 16.7143 8.35685C16.7143 12.6105 13.2537 16.0711 9.00004 16.0711ZM9.00004 1.7069C7.6848 1.7069 6.39909 2.09691 5.30551 2.82762C4.21193 3.55832 3.35959 4.5969 2.85627 5.81203C2.35295 7.02715 2.22127 8.36424 2.47786 9.65421C2.73445 10.9442 3.36777 12.1291 4.29778 13.0591C5.2278 13.9891 6.41272 14.6225 7.70268 14.879C8.99265 15.1356 10.3297 15.0039 11.5449 14.5006C12.76 13.9973 13.7986 13.145 14.5293 12.0514C15.26 10.9578 15.65 9.67209 15.65 8.35685C15.648 6.59379 14.9467 4.90351 13.7001 3.65683C12.4534 2.41016 10.7631 1.7089 9.00004 1.7069V1.7069Z' fill='%23000C2E'/%3E%3Cpath d='M7.25885 11.9122C7.18896 11.9122 7.11977 11.8984 7.05521 11.8717C6.99064 11.8449 6.93196 11.8057 6.88254 11.7563C6.83312 11.7069 6.79394 11.6483 6.76719 11.5837C6.74045 11.5191 6.72668 11.4499 6.72668 11.38V5.33391C6.72668 5.24065 6.75118 5.14902 6.79775 5.06821C6.84432 4.9874 6.91132 4.92025 6.99201 4.87348C7.07271 4.82672 7.16428 4.80199 7.25755 4.80176C7.35081 4.80153 7.4425 4.82582 7.52342 4.8722L12.8013 7.89657C12.8826 7.94317 12.9502 8.01042 12.9972 8.09151C13.0441 8.1726 13.0689 8.26467 13.0689 8.3584C13.0689 8.45212 13.0441 8.54418 12.9971 8.62526C12.9501 8.70633 12.8825 8.77355 12.8011 8.82011L7.52326 11.8418C7.44277 11.888 7.35161 11.9122 7.25885 11.9122V11.9122ZM7.79101 6.25221V10.4622L11.466 8.3581L7.79101 6.25221Z' fill='%23000C2E'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_796:1087'%3E%3Crect width='18' height='16.7143' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
        }
      }

      & .intro-video-wrap {
        padding: 1.2em;
        background: var(--blue-color);
        color: #fff;
        margin-top: 1rem;
        margin-bottom: 1.5rem;

        & .video-link {
          & span {
            font-size: var(--font);
            font-family: var(--font-family-semi-header);
            padding-left: 2rem;
            position: relative;
            cursor: pointer;

            &::after {
              content: "";
              position: absolute;
              width: 20px;
              height: 20px;
              top: 50%;
              transform: translateY(-50%);
              margin-left: 0px;
              left: 0;
              background-size: cover;
              background-position: center;
              background-repeat: no-repeat;
              background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='18' height='17' viewBox='0 0 18 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_796:1087)'%3E%3Cpath d='M9.00004 16.0711C4.74638 16.0711 1.28577 12.6105 1.28577 8.35685C1.28577 4.1032 4.74638 0.642578 9.00004 0.642578C13.2537 0.642578 16.7143 4.1032 16.7143 8.35685C16.7143 12.6105 13.2537 16.0711 9.00004 16.0711ZM9.00004 1.7069C7.6848 1.7069 6.39909 2.09691 5.30551 2.82762C4.21193 3.55832 3.35959 4.5969 2.85627 5.81203C2.35295 7.02715 2.22127 8.36424 2.47786 9.65421C2.73445 10.9442 3.36777 12.1291 4.29778 13.0591C5.2278 13.9891 6.41272 14.6225 7.70268 14.879C8.99265 15.1356 10.3297 15.0039 11.5449 14.5006C12.76 13.9973 13.7986 13.145 14.5293 12.0514C15.26 10.9578 15.65 9.67209 15.65 8.35685C15.648 6.59379 14.9467 4.90351 13.7001 3.65683C12.4534 2.41016 10.7631 1.7089 9.00004 1.7069V1.7069Z' fill='white'/%3E%3Cpath d='M7.25885 11.9122C7.18896 11.9122 7.11977 11.8984 7.05521 11.8717C6.99064 11.8449 6.93196 11.8057 6.88254 11.7563C6.83312 11.7069 6.79394 11.6483 6.76719 11.5837C6.74045 11.5191 6.72668 11.4499 6.72668 11.38V5.33391C6.72668 5.24065 6.75118 5.14902 6.79775 5.06821C6.84432 4.9874 6.91132 4.92025 6.99201 4.87348C7.07271 4.82672 7.16428 4.80199 7.25755 4.80176C7.35081 4.80153 7.4425 4.82582 7.52342 4.8722L12.8013 7.89657C12.8826 7.94317 12.9502 8.01042 12.9972 8.09151C13.0441 8.1726 13.0689 8.26467 13.0689 8.3584C13.0689 8.45212 13.0441 8.54418 12.9971 8.62526C12.9501 8.70633 12.8825 8.77355 12.8011 8.82011L7.52326 11.8418C7.44277 11.888 7.35161 11.9122 7.25885 11.9122V11.9122ZM7.79101 6.25221V10.4622L11.466 8.3581L7.79101 6.25221Z' fill='white'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_796:1087'%3E%3Crect width='18' height='16.7143' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
            }
          }
        }
      }
    }
  }
}

.illustation-wrapper {
  display: block;
  margin-top: -3rem;
  z-index: 0;
  & .innerwrapper {
    width: 100%;
    overflow: block;
    position: relative;
    overflow: hidden;

    & .btn-illu {
      position: absolute;
      right: 0px;
      bottom: 0px;
      font-size: 1rem;
      z-index: 3;
      vertical-align: middle;
      width: 210px;
      padding: 1rem 1rem;
      & .svg-house {
        width: 24px;
        height: 24px;
        vertical-align: middle;
        display: inline-block;
        margin-right: 0.75rem;
      }
    }

    & .svg-illu-front {
      position: absolute;
      z-index: 2;
      background: #ffffff;
      display: block;
    }
  }
}

@media screen and (max-width: 1023px) {
  .desktop-only {
    display: none;
  }
  .intro-front {
    & .row {
      padding-top: 8rem;
      padding-bottom: calc(var(--spacing-md-3) * 2);
      & .text {
        width: 100%;
        display: block;
      }
      & .search {
        display: block;
        margin-top: calc(var(--spacing-md-3) * 2);
      }
    }
  }
  .illustation-wrapper {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .intro-front {
    & .row {
      padding-top: 8rem;
      padding-bottom: 2rem;

      & .text {
        width: 100%;
        display: block;
      }
      & .search {
        width: 100%;
        display: block;
      }
    }
  }
}
</style>

<style lang="scss">
.intro-front {
  & p {
    width: 100%;
    max-width: 520px;
  }
}

.illustation-wrapper {
  & .innerwrapper {
    & .svg-stage {
      width: 100%;
    }
  }
}

@media screen and (max-width: 1023px) {
}

@media screen and (max-width: 767px) {
}
</style>

<template>
  <div class="spacer" :class="blok.size"></div>
</template>

<script>
export default {
  name: "AppSpacer",
  props: {
    blok: {
      type: Object,
      default: () => {}
    }
  }
};
</script>

<style lang="scss" scoped>
.spacer {
  &.sm {
    padding: var(--spacing-md-1) 0px;
  }

  &.md {
    padding: var(--spacing-md-2) 0px;
  }

  &.lg {
    padding: var(--spacing-md-3) 0px;
  }
}
</style>
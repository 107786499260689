<template>
  <teleport to="head" v-if="pageData">
    <title>{{ pageData.content.seo.title }}</title>
    <meta property="og:description" :content="pageData.content.seo.description" />
  </teleport>

  <loading v-if="!pageLoaded" />
  <transition name="fade-in">
    <div v-if="pageLoaded">
      <component :is="pageData.content.component" :blok="pageData"></component>
    </div>
  </transition>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import AppLoading from "@/components/AppLoading.vue";
import LayoutPage from "@/components/LayoutPage.vue";
import LayoutOverview from "@/components/LayoutOverview.vue";
import LayoutOverviewEnergi from "@/components/LayoutOverviewEnergi.vue";
export default {
  name: "Pages",
  components: {
    page: LayoutPage,
    overview: LayoutOverview,
    overviewEnergi: LayoutOverviewEnergi,
    loading: AppLoading
  },
  data() {
    return {
      pageLoaded: false
    };
  },
  computed: {
    pageData() {
      const slug = "site" + this.$route.fullPath;
      return this.$store.state.pageAll.filter(
        item => item.full_slug === slug
      )[0];
    }
  },
  methods: {
    setData() {
      this.$store
        .dispatch("getPages")
        .then(response => {
          // setTimeout(() => {
          //   this.pageLoaded = true;
          // }, 2000);
          this.pageLoaded = true;
        })
        .catch(error => {
          console.log(error);
        });
    }
  },
  mounted() {
    this.setData();
  }
};
</script>

<style lang="scss" scoped>
</style>
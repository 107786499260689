<template>
  <component v-if="story" :is="story.content.component" :key="story.id" :blok="story"></component>
</template>

<script>
const token = "bap5OPkhOLv0R9kXgDQAhQtt";
const loadStoryblokBridge = function(cb) {
  let script = document.createElement("script");
  script.type = "text/javascript";
  script.src = `//app.storyblok.com/f/storyblok-latest.js?t=` + token;
  script.onload = cb;
  document.getElementsByTagName("head")[0].appendChild(script);
};
/* eslint-disable vue/no-unused-components */
import { mapState } from "vuex";
import LayoutPage from "@/components/LayoutPage.vue";
import LayoutOverview from "@/components/LayoutOverview.vue";
import LayoutOverviewEnergi from "@/components/LayoutOverviewEnergi.vue";
export default {
  name: "Pages",
  data() {
    return {
      story: { content: {} }
    };
  },
  watch: {
    $route(to, from) {
      this.initStoryblokEvents();
    }
  },
  methods: {
    setData() {
      this.$store
        .dispatch("getPages")
        .then(response => {
          this.pageLoaded = true;
        })
        .catch(error => {
          console.log(error);
        });
    },
    loadStory() {
      window.storyblok.get(
        {
          slug: window.storyblok.getParam("path"),
          version: "draft"
        },
        data => {
          this.story = data.story;
        }
      );
    },
    initStoryblokEvents() {
      this.loadStory();

      let sb = window.storyblok;

      sb.on(["change", "published"], payload => {
        this.loadStory();
      });

      sb.on("input", payload => {
        if (this.story && payload.story.id === this.story.id) {
          payload.story.content = sb.addComments(
            payload.story.content,
            payload.story.id
          );
          this.story = payload.story;
        }
      });

      sb.pingEditor(() => {
        if (sb.inEditor) {
          sb.enterEditmode();
        }
      });
    }
  },
  components: {
    page: LayoutPage,
    overview: LayoutOverview,
    overviewEnergi: LayoutOverviewEnergi
  },
  created() {
    loadStoryblokBridge(() => {
      this.initStoryblokEvents();
      this.setData();
    });
  }
};
</script>

<template>
  <div class="accordion-outer">
    <div class="accordion-grid row">
      <s-t-c :blok="blok">
        <h3>{{ blok.title }}</h3>
      </s-t-c>
      <div class="accordion">
        <div v-for="item in blok.element" :key="item._uid" class="accordion-item">
          <s-t-c :blok="item">
            <button
              class="accordion-link h4"
              :id="'header-' + item._uid"
              :aria-controls="'panel-' + item._uid"
              :aria-expanded="activeEl === item._uid"
              @click="toggle(item._uid)"
            >
              {{ item.title }}
              <DefaultSvgPlus class="accordion-svg" />
            </button>
            <transition name="accordion" mode="out-in">
              <section
                class="accordion-content"
                :key="item._uid"
                v-if="activeEl === item._uid"
                :id="'panel-' + item._uid"
                :aria-labelledby="'header-' + item._uid"
              >
                <p class="h4">
                  <DefaultSvgMinus class="accordion-svg" @click="toggle(item._uid)" />
                </p>
                <AppRichText :text="item.text" />
              </section>
            </transition>
          </s-t-c>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import DefaultSvgPlus from "@/components/svg/DefaultSvgPlus.vue";
import DefaultSvgMinus from "@/components/svg/DefaultSvgMinus.vue";
export default {
  name: "AppAccordion",
  props: {
    blok: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      activeEl: 0
    };
  },
  components: {
    DefaultSvgPlus,
    DefaultSvgMinus
  },
  methods: {
    toggle(id) {
      if (this.activeEl === id) {
        this.activeEl = "";
      } else {
        this.activeEl = id;
      }
    }
    /*
                    @mouseover="accordionSvgLink(item._uid)"
                    @mouseleave="accordionSvgLinkLeave(item._uid)"


    accordionSvgLink(id) {
      var elem = document.querySelectorAll(
        "#header-" + id + " .accordion-svg"
      )[0];
      elem.classList.add("close");
    },
    accordionSvgLinkLeave(id) {
      var elem = document.querySelectorAll(
        "#header-" + id + " .accordion-svg"
      )[0];
      elem.classList.remove("close");
    }
    */
  }
};
</script>

<style lang="scss" >
.accordion-grid {
  margin: auto;
  width: 90%;
  max-width: 960px;
  box-sizing: border-box;

  &.row {
    padding-top: 0px;
    padding-bottom: calc(var(--spacing-md-3) * 2);
  }

  & h3 {
    margin-bottom: var(--spacing-md-1);
  }
  & .accordion-item {
    position: relative;
    & .accordion-link {
      position: relative;
      cursor: pointer;
      overflow: hidden;
      width: 100%;
      background: #ffffff;
      text-align: left;
      box-shadow: 0px 1px 0px var(--blue-color);
      border: 1px solid var(--blue-color);
      border-bottom: 0px solid var(--blue-color);
      padding: var(--spacing-md-1) var(--spacing-md-2);
      padding-right: calc(var(--spacing-md-3) * 2);
      box-sizing: border-box;
      outline: none;
      &.h4 {
        margin: 0px;
        font-family: "KBH-Medium";
        color: var(--blue-color);
      }
      & .accordion-svg {
        width: 1rem;
        position: absolute;
        top: 50%;
        right: var(--spacing-md-2);
        transform-origin: 50%;
        transform: rotate(0deg) translateY(-50%);
        &::after {
          /*
          content: "";
          position: absolute;
          transform: translateY(-50%) translateX(-50%);
          top: 50%;
          left: 50%;
          width: 0px;
          height: 0px;
          background: rgba(0, 12, 46, 0);
          z-index: -1;
          border-radius: 50%;
          transition: all 0.15s;*/
        }
        & svg {
          transform: rotate(0deg);
          transition: transform 0s ease-out;
          z-index: 2;
        }
      }
      &:hover {
        & .accordion-svg {
          &::after {
            /*
            width: 7.5rem;
            height: 7.5rem;
            background: rgba(0, 12, 46, 0.025);
            transition: all 0.35s ease-out;*/
          }
          & svg {
            transform: rotate(90deg);
            transition: transform 0.25s ease-in-out;
          }
        }
      }
    }
    & .accordion-content {
      padding: var(--spacing-md-1) var(--spacing-md-2);
      overflow: hidden;

      & p {
        padding-right: var(--spacing-md-2);
      }
      & .h4 {
        font-family: "KBH-Medium";
        position: relative;
        & .accordion-svg {
          width: 1rem;
          position: absolute;
          top: -0.5rem;
          right: -0.5rem;
          transform-origin: 50%;
          transform: rotate(0deg);
          transition: transform 0s ease-out;
          cursor: pointer;
          padding: 0.5rem;
          &.close {
            transform: rotate(45deg);
            transition: transform 0.25s ease-in-out;
            cursor: pointer;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .accordion-grid {
    & .accordion-item {
      & .accordion-link {
        padding-top: var(--spacing-md-2);
        padding-bottom: var(--spacing-md-2);
      }
    }
  }
}

.accordion-grid .accordion-item .accordion-content .h4 .accordion-svg {
  top: 0rem;
}
</style>
<template>
  <section class="video-wrap">
    <div class="video-wrap-grid" :class="{'chapters' : chapters}">
      <div class="item-video-player">
        <div class="item-video-player__inner" ref="videowrapper">
          <!-- 
        HTML video player
          -->
          <video playsinline ref="videoplayer" @timeupdate="setProgress" crossorigin="anonymous">
            <source :src="link" type="video/mp4" />Your browser does not support the video tag.
            <track
              :src="subtitle.filename"
              kind="subtitles"
              srclang="en"
              default
              v-if="showSubs && subtitle"
            />
          </video>
          <!-- 
        PLay icon overlay
          -->
          <div class="video-play" @click="playVideo">
            <svg fill="none" viewBox="0 0 49 57">
              <path fill="#fff" d="M49 28.5L.25 56.646V.354L49 28.5z" />
            </svg>
          </div>
          <div class="video-poster" v-if="poster">
            <img :src="poster.filename" />
          </div>
          <!-- 
        Custom controls - can be styled as you want
          -->
          <div class="video-control">
            <div class="video-control-inner video">
              <div class="video-control-title-display" ref="displayTitle"></div>
              <div class="video-pause" @click="pauseVideo">
                <svg fill="none" viewBox="0 0 9 12">
                  <path fill="#fff" d="M0 0h2.933v11.057H0zM5.867 0H8.8v11.057H5.867z" />
                </svg>
              </div>
              <div class="video-progress" ref="videoTimeline">
                <div class="video-progress-click" @click="clickTimeline"></div>
                <div class="video-progress-tp"></div>
                <div class="video-progress-chapters" ref="chapters" v-if="chapters">
                  <!--<div
                class="chapter"
                v-for="chapter in chapters.items"
                :style="'left: ' + setTimelineChapters(chapter) + '%'"
                  ></div>-->
                </div>
              </div>
              <div class="video-progress-fill" :style="'width: ' + playtimeInProncent + '%'"></div>
              <div class="video-sound" @click="changeVolume" ref="videovolume">
                <svg
                  class="video-sound-muted"
                  viewBox="0 0 26 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.0838 3.69141C12.7465 3.69136 12.4207 3.81317 12.167 4.03415L7.4935 8.104H3.34766C2.95256 8.10445 2.57376 8.26068 2.29438 8.53841C2.01501 8.81614 1.85786 9.1927 1.85742 9.58546V15.0517C1.85786 15.4444 2.015 15.821 2.29438 16.0987C2.57376 16.3765 2.95255 16.5327 3.34766 16.5332H7.49386L12.1676 20.5954C12.3688 20.7702 12.6165 20.8838 12.8809 20.9225C13.1454 20.9612 13.4154 20.9233 13.6588 20.8135C13.9022 20.7037 14.1086 20.5265 14.2534 20.3031C14.3982 20.0798 14.4752 19.8197 14.4752 19.554V5.07455C14.4748 4.70784 14.328 4.35627 14.0672 4.09697C13.8063 3.83768 13.4527 3.69182 13.0838 3.69141ZM13.0695 19.5232L8.22037 15.3085C8.09229 15.1972 7.92792 15.1358 7.75778 15.1358H3.34766C3.32524 15.1358 3.30374 15.1269 3.28789 15.1111C3.27204 15.0953 3.26312 15.074 3.2631 15.0517V9.58546C3.26313 9.56317 3.27204 9.54181 3.28789 9.52604C3.30374 9.51028 3.32524 9.50141 3.34766 9.50137H7.75778C7.92815 9.50136 8.09272 9.43983 8.22087 9.32823L13.0695 5.10583V19.5232Z"
                    fill="white"
                  />
                  <path
                    d="M21.2849 12.1397L23.9813 8.91051C24.0403 8.83992 24.0846 8.75847 24.1119 8.67083C24.1392 8.58318 24.1489 8.49106 24.1403 8.3997C24.1318 8.30835 24.1053 8.21955 24.0622 8.1384C24.0192 8.05724 23.9605 7.98531 23.8895 7.92671C23.8184 7.86811 23.7365 7.82399 23.6484 7.79686C23.5602 7.76973 23.4675 7.76014 23.3756 7.76862C23.2837 7.77709 23.1944 7.80348 23.1128 7.84628C23.0311 7.88907 22.9587 7.94743 22.8998 8.01802L20.3715 11.0459L17.8432 8.01802C17.7842 7.94743 17.7118 7.88907 17.6302 7.84628C17.5486 7.80348 17.4593 7.77709 17.3674 7.76862C17.2755 7.76014 17.1828 7.76973 17.0946 7.79686C17.0065 7.82399 16.9245 7.86811 16.8535 7.92671C16.7825 7.98531 16.7238 8.05724 16.6808 8.1384C16.6377 8.21955 16.6111 8.30835 16.6026 8.3997C16.5941 8.49106 16.6037 8.58318 16.631 8.67083C16.6583 8.75847 16.7027 8.83992 16.7617 8.91051L19.4581 12.1397L16.7617 15.3689C16.7027 15.4395 16.6583 15.5209 16.631 15.6086C16.6037 15.6962 16.5941 15.7884 16.6026 15.8797C16.6111 15.9711 16.6377 16.0599 16.6808 16.141C16.7238 16.2222 16.7825 16.2941 16.8535 16.3527C16.9245 16.4113 17.0065 16.4554 17.0946 16.4826C17.1828 16.5097 17.2755 16.5193 17.3674 16.5108C17.4593 16.5023 17.5486 16.4759 17.6302 16.4331C17.7118 16.3903 17.7842 16.332 17.8432 16.2614L20.3715 13.2335L22.8998 16.2614C22.9587 16.332 23.0311 16.3903 23.1128 16.4331C23.1944 16.4759 23.2837 16.5023 23.3756 16.5108C23.4675 16.5193 23.5602 16.5097 23.6484 16.4826C23.7365 16.4554 23.8184 16.4113 23.8895 16.3527C23.9605 16.2941 24.0192 16.2222 24.0622 16.141C24.1053 16.0599 24.1318 15.9711 24.1403 15.8797C24.1489 15.7884 24.1392 15.6962 24.1119 15.6086C24.0846 15.5209 24.0403 15.4395 23.9813 15.3689L21.2849 12.1397Z"
                    fill="white"
                  />
                </svg>

                <svg
                  class="video-sound-active"
                  viewBox="0 0 26 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.0821 3.69141C12.7448 3.69137 12.4191 3.81316 12.1654 4.03409L7.49268 8.1033H3.3475C2.95245 8.10374 2.5737 8.25995 2.29435 8.53763C2.015 8.81532 1.85788 9.19182 1.85742 9.58453V15.0499C1.85787 15.4426 2.015 15.8191 2.29435 16.0968C2.5737 16.3745 2.95245 16.5307 3.3475 16.5311H7.49304L12.166 20.5927C12.3673 20.7675 12.6148 20.8811 12.8792 20.9197C13.1436 20.9584 13.4136 20.9206 13.657 20.8108C13.9003 20.701 14.1067 20.5238 14.2514 20.3005C14.3962 20.0772 14.4732 19.8172 14.4732 19.5515V5.07434C14.4728 4.70769 14.3261 4.35618 14.0653 4.09692C13.8045 3.83766 13.4509 3.69182 13.0821 3.69141V3.69141ZM13.0678 19.5206L8.21943 15.3067C8.09138 15.1953 7.92704 15.134 7.75692 15.134H3.3475C3.32506 15.134 3.30356 15.1251 3.2877 15.1093C3.27184 15.0936 3.2629 15.0722 3.26288 15.0499V9.58453C3.26291 9.56223 3.27184 9.54086 3.2877 9.5251C3.30356 9.50933 3.32507 9.50047 3.3475 9.50044H7.75692C7.92726 9.50044 8.09181 9.43892 8.21994 9.32734L13.0678 5.10561V19.5206Z"
                    fill="white"
                  />
                  <path
                    d="M18.3753 9.1567C18.2264 9.2675 18.1277 9.43234 18.1007 9.61524C18.0736 9.79814 18.1204 9.98425 18.2309 10.133C18.2956 10.22 19.7962 12.2912 18.2204 14.5075C18.167 14.5824 18.1289 14.667 18.1084 14.7566C18.0879 14.8461 18.0853 14.9388 18.1009 15.0293C18.1164 15.1199 18.1498 15.2065 18.199 15.2841C18.2483 15.3618 18.3125 15.4291 18.388 15.4821C18.4634 15.5351 18.5486 15.5728 18.6387 15.5931C18.7289 15.6133 18.8221 15.6157 18.9131 15.6C19.0042 15.5844 19.0912 15.5511 19.1693 15.502C19.2474 15.4529 19.3149 15.3889 19.3681 15.3138C21.1095 12.8646 20.1214 10.3059 19.3556 9.2951C19.2439 9.14776 19.078 9.05045 18.8942 9.02451C18.7105 8.99856 18.5239 9.0461 18.3753 9.1567V9.1567Z"
                    fill="white"
                  />
                  <path
                    d="M23.8587 10.1644C23.3606 8.14877 22.3711 6.82709 22.3292 6.77183C22.2171 6.62418 22.0507 6.52677 21.8664 6.50097C21.6821 6.47517 21.4951 6.52308 21.3463 6.6342C21.1975 6.74531 21.0991 6.91056 21.0728 7.09368C21.0464 7.27681 21.0941 7.46285 21.2056 7.61101C21.8154 8.50319 22.2562 9.49859 22.5062 10.5482C23.0594 12.8457 22.6179 15.0261 21.194 17.0287C21.1406 17.1036 21.1025 17.1883 21.082 17.2778C21.0615 17.3674 21.0589 17.4601 21.0745 17.5506C21.0901 17.6411 21.1234 17.7277 21.1727 17.8054C21.2219 17.8831 21.2861 17.9504 21.3616 18.0034C21.437 18.0564 21.5222 18.0941 21.6123 18.1143C21.7025 18.1345 21.7957 18.1369 21.8867 18.1213C21.9778 18.1057 22.0648 18.0723 22.1429 18.0232C22.221 17.9741 22.2885 17.9102 22.3417 17.8351C24.4348 14.8912 24.3219 12.0389 23.8587 10.1644Z"
                    fill="white"
                  />
                </svg>
              </div>
              <div class="video-full-screen" @click="launchIntoFullscreen">
                <svg viewBox="0 0 28 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M2.82781 9.92834C2.60826 9.92834 2.39773 9.84112 2.24248 9.68587C2.08724 9.53063 2 9.32008 2 9.10053V1.82781C2 1.60826 2.08724 1.39771 2.24248 1.24247C2.39773 1.08722 2.60826 1 2.82781 1H10.1006C10.3201 1 10.5307 1.08722 10.6859 1.24246C10.8412 1.39771 10.9284 1.60826 10.9284 1.82781C10.9284 2.04736 10.8412 2.25792 10.6859 2.41316C10.5307 2.56841 10.3201 2.65562 10.1006 2.65562H3.65562V9.10053C3.65561 9.32008 3.56842 9.53063 3.41318 9.68587C3.25793 9.84112 3.04736 9.92834 2.82781 9.92834Z"
                    fill="white"
                  />
                  <path
                    d="M10.1006 25.0006H2.82781C2.60826 25.0006 2.39773 24.9134 2.24248 24.7581C2.08724 24.6029 2 24.3923 2 24.1728V16.9001C2 16.6805 2.08724 16.47 2.24248 16.3147C2.39773 16.1595 2.60826 16.0723 2.82781 16.0723C3.04736 16.0723 3.25793 16.1595 3.41318 16.3147C3.56842 16.47 3.65562 16.6805 3.65562 16.9001V23.345H10.1006C10.3201 23.345 10.5307 23.4322 10.6859 23.5874C10.8412 23.7427 10.9284 23.9532 10.9284 24.1728C10.9284 24.3923 10.8412 24.6029 10.6859 24.7581C10.5307 24.9134 10.3201 25.0006 10.1006 25.0006Z"
                    fill="white"
                  />
                  <path
                    d="M25.1719 25.0006H17.8991C17.6795 25.0006 17.469 24.9134 17.3138 24.7581C17.1585 24.6029 17.0713 24.3923 17.0713 24.1728C17.0713 23.9532 17.1585 23.7427 17.3138 23.5874C17.469 23.4322 17.6795 23.345 17.8991 23.345H24.344V16.9001C24.344 16.6805 24.4313 16.47 24.5865 16.3147C24.7418 16.1595 24.9523 16.0723 25.1719 16.0723C25.3914 16.0723 25.602 16.1595 25.7572 16.3147C25.9125 16.47 25.9997 16.6805 25.9997 16.9001V24.1728C25.9997 24.3923 25.9125 24.6029 25.7572 24.7581C25.602 24.9134 25.3914 25.0006 25.1719 25.0006Z"
                    fill="white"
                  />
                  <path
                    d="M25.1719 9.92834C24.9523 9.92834 24.7418 9.84112 24.5865 9.68587C24.4313 9.53063 24.344 9.32008 24.344 9.10053V2.65562H17.8991C17.6795 2.65562 17.469 2.56841 17.3138 2.41316C17.1585 2.25792 17.0713 2.04736 17.0713 1.82781C17.0713 1.60826 17.1585 1.39771 17.3138 1.24246C17.469 1.08722 17.6795 1 17.8991 1H25.1719C25.3914 1 25.602 1.08722 25.7572 1.24247C25.9125 1.39771 25.9997 1.60826 25.9997 1.82781V9.10053C25.9997 9.32008 25.9125 9.53063 25.7572 9.68587C25.602 9.84112 25.3914 9.92834 25.1719 9.92834Z"
                    fill="white"
                  />
                </svg>
              </div>

              <div
                class="video-subs"
                :class="{'active': !showSubs}"
                @click="toggleSubtitles"
                v-if="subtitle"
              >
                <svg viewBox="0 0 28 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M9.23056 16.9621H6.76131C6.55293 16.9621 6.35307 16.8793 6.20571 16.7319C6.05836 16.5846 5.97559 16.3847 5.97559 16.1763C5.97559 15.968 6.05836 15.7681 6.20571 15.6208C6.35307 15.4734 6.55293 15.3906 6.76131 15.3906H9.23056C9.43895 15.3906 9.63881 15.4734 9.78616 15.6208C9.93351 15.7681 10.0163 15.968 10.0163 16.1763C10.0163 16.3847 9.93351 16.5846 9.78616 16.7319C9.63881 16.8793 9.43895 16.9621 9.23056 16.9621Z"
                    fill="white"
                  />
                  <path
                    d="M21.2385 16.9621H12.4752C12.2668 16.9621 12.0669 16.8793 11.9196 16.7319C11.7722 16.5846 11.6895 16.3847 11.6895 16.1763C11.6895 15.968 11.7722 15.7681 11.9196 15.6208C12.0669 15.4734 12.2668 15.3906 12.4752 15.3906H21.2385C21.4469 15.3906 21.6468 15.4734 21.7941 15.6208C21.9415 15.7681 22.0243 15.968 22.0243 16.1763C22.0243 16.3847 21.9415 16.5846 21.7941 16.7319C21.6468 16.8793 21.4469 16.9621 21.2385 16.9621Z"
                    fill="white"
                  />
                  <path
                    d="M15.5123 20.7843H6.76131C6.55293 20.7843 6.35307 20.7016 6.20571 20.5542C6.05836 20.4069 5.97559 20.207 5.97559 19.9986C5.97559 19.7902 6.05836 19.5904 6.20571 19.443C6.35307 19.2957 6.55293 19.2129 6.76131 19.2129H15.5123C15.7207 19.2129 15.9206 19.2957 16.0679 19.443C16.2153 19.5904 16.2981 19.7902 16.2981 19.9986C16.2981 20.207 16.2153 20.4069 16.0679 20.5542C15.9206 20.7016 15.7207 20.7843 15.5123 20.7843Z"
                    fill="white"
                  />
                  <path
                    d="M21.2383 20.7843H18.7887C18.5803 20.7843 18.3804 20.7016 18.2331 20.5542C18.0857 20.4069 18.0029 20.207 18.0029 19.9986C18.0029 19.7902 18.0857 19.5904 18.2331 19.443C18.3804 19.2957 18.5803 19.2129 18.7887 19.2129H21.2383C21.4467 19.2129 21.6466 19.2957 21.7939 19.443C21.9413 19.5904 22.0241 19.7902 22.0241 19.9986C22.0241 20.207 21.9413 20.4069 21.7939 20.5542C21.6466 20.7016 21.4467 20.7843 21.2383 20.7843Z"
                    fill="white"
                  />
                  <path
                    d="M25.2143 25H2.78573C2.57734 25 2.37748 24.9172 2.23013 24.7699C2.08278 24.6225 2 24.4227 2 24.2143V1.78573C2 1.57735 2.08278 1.37749 2.23013 1.23014C2.37748 1.08279 2.57734 1 2.78573 1H25.2143C25.4227 1 25.6226 1.08279 25.7699 1.23014C25.9173 1.37749 26 1.57735 26 1.78573V24.2143C26 24.4227 25.9173 24.6225 25.7699 24.7699C25.6226 24.9172 25.4227 25 25.2143 25ZM3.57146 23.4285H24.4286V2.57146H3.57146V23.4285Z"
                    fill="white"
                  />
                </svg>
              </div>

              <div class="play-time">{{playtimeClock}} / {{playtimeDuration}}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="item-video-chapters" v-if="chapters">
        <h4>Videokapitler:</h4>
        <div v-for="(chapter,index) in chapters" v-bind:key="index">
          <button
            class="chapter-item progress-indicator"
            @click="jumpToChapter(chapter.start)"
            :data-chapter="index + 1"
          >
            <p class="headline">{{chapter.title}}</p>
            <p class="time">Fra {{chapter.start}}</p>
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "AppVideoPlayer",
  data() {
    return {
      videoStatePlaying: false,
      playtimeInProncent: Number,
      durationInProncent: Number,
      durationTime: Number,
      calculationsDone: false,
      showSubs: false,
      playtimeClock: {
        type: String,
        default: 0
      },
      playtimeDuration: {
        type: String,
        default: 0
      },
      currentTime: 0
    };
  },
  props: {
    link: {
      type: String,
      required: true
    },
    chapters: {
      type: Object,
      required: false
    },
    subtitle: {
      type: Object,
      required: false
    },
    poster: {
      type: Object,
      required: false
    }
  },
  methods: {
    playVideo() {
      this.playerElem.play();
      this.videoStatePlaying = true;
      this.wrapperElem.classList.add("video-playing");
      this.wrapperElem.classList.add("has-played");
    },
    pauseVideo() {
      this.playerElem.pause();
      this.videoStatePlaying = false;
      this.wrapperElem.classList.remove("video-playing");
    },
    changeVolume() {
      this.volumeElem = this.$refs.videovolume;
      if (this.playerElem.muted) {
        this.playerElem.muted = false;
        this.volumeElem.classList.remove("muted");
      } else {
        this.playerElem.muted = true;
        this.volumeElem.classList.add("muted");
      }
    },
    toggleSubtitles() {
      return (this.showSubs = !this.showSubs);
    },
    launchIntoFullscreen() {
      if (this.playerElem.requestFullscreen) {
        this.playerElem.requestFullscreen();
      } else if (this.playerElem.mozRequestFullScreen) {
        this.playerElem.mozRequestFullScreen();
      } else if (this.playerElem.webkitRequestFullscreen) {
        this.playerElem.webkitRequestFullscreen();
      } else if (this.playerElem.webkitEnterFullscreen) {
        this.playerElem.webkitEnterFullscreen();
      } else if (this.playerElem.msRequestFullscreen) {
        this.playerElem.msRequestFullscreen();
      }
    },
    exitFullscreen() {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      }
    },
    setTimer() {
      // Calculate seconds to min and secs and display it
      let time = this.currentTime;
      let minutes = Math.floor((time % 3600) / 60);
      let seconds = Math.floor(time % 60);
      seconds = seconds > 9 ? seconds : `0${seconds}`;
      minutes = minutes > 9 ? minutes : `0${minutes}`;
      seconds.toString();
      minutes.toString();
      this.playtimeClock = minutes + ":" + seconds;

      if (this.chapters) {
        this.setActiveChapter();
      }
    },
    setDuration() {
      // Calculate seconds to min and secs and display it
      let time = this.playerElem.duration;
      let minutes = Math.floor((time % 3600) / 60);
      let seconds = Math.floor(time % 60);
      seconds = seconds > 9 ? seconds : `0${seconds}`;
      minutes = minutes > 9 ? minutes : `0${minutes}`;
      seconds.toString();
      minutes.toString();
      this.playtimeDuration = minutes + ":" + seconds;
    },
    setVideoScene() {
      // Calculate videoduration to %
      this.durationInProncent = 100 / this.durationTime;
      if (this.chapters) {
        this.createTimelineChapters();
      }
      this.setDuration();
    },
    clickTimeline(e) {
      // Click on timeline to jump in time
      const timeline = this.$refs.videoTimeline;
      let calcClickToProcent = (100 / timeline.offsetWidth) * e.offsetX;
      let calcNewVideoTime = calcClickToProcent / this.durationInProncent;
      this.playerElem.currentTime = calcNewVideoTime;
    },
    setProgress() {
      // Update progressbar compared to how many % viewed
      this.currentTime = this.playerElem.currentTime;
      this.durationTime = this.playerElem.duration;

      this.playtimeInProncent = this.durationInProncent * this.currentTime;

      // only calculate duration to % once
      if (!this.calculationsDone) {
        this.setVideoScene();
      }

      // Update time in min and secs
      this.setTimer();
      this.calculationsDone = true;
    },
    createTimelineChapters() {
      let i;
      for (i = 0; i < this.chapters.length; i++) {
        let getMinutes = parseInt(this.chapters[i].start.split(":")[0]);
        let getSeconds = parseInt(this.chapters[i].start.split(":")[1]);
        let chapterStart = Math.floor(getMinutes * 60 + getSeconds);
        // create chapters
        const that = this;
        let caculateLeft = chapterStart * this.durationInProncent;
        let title = that.chapters[i].title;
        let time = that.chapters[i].start;
        const chapterDot = document.createElement("div");
        chapterDot.style.left = caculateLeft + "%";
        chapterDot.classList.add("chapter-dot");
        chapterDot.classList.add("progress-indicator");
        chapterDot.dataset.chapter = i + 1;
        chapterDot.addEventListener("mouseover", function() {
          document
            .querySelector(".video-control-title-display")
            .classList.add("active");
          that.showTitle(title);
        });
        chapterDot.addEventListener("mouseleave", function() {
          document
            .querySelector(".video-control-title-display")
            .classList.remove("active");
        });
        chapterDot.addEventListener("click", function() {
          that.jumpToChapter(time);
        });
        this.$refs.chapters.appendChild(chapterDot);
      }
    },
    jumpToChapter(time) {
      let getMinutes = parseInt(time.split(":")[0]);
      let getSeconds = parseInt(time.split(":")[1]);
      let chapterStart = Math.floor(getMinutes * 60 + getSeconds);
      this.playerElem.currentTime = chapterStart;
      this.playVideo();
    },
    showTitle(title) {
      this.$refs.displayTitle.innerHTML = title;
    },
    setActiveChapter() {
      const chaptersElem = document.querySelectorAll(".progress-indicator");
      let i;
      for (i = 0; i < this.chapters.length; i++) {
        if (i < this.chapters.length - 1) {
          let getMinutesCurrent = parseInt(
            this.chapters[i].start.split(":")[0]
          );
          let getSecondsCurrent = parseInt(
            this.chapters[i].start.split(":")[1]
          );
          let chapterStartCurrent = Math.floor(
            getMinutesCurrent * 60 + getSecondsCurrent
          );

          let getMinutesNext = parseInt(
            this.chapters[i + 1].start.split(":")[0]
          );
          let getSecondsNext = parseInt(
            this.chapters[i + 1].start.split(":")[1]
          );
          let chapterStartNext = Math.floor(
            getMinutesNext * 60 + getSecondsNext
          );
          if (
            this.playerElem.currentTime >= chapterStartCurrent &&
            this.playerElem.currentTime < chapterStartNext
          ) {
            chaptersElem.forEach(el => {
              if (el.getAttribute("data-chapter") == i + 1) {
                el.classList.add("active");
              } else {
                el.classList.remove("active");
              }
            });
          }
        }
      }

      let getMinutesLast = parseInt(this.chapters[i - 1].start.split(":")[0]);
      let getSecondsLast = parseInt(this.chapters[i - 1].start.split(":")[1]);
      let chapterStartLast = Math.floor(getMinutesLast * 60 + getSecondsLast);

      if (this.playerElem.currentTime > chapterStartLast) {
        chaptersElem.forEach(el => {
          if (el.getAttribute("data-chapter") == this.chapters.length) {
            el.classList.add("active");
          } else {
            el.classList.remove("active");
          }
        });
      }
    }
  },
  mounted() {
    this.playerElem = this.$refs.videoplayer;
    this.wrapperElem = this.$refs.videowrapper;
  }
};
</script>

<style lang="scss" scoped>
.video-wrap {
  padding: var(--spacing-md-2);
  box-sizing: border-box;

  @media screen and (max-width: 768px) {
    padding: 0;
  }
  &-grid {
    &.chapters {
      display: grid;
      grid-template-columns: 1fr 25%;
      grid-gap: var(--spacing-md-2);

      @media screen and (max-width: 1023px) {
        grid-template-columns: 1fr 30%;
      }
      @media screen and (max-width: 768px) {
        display: block;
      }
    }
  }
}
.item-video-player {
  position: relative;
  line-height: 0px;
  font-size: 0px;

  & video {
    width: 100%;
  }

  &__inner {
    width: 100%;
    height: 0;
    line-height: 0px;
    font-size: 0px;

    padding-bottom: calc(56.25% + 49px);
    overflow: hidden;
    position: sticky;
    top: calc(109px + var(--spacing-md-2));

    &.video-playing {
      & .video-cover {
        pointer-events: all;
        display: none;
      }

      & .video-play {
        display: none;
      }

      & .video-pause {
        opacity: 1;
        pointer-events: all;
        cursor: pointer;
      }

      &:hover .video-control {
        opacity: 1;
        transition: opacity 0.3s ease 0.5s;
      }

      & .video-control {
        & .play-time {
          opacity: 1;
          transition: opacity 0.3s ease 1s;
        }
      }
    }

    &.has-played {
      & .video-poster {
        display: none;
      }
    }

    & .video {
      z-index: 3;
      & video {
        width: 100%;
        position: absolute;
        z-index: 1;
        object-fit: cover;
        top: 0;
        left: 0;
      }

      &-control {
        box-sizing: border-box;
        position: absolute;
        z-index: 3;
        color: #fff;
        bottom: 0;
        padding: 0px;
        height: 50px;
        background: rgba(0, 12, 46, 0.85);
        width: 100%;
        transition: opacity 0.3s ease 3s;
        opacity: 1;

        .moving & {
          opacity: 1;
          transition: 0.3s ease;
        }

        &-inner {
          align-items: center;
          height: 100%;
          position: relative;
        }
      }

      & .play-time {
        position: absolute;
        left: 50px;
        z-index: 4;
        top: calc(50% + 2px);
        transform: translateY(-50%);
        font-size: var(--font-small);
        opacity: 0;
      }

      &-play {
        position: absolute;
        width: 65px;
        height: 65px;
        left: 50%;
        top: 50%;
        z-index: 4;
        transform: translate3d(-50%, -50%, 0);
        cursor: pointer;

        &::after {
          position: absolute;
          content: "";
          width: 100vw;
          height: 100vh;
          left: -50vw;
          right: 0;
          top: -50vh;
        }
      }

      &-poster {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 50%;
        top: 50%;
        z-index: 3;
        transform: translate3d(-50%, -50%, 0);
        cursor: pointer;
        line-height: 0px;
        font-size: 0px;

        & img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          position: relative;
        }
      }

      &-pause {
        opacity: 0.2;
        pointer-events: none;
        position: absolute;
        top: calc(50% + 4px);
        transform: translateY(-50%);
        left: 20px;
        & svg {
          height: 16px;
        }
      }

      &-progress-fill {
        height: 4px;
        width: var(--video-progress);
        background-color: var(--red-color);
        position: absolute;
        transition: 0.1s ease;
        top: 0;
        line-height: 0px;
        font-size: 0px;
      }

      &-progress {
        height: 4px;
        width: 100%;
        background-color: #fff;
        position: relative;
        top: 0px;
        pointer-events: all;
        line-height: 0px;
        font-size: 0px;

        &-click {
          height: 12px;
          width: 100%;
          background-color: transparent;
          z-index: 2;
          position: absolute;
          transition: 0.1s ease;
          bottom: -4px;
        }

        &-time {
          background-color: #fff;
          position: absolute;
          top: -38px;
          left: var(--video-progress);
          color: #000;
          transform: translateX(-50%);
          padding: 0 5px;
          transition: 0.1s ease;
          font-size: 12px;
        }

        &-chapters {
          position: relative;
          & .chapter {
            position: absolute;
            z-index: 3;
            width: 5px;
            height: 5px;
            background-color: #304e37;
            transform: translateX(-50%);
            pointer-events: none;
          }
        }
      }

      &-sound {
        width: 18px;
        margin-left: 13px;
        position: absolute;
        top: calc(50% + 3px);
        transform: translateY(-50%);
        right: 50px;
        cursor: pointer;
        display: block;

        & .video-sound-muted {
          display: none;
        }

        & .video-sound-active {
          display: block;
        }

        &.muted {
          & .video-sound-active {
            display: none;
          }

          & .video-sound-muted {
            display: block;
          }
        }
      }

      &-full-screen {
        width: 16px;
        margin-left: 12px;
        margin-bottom: -4px;
        position: absolute;
        top: calc(50% + 4px);
        transform: translateY(-50%);
        right: 20px;
        cursor: pointer;
      }

      &-subs {
        width: 16px;
        margin-left: 12px;
        margin-bottom: -4px;
        position: absolute;
        top: calc(50% + 4px);
        transform: translateY(-50%);
        right: 80px;
        cursor: pointer;

        &.active {
          opacity: 0.5;
        }
      }
    }

    & .video-control-title-display {
      left: 50%;
      position: absolute;
      top: 50%;
      z-index: 2;
      max-width: 50%;
      transform: translateY(-50%) translateX(-50%);
      cursor: pointer;
      font-size: 12px;
      opacity: 0;

      &.active {
        opacity: 1;
        transition: opacity 0.25s ease-out;

        @media screen and (max-width: 768px) {
          opacity: 0;
        }
      }
    }
  }
}

.item-video-chapters {
  @media screen and (max-width: 768px) {
    padding: var(--spacing-md-2);
  }
  & h4 {
    margin-bottom: 1rem;
  }
  & .chapter-item {
    border: none;
    outline: none;
    background: transparent;
    color: var(--blue-color);
    padding-left: 1.5rem;
    position: relative;
    margin-bottom: 1rem;
    &::before {
      content: "";
      position: absolute;
      width: 14px;
      left: 0;
      height: 14px;
      background: var(--red-color);
      border-radius: 50%;
      top: 6px;
    }

    &.active {
      &::before {
        background: var(--blue-color);
      }
    }

    & p {
      text-align: left;

      &.headline {
        font-size: 18px;
        font-family: var(--font-family-semi-header);

        @media screen and (max-width: 1023px) {
          font-size: 1rem;
        }
      }
    }
  }
}
</style>

<style lang="scss">
.chapter-dot {
  position: absolute;
  top: 2px;
  background: var(--red-color);
  width: 12px;
  height: 12px;
  border-radius: 50%;
  transform: translateY(-50%);
  z-index: 20;
  cursor: pointer;

  &.active {
    background: var(--blue-color);
  }
}
</style>
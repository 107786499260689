<template>
  <div>
    <svg width="48" height="8" viewBox="0 0 48 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <line x1="0.5" y1="3.49998" x2="46.5" y2="3.49998" stroke="#000C2E" stroke-linecap="round" />
      <line
        x1="44.3739"
        y1="0.999985"
        x2="47.0001"
        y2="3.62621"
        stroke="#000C2E"
        stroke-linecap="round"
      />
      <line
        x1="0.5"
        y1="-0.5"
        x2="4.21405"
        y2="-0.5"
        transform="matrix(0.707107 -0.707107 -0.707107 -0.707107 43.6667 6.33332)"
        stroke="#000C2E"
        stroke-linecap="round"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: "AppSvgArrowRight"
};
</script>

<style lang="scss" scoped>
</style>
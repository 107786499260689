<template>
  <div class="svg-stage">
    <svg
      version="1.1"
      id="Illustration-back"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 1216.3 488.2"
      style="enable-background:new 0 0 1216.3 488.2;"
      xml:space="preserve"
    >
      <g id="KK-1-5">
        <g id="_x31_-5_x5F_vindue_x5F_8">
          <polygon class="st0" points="251.3,300.9 252.8,344 270.4,348.6 268.8,304.1 		" />
          <polygon
            class="st1"
            points="269.3,318 269.3,317 264.1,315.9 263.6,303.2 261.3,302.7 261.7,315.4 253.5,313.6 251.8,314.2 
			261.7,316.4 262.7,346.6 265.1,346.1 264.1,316.9 		"
          />
          <polygon
            class="st1"
            points="269.4,321 269.3,318 261.7,316.4 261.3,302.7 259.6,302.4 260.1,316 251.8,314.2 251.9,317.2 
			260.2,319 261.1,346.2 262.7,346.6 261.8,319.3 		"
          />
          <polygon class="st1" points="251.3,300.9 251.8,314.2 253.5,313.6 253,301.2 		" />
          <polygon class="st1" points="253.5,317.5 254.4,343.3 252.8,344 251.9,317.2 		" />
          <polygon class="st1" points="265.1,346.1 262.7,346.6 270.4,348.6 270.4,347.4 		" />
          <polygon class="st1" points="261.1,346.2 252.8,344 254.4,343.3 261,345 		" />
        </g>
        <g id="_x31_-5_x5F_vindue_x5F_7">
          <polygon class="st0" points="247.9,219.4 249.6,263.7 267.3,266 265.7,220.3 		" />
          <polygon class="st1" points="252.1,262.9 249.6,263.7 267.3,266 267.3,265 		" />
          <polygon class="st1" points="259.6,265 261.9,264.2 260.2,220.1 258,219.9 		" />
          <polygon class="st1" points="250.7,219.5 252.1,262.9 249.6,263.7 247.9,219.4 		" />
          <polygon
            class="st1"
            points="266.4,237.9 266.2,234.7 258.5,234.1 258,219.9 256.4,219.8 256.9,234 248.4,233.3 248.6,236.6 
			257,237.2 258,264.8 259.6,265 258.6,237.3 		"
          />
          <polygon class="st1" points="248.4,233.3 251.2,232.9 256.9,233.2 256.9,234 		" />
          <polygon class="st1" points="260.7,233.5 258.5,234.1 266.2,234.7 266.2,233.9 		" />
        </g>
        <g id="_x31_-5_x5F_vindue_x5F_6">
          <polygon class="st0" points="201.4,291.1 202.9,330.9 217.9,334.9 216.3,293.7 		" />
          <polygon
            class="st1"
            points="216.8,306.6 216.8,305.6 212.5,304.7 212.1,293 209.7,292.6 210.2,304.2 204.4,302.9 201.8,303.3 
			210.2,305.1 211.5,333.2 213.3,332.1 212.6,305.7 		"
          />
          <polygon
            class="st1"
            points="216.9,309.4 216.8,306.6 210.2,305.1 209.7,292.6 208.4,292.3 208.9,304.8 201.8,303.3 201.9,306.1 
			209,307.7 210,332.8 211.5,333.2 210.4,308 		"
          />
          <polygon class="st1" points="204.4,302.9 203.9,291.6 201.4,291.1 201.8,303.3 		" />
          <polygon class="st1" points="204.4,306.7 201.9,306.1 202.9,330.9 205,329.6 		" />
          <polygon class="st1" points="213.3,332.1 211.5,333.2 217.9,334.9 217.9,333.2 		" />
          <polygon class="st1" points="210,332.8 202.9,330.9 205,329.6 209.9,330.9 		" />
        </g>
        <g id="_x31_-5_x5F_vindue_x5F_5">
          <polygon class="st0" points="197.7,216.7 199.3,257 214.6,259.1 212.7,217.2 		" />
          <polygon
            class="st1"
            points="213.3,230.3 213.3,229.8 208.9,229.4 208.4,217 206.1,217 206.7,229.2 200.6,228.7 198.2,229.2 
			206.7,229.8 207.9,258.2 210,257.5 208.9,230 		"
          />
          <polygon
            class="st1"
            points="213.4,233.1 213.3,230.3 206.7,229.8 206.1,217 204.8,216.9 205.3,229.7 198.2,229.2 198.3,231.8 
			205.4,232.4 206.5,258 207.9,258.2 206.8,232.5 		"
          />
          <polygon class="st1" points="200.6,228.7 200.1,216.7 197.7,216.7 198.2,229.2 		" />
          <polygon class="st1" points="200.6,232 201.4,256.2 199.3,257 198.3,231.8 		" />
          <path class="st1" d="M214.5,258.2" />
          <polygon class="st1" points="214.5,258.2 210,257.5 207.9,258.2 214.6,259.1 		" />
          <polygon class="st1" points="206.4,256.8 201.4,256.2 199.3,257 206.5,258 		" />
        </g>
        <g id="_x31_-5_x5F_vindue_x5F_4">
          <g>
            <polygon class="st0" points="887.5,229.1 885.1,280.5 868.3,283.8 870.8,230.1 			" />
            <polygon
              class="st1"
              points="886.7,245.6 884,245.4 877.7,246 878.4,229.7 875.3,229.8 874.5,246.4 870,247 870,247.8 
				874.5,247.2 872.9,281.8 876.1,282.2 877.5,250.1 877.7,246.8 			"
            />
            <polygon
              class="st1"
              points="886.7,245.6 879.1,246.6 879.8,229.6 878.4,229.7 877.7,246.8 870,247.8 869.8,251.3 877.5,250.1 
				876.1,282.2 877.5,282 878.9,249.9 886.6,248.8 			"
            />
          </g>
          <polygon class="st1" points="884,245.4 884.6,229.3 887.5,229.1 886.7,245.6 		" />
          <polygon class="st1" points="883.9,249.2 882.7,279.8 885.1,280.5 886.6,248.8 		" />
          <line class="st1" x1="872.9" y1="281.8" x2="868.3" y2="282.7" />
          <line class="st1" x1="882.7" y1="279.8" x2="877.5" y2="280.8" />
        </g>
        <g id="_x31_-5_x5F_vindue_x5F_3">
          <polygon class="st0" points="882.8,321.6 880.3,372.1 864,378.7 866.1,326.6 		" />
          <polygon
            class="st1"
            points="882,338 878.8,337.7 873,339.6 873.6,324.3 870.6,325.2 869.9,340.7 865.5,342.3 865.4,343.7 
			869.8,342.2 868.4,375.1 871.5,375.7 872.9,341.1 		"
          />
          <polygon
            class="st1"
            points="882,338 874.4,340.6 875.2,323.8 873.6,324.3 872.9,341.1 865.4,343.7 865.3,347.1 872.8,344.5 
			871.5,375.7 872.7,375.2 874.2,343.9 881.8,341.3 		"
          />
          <polygon class="st1" points="878.8,337.7 879.4,322.6 882.8,321.6 882,338 		" />
          <polygon class="st1" points="879.1,342.2 877.8,371.2 880.3,372.1 881.8,341.3 		" />
          <line class="st1" x1="868.4" y1="375.1" x2="864.1" y2="376.9" />
          <line class="st1" x1="877.8" y1="371.2" x2="872.8" y2="373.3" />
        </g>
        <g id="_x31_-5_x5F_vindue_x5F_2">
          <line class="st0" x1="758.6" y1="239.6" x2="744.6" y2="238.3" />
          <polygon
            class="st1"
            points="758.8,221.3 739.8,220.2 739.8,220.9 752.1,221.7 751.9,240.1 754.3,239.1 754.5,221.8 758.8,222.1 
					"
          />
          <polyline
            class="st0"
            points="739.8,220.2 739.8,220.2 758.8,221.3 758.6,240.7 739.8,238.9 739.8,220.2 		"
          />
          <polygon
            class="st1"
            points="739.8,220.2 739.8,220.9 749.4,221.5 749.2,239.8 751.9,240.1 752.1,221.7 758.8,222.1 758.8,221.3 
					"
          />
          <path class="st0" d="M754.3,239.1" />
          <path
            class="st0"
            d="M739.8,220.9v18l4.8-0.6l0.2-17.1L739.8,220.9z M742.3,229.3c-0.3,0-0.5-0.2-0.5-0.5c0-0.3,0.2-0.5,0.5-0.5
			c0.3,0,0.5,0.2,0.5,0.5C742.8,229.1,742.6,229.3,742.3,229.3z"
          />
        </g>
        <g id="_x31_-5_x5F_vindue_x5F_1">
          <polygon
            class="st0"
            points="583.7,215.8 602.6,217 602.6,217.8 595.9,217.3 595.8,235.7 593.1,235.5 593.2,217.2 583.7,216.6 		
			"
          />
          <polyline
            class="st0"
            points="598.2,234.9 602.5,235.3 602.5,236.4 583.7,234.6 588.5,234 593.1,234.4 		"
          />
          <polygon class="st0" points="583.7,215.8 602.6,217 602.5,236.4 583.7,234.6 		" />
          <polygon class="st0" points="595.8,235.7 598.2,234.9 598.3,217.5 595.9,217.3 		" />
          <path
            class="st0"
            d="M583.7,216.6v18l4.8-0.6l0.2-17.1L583.7,216.6z M586.1,225c-0.3,0-0.5-0.2-0.5-0.5s0.2-0.5,0.5-0.5
			s0.5,0.2,0.5,0.5C586.6,224.7,586.4,225,586.1,225z"
          />
        </g>
      </g>
      <polygon id="gulv" class="st0" points="448.8,367.3 804.8,447.1 805.6,427.4 448.8,353 " />
      <g id="ydrevaegge">
        <path
          class="st1"
          d="M282.2,188.3h-9l-29.4,21.8l-62.4-3.7l11.3,239l98.8,42.7L282.2,188.3z M197.7,216.7l15,0.5l1.9,42l-15.3-2.1
		L197.7,216.7z M202.9,330.9l-1.5-39.7l14.9,2.5l1.7,41.2L202.9,330.9z M247.9,219.4l17.8,1l1.6,45.6l-17.8-2.3L247.9,219.4z
		M252.8,344l-1.5-43.1l17.5,3.2l1.6,44.5L252.8,344z"
        />
        <path
          class="st1"
          d="M839.9,17.1l-16.1,421.1l96.1-45.5l10.2-205.4L839.9,17.1z M880.3,372.1l-16.3,6.6l2-52.2l16.7-5L880.3,372.1z M885.1,280.5l-16.7,3.3l2.5-53.7l16.7-1L885.1,280.5z"
        />
        <polygon class="st0" points="820.3,19.6 804.8,447.1 823.3,451.5 839.9,17.1 	" />
        <polygon class="st0" points="823.3,451.5 823.8,438.2 919.9,392.7 919.5,403.5 	" />
      </g>
      <g id="kaeldervaegge">
        <line class="st0" x1="291.5" y1="488.1" x2="453.3" y2="442.3" />
        <polygon class="st0" points="453.3,442.3 306.1,484 303.5,396 448.8,367.3 452.4,368.1 	" />
        <polygon class="st0" points="305.9,478.5 453.3,438.1 453.3,442.3 306.1,484 	" />
        <polygon class="st0" points="448.8,367.3 449.7,439 453.3,438.1 452.4,368.1 	" />
        <polygon class="st0" points="303.5,396 303,380.8 448.8,353 448.8,367.3 	" />
      </g>
      <g id="tag">
        <path
          class="st1"
          d="M871.7,0.2l-0.5,13l96,196.1c0,0,2.5-5,0.4-11.6C964.2,190.2,871.7,0.2,871.7,0.2z"
        />
        <polygon class="st1" points="967.2,209.3 929.1,208.2 930.2,187.3 839.9,17.1 871.2,13.2 	" />
        <polygon class="st1" points="435.8,58.6 871.7,0.2 871.2,13.2 435.8,67.6 	" />
        <g>
          <polyline
            class="st1"
            points="435.8,58.6 435.8,67.6 243.7,210.1 238.6,202.5 139.1,197.7 		"
          />
          <polygon
            class="st1"
            points="243.7,210.1 238.6,202.5 435.8,58.6 356.5,69.3 356.9,83.9 333.5,100.8 317.3,102.2 316.5,78.5 
			139.1,197.7 143.6,204.1 		"
          />
          <line class="st1" x1="238.6" y1="202.5" x2="139.1" y2="197.7" />
        </g>
        <polygon class="st1" points="778.9,183.7 778.8,187.1 792.1,187.2 792.2,183.7 780,183.7 	" />
        <path
          class="st1"
          d="M808.4,168.9h-0.6c-1.2,4.8-3.6,8.1-6.4,8.1c0,0,0,0,0,0h-0.1l-0.2,6.7h-4.6l-0.1,3.6l17.8,0.2l0.7-20.1
		L808.4,168.9z"
        />
        <polygon class="st1" points="707.5,186.2 722.3,186.4 722.3,183.1 707.5,183 	" />
        <polygon class="st1" points="726.1,186.4 774.5,187 774.6,183.6 726.2,183.2 	" />
        <g>
          <path
            class="st1"
            d="M699.4,172.7l-69.2-0.8c-0.7,0-1.2,0.5-1.2,1.2l0,12.1l74.9,0.9V183l-4.6,0L699.4,172.7z"
          />
          <path
            class="st1"
            d="M815.5,151.3l4.8-131.8L435.8,67.6l-60.1,44.6l78.3,70.5c2.6-2,5.9-3.1,9.5-3.1c3.9,0,7.5,1.4,10.3,3.8
			l145.9,1.8l0.1-16.4c0-3.4,2.9-6.2,6.3-6.2l73.6,0.7l0.5-23l81.5-2.2l20.9,1.4l-0.2,7.1c2.8,0,4.7,3.3,5.7,8.2h1.9l1.6-1.3
			l1.5-3.3l-0.6-34.2c0-1.2-0.8-4.3-4.6-4.7c-7.4-0.7-42.3-1.2-66.4-1.7c-12.2-0.3-22.3-10.1-22-22.3l0-4.4l0-1.3
			c-0.1-4.7,8.2-10.8,15.1-10.8h0.3c2.6,0,4.1,2.3,4,4.9l0,0.3l0.1,10.7c-0.1,2.7,2.1,4.8,4.7,4.8H812c3.9,0,5.5,3.4,5.5,5.8
			l-1.4,37.1L815.5,151.3z"
          />
        </g>
        <g>
          <polygon
            class="st1"
            points="274.8,187.1 278.2,184.5 318,184 317.4,155.4 333.2,143.7 340.8,138.1 273.1,188.3 297.5,188.3 
			297.8,192 318.2,191.6 318.1,186.5 		"
          />
          <g>
            <path class="st1" d="M409.1,186c0-0.4-0.3-0.7-0.7-0.7l-50.5,0.7l0.1,4.9l51.1-0.9V186z" />
            <path
              class="st1"
              d="M411.6,185.3v4.7l37.2-0.7c1.1-2.7,2.9-4.9,5.2-6.6l-78.3-70.5l-19,14.1l1.1,57.2l50.6-0.7l1.4,0
				c0.5,0,0.9,0.2,1.3,0.5l0,0c0,0,0,0,0,0c0.3,0.3,0.6,0.8,0.6,1.3L411.6,185.3L411.6,185.3z"
            />
          </g>
        </g>
      </g>
      <g id="trappe">
        <polyline class="st0" points="601,364.1 562.9,355.6 562.9,349.1 601.1,357.2 	" />
        <polygon class="st0" points="601.3,357.3 562.9,349.1 569.5,347.9 601.3,355 	" />
        <polyline class="st0" points="601.1,355 569.5,347.9 569.5,341.5 596.8,347.3 	" />
        <polyline class="st0" points="594.8,344.4 576,340.2 576,333.8 592.8,337.4 	" />
        <polyline class="st0" points="596.8,347.3 569.5,341.5 576,340.2 594.8,344.4 	" />
        <polyline class="st0" points="592.9,334.9 582.5,332.6 582.5,326.1 594.9,328.7 	" />
        <polyline class="st0" points="596.4,326.5 589.1,324.9 589.1,318.5 601.7,321.1 	" />
        <polyline class="st0" points="601.4,321.1 589.1,318.5 595.6,317.2 601.4,318.5 	" />
        <polyline class="st0" points="601.7,318.6 595.6,317.2 595.6,310.8 601.8,312.1 	" />
        <polyline class="st0" points="601.8,312.1 595.6,310.8 601.8,309.6 601.8,312.1 	" />
        <path
          class="st0"
          d="M558.1,356.5v-15.7c0-1.2,0.5-2.3,1.3-3.1l41.1-41.4l1.4,0.2l0,1l-41.3,41.3c-0.5,0.6-0.9,1.3-0.9,2.1l0,15.5
		L558.1,356.5z"
        />
        <polygon class="st0" points="556.4,356.8 562.9,355.6 601.3,364.1 601.3,366.3 	" />
        <polygon class="st0" points="601.2,373.3 556.4,363.2 556.4,356.8 601.3,366.3 	" />
        <path
          class="st0"
          d="M592.8,336.6c0-0.6,0-1.2,0.1-1.8l-10.3-2.3l-6.5,1.2l16.8,3.6C592.8,337.1,592.8,336.9,592.8,336.6z"
        />
        <path
          class="st0"
          d="M592.8,336.6c0-6.3,3.6-11.7,8.9-14.3l0.1-12.7l-6.2,1.2v6.4l-6.5,1.2v6.4l-6.5,1.2v6.4l-6.5,1.2v6.4l-6.5,1.2
		v6.4l-6.5,1.2v6.4l-6.5,1.2v6.4l44.8,10l0.2-22.5C596.3,348.1,592.8,342.8,592.8,336.6z"
        />
      </g>
      <g id="skortsten">
        <g id="skorsten_x5F_4">
          <path
            class="st0"
            d="M357.9,326.8c-8.8,0-15.9-7.1-15.9-15.9s7.1-15.9,15.9-15.9c0.7,0,1.4,0.1,2.1,0.1l-0.2-7.4l-23.2,2.7l1,48.5
			l23.2-4.1l-0.2-8.3C359.8,326.7,358.9,326.8,357.9,326.8z"
          />
          <g>
            <path
              class="st0"
              d="M321.8,315l1,0.7l0,2.1c0,0,0,0,0,0h0.9c0,0,0.1,0,0.1,0l0.4,0.1c0.2-0.4,0.6-0.6,1-0.5l1.9,0.6
				c0.5,0.1,0.8,0.7,0.7,1.3c-0.1,0.6-0.6,1.1-1.1,1l-1.9-0.6c-0.5-0.1-0.8-0.6-0.7-1.2l-0.4-0.1h-0.8c0,0,0,0,0,0l0.2,11.9
				c0.1,0,0.3-0.1,0.4-0.1c0.5,0,0.9,0.1,0.9,0.3l-0.1,1.6c0,0.2-0.2,0.3-0.5,0.4l0,2.7l13.8,3.7l-1-48.5l-15.8,1.8l0.5,22.8
				L321.8,315z"
            />
            <polygon class="st0" points="323.2,333.3 321.8,333.5 321.8,334.7 323.2,335.1 			" />
          </g>
        </g>
        <g id="skorsten_x5F_3">
          <path
            class="st0"
            d="M358.9,238l-0.1-2.7c-0.1,0-0.2,0-0.2,0c-8.8,0-15.9-7.1-15.9-15.9c0-8.6,6.9-15.6,15.4-15.9L358,200
			l-23.4,0.6l1.7,65.3l11.8-1.2l-0.4-24.1l-2.3-0.2v-1.4L358.9,238z"
          />
          <g>
            <path
              class="st0"
              d="M320.6,243.2l1,0.7l0,2.1c0,0,0,0,0,0h0.9c0,0,0.1,0,0.1,0l0.4,0.1c0.2-0.4,0.6-0.6,1-0.5l1.9,0.6
				c0.5,0.1,0.8,0.7,0.7,1.3c-0.1,0.6-0.6,1.1-1.1,1c0,0,0,0,0,0l-1.9-0.6c-0.5-0.1-0.8-0.6-0.7-1.2l-0.4-0.1h-0.8c0,0,0,0,0,0
				l0.2,11.9c0.1,0,0.3-0.1,0.4-0.1c0.5,0,0.9,0.1,0.9,0.3l-0.1,1.6c0,0.2-0.2,0.3-0.5,0.4l0,3.4l13.7,1.8l-1.7-65.3l-16.3,0.4
				l1.3,42.2L320.6,243.2z"
            />
            <polygon class="st0" points="321.9,261.5 320.2,261.7 320.2,263.8 321.9,264.1 			" />
          </g>
          <g>
            <path
              class="st0"
              d="M357.7,250.4L357.7,250.4L357.7,250.4c-1-0.1-1.8-0.3-1.8-0.5l0-1l0-0.1c0-0.2,1.3-0.5,3.2-0.5l-0.1-6.5
				l-10.1-0.9l0.3,23.9l7.3-0.8L357.7,250.4z"
            />
            <polygon class="st0" points="358.4,257.2 359.3,256.6 359.2,250.6 358.7,250.6 			" />
            <polygon class="st0" points="358.4,258.5 358.2,263.7 359.5,263.6 359.4,257.8 			" />
            <polygon class="st0" points="357.4,263.8 357.4,263.3 357.3,263.8 			" />
          </g>
        </g>
        <g id="skorsten_x5F_2">
          <g>
            <polygon class="st0" points="357.8,183.5 356.7,126.3 333.2,143.7 334.3,183.8 			" />
            <path
              class="st0"
              d="M349.5,186.6c0-0.2-0.2-0.4-0.4-0.4l-14.8,0.2l0.2,6.9l15.1-0.4L349.5,186.6z"
            />
            <path
              class="st0"
              d="M352.5,186.1c-0.2,0-0.4,0.2-0.4,0.4l0.1,6.3l5.8-0.2l-0.1-6.6L352.5,186.1z"
            />
          </g>
          <g>
            <polygon class="st0" points="334.3,183.8 333.2,143.7 317.4,155.4 318,184 			" />
            <polygon class="st0" points="318.1,186.5 318.2,192.7 334.5,193.3 334.3,186.3 			" />
          </g>
        </g>
        <g id="skorsten_x5F_1">
          <polygon class="st1" points="315.8,53.1 332.2,50.5 333.5,100.8 317.3,102.2 		" />
          <polygon class="st1" points="333.5,100.8 356.9,83.9 356,53.3 332.2,50.5 		" />
        </g>
      </g>

      <g id="KK-2-1" class="hover-obj lejer" @click="$store.commit('setModal', '2.1')">
        <g id="_x32_-1_x5F_vaeg_x5F_23">
          <polygon class="st0" points="606.8,202 602.8,201.9 601.9,292.1 606,292.6 		" />
          <g>
            <path
              class="st0"
              d="M605.7,321l0.2-24.1l-4.1-0.5l-0.2,26C602.9,321.7,604.3,321.3,605.7,321z"
            />
            <path
              class="st0"
              d="M601.4,350.8l-0.3,34l4.1,0.8l0.3-33.4C604,351.9,602.7,351.4,601.4,350.8z"
            />
          </g>
        </g>
        <path
          id="_x32_-1_x5F_vaeg_x5F_22"
          class="st1"
          d="M560.2,204.7c0.6-0.1,1.3-0.1,2-0.1c8.8,0,15.9,7.1,15.9,15.9
		s-7.1,15.9-15.9,15.9c-0.8,0-1.5-0.1-2.2-0.2l-0.2,25.4l42.4,4.1l0.3-29.3l-18.8-1.8v-33.2l-23.4-0.7L560.2,204.7z"
        />
        <path
          id="_x32_-1_x5F_vaeg_x5F_21"
          class="st1"
          d="M409.3,427l2.4,0.7v-1.5C411,426.5,410.2,426.7,409.3,427z"
        />
        <path
          id="_x32_-1_x5F_vaeg_x5F_20"
          class="st1"
          d="M408.2,382.1c0-1.7,0-6.8,0-6.8l2.4-0.5l-8,1.6v8.5l4.1-0.9
		C406.8,384,408.2,383.7,408.2,382.1z"
        />
        <g id="_x32_-1_x5F_vaeg_x5F_19">
          <polygon class="st1" points="361.4,384.6 359.7,384.9 359.7,391.9 361.4,392.4 		" />
          <polygon class="st1" points="364.5,395.3 359.7,393.9 359.7,397.9 364.5,399.1 		" />
          <polygon class="st1" points="370.8,397 366,395.7 366,399.6 370.8,400.8 		" />
          <polygon
            class="st1"
            points="358.3,385.2 358.3,385.2 357.3,385.4 357.4,391.3 358.3,391.5 		"
          />
          <polygon class="st1" points="358.3,393.5 357.5,393.3 357.5,397.3 358.3,397.5 		" />
          <polygon class="st1" points="364.5,384 364.5,384 362.9,384.3 362.9,392.7 364.5,393.2 		" />
          <g>
            <polygon class="st1" points="303.5,396 304.1,417.9 347.3,408.5 347,387.4 			" />
            <polygon class="st1" points="354.7,385.9 347,387.4 347.3,408.5 355.3,410.8 			" />
          </g>
          <path
            class="st1"
            d="M399.1,377.1l-20.2,4v3.3v12.6l1.7,0.5l0,0c0,0,0,0,0.1,0c0,0,0.1,0,0.1,0.1c0.1,0,0.1,0.1,0.2,0.1
			c0,0,0.1,0.1,0.1,0.1c0.1,0.1,0.1,0.1,0.1,0.2c0,0,0,0.1,0.1,0.1c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.4,1.1-1,1.1c-0.1,0-0.2,0-0.3,0
			l0,0l-1.2-0.3v3.7l1.7,0.5l0,0c0,0,0,0,0.1,0c0,0,0.1,0,0.1,0.1c0.1,0,0.1,0.1,0.2,0.1c0,0,0.1,0.1,0.1,0.1
			c0.1,0.1,0.1,0.1,0.1,0.2c0,0,0,0.1,0.1,0.1c0,0.1,0.1,0.3,0.1,0.4c0,0.6-0.4,1.1-1,1.1c-0.1,0-0.2,0-0.3,0l0,0l-22.4-6.4
			l0.3,12.3l30.6,9.1c0-0.2-0.1-0.3-0.1-0.5l-0.6-29.7c0-0.9,0.4-1.7,1.2-2.2c0.3-0.2,0.6-0.3,1-0.5c-0.1,0-0.1,0.1-0.2,0.1
			c1-0.5,4.5-2,9.3-2.3V377.1z"
          />
          <polygon
            class="st1"
            points="370.8,385.5 370.8,382.7 370.8,382.7 366,383.6 366,384 366,393.6 367.6,394 367.6,383.3 369.3,383 
			369.3,394.5 370.8,394.9 		"
          />
          <polygon
            class="st1"
            points="377.2,384.5 377.2,381.4 375.6,381.8 375.6,396.2 377.2,396.6 		"
          />
          <polygon
            class="st1"
            points="374,395.7 374,382.1 374.9,381.9 372.4,382.4 372.4,385.5 372.4,395.3 		"
          />
          <polygon class="st1" points="377.2,398.9 372.4,397.5 372.4,401.3 377.2,402.6 		" />
          <line class="st1" x1="347.3" y1="408.5" x2="346.9" y2="387.4" />
        </g>
        <path
          id="_x32_-1_x5F_vaeg_x5F_18"
          class="st1"
          d="M414.4,424.9c-0.2,0.1-0.4,0.2-0.7,0.4v3l36,10.8l-0.9-71.7l-38.1,7.5v7.2 c0,0,0,3.7-3.1,4.3c-0.1,0-0.1,0-0.2,0c3.1,1,5.2,2.8,6.4,4.1c0.8,0.8,1.2,1.9,1.2,3l0.4,29.6C415.4,423.8,415,424.5,414.4,424.9z M432.4,377l2.1-0.7l10.1,2.7l0.3,29.4l-1.5,0.7l-11-2.9V377z"
        />
        <path
          id="_x32_-1_x5F_vaeg_x5F_17"
          class="st1"
          d="M552.6,313.6c-8.8,0-15.9-7.1-15.9-15.9c0-3.3,1-6.4,2.8-9l-90.7-11.2V353
		l8.1-1.6l-0.1-59.9l40-3.4l0.1,55.4l62.9-12.4v-19.2C557.6,313,555.2,313.6,552.6,313.6z M502.9,326.5l-3.7,0.3v0h0l0,0l0-6.1
		l3.7-0.3v0h0l0,0L502.9,326.5z M502.9,319l-3.7,0.3v0h0l0,0l0-6.1l3.7-0.3v0h0l0,0L502.9,319z M527.8,297.2l-17.9,3.4l-0.7-0.5
		v-4.1l17.9-3.1l0.7,0.5V297.2z"
        />
        <path
          id="_x32_-1_x5F_vaeg_x5F_16"
          class="st1"
          d="M560.2,204.7l0-4l-29.2-0.8c0.9,1.9,1.3,4.1,1.3,6.4c0,8.8-7.1,15.9-15.9,15.9
		s-15.9-7.1-15.9-15.9c0-2.6,0.6-5,1.7-7.2l-23.1-0.6c-1.4,7.4-7.9,12.9-15.6,12.9c-6.6,0-12.3-4-14.7-9.8v72.4l8.1-0.9l-0.1-59.5
		l40-1.4l0.1,56.5l62.9-7l0.2-25.4c-7.7-1.1-13.7-7.7-13.7-15.7C546.3,212.3,552.4,205.6,560.2,204.7z M499.2,237.1l3.7-0.1
		c0,0,0,6.1,0,6.1l-3.7,0.1C499.2,243.3,499.2,237.1,499.2,237.1z M499.2,251c0,0,0-6.1,0-6.1l3.7-0.1c0,0,0,6.1,0,6.1L499.2,251z
		M508.4,255c0,0.2-0.5,0.2-1.1,0.2c-0.6-0.1-1-0.3-1-0.5l0.5-3.6c0.1-0.6,0.6-1,1.2-0.9c0.6,0.1,1,0.6,0.9,1.2L508.4,255z
		M512.8,246.1c0.4,0.2,1.2,0.6,1.2,1v4.2v3.3v4.8c0,0.3-1.1,0.5-2.4,0.5s-2.4-0.2-2.4-0.5v-4.8v-3.3v-4.2c0-0.4,0.8-0.8,1.2-1 c0.3-0.1,0.6-0.5,0.6-0.5v-0.1v-1c-0.2,0-0.3,0-0.3,0v-0.7c0,0,2-0.8,3.7-0.6c0.1,0.3,0.1,0.3,0.1,0.3s-1.6,0.2-2.3,0.5 c1.1-0.1,2.9,0.5,3.4,0.9c0,0.5,0,0.5,0,0.5s-2.3-0.9-3.3-0.9c-0.1,0-0.2,0-0.3,0v1v0.1C512.1,245.7,512.5,246,512.8,246.1z M544.7,241.9l-18.3,1.9l-3.2-1.2v-18.7l18.3-1.2l3.2,1.2V241.9z"
        />
        <g id="_x32_-1_x5F_vaeg_x5F_15">
          <path
            class="st1"
            d="M600.5,296.2l-33-4.1c0.6,1.7,1,3.6,1,5.5c0,6.2-3.6,11.6-8.8,14.2v19.2l5.2,0.9L600.5,296.2z"
          />
          <polygon
            class="st1"
            points="567,332.3 576,333.9 582.5,332.6 582.5,326.1 589.1,324.9 589.1,318.5 595.6,317.2 595.6,310.8 
			601.8,309.6 601.9,297.4 		"
          />
        </g>
        <g id="_x32_-1_x5F_vaeg_x5F_14">
          <polygon class="st1" points="425.7,326.2 425.8,348.3 427.2,348.6 427.1,326.5 		" />
          <path
            class="st1"
            d="M359.8,287.8l0.2,7.4c7.8,1,13.9,7.7,13.9,15.8c0,7.9-5.7,14.4-13.2,15.7l0.2,8.3l13.4,2.8c0,0,0-0.1,0-0.1
			c0,0,0-0.1,0-0.1c0-0.3,0-0.5,0.1-0.8c0-0.1,0-0.1,0-0.2c0-0.3,0-0.5,0-0.8c0,0,0-0.1,0-0.1c0-0.3,0-0.5,0-0.7
			c-1.1-0.7-2.7-1.8-3.1-2.9c-0.4-1.1-1.2-2.3-1.6-2.6c-0.1,0-0.1-0.1-0.1-0.2c0,0,0,0,0-0.1c0-0.1,0-0.1,0-0.2c0,0,0,0,0-0.1
			c0-0.2,0.2-0.4,0.4-0.7c0.2-0.2,0.5-0.5,0.9-0.7c0.1-0.1,0.2-0.2,0.4-0.2c0.1-0.1,0.3-0.1,0.5-0.2c0,0,0,0,0,0
			c0.2-0.1,0.4-0.1,0.6-0.2c0,0,0,0,0,0c0,0,0,0,0,0c0.2,0,0.3-0.1,0.5-0.1c0,0,0,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0,0,0,0
			c0.4-0.1,0.8-0.2,1.3-0.2c0,0,0,0,0.1,0c0.2,0,0.4-0.1,0.7-0.1c0.2,0,0.5-0.1,0.7-0.1c0,0,0,0,0,0c1.9-0.2,4.3-0.4,6.1-0.3l1-0.9
			c0,0,0-0.1,0-0.1c-0.7,0.1-1.8-0.3-1.7-2c0.1-1.7,0.5-9.4,3.4-11.3c0.7-0.5,1.3-0.6,1.9-0.5c2-0.2,2.9,2.4,3,3
			c0.2,0.7,1.3,8.1-0.8,11.1c-0.1,0.2-0.3,0.3-0.4,0.3l3,0.5l0.1,1l-1.2,1.6l-1,0.2c0,0,0,0,0,0.1c0,0,0,0,0,0
			c-0.2,0.3-0.9,1.6-1.5,3.2c0,0,0,0,0,0c0,0,1.1,0.4,1.1,1.7c0,0.8-0.9,1.3-1.6,1.5c0,0,0,0,0,0v4.4c0,0,1.2,0.3,1.6,0.8l13.9,2.9
			l-0.1-14.7c0.1,0,0.3,0.1,0.5,0.1c0.2,0,0.4,0,0.5-0.1l0.1,14.9l1.8,0.4l-0.1-14.8c0.1,0,0.3,0.1,0.5,0.1c0.2,0,0.4,0,0.5-0.1
			l0.1,15l4.4,0.9v-13.8c0-0.2-0.2-0.3-0.3-0.3c-0.2,0-0.3,0.1-0.3,0.3v0.8c0,0.8-0.6,1.4-1.4,1.4c-0.8,0-1.4-0.6-1.4-1.4l0,0l0-6.6
			l-1-0.2v3.7c-0.1,0-0.3,0.1-0.5,0.1c-0.2,0-0.4,0-0.5-0.1l0-3.9l-1.8-0.3v3.7c-0.1,0-0.3,0.1-0.5,0.1c-0.2,0-0.4,0-0.5-0.1l0-3.9
			l-4.5-0.8v-4l7.6-1.6l3.8,0.7v-3.3h-1.2v0.9c0,0.1-0.2,0.1-0.4,0.1c-0.2,0-0.4-0.1-0.4-0.1v-0.9v-0.7h1.9h0.4v-0.8
			c-0.6,0-1.6-0.2-1.6-0.4c0-0.3,1.7-0.3,2.1,0.1c0.1,0.1,0.1,0.2,0.1,0.2v0.9h0.2v4.2l7.7,1.3v3.9l-8.6,1.4l-1.2-0.2l0,6.4h0
			c0,0.2,0.1,0.3,0.3,0.3c0.2,0,0.3-0.2,0.3-0.3v-0.8c0-0.8,0.6-1.4,1.4-1.4s1.4,0.6,1.4,1.4v14l12.7,2.6l-0.1-22l-2.3-0.4v-2.6
			l2.1-0.5l1.4,0.2v-23.7c0-0.7-0.6-1.3-1.3-1.3s-1.3,0.6-1.3,1.3l0,0.6l0,0c0.9,0.1,1.5,0.2,1.5,0.4l-0.1,0.8c0,0.1-0.9,0.2-2,0.1
			c-1.1-0.1-2-0.3-2-0.4l0.1-0.8c0-0.1,0.7-0.2,1.6-0.1l0,0l0-0.5c0-1.2,1-2.2,2.2-2.2s2.2,1,2.2,2.2L427,323c0,0,0,0,0,0l0,0
			l2.8,0.5v2.5l-2.1,0.5l0,0l0.2-0.1l0.1,22.2l20.7,4.3v-75.5L359.8,287.8z M445.8,292.3l-13.8-2.5v-8.2l13.8,2.5V292.3z"
          />
        </g>
        <g id="_x32_-1_x5F_vaeg_x5F_13">
          <path
            class="st1"
            d="M605.7,321c1-0.2,1.9-0.3,2.9-0.3c8.8,0,15.9,7.1,15.9,15.9s-7.1,15.9-15.9,15.9c-1.1,0-2.2-0.1-3.2-0.3
			l0,2.4l31.3-7.4v-6.3h0.4v-1.4c0,0,0-0.2,0.1-0.3c0.6-0.7,3.2-0.6,3.2-0.2c0,0.2-1.6,0.5-2.4,0.6v1.3l3.5,0.7v1.1v1.4
			c0,0.1-0.2,0.2-0.5,0.2s-0.5-0.1-0.5-0.2v-1.4l-1.9-0.3v4.4l5-1.2l0.7-29.5l19-4.5l24.8,4.3l-1.1,47l4.9-1.4l0.5-50.3l11.8-2.3
			L606,296.9L605.7,321z M607.4,315.9l12.2-3.6l0.1-13.6l9.2,1.1l0,10.9l14.1,2.9l0,3.5l-19.1,5.7l-16.5-3.4L607.4,315.9z"
          />
          <polygon class="st1" points="704.2,308.9 704.1,309 710.6,309.8 710.6,309.8 		" />
          <polygon class="st1" points="710.6,309.8 709.2,356.7 711.8,356 712.9,310 		" />
        </g>
        <polygon
          id="_x32_-1_x5F_vaeg_x5F_12"
          class="st1"
          points="731.9,359.2 759.6,363.8 759.7,362.4 732,357.9 	"
        />
        <polygon
          id="_x32_-1_x5F_vaeg_x5F_11"
          class="st1"
          points="733,275.8 733,277.7 761,280.3 761,278.4 	"
        />
        <polygon
          id="_x32_-1_x5F_vaeg_x5F_10"
          class="st1"
          points="789.1,277.7 789.1,282.3 799.4,279.5 799.4,274.8 	"
        />
        <path
          id="_x32_-1_x5F_vaeg_x5F_9"
          class="st1"
          d="M810.6,231c-3.1,0-5.7-0.7-5.7-1.5c0,0-0.5-4,3.7-4.9c0,0,0,0,0-0.1V224h0
		c0-0.3,0.9-0.5,2-0.5c1.1,0,2,0.2,2,0.5c0,0,0,0,0,0v0.6c0,0,0,0.1,0,0.1c0.1,0,0.2,0,0.3,0.1l0,0l0.6-17l-98.1-2.8l-1.7,70.9
		l18.6,1.7l0-2.6c-0.3-0.1-0.5-0.3-0.5-0.4l-0.1-1.6h0v0c0-0.2,0.4-0.4,0.9-0.3c0.2,0,0.3,0.1,0.4,0.1l0.2-11.9c0,0,0,0,0,0h-0.8
		l-0.4,0.1c0,0.6-0.3,1.1-0.7,1.2l-1.9,0.6c-0.5,0.1-1-0.3-1.1-1c-0.1-0.6,0.2-1.2,0.7-1.3l1.9-0.6c0.4-0.1,0.8,0.2,1,0.5l0.4-0.1
		c0,0,0.1,0,0.1,0h0.9c0,0,0,0,0,0l0-2.1l1-0.7l29.1,2.4l0,18l-1,0.7l-0.7-0.1l0,1.8l26.4,2.3l0-4.7l-0.4,0.1l-0.7-0.3l0.1-6.8
		l13.8-3.6l0.7,0.3l0,6.7l-1.1,0.3l0,4.7l10.6-2.9l-0.3,8.5l0,0l1.9-53.9C812,231,811.3,231,810.6,231z M758.6,240.7l-18.8-1.8
		v-33.2l19.1,0.5L758.6,240.7z M806.1,236.1c0,0.9-2.7,1.6-6.1,1.6s-6.1-0.7-6.1-1.6c0,0-0.6-4.3,3.9-5.3c0,0,0,0,0-0.1v-0.6
		c0-0.3,1-0.6,2.2-0.6c1.2,0,2.2,0.3,2.2,0.6c0,0,0,0,0,0v0.6c0,0,0,0.1,0,0.1C806.5,231.9,806.1,236.1,806.1,236.1z"
        />
        <path
          id="_x32_-1_x5F_vaeg_x5F_8"
          class="st1"
          d="M697.9,204.5c0.8,1.9,1.2,4,1.2,6.2c0,8.8-7.1,15.9-15.9,15.9
		s-15.9-7.1-15.9-15.9c0-2.5,0.6-4.9,1.6-7l-62.1-1.7l-0.8,90.6l10-1.5l0.8-69.6l28.1-1.1l-0.7,66.3l69.5-10.7l1.7-70.9L697.9,204.5
		z M638.9,218.6l-14.2,0.5l-1.3-0.6l0.1-6.8l14.2-0.3l1.3,0.6L638.9,218.6z M666.2,281.2l-3.6,0.5l-0.9-0.6l0-3.7l3.6-0.5l0.9,0.6
		L666.2,281.2z M664.1,266.9c-8.8,0-15.9-7.1-15.9-15.9c0-8.8,7.1-15.9,15.9-15.9S680,242.2,680,251
		C680,259.7,672.9,266.9,664.1,266.9z"
        />
        <g id="_x32_-1_x5F_vaeg_x5F_7">
          <polygon class="st1" points="321.3,315.1 320.8,292.3 300.6,294.6 301.2,318.2 		" />
          <polygon class="st1" points="301.8,336.7 301.8,338.2 321.8,334.7 321.8,333.5 		" />
        </g>
        <polygon
          id="_x32_-1_x5F_vaeg_x5F_6"
          class="st1"
          points="299.7,263.6 299.2,245.8 299.1,245 319.6,243.3 318.4,201.1 297.9,201.6 
		299.7,266 320.2,263.8 320.2,261.7 	"
        />
        <path
          id="_x32_-1_x5F_vaeg_x5F_5"
          class="st1"
          d="M712.9,310l-1.2,45.9l19.4,3.2l0.1-1.9c-0.3-0.1-0.5-0.3-0.5-0.5l0-1.6
		c0-0.2,0.5-0.3,1-0.3c0.2,0,0.3,0.1,0.4,0.1l1-11.9c0,0,0,0,0,0l-0.8-0.1l-0.4,0.1c0,0.6-0.3,1-0.8,1.1l-1.9,0.5
		c-0.5,0.1-1-0.4-1.1-1.1c-0.1-0.6,0.3-1.2,0.8-1.3l1.9-0.5c0.4,0,0.7,0.2,0.9,0.6l0.4-0.1c0,0,0.1,0,0.1,0l0.9,0.1c0,0,0,0,0,0
		l0.2-2.1l1.1-0.7l28.8,4.2l-1.2,18l-1.1,0.7l-0.7-0.1l-0.1,1.4l16.3,2.7l11.8-3.1l0.7,0.3v4.9l18.4,3l1.8-49.7L712.9,310z M801.2,351.6c0,1.7-5.2,3.1-11.7,3.1s-11.7-1.4-11.7-3.1c0,0-1.1-8.6,8.2-10.3v-0.9c0-0.5,1.6-0.9,3.5-0.9c2,0,3.5,0.4,3.5,0.9v1
		c0,0,0,0,0,0C802.1,343.2,801.2,351.6,801.2,351.6z"
        />
        <g id="_x32_-1_x5F_vaeg_x5F_4">
          <polygon class="st1" points="375.6,251.9 375.3,258.7 377.5,257.4 377.5,252 		" />
          <polygon class="st1" points="375,265.4 381.3,266.1 380.6,259.8 378.1,258.3 375.3,260 		" />
          <path
            class="st1"
            d="M366,248.7v1.2c0,0.2-0.6,0.3-1.5,0.4l1.5,14l7.3,0.8l1.3-13.3l0.1,0l0,0c-1.2-0.1-1.9-0.3-1.9-0.5v-1.2h0
			c0-0.3,2.3-0.6,5.1-0.6c2.8,0,5.1,0.3,5.1,0.6h0v1.2c0,0.2-0.6,0.3-1.5,0.4l1.6,14.5l6.9,0.8l-0.4-22.8l-27.2-2.4l0.1,6.2
			C364.5,248.3,366,248.5,366,248.7z"
          />
          <polygon class="st1" points="374.2,265.3 374.2,264.9 374.2,265.3 		" />
          <polygon class="st1" points="381.5,260.4 382.1,266.2 382.3,266.2 		" />
          <path
            class="st1"
            d="M362.8,250.6L362.8,250.6c-0.1,0-0.2,0-0.3,0l0.1,5.9l0.9,0.5L362.8,250.6z"
          />
          <polygon class="st1" points="363.6,258.3 362.6,257.7 362.7,263.9 364.2,264.1 		" />
          <polygon class="st1" points="364.6,258.6 365.1,264.2 365.2,264.2 		" />
          <path class="st1" d="M378.6,252v5.4l1.9,1.1l-0.6-6.6C379.3,251.9,378.6,252,378.6,252z" />
        </g>
        <polygon
          id="_x32_-1_x5F_vaeg_x5F_3"
          class="st1"
          points="403.3,243.5 391.6,244.5 391,244.4 391.2,267.2 403.7,268.7 	"
        />
        <path
          id="_x32_-1_x5F_vaeg_x5F_2"
          class="st1"
          d="M447.7,197.6L358,200l0.1,3.5c0.2,0,0.3,0,0.5,0c8.8,0,15.9,7.1,15.9,15.9
		c0,8.7-7,15.8-15.7,15.9l0.1,2.7l0.3,0l48.4,3.7v1.4l-3,0.3l0.3,25.5l9.3,1.1l-0.6-20.3l1.1,0.1l0.5,20.4l1.8,0.2l-0.6-20.4
		l1.1,0.1l0.6,20.4l5.6,0.6l-0.2-15.3c0-0.2-0.2-0.3-0.3-0.3s-0.3,0.2-0.3,0.3v0.8c0,0.8-0.6,1.4-1.4,1.4c-0.8,0-1.4-0.6-1.4-1.4
		l0,0l0-6.4l1.1,0.1l0,6.3h0c0,0.2,0.1,0.3,0.3,0.3c0.2,0,0.3-0.1,0.3-0.3v-0.8c0-0.8,0.6-1.4,1.4-1.4s1.4,0.6,1.4,1.4l0.2,15.4
		l24.1,2.8v-72.4C448.3,200.3,447.9,199,447.7,197.6z M383.2,213.5h-1c-1.7,0-3-2.2-3-4.9s1.3-4.9,3-4.9h1c1.7,0,3,2.2,3,4.9
		S384.9,213.5,383.2,213.5z M407.6,213.7c-1.5,0.3-3.3,0.7-4.8,1l0,0.1c-0.6,0.1-1.1,0.2-1.5,0.3c-0.4,0.1-0.8,0.5-0.8,0.9v1.2
		l-0.2,0v7h0.4v0.7l4.7,3.6l-3.5,2l-10-0.8l5.5-4.8v-0.7h0.4v-6.9l-0.3,0v-3.6c0,0,0,0,0,0c0-0.1,0-0.3,0.1-0.4
		c0.2-0.6,0.6-0.8,1.4-1.1c0,0,1.3-0.3,3.1-0.7l0.1,0.4c1.6-0.3,3.4-0.7,4.8-1c0-0.1-0.1-0.2-0.1-0.4c0.3-0.1,0.6-0.1,0.8-0.2
		c0.6-0.1,1-0.6,1-1.2v-0.8h0.3v-9.9l2.5-0.1v10h0.2v3.3c0,0,0,0,0,0c0,0.2,0,0.3-0.1,0.5c-0.2,0.6-0.7,0.9-1.5,1.1
		c0,0-1.1,0.2-2.6,0.6C407.6,213.9,407.6,213.8,407.6,213.7z M435.4,249.2l-13.6,1.1L408,249v-4.2l8.2-0.6l5.5-0.4l3.1,0.3v-3.6
		h-1.2v0.9c0,0.1-0.2,0.1-0.4,0.1c-0.2,0-0.4-0.1-0.4-0.1v-0.9v-0.7h1.9h0.4v-0.8c-0.6,0-1.6-0.2-1.6-0.4c0-0.3,1.7-0.3,2.1,0.1
		c0.1,0.1,0.1,0.2,0.1,0.2v0.9h0.2v4.4l9.5,0.8V249.2z M445.8,211.1l-13.8-1v-8.2l13.8,1V211.1z"
        />
        <g id="_x32_-1_x5F_vaeg_x5F_1">
          <polygon class="st1" points="360.6,250.6 359.2,250.6 359.3,256.6 360.6,255.8 		" />
          <path
            class="st1"
            d="M361,248.1c0.2,0,0.3,0,0.4,0l-0.1-6.3l-2.3-0.2l0.1,6.5C359.7,248.1,360.3,248.1,361,248.1z"
          />
          <polygon
            class="st1"
            points="361.1,256.7 359.4,257.8 359.5,263.6 361.7,263.8 361.6,257 		"
          />
        </g>
        <g id="_x32_-1_x5F_loft_x5F_4">
          <path class="st1" d="M694.5,199.5c1.4,1.4,2.6,3.1,3.4,5l115.6,3.2l0.2-5.4L694.5,199.5z" />
          <g>
            <path
              class="st1"
              d="M528.2,195.5c1.2,1.3,2.1,2.7,2.8,4.3l137.9,3.9c0.9-1.8,2.1-3.4,3.5-4.7L528.2,195.5z"
            />
            <path
              class="st1"
              d="M479.4,194.4c0,0.3,0.1,0.7,0.1,1c0,1-0.1,2-0.3,3l23.1,0.6c0.8-1.5,1.7-2.9,2.9-4.1L479.4,194.4z"
            />
          </g>
        </g>
        <g id="_x32_-1_x5F_loft_x5F_3">
          <path class="st0" d="M694.5,199.5c1.4,1.4,2.6,3.1,3.4,5l115.6,3.2l0.2-5.4L694.5,199.5z" />
          <path
            class="st1"
            d="M447.6,195.4c0-0.6,0-1.2,0.1-1.7l-149.9,3.9l0.1,4.1l149.8-4.1C447.6,196.9,447.6,196.1,447.6,195.4z"
          />
        </g>
        <g id="_x32_-1_x5F_loft_x5F_2">
          <path
            class="st1"
            d="M542.9,285.1l-94.1-11.2v3.6l90.7,11.2C540.4,287.3,541.6,286.1,542.9,285.1z"
          />
          <path class="st1" d="M565,287.7c1.1,1.3,1.9,2.8,2.5,4.5l241.9,29.8l0.2-5.2L565,287.7z" />
        </g>
        <polygon
          id="_x32_-1_x5F_loft_x5F_1"
          class="st1"
          points="300.6,294.6 448.8,277.5 448.8,273.9 300.4,290.3 	"
        />
        <g id="_x32_-1_x5F_indvendig-solafskaermning_x5F_8">
          <polygon class="st0" points="869.9,340.7 865.5,342.3 866.1,326.6 870.6,325.2 		" />
          <polygon class="st0" points="879.4,322.6 875.2,323.8 874.4,339.1 878.8,337.7 		" />
          <polygon class="st0" points="878.8,347.5 879.1,342.2 874.2,343.9 874,349.1 		" />
          <line class="st1" x1="874.1" y1="346.4" x2="878.9" y2="344.8" />
          <line class="st1" x1="874" y1="347.7" x2="878.9" y2="346.1" />
          <line class="st1" x1="874.2" y1="345" x2="879" y2="343.3" />
          <line class="st1" x1="874" y1="349.1" x2="878.8" y2="347.5" />
          <line class="st1" x1="875.9" y1="348.5" x2="875.6" y2="357.7" />
          <circle class="st1" cx="875.6" cy="358.4" r="0.7" />
          <g>
            <polygon class="st0" points="869.5,350.5 869.7,345.6 865.3,347.1 865.1,352 			" />
            <line class="st1" x1="865.2" y1="349.4" x2="869.6" y2="347.9" />
            <line class="st1" x1="865.1" y1="350.6" x2="869.5" y2="349.2" />
            <line class="st1" x1="865.3" y1="348.2" x2="869.6" y2="346.6" />
            <line class="st1" x1="865.1" y1="352" x2="869.5" y2="350.5" />
          </g>
          <line class="st1" x1="866.1" y1="329.3" x2="870.4" y2="328" />
          <line class="st1" x1="866.1" y1="327.9" x2="870.5" y2="326.6" />
          <line class="st1" x1="865.9" y1="332.1" x2="870.3" y2="330.8" />
          <line class="st1" x1="866" y1="330.7" x2="870.4" y2="329.4" />
          <line class="st1" x1="865.8" y1="335" x2="870.2" y2="333.6" />
          <line class="st1" x1="865.9" y1="333.5" x2="870.3" y2="332.2" />
          <line class="st1" x1="865.8" y1="337.9" x2="870.1" y2="336.5" />
          <line class="st1" x1="865.7" y1="340.8" x2="870" y2="339.4" />
          <line class="st1" x1="865.7" y1="339.3" x2="870" y2="337.9" />
          <line class="st1" x1="865.7" y1="336.5" x2="870.1" y2="335.1" />
          <line class="st1" x1="875" y1="326.6" x2="879.3" y2="325.4" />
          <line class="st1" x1="875.1" y1="325.2" x2="879.3" y2="324" />
          <line class="st1" x1="874.9" y1="329.4" x2="879.2" y2="328.1" />
          <line class="st1" x1="875" y1="328" x2="879.2" y2="326.8" />
          <line class="st1" x1="874.8" y1="332.2" x2="879.1" y2="330.9" />
          <line class="st1" x1="874.8" y1="330.8" x2="879.1" y2="329.5" />
          <line class="st1" x1="874.6" y1="335.1" x2="879" y2="333.7" />
          <line class="st1" x1="874.5" y1="337.9" x2="878.9" y2="336.5" />
          <line class="st1" x1="874.6" y1="336.5" x2="878.9" y2="335.1" />
          <line class="st1" x1="874.7" y1="333.7" x2="879" y2="332.4" />
        </g>
        <g id="_x32_-1_x5F_indvendig-solafskaermning_x5F_7">
          <polygon class="st0" points="200.1,216.7 200.6,228.7 205.3,229.1 204.8,216.9 		" />
          <polygon class="st0" points="208.4,217 208.9,229.4 213.3,229.8 212.7,217.2 		" />
          <g>
            <polygon class="st0" points="205.4,232.4 205.6,237.5 200.7,237.2 200.6,232 			" />
            <polygon class="st0" points="209,232.7 209.2,237.7 213.6,238 213.4,233.1 			" />
          </g>
          <g>
            <line class="st3" x1="208.4" y1="218.7" x2="212.7" y2="218.8" />
            <line class="st3" x1="200.2" y1="218.4" x2="204.9" y2="218.5" />
            <line class="st3" x1="208.5" y1="220.3" x2="212.7" y2="220.5" />
            <line class="st3" x1="200.2" y1="220" x2="204.9" y2="220.2" />
            <line class="st3" x1="208.6" y1="222.1" x2="212.9" y2="222.3" />
            <line class="st3" x1="200.3" y1="221.8" x2="205" y2="222" />
            <line class="st3" x1="208.7" y1="223.9" x2="213" y2="224.1" />
            <line class="st3" x1="200.4" y1="223.5" x2="205.1" y2="223.7" />
            <line class="st3" x1="208.7" y1="225.5" x2="213.1" y2="225.8" />
            <line class="st3" x1="200.4" y1="225.1" x2="205.1" y2="225.3" />
            <line class="st3" x1="208.8" y1="227.2" x2="213.2" y2="227.5" />
            <line class="st3" x1="200.5" y1="226.7" x2="205.2" y2="227" />
            <line class="st3" x1="208.9" y1="228.7" x2="213.2" y2="229" />
            <line class="st3" x1="200.5" y1="228.1" x2="205.3" y2="228.4" />
            <line class="st3" x1="209.1" y1="234.4" x2="213.5" y2="234.7" />
            <line class="st3" x1="200.6" y1="233.8" x2="205.5" y2="234.1" />
            <line class="st3" x1="209.2" y1="236" x2="213.5" y2="236.3" />
            <line class="st3" x1="200.7" y1="235.5" x2="205.6" y2="235.8" />
            <line class="st0" x1="205.6" y1="237.5" x2="200.7" y2="237.2" />
          </g>
          <line class="st3" x1="202.9" y1="243.5" x2="202.7" y2="237.3" />
          <circle class="st1" cx="202.9" cy="244.3" r="0.6" />
        </g>
        <g id="_x32_-1_x5F_indvendig-solafskaermning_x5F_6">
          <polygon class="st0" points="204.4,302.9 203.9,291.6 208.4,292.3 208.9,303.9 		" />
          <polygon class="st0" points="212.5,304.7 212.1,293 216.3,293.7 216.8,305.6 		" />
          <g>
            <polygon class="st0" points="204.5,311.8 209.2,312.7 209,307.7 204.4,306.7 			" />
            <polygon class="st0" points="212.8,313.4 217.1,314.3 216.9,309.4 212.7,308.5 			" />
            <line class="st1" x1="212.2" y1="294.7" x2="216.3" y2="295.4" />
            <line class="st1" x1="203.9" y1="293.3" x2="208.5" y2="294.1" />
            <line class="st1" x1="212.2" y1="296.6" x2="216.3" y2="297.3" />
            <line class="st1" x1="204" y1="295.2" x2="208.6" y2="296" />
            <line class="st1" x1="212.3" y1="298.4" x2="216.5" y2="299.2" />
            <line class="st1" x1="204.1" y1="296.9" x2="208.6" y2="297.8" />
            <line class="st1" x1="212.4" y1="300.5" x2="216.6" y2="301.4" />
            <line class="st1" x1="204.2" y1="298.8" x2="208.7" y2="299.8" />
            <line class="st1" x1="212.4" y1="302.3" x2="216.7" y2="303.2" />
            <line class="st1" x1="204.3" y1="300.5" x2="208.8" y2="301.5" />
            <line class="st1" x1="212.5" y1="303.9" x2="216.7" y2="304.8" />
            <line class="st1" x1="204.3" y1="302.2" x2="208.8" y2="303.1" />
            <line class="st1" x1="212.7" y1="309.9" x2="217" y2="310.8" />
            <line class="st1" x1="204.4" y1="308.1" x2="209.1" y2="309.1" />
            <line class="st1" x1="212.8" y1="311.8" x2="217" y2="312.6" />
            <line class="st1" x1="204.5" y1="310" x2="209.1" y2="311" />
          </g>
          <line class="st3" x1="206.4" y1="318.5" x2="206.2" y2="312.3" />
          <circle class="st1" cx="206.4" cy="319.2" r="0.6" />
        </g>
        <g id="_x32_-1_x5F_indvendig-solafskaermning_x5F_5">
          <polygon class="st0" points="269.3,317 264.1,315.9 263.6,303.2 268.8,304.1 		" />
          <polygon class="st0" points="260,315 253.5,313.6 253,301.2 259.6,302.4 		" />
          <g>
            <polygon class="st0" points="269.7,327.5 264.4,326.5 264.2,319.8 269.4,321 			" />
            <polygon class="st0" points="253.7,324.5 260.4,325.7 260.2,319 253.5,317.5 			" />
            <line class="st1" x1="263.7" y1="305" x2="268.8" y2="306" />
            <line class="st1" x1="253.1" y1="303" x2="259.7" y2="304.3" />
            <line class="st1" x1="263.8" y1="307" x2="268.9" y2="307.9" />
            <line class="st1" x1="253.1" y1="305" x2="259.7" y2="306.2" />
            <line class="st1" x1="263.8" y1="308.9" x2="269" y2="309.9" />
            <line class="st1" x1="253.2" y1="306.8" x2="259.8" y2="308.1" />
            <line class="st1" x1="263.9" y1="310.9" x2="269.1" y2="311.9" />
            <line class="st1" x1="253.3" y1="308.7" x2="259.9" y2="310.1" />
            <line class="st1" x1="263.9" y1="312.9" x2="269.2" y2="314" />
            <line class="st1" x1="253.4" y1="310.7" x2="259.9" y2="312" />
            <line class="st1" x1="264" y1="314.7" x2="269.2" y2="315.9" />
            <line class="st1" x1="253.4" y1="312.5" x2="260" y2="313.9" />
            <line class="st1" x1="264.2" y1="321" x2="269.5" y2="322.1" />
            <line class="st1" x1="253.5" y1="318.7" x2="260.2" y2="320.1" />
            <line class="st1" x1="264.3" y1="322.8" x2="269.5" y2="323.8" />
            <line class="st1" x1="253.6" y1="320.6" x2="260.3" y2="322" />
            <line class="st1" x1="264.4" y1="324.6" x2="269.6" y2="325.6" />
            <line class="st1" x1="253.7" y1="322.5" x2="260.3" y2="323.8" />
          </g>
          <line class="st3" x1="256.1" y1="331.3" x2="255.9" y2="325.1" />
          <circle class="st1" cx="256.1" cy="332" r="0.6" />
        </g>
        <g id="_x32_-1_x5F_indvendig-solafskaermning_x5F_4">
          <g>
            <polygon class="st0" points="583.7,201.4 583.7,215.8 602.6,217 602.8,201.9 			" />
            <g>
              <line class="st0" x1="583.7" y1="213.1" x2="602.6" y2="214.3" />
              <line class="st0" x1="583.7" y1="211.6" x2="602.6" y2="212.7" />
              <line class="st0" x1="583.7" y1="210.1" x2="602.6" y2="211.1" />
              <line class="st0" x1="583.7" y1="208.6" x2="602.6" y2="209.5" />
              <line class="st0" x1="583.7" y1="207" x2="602.6" y2="207.9" />
              <line class="st0" x1="583.7" y1="205.5" x2="602.6" y2="206.4" />
              <line class="st0" x1="583.7" y1="204" x2="602.6" y2="204.7" />
              <line class="st0" x1="583.7" y1="202.5" x2="602.6" y2="203.2" />
              <line class="st0" x1="583.7" y1="214.6" x2="602.6" y2="215.7" />
            </g>
          </g>
          <line class="st0" x1="586.1" y1="216" x2="586.1" y2="223.9" />
          <circle class="st0" cx="586.1" cy="224.5" r="0.5" />
        </g>
        <g id="_x32_-1_x5F_indvendig-solafskaermning_x5F_3">
          <g>
            <path class="st0" d="M739.8,220.2" />
            <g>
              <polygon class="st0" points="758.8,221.3 758.9,206.3 739.8,205.7 739.8,220.2 				" />
              <line class="st0" x1="739.8" y1="217.4" x2="758.8" y2="218.6" />
              <line class="st0" x1="739.8" y1="215.9" x2="758.8" y2="217.1" />
              <line class="st0" x1="739.8" y1="214.4" x2="758.8" y2="215.4" />
              <line class="st0" x1="739.8" y1="212.9" x2="758.8" y2="213.9" />
              <line class="st0" x1="739.8" y1="211.4" x2="758.8" y2="212.3" />
              <line class="st0" x1="739.8" y1="209.9" x2="758.8" y2="210.7" />
              <line class="st0" x1="739.8" y1="208.3" x2="758.8" y2="209" />
              <line class="st0" x1="739.8" y1="218.9" x2="758.8" y2="220" />
            </g>
          </g>
          <circle class="st0" cx="742.2" cy="228.8" r="0.5" />
          <polygon class="st1" points="739.8,205.7 739.8,206.8 758.9,207.5 758.9,206.3 		" />
          <line class="st0" x1="742.2" y1="220.3" x2="742.2" y2="228.2" />
        </g>
        <g id="_x32_-1_x5F_indvendig-solafskaermning_x5F_2">
          <polygon class="st0" points="879.8,229.6 879.1,245.9 884,245.4 884.6,229.3 		" />
          <polygon class="st0" points="875.3,229.8 874.5,246.4 870,247 870.8,230.1 		" />
          <line class="st1" x1="879.7" y1="232.4" x2="884.5" y2="232.1" />
          <line class="st1" x1="870.8" y1="232.9" x2="875.2" y2="232.7" />
          <line class="st1" x1="879.8" y1="231" x2="884.6" y2="230.7" />
          <line class="st1" x1="870.8" y1="231.5" x2="875.2" y2="231.3" />
          <line class="st1" x1="879.6" y1="235.2" x2="884.3" y2="234.9" />
          <line class="st1" x1="870.6" y1="235.7" x2="875" y2="235.5" />
          <line class="st1" x1="879.6" y1="233.8" x2="884.5" y2="233.5" />
          <line class="st1" x1="870.8" y1="234.3" x2="875.1" y2="234.1" />
          <line class="st1" x1="879.4" y1="238" x2="884.3" y2="237.7" />
          <line class="st1" x1="870.5" y1="238.5" x2="874.9" y2="238.2" />
          <line class="st1" x1="879.5" y1="236.6" x2="884.4" y2="236.3" />
          <line class="st1" x1="870.6" y1="237.1" x2="875" y2="236.8" />
          <line class="st1" x1="879.3" y1="240.8" x2="884.2" y2="240.5" />
          <line class="st1" x1="870.4" y1="241.5" x2="874.8" y2="241.2" />
          <line class="st1" x1="879.2" y1="243.7" x2="884.1" y2="243.3" />
          <line class="st1" x1="870.2" y1="244.5" x2="874.6" y2="244.1" />
          <line class="st1" x1="879.2" y1="242.3" x2="884.2" y2="241.9" />
          <line class="st1" x1="870.2" y1="243" x2="874.7" y2="242.7" />
          <line class="st1" x1="879.4" y1="239.4" x2="884.3" y2="239.1" />
          <line class="st1" x1="870.5" y1="239.9" x2="874.8" y2="239.7" />
          <line class="st1" x1="879.1" y1="245.1" x2="884" y2="244.6" />
          <line class="st1" x1="870.1" y1="246.1" x2="874.6" y2="245.6" />
          <g>
            <polygon class="st0" points="874.3,250.6 874.1,255.5 869.6,256 869.8,251.3 			" />
            <polygon class="st0" points="878.9,249.9 878.7,255 883.7,254.5 883.9,249.2 			" />
            <line class="st1" x1="878.8" y1="252.3" x2="883.8" y2="251.7" />
            <line class="st1" x1="869.8" y1="253.4" x2="874.2" y2="252.9" />
            <line class="st1" x1="878.7" y1="253.7" x2="883.8" y2="253.1" />
            <line class="st1" x1="869.8" y1="254.8" x2="874.2" y2="254.3" />
            <line class="st1" x1="878.9" y1="251" x2="883.9" y2="250.3" />
            <line class="st1" x1="869.8" y1="252.2" x2="874.3" y2="251.6" />
            <line class="st1" x1="881.3" y1="254.8" x2="881" y2="264.4" />
            <circle class="st1" cx="881" cy="265.1" r="0.7" />
          </g>
        </g>
        <g id="_x32_-1_x5F_indvendig-solafskaermning_x5F_1">
          <polyline class="st1" points="251.6,246.1 251.3,236.8 257,237.2 257.3,246.5 251.6,246.1" />
          <polygon class="st0" points="256.9,233.2 251.2,232.9 250.7,219.5 256.4,219.8 		" />
          <line class="st1" x1="250.8" y1="221.6" x2="256.5" y2="221.9" />
          <line class="st1" x1="250.9" y1="223.6" x2="256.5" y2="223.9" />
          <line class="st1" x1="250.9" y1="225.7" x2="256.6" y2="226" />
          <line class="st1" x1="251" y1="227.7" x2="256.7" y2="228.1" />
          <line class="st1" x1="251.1" y1="229.8" x2="256.8" y2="230.1" />
          <line class="st1" x1="251.1" y1="231.8" x2="256.8" y2="232.2" />
          <polygon class="st0" points="260.7,233.5 260.2,220.1 265.7,220.3 266.2,233.9 		" />
          <line class="st1" x1="260.3" y1="222.1" x2="265.8" y2="222.4" />
          <line class="st1" x1="260.4" y1="224.1" x2="265.9" y2="224.4" />
          <line class="st1" x1="260.5" y1="226.2" x2="266" y2="226.5" />
          <line class="st1" x1="260.5" y1="228.3" x2="266" y2="228.6" />
          <line class="st1" x1="260.6" y1="230.4" x2="266.1" y2="230.7" />
          <line class="st1" x1="260.7" y1="232.5" x2="266.2" y2="232.9" />
          <line class="st1" x1="251.3" y1="238" x2="257.1" y2="238.5" />
          <line class="st1" x1="251.4" y1="240" x2="257.1" y2="240.4" />
          <line class="st1" x1="251.5" y1="242.1" x2="257.2" y2="242.4" />
          <line class="st1" x1="251.5" y1="244.1" x2="257.3" y2="244.5" />
          <line class="st3" x1="254" y1="252.8" x2="253.8" y2="246.3" />
          <circle class="st1" cx="254" cy="253.5" r="0.6" />
          <polygon class="st0" points="260.9,237.5 261.2,246.8 266.7,247.2 266.4,237.9 		" />
          <line class="st1" x1="260.9" y1="238.8" x2="266.4" y2="239.2" />
          <line class="st1" x1="261" y1="240.7" x2="266.4" y2="241" />
          <line class="st1" x1="261.1" y1="242.7" x2="266.4" y2="243.1" />
          <line class="st1" x1="261.2" y1="244.8" x2="266.6" y2="245.1" />
          <line class="st0" x1="266.7" y1="247.2" x2="261.2" y2="246.8" />
        </g>
      </g>
      <g id="KK-3-3" class="hover-obj keid kejd" @click="$store.commit('setModal', '3.3')">
        <g id="_x33_-3_x5F_afloeb_x5F_3">
          <polygon class="st0" points="433.2,351.6 425.8,353 432.6,354.5 440.2,353.1 		" />
          <line class="st0" x1="428" y1="353.5" x2="435.3" y2="352" />
          <line class="st0" x1="430.1" y1="353.9" x2="437.4" y2="352.5" />
          <line class="st0" x1="428.5" y1="352.5" x2="434.8" y2="354.1" />
          <line class="st0" x1="431.2" y1="352" x2="437.6" y2="353.6" />
        </g>
        <g id="_x33_-3_x5F_afloeb_x5F_2">
          <g>
            <path
              class="st0"
              d="M413,361.6v0.3c0,0.3-0.7,0.6-1.5,0.6c-0.8,0-1.5-0.3-1.5-0.6v-0.3c-0.3,0.1-0.5,0.3-0.5,0.6
				c0,0.5,0.9,0.8,2,0.8c1.1,0,2-0.4,2-0.8C413.5,362,413.4,361.8,413,361.6z"
            />
            <path
              class="st0"
              d="M413.5,362.2c0-0.2-0.2-0.4-0.5-0.5v0.3c0,0.3-0.7,0.6-1.5,0.6c-0.8,0-1.5-0.3-1.5-0.6v-0.3
				c-0.3,0.1-0.5,0.3-0.5,0.6v2c0,0.5,0.9,0.8,2,0.8s2-0.4,2-0.8L413.5,362.2L413.5,362.2z"
            />
            <g>
              <path
                class="st0"
                d="M410,360.4v1.5c0,0.3,0.7,0.6,1.5,0.6c0.8,0,1.5-0.3,1.5-0.6v-2.1L410,360.4z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                class="st0"
                d="M381.2,365.9v1c0,0.4,0.8,0.7,1.7,0.7s1.7-0.3,1.7-0.7v-1.6L381.2,365.9z"
              />
            </g>
            <path
              class="st0"
              d="M382.9,370.5c-0.7,0-1.3-0.1-1.7-0.3v1.5c0,0.4,0.8,0.7,1.7,0.7s1.7-0.3,1.7-0.7v-1.5
				C384.2,370.3,383.6,370.5,382.9,370.5z"
            />
            <path
              class="st0"
              d="M384.6,366.7v0.2c0,0.4-0.8,0.7-1.7,0.7s-1.7-0.3-1.7-0.7v-0.2c-0.4,0.2-0.6,0.4-0.6,0.6
				c0,0.5,1,0.9,2.3,0.9c1.3,0,2.3-0.4,2.3-0.9C385.2,367.1,385,366.9,384.6,366.7z"
            />
            <g>
              <path
                class="st0"
                d="M381.2,365.9v1c0,0.4,0.8,0.7,1.7,0.7s1.7-0.3,1.7-0.7v-1.6L381.2,365.9z"
              />
            </g>
            <path
              class="st0"
              d="M384.6,366.7v0.2c0,0.4-0.8,0.7-1.7,0.7s-1.7-0.3-1.7-0.7v-0.2c-0.4,0.2-0.6,0.4-0.6,0.6v0v2.2
				c0,0.5,1,0.9,2.3,0.9s2.3-0.4,2.3-0.9v-2.2v0C385.2,367.1,385,366.9,384.6,366.7z"
            />
          </g>
          <path
            class="st0"
            d="M413,364.7c-0.4,0.2-0.9,0.3-1.5,0.3c-0.6,0-1.1-0.1-1.5-0.3v0.6c0,0.5-0.3,0.8-0.8,0.9l-24.6,4.7v0.8
			c0,0.4-0.8,0.7-1.7,0.7s-1.7-0.3-1.7-0.7v-0.1l-78,15l0.1,2.8L411,368.5c1.2-0.2,2.1-1.3,2.1-2.6C413.1,365.5,413,365,413,364.7z"
          />
        </g>
        <g id="_x33_-3_x5F_afloeb_x5F_1">
          <g>
            <path
              class="st0"
              d="M639.8,394v0.3c0,0.3-0.7,0.6-1.5,0.6c-0.8,0-1.5-0.3-1.5-0.6V394c-0.3,0.1-0.5,0.3-0.5,0.5
				c0,0.5,0.9,0.8,2,0.8c1.1,0,2-0.4,2-0.8C640.3,394.3,640.1,394.1,639.8,394z"
            />
            <path
              class="st0"
              d="M640.3,394.5c0-0.2-0.2-0.4-0.5-0.6v0.3c0,0.3-0.7,0.6-1.5,0.6c-0.8,0-1.5-0.3-1.5-0.6V394
				c-0.3,0.1-0.5,0.3-0.5,0.5v2c0,0.5,0.9,0.8,2,0.8s2-0.4,2-0.8L640.3,394.5L640.3,394.5z"
            />
            <g>
              <path
                class="st0"
                d="M639.8,392.9v1.4c0,0.3-0.7,0.6-1.5,0.6c-0.8,0-1.5-0.3-1.5-0.6v-2L639.8,392.9z"
              />
            </g>
          </g>
          <path
            class="st0"
            d="M640.6,398.6c-0.4-0.1-0.8-0.5-0.8-0.9V397c-0.4,0.2-0.9,0.3-1.5,0.3c-0.6,0-1.2-0.1-1.5-0.3
			c0,0.3,0,0.8,0,1.3c0,1.2,0.9,2.3,2.1,2.6l166.4,35.9l0.1-3.2L640.6,398.6z"
          />
        </g>
        <g id="_x33_-3_x5F_skjult-vandlaas">
          <g>
            <polygon
              class="st0"
              points="430.5,356.5 432.8,356.1 432.8,356.1 438.3,355 438.3,356 432.7,357 			"
            />
          </g>
          <path
            class="st0"
            d="M444.9,356.6l-6.5,1.2c-1.8,0.2-2.8,1.4-2.8,3v1.7c0,0.4-0.3,0.7-0.7,0.7s-0.7-0.3-0.7-0.7l0-5.8l-1.5,0.3
			l-0.7-0.1l0,5.7l0,0c0,1.6,1.3,2.9,2.9,2.9c1.6,0,2.9-1.3,2.9-2.9v-1.7c0-0.4,0.2-0.6,0.7-0.7l6.5-1.2c0.4,0,0.7-0.5,0.7-1.1
			S445.2,356.6,444.9,356.6z"
          />
        </g>
      </g>
      <g id="KK-2-7" class="hover-obj lejer" @click="$store.commit('setModal', '2.7')">
        <g id="_x32_-7_x5F_personalekoekken_x5F_3">
          <polygon class="st0" points="631,359.2 605.4,354.7 605.2,385.6 630.6,391 		" />
          <polygon
            class="st0"
            points="649.7,385.3 650.1,354.5 625.2,350 605.4,354.7 631,359.2 630.6,391 		"
          />
          <polygon class="st0" points="647.3,377.8 647.5,364.6 633.8,368 633.6,381.8 		" />
          <polygon class="st0" points="648.1,363.3 648.1,361.7 632.8,365.4 632.8,366.9 		" />
          <g>
            <path
              class="st0"
              d="M619.1,353.1c-2.6,0-4.7,0.5-4.7,1.1v0.5c0,0.6,2.1,1.1,4.7,1.1c2.6,0,4.7-0.5,4.7-1.1v-0.5
				C623.8,353.6,621.7,353.1,619.1,353.1z"
            />
            <ellipse class="st0" cx="619.1" cy="354.2" rx="4.7" ry="1.1" />
            <path
              class="st0"
              d="M634.3,355.9c0-0.6-2.1-1.1-4.7-1.1c-2.6,0-4.7,0.5-4.7,1.1c0,0,0,0,0,0v0.5c0,0.6,2.1,1.1,4.7,1.1
				c2.6,0,4.7-0.5,4.7-1.1L634.3,355.9L634.3,355.9z"
            />
            <ellipse class="st0" cx="629.7" cy="355.9" rx="4.7" ry="1.1" />
            <path
              class="st0"
              d="M642.1,353.9c0-0.6-1.9-1-4.3-1s-4.3,0.5-4.3,1c0,0,0,0,0,0v0.5c0,0.6,1.9,1,4.3,1c2.4,0,4.3-0.5,4.3-1
				L642.1,353.9L642.1,353.9z"
            />
            <ellipse class="st0" cx="637.7" cy="353.9" rx="4.3" ry="1" />
            <path
              class="st0"
              d="M631.5,352.1c0-0.6-1.9-1-4.3-1c-2.4,0-4.3,0.5-4.3,1c0,0,0,0,0,0v0.5c0,0.6,1.9,1,4.3,1
				c2.4,0,4.3-0.5,4.3-1V352.1L631.5,352.1L631.5,352.1z"
            />
            <ellipse class="st0" cx="627.2" cy="352.1" rx="4.3" ry="1" />
          </g>
          <g>
            <path
              class="st0"
              d="M634.6,360.2h-0.6c-0.5,0-0.9,0.5-0.9,1.1c0,0.6,0.4,1.1,0.9,1.1h0.6c0.5,0,0.9-0.5,0.9-1.1
				S635.1,360.2,634.6,360.2z"
            />
            <ellipse class="st0" cx="634.6" cy="361.4" rx="0.9" ry="1.1" />
            <path
              class="st0"
              d="M643.2,358C643.2,358,643.2,358,643.2,358l-0.6,0c-0.5,0-0.9,0.5-0.9,1.1c0,0.6,0.4,1.1,0.9,1.1h0.6
				c0.5,0,0.9-0.5,0.9-1.1S643.7,358,643.2,358z"
            />
            <ellipse class="st0" cx="643.2" cy="359.2" rx="0.9" ry="1.1" />
            <path
              class="st0"
              d="M647.1,357.1h-0.6c-0.5,0-0.9,0.5-0.9,1.1s0.4,1.1,0.9,1.1h0.6c0.5,0,0.9-0.5,0.9-1.1
				S647.5,357.1,647.1,357.1z"
            />
            <ellipse class="st0" cx="647.1" cy="358.2" rx="0.9" ry="1.1" />
            <path
              class="st0"
              d="M639,359.2h-0.6c-0.5,0-0.9,0.5-0.9,1.1s0.4,1.1,0.9,1.1h0.6c0.5,0,0.9-0.5,0.9-1.1S639.5,359.2,639,359.2z"
            />
            <ellipse class="st0" cx="639" cy="360.3" rx="0.9" ry="1.1" />
          </g>
          <line class="st0" x1="631" y1="359.2" x2="650.1" y2="354.5" />
        </g>
        <g id="_x32_-7_x5F_personalekoekken_x5F_2">
          <path
            class="st0"
            d="M643.5,345.6l-5,1.2v0.9c0,0.3-0.4,0.5-0.8,0.5c-0.5,0-1-0.2-1-0.5v-0.5l-11.6,2.7l24.9,4.5l-0.4,30.8
			l18.3-5.4l0.8-29.9L643.5,345.6z"
          />
          <g>
            <polygon class="st0" points="632.2,350.1 644.5,347.2 661.4,350 648.9,353.1 			" />
            <line class="st0" x1="644.5" y1="347.2" x2="644.5" y2="352.3" />
          </g>
          <line class="st0" x1="668.8" y1="350" x2="650.1" y2="354.5" />
          <polygon class="st0" points="651.2,355.6 651.2,356.8 656.7,355.5 656.7,354.3 		" />
        </g>
        <g id="_x32_-7_x5F_personalekoekken_x5F_1">
          <polygon
            class="st0"
            points="663.2,311.7 688,315.9 686.6,374.4 668,379.9 668.7,350 669.4,320.5 644.2,316.2 		"
          />
          <polygon class="st0" points="672,346.3 670.7,346.7 670.8,337.5 672.1,337.1 		" />
          <polygon class="st0" points="668.7,350 669.4,320.5 644.2,316.2 643.5,345.6 		" />
          <line class="st0" x1="669.4" y1="320.5" x2="688" y2="315.9" />
        </g>
      </g>
      <g id="KK-2-5" class="hover-obj lejer" @click="$store.commit('setModal', '2.5')">
        <g id="_x32_-5_x5F_indvendig-doer_x5F_1">
          <g>
            <path
              class="st0"
              d="M616.8,221.4L616,291l28.2-4.4l0.7-66.3L616.8,221.4z M624.4,287.5l-3.6,0.5l-0.9-0.6l0-3.7l3.6-0.5l0.9,0.6
				L624.4,287.5z"
            />
            <path
              class="st0"
              d="M636.4,222.2L636.4,222.2l0,1.6l-0.7,0v-1.6l-17.4,0.6l-0.7,67.9l25.1-3.9l0.7-65L636.4,222.2z M624.4,287.5
				l-3.6,0.5l-0.9-0.6l0-3.7l3.6-0.5l0.9,0.6L624.4,287.5z"
            />
            <g>
              <polygon class="st0" points="622.2,255.7 628.1,254.9 628.2,253.8 622.2,254.7 				" />
              <polygon
                class="st0"
                points="621.3,255.1 622.2,255.7 622.2,254.7 628.2,253.8 627.3,253.2 621.4,254.1 				"
              />
              <line class="st0" x1="622.2" y1="254.7" x2="621.4" y2="254.1" />
            </g>
            <line class="st0" x1="618.3" y1="222.9" x2="616.8" y2="221.4" />
            <line class="st0" x1="643.4" y1="221.9" x2="644.9" y2="220.4" />
            <path
              class="st0"
              d="M622.2,255.7l-0.9-0.6l0-1.1l1.9-0.3c-0.3-0.5-0.8-0.7-1.4-0.7c-1.1,0-1.7,0.6-1.7,1.8
				c0,1.4,1.1,1.6,1.8,1.6c0.7,0,1.2-0.4,1.4-0.9L622.2,255.7z"
            />
          </g>
          <g>
            <polygon class="st0" points="632.8,227.4 642.3,227.1 642.3,224.2 632.8,224.6 			" />
            <polygon
              class="st0"
              points="631.9,226.9 632.8,227.4 632.8,224.6 642.3,224.2 641.5,223.7 632,224 			"
            />
            <line class="st0" x1="632.8" y1="224.6" x2="632" y2="224" />
          </g>
          <polygon
            class="st0"
            points="642.9,221.3 635.7,221.5 635.7,223.9 636.4,223.8 636.4,222.2 642.9,221.9 		"
          />
        </g>
        <g id="_x32_-5_x5F_indvendig-doer_x5F_2">
          <polygon
            class="st0"
            points="691.8,361.5 692.3,311.2 704.2,308.9 710.6,309.8 709.2,356.7 		"
          />
          <g>
            <polygon class="st0" points="695,337.6 699.2,336.9 699.2,336.2 695,336.9 			" />
            <polygon
              class="st0"
              points="694.4,337.2 695,337.6 695,336.9 699.2,336.2 698.6,335.8 694.4,336.5 			"
            />
            <line class="st0" x1="695" y1="336.9" x2="694.4" y2="336.5" />
          </g>
          <path
            class="st0"
            d="M695,337.6l-0.6-0.4l0-0.7l1.3-0.2c-0.2-0.3-0.5-0.5-0.9-0.5c-0.7,0-1.2,0.4-1.2,1.3c0,1,0.8,1.1,1.2,1.1
			c0.5,0,0.8-0.3,1-0.6L695,337.6z"
          />
        </g>
      </g>
      <g id="KK-3-12" class="hover-obj keid kejd" @click="$store.commit('setModal', '3.12')">
        <g id="_x33_-12_x5F_elevator_x5F_2">
          <polygon class="st0" points="456.9,273 456.8,213.5 496.8,212.1 496.9,268.6 		" />
          <polygon class="st0" points="460.5,272.6 460.5,216.7 493.5,215.3 493.6,268.9 		" />
          <line class="st0" x1="474.5" y1="216.1" x2="474.5" y2="269" />
          <polyline class="st0" points="493.6,268.9 487,267.7 460.5,270.7 		" />
          <line class="st0" x1="487" y1="267.7" x2="487" y2="215.6" />
          <g>
            <polygon class="st0" points="499.2,243.3 502.9,243.1 502.9,237 499.2,237.1 			" />
          </g>
          <g>
            <polygon class="st0" points="501.1,241.3 501.9,239.5 500.1,239.6 			" />
          </g>
        </g>
        <g id="_x33_-12_x5F_elevator_x5F_1">
          <polygon class="st0" points="456.9,351.4 456.8,291.5 496.8,288.1 496.9,343.5 		" />
          <polygon class="st0" points="460.5,350.7 460.5,294.7 493.5,291.3 493.6,344.1 		" />
          <line class="st0" x1="474.5" y1="293.3" x2="474.5" y2="345.2" />
          <polyline class="st0" points="493.6,344.1 487,342.9 460.5,348.3 		" />
          <line class="st0" x1="487" y1="342.9" x2="487" y2="292" />
          <polyline
            class="st1"
            points="499.2,319.3 502.9,319 502.9,312.8 502.9,312.8 499.2,313.2 499.2,319.3 		"
          />
          <polygon class="st1" points="501,315.1 500.1,317 500.1,317 501.9,316.8 		" />
        </g>
      </g>
      <g id="KK-2-6" class="hover-obj lejer" @click="$store.commit('setModal', '2.6')">
        <g id="_x32_.6_x5F_kodesystem_x5F_2">
          <polygon class="st1" points="499.2,251 502.9,250.8 502.9,244.7 499.2,244.8 		" />
          <g>
            <ellipse class="st0" cx="500.4" cy="246.6" rx="0.4" ry="0.4" />
            <ellipse class="st0" cx="501.7" cy="246.5" rx="0.4" ry="0.4" />
            <ellipse class="st0" cx="500.4" cy="247.9" rx="0.4" ry="0.4" />
            <ellipse class="st0" cx="501.7" cy="247.8" rx="0.4" ry="0.4" />
            <ellipse class="st0" cx="500.4" cy="249.1" rx="0.4" ry="0.4" />
            <ellipse class="st0" cx="501.7" cy="249.1" rx="0.4" ry="0.4" />
          </g>
        </g>
        <g id="_x32_.6_x5F_kodesystem_x5F_1">
          <polyline
            class="st1"
            points="499.2,326.8 502.9,326.5 502.9,320.4 502.9,320.4 499.2,320.7 499.2,326.8 		"
          />
          <g>
            <ellipse class="st0" cx="500.4" cy="322.5" rx="0.4" ry="0.4" />
            <ellipse class="st0" cx="501.7" cy="322.4" rx="0.4" ry="0.4" />
            <ellipse class="st0" cx="500.4" cy="323.7" rx="0.4" ry="0.4" />
            <ellipse class="st0" cx="501.7" cy="323.7" rx="0.4" ry="0.4" />
            <ellipse class="st0" cx="500.4" cy="325" rx="0.4" ry="0.4" />
            <ellipse class="st0" cx="501.7" cy="325" rx="0.4" ry="0.4" />
          </g>
        </g>
      </g>
      <g id="KK-3-16" class="hover-obj keid kejd" @click="$store.commit('setModal', '3.16')">
        <polygon class="st0" points="639.1,212 638.9,218.6 624.7,219.1 624.8,212.3 	" />
        <polygon
          class="st0"
          points="624.7,219.1 624.8,212.3 639.1,212 637.7,211.5 623.5,211.8 623.4,218.6 	"
        />
        <line class="st1" x1="624.8" y1="212.3" x2="623.5" y2="211.8" />
        <polygon class="st1" points="632.9,217.7 632.9,213.4 637.5,213.3 637.5,217.6 	" />
        <polygon
          class="st1"
          points="629.6,217.7 629.6,216.8 628.5,216.1 631.5,216 631.5,215 628.5,215.1 629.5,214.3 629.5,213.4 
		626.2,215.6 	"
        />
      </g>
      <g id="KK-3-14" class="hover-obj lejer" @click="$store.commit('setModal', '3.14')">
        <g id="_x33_-14_x5F_slangevinder">
          <polygon
            class="st0"
            points="523.1,242.5 526.3,243.7 526.3,225 544.7,223.8 541.4,222.5 523.1,223.8 		"
          />
          <line class="st0" x1="526.3" y1="225" x2="523.1" y2="223.8" />
          <polygon class="st0" points="526.3,225 544.7,223.8 544.7,241.9 526.3,243.7 		" />
          <g>
            <polygon class="st0" points="527.5,232.3 527.5,235.2 528.2,235.1 528.2,232.3 			" />
          </g>
        </g>
        <g id="_x33_-14_x5F_pulverslukker">
          <path
            class="st0"
            d="M512.8,246.1c-0.3-0.1-0.6-0.5-0.6-0.5c0,0.1-0.1,0.2-0.5,0.2s-0.5-0.1-0.5-0.2c0,0-0.3,0.3-0.6,0.5
			c-0.4,0.2-1.2,0.6-1.2,1v12.4c0,0.3,1.1,0.5,2.4,0.5s2.4-0.2,2.4-0.5v-12.4C514,246.7,513.2,246.3,512.8,246.1z"
          />
          <path
            class="st0"
            d="M509.2,254.7c0,0.3,1.1,0.5,2.4,0.5s2.4-0.2,2.4-0.5v-3.3c0,0.3-1.1,0.5-2.4,0.5s-2.4-0.2-2.4-0.5V254.7z"
          />
          <path
            class="st0"
            d="M515.7,245c-0.5-0.4-2.3-0.9-3.4-0.9c0.6-0.3,2.3-0.5,2.3-0.5s0,0-0.1-0.3c-1.7-0.2-3.7,0.6-3.7,0.6v0.7
			c0,0,0.1,0,0.3,0v1v0.3c0.1,0,0.3,0,0.5,0c0.2,0,0.4,0,0.5,0v-0.3v-1c0.1,0,0.2,0,0.3,0c1,0,3.3,0.9,3.3,0.9
			S515.7,245.4,515.7,245z"
          />
          <g>
            <path class="st0" d="M510.8,244.6c0,0-1.8,0.2-1.8,1.4c-0.1,1.3-0.8,4.2-0.8,4.2" />
            <g>
              <path
                class="st0"
                d="M508,250.2c-0.6-0.1-1.1,0.3-1.2,0.9l-0.5,3.6c0,0.2,0.4,0.4,1,0.5s1.1,0,1.1-0.2l0.5-3.6
					C508.9,250.8,508.5,250.3,508,250.2z"
              />
            </g>
          </g>
        </g>
      </g>

      <g id="KK-3-1" class="hover-obj lejer" @click="$store.commit('setModal', '3.1')">
        <g id="_x33_-1_x5F_wc-skaal">
          <path
            class="st0"
            d="M391.4,326l-3-0.5c0.1-0.1,0.3-0.2,0.4-0.3c2-3,0.9-10.4,0.8-11.1c-0.1-0.5-1.1-3.2-3-3
			c-0.5-0.1-1.2,0-1.9,0.5c-2.9,1.9-3.3,9.6-3.4,11.3c-0.1,1.7,1,2.1,1.7,2c0,0.1,0,0.1,0,0.1l-1,0.9c0,0,0,0,0,0
			c-1.9-0.1-4.2,0.1-6.1,0.3c0,0,0,0,0,0c-0.2,0-0.5,0.1-0.7,0.1c-0.2,0-0.4,0.1-0.7,0.1c0,0,0,0-0.1,0c-0.5,0.1-0.9,0.1-1.3,0.2
			c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0,0,0-0.1,0c-0.2,0-0.3,0.1-0.5,0.1c0,0,0,0,0,0c0,0,0,0,0,0c-0.2,0.1-0.4,0.1-0.6,0.2
			c0,0,0,0,0,0c-0.2,0.1-0.3,0.1-0.5,0.2c-0.1,0.1-0.3,0.2-0.4,0.2c-0.4,0.2-0.8,0.5-0.9,0.7c-0.2,0.3-0.4,0.5-0.4,0.7
			c0,0,0,0,0,0.1c0,0.1,0,0.1,0,0.2c0,0,0,0,0,0.1c0,0.1,0.1,0.1,0.1,0.2c0.4,0.3,1.2,1.5,1.6,2.6c0.4,1.1,2,2.2,3.1,2.9
			c0,0.2,0,0.5,0,0.7c0,0,0,0.1,0,0.1c0,0.2,0,0.5,0,0.8c0,0.1,0,0.1,0,0.2c0,0.3,0,0.5-0.1,0.8c0,0,0,0.1,0,0.1
			c0,0.3,0,0.5-0.1,0.8c0,0,0,0,0,0c0,0.3-0.1,0.6-0.1,0.8c0,0,0,0.1,0,0.1c0,0.3-0.1,0.5-0.1,0.7c0,0,0,0.1,0,0.1
			c0,0.2-0.1,0.4-0.1,0.6c0,0,0,0.1,0,0.1c0,0.2,0,0.4-0.1,0.5c0,0-1.3,0.1-1.8,1.7c-0.2,0.6,3.5,0.8,5.6,0.6s8.4-0.9,11-2.3
			c1.3-1-1.4-1.6-1.4-1.6v-4.4c0,0,0,0,0,0c0.7-0.3,1.5-0.8,1.6-1.5c0-1.3-1.1-1.7-1.1-1.7c0,0,0,0,0,0c0.5-1.7,1.3-3,1.5-3.2
			c0,0,0,0,0,0c0,0,0-0.1,0-0.1l1-0.2l1.2-1.6L391.4,326z"
          />
          <g>
            <path class="st0" d="M387.8,332.2c0,0,1.1,0.4,1.1,1.7c0,1.3-1.6,1.6-1.6,1.6" />
            <path
              class="st0"
              d="M387.3,339.9c0,0,2.7,0.6,1.4,1.6c-2.6,1.4-8.9,2.2-11,2.3c-2.2,0.2-5.9,0-5.6-0.6c0.5-1.6,1.8-1.7,1.8-1.7"
            />
            <path
              class="st0"
              d="M386.6,327.9c0,0-3.7,1.1-6.5,1.4c-2.7,0.3-8.7,0.2-10-0.4c-1.2-0.6,1.3-1.9,2.7-2.2c1.5-0.3,5.9-1,9-0.7
				C383.7,326.6,387.5,327.5,386.6,327.9z"
            />
            <path
              class="st0"
              d="M377.2,327.2c1.4-0.1,4-0.2,4.8,0.1c0.9,0.3-2,1-3.2,1c-1.3,0-6,0.5-6.3,0S376.5,327.3,377.2,327.2z"
            />
            <path
              class="st0"
              d="M382.2,326.1c0,0,0.8-0.2,0.7-1.1c-0.7,0.1-1.8-0.3-1.7-2c0.1-1.7,0.5-9.4,3.4-11.3c2.9-1.9,4.2,1.8,4.3,2.4
				c0.1,0.7,1.3,8.1-0.8,11.1c-0.7,0.8-1.4,0.5-1.4,0.5s-0.4,0.9-0.8,1.1c-0.8-0.2-1.1-0.2-1.1-0.2s0.4-0.2,0.8-1
				c-1.6-0.2-1.6-0.2-1.6-0.2s-0.2,0.7-0.7,0.9C382.7,326.2,382.2,326.1,382.2,326.1z"
            />
            <polygon class="st0" points="390.1,327.6 391.4,326 391.4,327.1 390.3,328.7 			" />
            <path class="st0" d="M378.1,342.4l0.2-6.1c0,0,5.7,0.2,8.8-6.8" />
            <path class="st0" d="M374.5,334.8c0,0,6,0.6,7.5-1.9" />
            <g>
              <path
                class="st0"
                d="M389.5,314.2c-0.1-0.5-1.1-3.2-3-3c1.6,0.3,2.4,2.4,2.5,3c0.1,0.7,1.3,8.1-0.8,11.1
					c-0.3,0.3-0.5,0.5-0.8,0.5c0.2,0.1,0.8,0.1,1.3-0.5C390.8,322.3,389.6,314.8,389.5,314.2z"
              />
            </g>
            <g>
              <path class="st0" d="M382.6,325.8c0.1-0.1,0.2-0.2,0.2-0.4L382.6,325.8z" />
              <path
                class="st0"
                d="M383.4,326.3l1.5,0.3c0.1,0,0.4-0.3,0.7-1c-1.6-0.2-1.6-0.2-1.6-0.2S383.8,326.1,383.4,326.3z"
              />
              <path
                class="st0"
                d="M387.9,325.5c-0.6,0.5-1.1,0.2-1.1,0.2s-0.3,0.8-0.8,1.1l4.1,0.8l1.3-1.6L387.9,325.5z"
              />
              <path
                class="st0"
                d="M382.5,326.1L382.5,326.1l0.5,0.1C382.7,326.2,382.6,326.2,382.5,326.1z"
              />
            </g>
          </g>
        </g>
        <g id="_x33_-1_x5F_synlig-vandlaas_x5F_2">
          <ellipse class="st1" cx="411.7" cy="347.4" rx="1.7" ry="0.5" />
          <g>
            <path
              class="st0"
              d="M408.7,332.3c0,0.2,0.1,0.3,0.3,0.3s0.3-0.1,0.3-0.3v-0.8c0-0.8,0.6-1.4,1.4-1.4c0.8,0,1.4,0.6,1.4,1.4v15.8
				c0,0.1-0.2,0.2-0.5,0.2c-0.3,0-0.5-0.1-0.5-0.2v-15.8c0-0.2-0.1-0.3-0.3-0.3s-0.3,0.1-0.3,0.3v0.8c0,0.8-0.6,1.4-1.4,1.4
				s-1.4-0.6-1.4-1.4l0,0l0-6.5l1.1,0.2L408.7,332.3"
            />
          </g>
        </g>
        <g id="_x33_-1_x5F_synlig-vandlaas_x5F_1">
          <ellipse class="st1" cx="424.2" cy="273.4" rx="1.7" ry="0.5" />
          <g>
            <path
              class="st1"
              d="M421,256.5c0,0.2,0.1,0.3,0.3,0.3s0.3-0.1,0.3-0.3v-0.8c0-0.8,0.6-1.4,1.4-1.4s1.4,0.6,1.4,1.4l0.2,17.6
				c0,0.1-0.2,0.2-0.5,0.2c-0.3,0-0.5-0.1-0.5-0.2l-0.2-17.6c0-0.2-0.1-0.3-0.3-0.3s-0.3,0.1-0.3,0.3v0.8c0,0.8-0.6,1.4-1.4,1.4
				s-1.4-0.6-1.4-1.4l0,0l0-6.4l1.1,0.1L421,256.5"
            />
          </g>
        </g>
        <g id="_x33_-1_x5F_vask_x5F_1">
          <polygon class="st0" points="418.5,320.8 418.5,324.7 409.9,326.2 409.9,322.3 		" />
          <polygon class="st0" points="409.9,322.3 409.9,326.2 398.2,324.1 398.2,320.2 		" />
          <path
            class="st0"
            d="M410.8,319.5v0.2c0,0.2-0.3,0.3-0.6,0.3c-0.2,0-0.5-0.1-0.5-0.3v-0.4l-3.8-0.7l-7.6,1.6l11.7,2.1l8.6-1.4
			L410.8,319.5z"
          />
          <line class="st0" x1="403.4" y1="319.2" x2="415.1" y2="321.4" />
          <line class="st0" x1="403.4" y1="319.2" x2="403.4" y2="321.1" />
        </g>
        <g id="_x33_-1_x5F_roer_x5F_3">
          <path class="st0" d="M416.3,255.4c0-0.1,0.4-0.3,0.8-0.3c0.4,0,0.8,0.1,0.8,0.2" />
          <path
            class="st0"
            d="M416.5,249.8l0.1,5.8c0.1,0,0.3,0.1,0.5,0.1c0.2,0,0.4,0,0.5-0.1l-0.1-5.6L416.5,249.8z"
          />
        </g>
        <g id="_x33_-1_x5F_roer_x5F_2">
          <path class="st0" d="M413.4,254.9c0-0.1,0.4-0.3,0.8-0.3s0.8,0.1,0.8,0.2" />
          <path
            class="st0"
            d="M413.6,249.6l0.1,5.6c0.1,0,0.3,0.1,0.5,0.1c0.2,0,0.4,0,0.5-0.1l-0.1-5.5L413.6,249.6z"
          />
        </g>
        <g id="_x33_-1_x5F_roer_x5F_1">
          <g>
            <path class="st0" d="M402.4,328.7c0-0.1,0.4-0.2,0.8-0.2c0.4,0,0.8,0.1,0.8,0.2" />
            <path
              class="st0"
              d="M402.7,324.9l0,3.9c0.1,0,0.3,0.1,0.5,0.1c0.2,0,0.4,0,0.5-0.1l0-3.7L402.7,324.9z"
            />
          </g>
          <g>
            <path class="st0" d="M405.3,329.1c0-0.1,0.4-0.2,0.8-0.2c0.4,0,0.8,0.1,0.8,0.2" />
            <path
              class="st0"
              d="M405.6,325.4l0,3.9c0.1,0,0.3,0.1,0.5,0.1c0.2,0,0.4,0,0.5-0.1l0-3.7L405.6,325.4z"
            />
          </g>
        </g>
        <g id="_x33_-1_x5F_vask">
          <polygon class="st1" points="408,244.9 408,249 421.8,250.3 421.7,246 		" />
          <polygon class="st1" points="435.4,244.9 421.7,246 421.8,250.3 435.4,249.2 		" />
          <polygon class="st1" points="408,244.9 416.2,244.2 416.2,245.5 		" />
          <polygon class="st1" points="430.1,245.4 416.2,244.2 416.2,245.5 421.7,246 		" />
          <path
            class="st1"
            d="M425.9,244.1L425.9,244.1c0,0.3-0.3,0.4-0.6,0.4c-0.2,0-0.5-0.1-0.5-0.3V244l-3.1-0.3l-5.5,0.4l13.9,1.1
			l5.3-0.4L425.9,244.1z"
          />
        </g>
      </g>
      <g id="KK-3-4" class="hover-obj keid kejd" @click="$store.commit('setModal', '3.4')">
        <g id="_x33_-4_x5F_vandroer_x5F_4">
          <polygon class="st0" points="403.9,367.2 403.9,361.6 402.9,361.8 402.9,367.4 		" />
          <g>
            <path
              class="st0"
              d="M406.8,371.8l17.7-3.5c0.8-0.2,1.3-0.8,1.3-1.6v-9.3l-1,0.2l0.1,8.5c0,0.8,0,1.1-0.8,1.3l-17.4,3.4V371.8z"
            />
            <polygon
              class="st0"
              points="405.7,371.1 403.9,371.4 403.9,369.9 403,370.1 403,371.6 303.3,391.3 303.4,392.2 405.7,372 			"
            />
          </g>
        </g>
        <g id="_x33_-4_x5F_vandroer_x5F_3">
          <polygon class="st0" points="406.8,366.7 406.8,361 405.8,361.2 405.8,366.9 		" />
          <path
            class="st0"
            d="M427.1,357.2l0,10.5c0,0.8-0.1,1.4-0.9,1.5l-19.4,3.8v-3.7l-1,0.2l0,3.7l-102.3,20.2l0,1l123.2-24.3
			c0.8-0.2,1.4-0.9,1.4-1.7V357L427.1,357.2z"
          />
        </g>
        <g id="_x33_-4_x5F_vandroer_x5F_2">
          <g>
            <path
              class="st0"
              d="M402.7,328.8l0.1,16.7c0,0.1,0.2,0.2,0.5,0.2s0.5-0.1,0.5-0.2l-0.1-16.7c-0.1,0-0.3,0.1-0.5,0.1
				C403,328.9,402.9,328.9,402.7,328.8z"
            />
            <path class="st0" d="M404,328.7c0,0.1-0.4,0.2-0.8,0.2c-0.4,0-0.8-0.1-0.8-0.2" />
          </g>
          <g>
            <path
              class="st0"
              d="M405.6,329.3l0.1,16.7c0,0.1,0.2,0.2,0.5,0.2s0.5-0.1,0.5-0.2l-0.1-16.7c-0.1,0-0.3,0.1-0.5,0.1
				C405.9,329.4,405.7,329.4,405.6,329.3z"
            />
            <path class="st0" d="M406.9,329.1c0,0.1-0.4,0.2-0.8,0.2c-0.4,0-0.8-0.1-0.8-0.2" />
          </g>
          <g>
            <path
              class="st0"
              d="M427.1,326.4l0.1,23.3c0,0.1,0.2,0.2,0.4,0.2c0.2,0,0.4-0.1,0.4-0.2l-0.1-23.2l-0.2,0.1L427.1,326.4z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M424.8,326.1l0.1,23.3c0,0.1,0.2,0.2,0.4,0.2s0.4-0.1,0.4-0.2l-0.1-23.1L424.8,326.1z"
            />
          </g>
        </g>
        <g id="_x33_-4_x5F_vandroer_x5F_1">
          <g>
            <g>
              <g>
                <path
                  class="st0"
                  d="M413.7,255.2l0.5,16.7c0,0.1,0.2,0.2,0.5,0.2s0.5-0.1,0.5-0.2l-0.5-16.7c-0.1,0-0.3,0.1-0.5,0.1
						C414.1,255.2,413.9,255.2,413.7,255.2z"
                />
                <path class="st0" d="M415,254.9c0,0.1-0.4,0.3-0.8,0.3s-0.8-0.1-0.8-0.2" />
              </g>
              <g>
                <path
                  class="st0"
                  d="M416.6,255.6l0.5,16.7c0,0.1,0.2,0.2,0.5,0.2c0.3,0,0.5-0.1,0.5-0.2l-0.5-16.7c-0.1,0-0.3,0.1-0.5,0.1
						C416.9,255.6,416.7,255.6,416.6,255.6z"
                />
                <path class="st0" d="M417.9,255.4c0,0.1-0.4,0.3-0.8,0.3s-0.8-0.1-0.8-0.2" />
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="KK-2-9" class="hover-obj lejer" @click="$store.commit('setModal', '2.9')">
        <g id="_x32_-9_x5F_inventar_x5F_6">
          <g id="_x32_.9_x5F_inventar_x5F_3">
            <polygon class="st0" points="760,409.3 761.3,409.6 761.4,394.4 760.2,394.1 			" />
            <polygon class="st0" points="762.4,409 762.6,394 761.4,394.4 761.3,409.6 			" />
            <polygon class="st0" points="770.5,406.1 771.7,406.4 771.9,391.4 770.7,391.7 			" />
            <polygon
              class="st0"
              points="772.9,405.8 773.1,391.3 772.5,391.2 771.9,391.4 771.7,406.4 			"
            />
            <polygon class="st0" points="753.6,391 753.6,392.5 761.4,394.4 761.5,393 			" />
            <g>
              <polygon
                class="st0"
                points="757.9,388.1 753.6,389.2 753.6,391 761.5,393 769.7,390.6 				"
              />
              <polygon class="st0" points="761.5,393 761.4,394.4 772.5,391.2 769.7,390.6 				" />
            </g>
            <polygon class="st0" points="750.1,384 750.1,381.1 751.1,380.9 751.1,383.7 			" />
            <polygon class="st0" points="761.5,381 761.5,378 762.5,377.8 762.5,380.7 			" />
            <polygon class="st0" points="749.8,374.3 763.6,370.6 763.6,377.4 749.8,381.2 			" />
            <polygon
              class="st0"
              points="749.8,381.2 749,380.9 749.1,374 762.9,370.3 763.6,370.6 749.8,374.3 			"
            />
            <line class="st0" x1="749.1" y1="374" x2="749.8" y2="374.3" />
          </g>
          <g id="_x32_.9_x5F_inventar_x5F_2">
            <polygon class="st0" points="775.4,399.8 776.7,400.1 776.8,392.1 775.6,391.9 			" />
            <polygon class="st0" points="777.8,399.5 777.9,392.4 776.8,392.1 776.7,400.1 			" />
            <polygon class="st0" points="785.1,402.2 786.4,402.4 786.7,394.2 785.4,394 			" />
            <polygon class="st0" points="787.5,401.9 787.8,394.5 786.7,394.2 786.4,402.4 			" />
            <polygon class="st0" points="796,399.1 797.3,399.4 797.3,393.3 796.1,393.7 			" />
            <polyline class="st0" points="797.3,393.3 797.3,399.4 798.4,398.8 798.5,393.1 			" />
            <polygon class="st0" points="775.7,377.1 775.7,374.2 776.7,373.9 776.7,376.9 			" />
            <polygon class="st0" points="787.1,374 787.1,371.1 788.1,370.8 788.1,373.8 			" />
            <polygon class="st0" points="775.4,367.3 789.1,363.6 789.1,370.4 775.3,374.2 			" />
            <polygon
              class="st0"
              points="775.3,374.2 774.6,373.9 774.6,367 788.4,363.3 789.1,363.6 775.4,367.3 			"
            />
            <line class="st0" x1="774.6" y1="367" x2="775.4" y2="367.3" />
          </g>
          <g id="_x32_.9_x5F_inventar_x5F_1">
            <polygon class="st0" points="749.4,411.2 751.4,411.7 751.7,386.8 749.7,386.4 			" />
            <polygon class="st0" points="753.3,410.7 753.7,387.2 751.7,386.8 751.4,411.7 			" />
            <polygon class="st0" points="787.8,419.6 789.9,420 790.8,395.1 788.9,394.7 			" />
            <polygon class="st0" points="791.7,419.1 792.8,394.6 790.8,395.1 789.9,420 			" />
            <polygon class="st0" points="749.7,384.1 749.7,386.3 790.8,395.1 790.9,392.9 			" />
            <g>
              <polygon
                class="st0"
                points="807.4,376.1 791.7,372.7 749.7,384.1 790.9,392.9 807,388.4 				"
              />
              <polygon class="st0" points="790.9,392.9 790.8,395.1 806.9,390.7 807,388.4 				" />
            </g>
          </g>
        </g>
        <g id="_x32_-9_x5F_inventar_x5F_5">
          <polygon class="st0" points="787.8,306.6 789.1,306.7 789.3,288.5 788.1,288.2 		" />
          <polygon class="st0" points="790.3,306.1 790.4,288.7 789.3,288.5 789.1,306.7 		" />
          <polygon class="st0" points="797.9,308.5 799.2,308.6 799.5,290.2 798.1,290 		" />
          <polygon class="st0" points="800.3,307.9 800.5,290 799.5,290.2 799.2,308.7 		" />
          <polygon class="st0" points="806.8,304.7 808,305 808.4,289.1 807.2,289 		" />
          <polygon class="st0" points="809.2,304.4 809.6,289.3 808.4,289.1 808,305 		" />
          <polygon class="st0" points="788,287 788.1,288.2 799.5,290.2 799.6,288.9 		" />
          <g>
            <polygon class="st0" points="789.1,286.8 788,287 799.5,288.9 801.6,288.3 			" />
            <polygon class="st0" points="799.6,288.9 799.5,290.2 804.9,288.7 801.6,288.3 			" />
          </g>
          <polygon class="st0" points="788,282.6 788.1,277.9 789.1,277.7 789.1,282.3 		" />
          <polygon class="st0" points="799.4,279.5 799.4,274.8 800.4,274.5 800.4,279.2 		" />
          <polygon class="st0" points="787.8,271.1 801.5,267.5 801.5,274.2 787.7,278 		" />
          <polygon
            class="st0"
            points="787.7,278 787,277.7 787,270.8 800.8,267.2 801.5,267.5 787.8,271.1 		"
          />
          <line class="st0" x1="787" y1="270.8" x2="787.8" y2="271.1" />
        </g>
        <g id="_x32_-9_x5F_inventar_x5F_4">
          <polygon class="st0" points="783,311.1 785,311.4 785.3,286.3 783.3,286.1 		" />
          <polygon class="st0" points="786.9,310.5 787.2,286.6 785.3,286.3 785,311.4 		" />
          <g>
            <polygon class="st0" points="810.6,287.2 783.3,283.9 811,276.3 			" />
          </g>
          <polyline
            class="st1"
            points="810.6,287.2 810.5,289.4 783.3,286.1 783.3,283.9 810.6,287.2 		"
          />
        </g>
        <g id="_x32_-9_x5F_inventar_x5F_1">
          <g>
            <polygon class="st0" points="510,296.6 510,300.6 527.8,297.2 527.8,293.5 			" />
          </g>
          <path class="st0" d="M513.3,298.9c0,0.3-0.2,0.5-0.5,0.5c-0.3,0-0.5-0.2-0.5-0.5v-1.7" />
          <path class="st0" d="M516.5,298.3c0,0.3-0.2,0.5-0.5,0.5c-0.3,0-0.5-0.2-0.5-0.5v-1.7" />
          <path class="st0" d="M519.7,297.6c0,0.3-0.2,0.5-0.5,0.5c-0.3,0-0.5-0.2-0.5-0.5V296" />
          <path class="st0" d="M522.9,297c0,0.3-0.2,0.5-0.5,0.5c-0.3,0-0.5-0.2-0.5-0.5v-1.7" />
          <path class="st0" d="M526.1,296.4c0,0.3-0.2,0.5-0.5,0.5c-0.3,0-0.5-0.2-0.5-0.5v-1.7" />
          <polygon
            class="st0"
            points="510,300.6 509.2,300.2 509.2,296.1 527.1,293 527.8,293.5 510,296.6 		"
          />
        </g>
      </g>
      <g id="KK-3-2" class="hover-obj lejer" @click="$store.commit('setModal', '3.2')">
        <g id="_x33_-2_x5F_bruseslange-og-hoved">
          <g>
            <polygon class="st1" points="427.7,324 427.7,326.6 429.9,326 429.9,323.5 			" />
            <polygon class="st1" points="427.7,324 427.7,326.6 422.5,325.7 422.5,323.1 			" />
            <polyline
              class="st1"
              points="426,322.9 424.6,322.6 422.5,323.1 427.7,324 429.9,323.5 427.1,323 			"
            />
          </g>
          <path
            class="st1"
            d="M423.4,299.7v-0.6c0-0.7,0.6-1.3,1.3-1.3s1.3,0.6,1.3,1.3V323c0,0.1,0.2,0.2,0.5,0.2s0.5-0.1,0.5-0.2
			l-0.2-23.9c0-1.2-1-2.2-2.2-2.2s-2.2,1-2.2,2.2l0,0.5"
          />
          <path
            class="st1"
            d="M422.8,300.2c-1.1-0.1-2-0.3-2-0.4l-0.1,0.8c0,0.1,0.9,0.3,2,0.4c1.1,0.1,2,0,2-0.1l0.1-0.8
			C424.9,300.2,424,300.3,422.8,300.2z"
          />
          <path
            class="st1"
            d="M423.4,299.7c0.9,0.1,1.5,0.2,1.5,0.4c0,0.1-0.9,0.2-2,0.1c-1.1-0.1-2-0.3-2-0.4c0-0.1,0.7-0.2,1.6-0.1"
          />
        </g>
        <g id="_x33_-2_x5F_blandingsbatteri_x5F_3">
          <path
            class="st1"
            d="M637.1,340.9v-1.4c0,0,0-0.2,0.1-0.3c0.6-0.7,3.2-0.6,3.2-0.2c0,0.2-1.6,0.5-2.4,0.6v1.3l3.5,0.7v1.1v1.4
			c0,0.1-0.2,0.2-0.5,0.2s-0.5-0.1-0.5-0.2v-1.4l-1.9-0.3v5.4c0,0.3-0.4,0.5-0.8,0.5c-0.5,0-1-0.2-1-0.5v-6.8H637.1z"
          />
          <path class="st1" d="M637.9,339.6c0,0-0.4,0.1-0.8-0.1" />
          <line class="st1" x1="637.9" y1="340.9" x2="637.1" y2="340.9" />
        </g>
        <g id="_x33_-2_x5F_blandingsbatteri_x5F_2">
          <path
            class="st1"
            d="M410.6,315.2v-0.9c0,0,0-0.1-0.1-0.2c-0.4-0.4-2.1-0.4-2.1-0.1c0,0.2,1,0.3,1.6,0.4v0.8h-0.4h-1.9v0.7v0.9
			c0,0.1,0.2,0.1,0.4,0.1c0.2,0,0.4-0.1,0.4-0.1v-0.9h1.2v3.7c0,0.2,0.3,0.3,0.5,0.3c0.4,0,0.6-0.1,0.6-0.3v-4.5H410.6z"
          />
          <path class="st1" d="M410,314.4c0,0,0.2,0,0.6,0" />
          <line class="st1" x1="410" y1="315.2" x2="410.6" y2="315.2" />
        </g>
        <g id="_x33_-2_x5F_blandingsbatteri_x5F_1">
          <g>
            <path
              class="st1"
              d="M425.7,239.8v-0.9c0,0,0-0.1-0.1-0.2c-0.4-0.4-2.1-0.4-2.1-0.1c0,0.2,1,0.3,1.6,0.4v0.8h-0.4h-1.9v0.7v0.9
				c0,0.1,0.2,0.1,0.4,0.1c0.2,0,0.4-0.1,0.4-0.1v-0.9h1.2v3.7c0,0.2,0.3,0.3,0.5,0.3c0.4,0,0.6-0.1,0.6-0.3v-4.5H425.7z"
            />
            <path class="st1" d="M425.1,238.9c0,0,0.2,0,0.6,0" />
            <line class="st1" x1="425.1" y1="239.8" x2="425.7" y2="239.8" />
          </g>
        </g>
      </g>
      <g id="KK-3-13_1_" class="hover-obj keid kejd" @click="$store.commit('setModal', '3.13')">
        <g id="_x33_-13_x5F_abdl">
          <g id="_x33_-13_x5F_abdl_x5F_2">
            <g>
              <polygon class="st0" points="662.6,281.8 662.6,278.1 666.2,277.5 666.2,281.3 				" />
            </g>
            <path
              class="st0"
              d="M664.4,278.6c0.5-0.1,1,0.3,1,1c0,0.7-0.3,1.1-1,1.2c-0.6,0.1-1.1-0.3-1.1-1
				C663.4,279,663.7,278.6,664.4,278.6z"
            />
            <path class="st0" d="M664.1,278.6c0.4,0.1,0.8,0.4,0.8,1c0,0.6-0.3,1-0.8,1.1" />
            <polygon
              class="st0"
              points="662.6,281.8 661.7,281.2 661.7,277.5 665.3,277 666.2,277.5 662.6,278.1 			"
            />
            <line class="st0" x1="661.7" y1="277.5" x2="662.6" y2="278.1" />
          </g>
          <g id="_x33_-13_x5F_abdl_x5F_1">
            <g>
              <polygon class="st0" points="620.8,288 620.8,284.3 624.4,283.8 624.4,287.5 				" />
            </g>
            <polygon
              class="st0"
              points="620.8,288 619.8,287.5 619.9,283.7 623.5,283.2 624.4,283.8 620.8,284.3 			"
            />
            <line class="st0" x1="620.8" y1="284.3" x2="619.9" y2="283.7" />
            <path
              class="st0"
              d="M623.5,285l-0.4-0.1c-0.2-0.1-0.3-0.1-0.5-0.1c-0.7,0-1,0.4-1,1.1c0,0.5,0.2,0.8,0.6,1l0.6,0.2"
            />
            <path
              class="st0"
              d="M622.7,287.1c-0.3-0.1-0.6-0.4-0.6-1c0-0.7,0.4-1.1,1.1-1.1c0.4,0,0.9,0.3,1,1c0,0.7-0.3,1.1-1,1.2
				C623,287.2,622.8,287.1,622.7,287.1"
            />
          </g>
        </g>
        <g id="_x33_-13_x5F_avs">
          <circle class="st0" cx="463.5" cy="195.4" r="15.9" />
          <g>
            <path
              class="st1"
              d="M472.7,187.6c0-1.1-4.1-2.1-9.2-2.1c-5.1,0-9.2,0.9-9.2,2.1v4.8c0,1.1,4.1,2.1,9.2,2.1s9.2-0.9,9.2-2.1
				L472.7,187.6C472.7,187.6,472.7,187.6,472.7,187.6z"
            />
            <ellipse class="st1" cx="463.5" cy="187.6" rx="9.2" ry="2.1" />
            <ellipse class="st1" cx="463.5" cy="187.6" rx="6.9" ry="1.3" />
            <path
              class="st1"
              d="M465.2,194.4c0.9,1.2,1.5,3.2,1.5,5.4c0,3.6-3.2,4-3.2,4s-3.2-0.4-3.2-4c0-2.3,0.7-4.2,1.5-5.4
				c-0.4,0-0.8,0-1.2-0.1c-0.8,1.5-1.3,3.6-1.3,5.9c0,2.3,1,3.7,2.1,4.4c1.3,0.9,2.9,0.9,4.2,0c1-0.7,2.1-2,2.1-4.4
				c0-2.3-0.5-4.3-1.3-5.9C466,194.3,465.6,194.4,465.2,194.4z"
            />
            <g>
              <path
                class="st1"
                d="M468.2,204.3h-2.1l1.5-0.9L467,203c-0.4,0.7-0.9,1.2-1.4,1.5c-1.3,0.9-2.9,0.9-4.2,0
					c-0.5-0.4-1-0.8-1.4-1.5l-0.7,0.4l1.5,0.9h-2.1v1.3h2.1l-1.5,0.9l1.6,0.9l1.5-0.9v1.2h2.2v-1.2l1.5,0.9l1.6-0.9l-1.5-0.9h2.1
					V204.3z"
              />
              <path
                class="st1"
                d="M464.1,202.1L464.1,202.1c0,0.3-0.2,0.6-0.5,0.6h0c-0.3,0-0.5-0.2-0.5-0.5v0h-0.5v1.3
					c0.6,0.3,1.1,0.4,1.1,0.4s0.5-0.1,1.1-0.4v-1.3H464.1z"
              />
            </g>
            <path
              class="st1"
              d="M463.5,194.4c-0.2,0-0.4,0-0.6,0v7.8c0,0.3,0.2,0.5,0.5,0.5h0c0.3,0,0.5-0.2,0.5-0.5v-7.8
				C463.9,194.4,463.7,194.4,463.5,194.4z"
            />
          </g>
        </g>
        <g id="_x33_-13_x5F_aba">
          <circle class="st0" cx="562.2" cy="220.4" r="15.9" />
          <g id="_x33_.13_x5F_automatisk-brandalarmeringsanlaeg_4_">
            <polygon
              class="st1"
              points="554.1,229.2 556.5,230.1 556.5,213.2 570.3,212.3 567.9,211.3 554.1,212.3 			"
            />
            <line class="st1" x1="556.5" y1="213.2" x2="554.1" y2="212.3" />
            <polygon class="st1" points="556.5,213.2 570.3,212.3 570.3,228.7 556.5,230.1 			" />
            <polygon class="st1" points="556.5,213.2 570.3,212.3 570.3,228.7 556.5,230.1 			" />
            <polygon class="st1" points="570.3,212.3 567.9,222.1 559.5,222.9 556.5,213.2 			" />
            <polygon class="st1" points="560.1,214.6 566.4,214.2 566.4,215.8 560.4,216.2 			" />
            <polygon class="st1" points="559.3,218.2 567.8,217.6 567.6,219.2 559.6,219.8 			" />
          </g>
        </g>
        <g id="_x33_-13_x5F_ava">
          <circle class="st0" cx="516.4" cy="206.2" r="15.9" />
          <g>
            <path
              class="st1"
              d="M517.6,195.1h-2.4c-3.8,0-6.9,5.1-6.9,11.4c0,6.3,3.1,11.4,6.9,11.4h2.4c3.8,0,6.9-5.1,6.9-11.4
				C524.5,200.2,521.4,195.1,517.6,195.1z"
            />
            <ellipse class="st1" cx="517.6" cy="206.5" rx="6.9" ry="11.4" />
            <path
              class="st1"
              d="M520.5,197.3c0-0.8-0.5-1.6-1.3-1.9c-0.5-0.2-1-0.3-1.6-0.3c-0.3,0-0.6,0-0.9,0.1c-0.9,0.2-1.6,1-1.6,2v4.8
				c0,1.1-0.9,2-2,2h-0.4c-1.1,0-2,0.9-2,1.9c0,0.2,0,0.4,0,0.6c0,0.3,0,0.5,0,0.8c0,1.1,0.9,1.9,2,1.9h0.4c1.1,0,2,0.9,2,2v4.6
				c0,0.9,0.6,1.8,1.6,2c0.3,0.1,0.6,0.1,0.9,0.1c0.5,0,1.1-0.1,1.6-0.3c0.8-0.3,1.3-1,1.3-1.9v-4.5c0-1.1,0.9-2,2-2l0,0
				c1.1,0,2-0.9,2-1.9c0-0.3,0-0.5,0-0.8c0-0.2,0-0.4,0-0.6c0-1.1-0.9-1.9-2-1.9h0c-1.1,0-2-0.9-2-2V197.3z"
            />
            <ellipse class="st1" cx="517.6" cy="206.5" rx="1.9" ry="3.2" />
          </g>
        </g>
      </g>
      <g id="KK-3-6" class="hover-obj keid kejd" @click="$store.commit('setModal', '3.6')">
        <g id="_x33_-6_x5F_radiator_x5F_4">
          <g id="_x33_.6_x5F_radiator_x5F_1_2_">
            <path
              class="st0"
              d="M732,260.9c-0.1-0.6-0.6-1.1-1.1-1l-1.9,0.6c-0.5,0.1-0.8,0.7-0.7,1.3c0.1,0.6,0.6,1.1,1.1,1c0,0,0,0,0,0
				l1.9-0.6C731.8,262.1,732.1,261.5,732,260.9z"
            />

            <ellipse
              transform="matrix(0.9841 -0.1775 0.1775 0.9841 -34.8617 133.5748)"
              class="st0"
              cx="729.2"
              cy="261.7"
              rx="0.9"
              ry="1.2"
            />
            <g>
              <path
                class="st0"
                d="M733.1,272.8c-0.1,0-0.3-0.1-0.4-0.1c-0.5,0-0.9,0.1-0.9,0.3c0,0.2,0.4,0.4,0.9,0.5c0.2,0,0.3,0,0.5,0
					L733.1,272.8z"
              />
              <path
                class="st0"
                d="M733.1,272.8c-0.1,0-0.3-0.1-0.4-0.1c-0.5,0-0.9,0.1-0.9,0.3l0.1,1.6c0,0.2,0.4,0.4,0.9,0.5
					c0.1,0,0.3,0,0.4,0L733.1,272.8z"
              />
            </g>
            <polygon
              class="st0"
              points="733.3,258.2 734.3,257.5 763.4,259.8 763.3,277.8 762.3,278.5 762.4,260.5 			"
            />
            <polygon class="st0" points="761,278.4 761,282.2 761.7,282.2 761.6,278.5 			" />
            <polygon class="st0" points="762.4,260.5 762.3,278.5 733,275.8 733.3,258.2 			" />
            <line class="st0" x1="760.6" y1="262.2" x2="760.6" y2="276.7" />
            <line class="st0" x1="735.1" y1="260.2" x2="734.9" y2="274.2" />
            <line class="st0" x1="736.7" y1="260.3" x2="736.5" y2="274.3" />
            <line class="st0" x1="738.4" y1="260.4" x2="738.3" y2="274.5" />
            <line class="st0" x1="739.8" y1="260.5" x2="739.8" y2="274.7" />
            <line class="st0" x1="741.4" y1="260.6" x2="741.4" y2="274.8" />
            <line class="st0" x1="743" y1="260.8" x2="743" y2="275" />
            <line class="st0" x1="744.5" y1="260.9" x2="744.5" y2="275.1" />
            <line class="st0" x1="746.2" y1="261" x2="746.2" y2="275.3" />
            <line class="st0" x1="747.8" y1="261.2" x2="747.8" y2="275.4" />
            <line class="st0" x1="749.4" y1="261.3" x2="749.4" y2="275.6" />
            <line class="st0" x1="751" y1="261.4" x2="750.9" y2="275.7" />
            <line class="st0" x1="752.6" y1="261.5" x2="752.5" y2="275.9" />
            <line class="st0" x1="754.3" y1="261.7" x2="754.3" y2="276.1" />
            <line class="st0" x1="755.9" y1="261.8" x2="755.9" y2="276.2" />
            <line class="st0" x1="757.4" y1="261.9" x2="757.4" y2="276.4" />
            <line class="st0" x1="759" y1="262.1" x2="759" y2="276.5" />
            <line class="st0" x1="762.4" y1="260.5" x2="763.4" y2="259.8" />
            <path
              class="st0"
              d="M732.6,275.1c-0.1,0-0.2,0-0.3-0.1l0,4.4l0.7,0l0-4.4C732.9,275.1,732.8,275.1,732.6,275.1z"
            />
          </g>
          <path
            class="st0"
            d="M733.3,260.3C733.3,260.3,733.3,260.3,733.3,260.3l-0.9,0c0,0-0.1,0-0.1,0l-0.4,0.1c0.1,0.1,0.1,0.3,0.2,0.4
			c0,0.1,0,0.1,0,0.2l0.4-0.1h0.8c0,0,0,0,0,0L733.3,260.3z"
          />
          <path
            class="st0"
            d="M733,279l0,0.4l-0.7,0l0-0.4c-0.6,0-1.1,0.2-1.1,0.4c0,0.3,0.6,0.5,1.3,0.6c0.8,0,1.4-0.1,1.4-0.4
			C734,279.3,733.6,279.1,733,279z"
          />
          <path
            class="st0"
            d="M761.7,281.8l0,0.4l-0.6,0l0-0.4c-0.6,0-1,0.2-1.1,0.4c0,0.3,0.6,0.5,1.3,0.6c0.8,0,1.4-0.1,1.4-0.4
			C762.7,282.1,762.3,281.8,761.7,281.8z"
          />
        </g>
        <g id="_x33_-6_x5F_radiator_x5F_3">
          <path
            class="st0"
            d="M760.1,365.8l0,0.4l-0.6,0l0-0.4c-0.6,0-1,0.1-1.1,0.3c0,0.3,0.5,0.6,1.3,0.6c0.7,0.1,1.4-0.1,1.4-0.3
			C761.2,366.2,760.7,365.9,760.1,365.8z"
          />
          <g id="_x33_.6_x5F_radiator_x5F_1_1_">
            <path
              class="st0"
              d="M730.9,342l-1.9,0.5c-0.5,0.1-0.9,0.6-0.8,1.3s0.5,1.1,1.1,1.1c0,0,0,0,0,0l1.9-0.5c0.5-0.1,0.9-0.6,0.8-1.3
				C731.9,342.5,731.4,342,730.9,342z"
            />

            <ellipse
              transform="matrix(0.9935 -0.1134 0.1134 0.9935 -34.2732 84.9092)"
              class="st0"
              cx="729.1"
              cy="343.7"
              rx="0.9"
              ry="1.2"
            />
            <path
              class="st0"
              d="M731.6,357.3c-0.1,0-0.2,0-0.3-0.1l-0.3,4.4l0.7,0.1l0.3-4.4C731.9,357.3,731.7,357.3,731.6,357.3z"
            />
            <polygon
              class="st0"
              points="733.4,340.5 734.5,339.8 763.3,344 762.1,362 761,362.7 762.2,344.7 			"
            />
            <polygon class="st0" points="759.7,362.4 759.5,366.2 760.1,366.2 760.3,362.5 			" />
            <polygon class="st0" points="762.2,344.7 761,362.7 732,358 733.4,340.5 			" />
            <line class="st0" x1="760.4" y1="346.2" x2="759.4" y2="360.7" />
            <line class="st0" x1="735.1" y1="342.6" x2="734" y2="356.5" />
            <line class="st0" x1="736.7" y1="342.8" x2="735.6" y2="356.8" />
            <line class="st0" x1="738.3" y1="343" x2="737.3" y2="357.1" />
            <line class="st0" x1="739.7" y1="343.2" x2="738.8" y2="357.3" />
            <line class="st0" x1="741.3" y1="343.4" x2="740.3" y2="357.6" />
            <line class="st0" x1="742.9" y1="343.7" x2="741.9" y2="357.8" />
            <line class="st0" x1="744.4" y1="343.9" x2="743.5" y2="358.1" />
            <line class="st0" x1="746" y1="344.1" x2="745.1" y2="358.3" />
            <line class="st0" x1="747.7" y1="344.4" x2="746.7" y2="358.6" />
            <line class="st0" x1="749.3" y1="344.6" x2="748.3" y2="358.9" />
            <line class="st0" x1="750.8" y1="344.8" x2="749.9" y2="359.1" />
            <line class="st0" x1="752.4" y1="345.1" x2="751.4" y2="359.4" />
            <line class="st0" x1="754.1" y1="345.3" x2="753.1" y2="359.7" />
            <line class="st0" x1="755.7" y1="345.5" x2="754.7" y2="359.9" />
            <line class="st0" x1="757.2" y1="345.8" x2="756.3" y2="360.2" />
            <line class="st0" x1="758.8" y1="346" x2="757.8" y2="360.4" />
            <line class="st0" x1="762.2" y1="344.7" x2="763.3" y2="344" />
            <path
              class="st0"
              d="M732.2,355.7l0.1-0.7c-0.1-0.1-0.3-0.1-0.4-0.1c-0.5-0.1-0.9,0-1,0.3c0,0.2,0.3,0.5,0.8,0.5
				C731.8,355.7,732,355.7,732.2,355.7z"
            />
            <path
              class="st0"
              d="M732.2,355c-0.1-0.1-0.3-0.1-0.4-0.1c-0.5-0.1-0.9,0-1,0.3h0l0,1.6c0,0.2,0.3,0.5,0.8,0.5c0.1,0,0.3,0,0.4,0
				L732.2,355z"
            />
          </g>
          <path
            class="st0"
            d="M733.2,342.6l-0.9,0c0,0-0.1,0-0.1,0l-0.4,0.1c0.1,0.1,0.1,0.3,0.1,0.4c0,0.1,0,0.1,0,0.2l0.4-0.1l0.8,0.1
			C733.2,342.9,733.2,342.9,733.2,342.6z"
          />
          <path
            class="st0"
            d="M731.7,361.2l0,0.4l-0.7-0.1l0-0.4c-0.6,0-1.1,0.1-1.1,0.3c0,0.3,0.5,0.6,1.3,0.6c0.7,0.1,1.4-0.1,1.4-0.3
			C732.7,361.6,732.3,361.4,731.7,361.2z"
          />
        </g>
        <g id="_x33_-6_x5F_radiator_x5F_2">
          <g id="_x33_.6_x5F_radiator_x5F_1_4_">
            <path
              class="st0"
              d="M325.9,246.3l-1.9-0.6c-0.5-0.1-1,0.3-1.1,1c-0.1,0.6,0.2,1.2,0.7,1.3l1.9,0.6c0,0,0,0,0,0
				c0.5,0.1,1-0.3,1.1-1C326.7,247,326.4,246.4,325.9,246.3z"
            />

            <ellipse
              transform="matrix(0.1775 -0.9841 0.9841 0.1775 24.3712 524.0156)"
              class="st0"
              cx="325.7"
              cy="247.4"
              rx="1.2"
              ry="0.9"
            />
            <path
              class="st0"
              d="M322.3,258.4c-0.2,0-0.3,0.1-0.4,0.1l0,0.7c0.1,0,0.3,0,0.5,0c0.5,0,0.9-0.3,0.9-0.5
				C323.2,258.5,322.8,258.4,322.3,258.4z"
            />
            <path
              class="st0"
              d="M322.3,258.4c-0.2,0-0.3,0.1-0.4,0.1l0,2.3c0.1,0,0.3,0,0.4,0c0.5,0,0.9-0.3,0.9-0.5l0.1-1.6
				C323.2,258.5,322.8,258.4,322.3,258.4z"
            />
            <polyline
              class="st0"
              points="299.2,245.8 299.1,245 320.6,243.2 321.6,243.9 299.2,245.8 			"
            />
            <polygon class="st0" points="299.2,245.8 321.6,243.9 321.9,261.5 299.7,263.6 			" />
            <line class="st0" x1="319.8" y1="245.9" x2="320" y2="259.9" />
            <line class="st0" x1="318.2" y1="246.1" x2="318.4" y2="260.1" />
            <line class="st0" x1="316.5" y1="246.1" x2="316.7" y2="260.3" />
            <line class="st0" x1="315.1" y1="246.3" x2="315.1" y2="260.4" />
            <line class="st0" x1="313.5" y1="246.4" x2="313.5" y2="260.6" />
            <line class="st0" x1="311.9" y1="246.5" x2="311.9" y2="260.7" />
            <line class="st0" x1="310.4" y1="246.6" x2="310.4" y2="260.9" />
            <line class="st0" x1="308.6" y1="246.8" x2="308.7" y2="261" />
            <line class="st0" x1="307" y1="246.9" x2="307.1" y2="261.2" />
            <line class="st0" x1="305.3" y1="247.1" x2="305.5" y2="261.4" />
            <line class="st0" x1="303.7" y1="247.2" x2="304" y2="261.5" />
            <line class="st0" x1="302" y1="247.3" x2="302.4" y2="261.7" />
            <line class="st0" x1="300.3" y1="247.4" x2="300.6" y2="261.8" />
            <path
              class="st0"
              d="M322.3,260.8c-0.1,0-0.2,0-0.3,0l0,4.4l0.7,0l0-4.4C322.5,260.8,322.4,260.8,322.3,260.8z"
            />
          </g>
          <path
            class="st0"
            d="M322.6,246.1c0,0-0.1,0-0.1,0h-0.9c0,0,0,0,0,0l0,0.6c0,0,0,0,0,0h0.8l0.4,0.1c0-0.1,0-0.1,0-0.2
			c0-0.2,0.1-0.3,0.2-0.4L322.6,246.1z"
          />
          <path
            class="st0"
            d="M322.6,264.8l0,0.4l-0.7,0l0-0.4c-0.6,0.1-1,0.3-1,0.5c0,0.3,0.6,0.5,1.4,0.4s1.4-0.3,1.3-0.6
			C323.6,264.9,323.2,264.8,322.6,264.8z"
          />
        </g>
        <g id="_x33_-6_x5F_radiator_x5F_1">
          <g id="_x33_.6_x5F_radiator_x5F_1_5_">
            <path
              class="st0"
              d="M327.1,318l-1.9-0.6c-0.5-0.1-1,0.3-1.1,1c-0.1,0.6,0.2,1.2,0.7,1.3l1.9,0.6c0.5,0.1,1-0.3,1.1-1
				C328,318.7,327.6,318.1,327.1,318z"
            />

            <ellipse
              transform="matrix(0.1775 -0.9841 0.9841 0.1775 -45.1783 584.2576)"
              class="st0"
              cx="326.9"
              cy="319.2"
              rx="1.2"
              ry="0.9"
            />
            <path
              class="st0"
              d="M323.5,330.2c-0.2,0-0.3,0.1-0.4,0.1l0,0.7c0.1,0,0.3,0,0.5,0c0.5,0,0.9-0.3,0.9-0.5S324,330.1,323.5,330.2z
				"
            />
            <path
              class="st0"
              d="M323.5,330.2c-0.2,0-0.3,0.1-0.4,0.1l0.1,2.3c0.1,0,0.2,0,0.4,0c0.5,0,0.9-0.3,0.9-0.5l0.1-1.6
				C324.4,330.3,324,330.1,323.5,330.2z"
            />
            <polyline
              class="st0"
              points="301.3,318.8 301.3,318.1 321.8,315 322.8,315.7 301.3,318.8 			"
            />
            <polygon class="st0" points="301.3,318.8 322.8,315.7 323.1,333.3 301.8,336.7 			" />
            <line class="st0" x1="321" y1="317.5" x2="321.2" y2="331.9" />
            <line class="st0" x1="319.4" y1="317.7" x2="319.6" y2="332.1" />
            <line class="st0" x1="317.7" y1="318" x2="317.9" y2="332.4" />
            <line class="st0" x1="315.9" y1="318.3" x2="316.2" y2="332.7" />
            <line class="st0" x1="314.2" y1="318.5" x2="314.5" y2="333" />
            <line class="st0" x1="312.4" y1="318.8" x2="312.8" y2="333.3" />
            <line class="st0" x1="310.7" y1="319" x2="311.1" y2="333.5" />
            <line class="st0" x1="309" y1="319.3" x2="309.4" y2="333.8" />
            <line class="st0" x1="307.2" y1="319.5" x2="307.7" y2="334.1" />
            <line class="st0" x1="305.5" y1="319.8" x2="305.9" y2="334.3" />
            <line class="st0" x1="303.8" y1="320" x2="304.2" y2="334.6" />
            <line class="st0" x1="302.3" y1="320.2" x2="302.7" y2="334.8" />
            <path
              class="st0"
              d="M323.5,332.6c-0.1,0-0.2,0-0.3,0l0,4.4l0.7,0l0-4.4C323.8,332.5,323.6,332.6,323.5,332.6z"
            />
          </g>
          <path
            class="st0"
            d="M323.9,317.8c0,0-0.1,0-0.1,0h-0.9c0,0,0,0,0,0l0,0.6c0,0,0,0,0,0h0.8l0.4,0.1c0-0.1,0-0.1,0-0.2
			c0-0.2,0.1-0.3,0.2-0.4L323.9,317.8z"
          />
          <path
            class="st0"
            d="M323.8,336.5l0,0.4l-0.7,0l0-0.4c-0.6,0.1-1,0.3-1,0.5c0,0.3,0.6,0.5,1.4,0.4s1.4-0.3,1.3-0.6
			C324.9,336.7,324.4,336.5,323.8,336.5z"
          />
        </g>
        <g id="_x33_-6_x5F_varmesystem">
          <path
            class="st0"
            d="M357.4,391.3l-0.1-5.9c-0.5,0.1-1.6,0.3-2.6,0.5l0.7,27.7c0,0.3,0.6,0.5,1.3,0.5c0.7,0,1.3-0.2,1.3-0.5
			l-0.3-14.4"
          />
          <line class="st0" x1="357.5" y1="397.3" x2="357.5" y2="393.3" />
          <path
            class="st0"
            d="M358.3,397.5l-2-0.5v2l23.7,6.7l0,0c0.1,0,0.2,0,0.3,0c0.6,0,1-0.5,1-1.1c0-0.5-0.3-0.9-0.7-1.1l0,0l-1.7-0.5
			"
          />
          <line class="st0" x1="364.5" y1="399.1" x2="359.7" y2="397.9" />
          <line class="st0" x1="370.8" y1="400.8" x2="366" y2="399.6" />
          <line class="st0" x1="377.2" y1="402.6" x2="372.4" y2="401.3" />
          <ellipse class="st0" cx="380.3" cy="404.5" rx="1" ry="1.1" />
          <path
            class="st0"
            d="M378.8,399.3v3.8c0,0.4-0.3,0.8-0.8,0.8H378c-0.4,0-0.8-0.3-0.8-0.8v-4.3"
          />
          <path
            class="st0"
            d="M372.4,397.5v3.9c0,0.4-0.3,0.8-0.8,0.8h-0.1c-0.4,0-0.8-0.3-0.8-0.8V397"
          />
          <path
            class="st0"
            d="M366,395.7v4c0,0.4-0.3,0.7-0.7,0.7h-0.1c-0.4,0-0.7-0.3-0.7-0.7v-4.5"
          />
          <path
            class="st0"
            d="M359.7,393.9v4c0,0.4-0.3,0.7-0.7,0.7H359c-0.4,0-0.7-0.3-0.7-0.7v-4.4"
          />
          <line class="st0" x1="361.4" y1="392.4" x2="359.7" y2="391.9" />
          <line class="st0" x1="364.5" y1="393.2" x2="362.9" y2="392.7" />
          <line class="st0" x1="367.6" y1="394" x2="366" y2="393.6" />
          <line class="st0" x1="370.8" y1="394.9" x2="369.3" y2="394.5" />
          <line class="st0" x1="374" y1="395.7" x2="372.4" y2="395.3" />
          <line class="st0" x1="377.2" y1="396.6" x2="375.6" y2="396.2" />
          <path
            class="st0"
            d="M358.3,391.5l-2-0.5v2l23.7,6.7l0,0c0.1,0,0.2,0,0.3,0c0.6,0,1-0.5,1-1.1c0-0.5-0.3-0.9-0.7-1.1l0,0l-1.7-0.5
			"
          />
          <path
            class="st0"
            d="M374.9,397.3h-0.1c-0.4,0-0.8-0.3-0.8-0.8v-14.4l1.7-0.3v14.8C375.6,396.9,375.3,397.3,374.9,397.3z"
          />
          <path
            class="st0"
            d="M368.5,395.5h-0.1c-0.4,0-0.8-0.3-0.8-0.8v-11.4l1.7-0.3v11.8C369.3,395.2,369,395.5,368.5,395.5z"
          />
          <path
            class="st0"
            d="M362.2,393.6h-0.1c-0.4,0-0.7-0.3-0.7-0.7v-8.4l1.5-0.3v8.7C362.9,393.3,362.6,393.6,362.2,393.6z"
          />
          <ellipse class="st0" cx="380.3" cy="398.6" rx="1" ry="1.1" />
          <path
            class="st0"
            d="M365.3,394.6h-0.1c-0.4,0-0.7-0.3-0.7-0.7V384l1.6-0.3v10.2C366,394.3,365.7,394.6,365.3,394.6z"
          />
          <path
            class="st0"
            d="M371.6,396.4h-0.1c-0.4,0-0.8-0.3-0.8-0.8v-13l1.7-0.3v13.3C372.4,396.1,372.1,396.4,371.6,396.4z"
          />
          <path
            class="st0"
            d="M359.1,392.9H359c-0.4,0-0.7-0.3-0.7-0.7v-7.1l1.5-0.3v7.4C359.7,392.6,359.4,392.9,359.1,392.9z"
          />
          <path
            class="st0"
            d="M378.1,397.9H378c-0.4,0-0.8-0.3-0.8-0.8v-15.6l1.7-0.3v16C378.8,397.5,378.5,397.9,378.1,397.9z"
          />
        </g>
      </g>
      <g id="KK-2-4" class="hover-obj lejer" @click="$store.commit('setModal', '2.4')">
        <g id="_x32_-4_x5F_lampe_x5F_3">
          <path
            class="st0"
            d="M802.1,230.8c-0.2,0.3-1.1,0.5-2.2,0.5c-1.1,0-2-0.2-2.2-0.5c-4.5,1-3.9,5.3-3.9,5.3c0,0.9,2.7,1.6,6.1,1.6
			s6.1-0.7,6.1-1.6C806.1,236.1,806.5,231.9,802.1,230.8z"
          />
          <path
            class="st0"
            d="M802.1,230.1c0-0.3-1-0.6-2.2-0.6c-1.2,0-2.2,0.3-2.2,0.6v0.6c0,0.3,1,0.6,2.2,0.6c1.2,0,2.2-0.3,2.2-0.6
			L802.1,230.1C802.1,230.1,802.1,230.1,802.1,230.1z"
          />
          <ellipse class="st0" cx="799.9" cy="230.1" rx="2.2" ry="0.6" />
          <line class="st0" x1="799.9" y1="230.1" x2="800.5" y2="207.5" />
        </g>
        <g id="_x32_-4_x5F_lampe_x5F_2">
          <path
            class="st0"
            d="M812.7,224c0-0.3-0.9-0.5-2-0.5c-1.1,0-2,0.2-2,0.5h0v0.6c0,0.3,0.9,0.5,2,0.5s2-0.2,2-0.5L812.7,224
			C812.7,224,812.7,224,812.7,224z"
          />
          <path
            class="st0"
            d="M812.6,224.7c-0.2,0.2-1,0.4-2,0.4c-1,0-1.9-0.2-2-0.5c-4.2,0.9-3.7,4.9-3.7,4.9c0,0.8,2.5,1.5,5.7,1.5
			c0.7,0,1.4,0,2-0.1l0.2-6.2C812.8,224.7,812.7,224.7,812.6,224.7z"
          />
          <ellipse class="st0" cx="810.6" cy="224" rx="2" ry="0.5" />
          <line class="st0" x1="810.6" y1="224" x2="811" y2="207.7" />
        </g>
        <g id="_x32_-4_x5F_lampe_x5F_1">
          <path
            class="st0"
            d="M793.1,341.4c0,0.5-1.6,0.9-3.5,0.9c-2,0-3.5-0.4-3.5-0.9v0c-9.3,1.7-8.2,10.3-8.2,10.3
			c0,1.7,5.2,3.1,11.7,3.1s11.7-1.4,11.7-3.1C801.2,351.6,802.1,343.2,793.1,341.4z"
          />
          <path
            class="st0"
            d="M789.5,339.5c-2,0-3.5,0.4-3.5,0.9v1c0,0.5,1.6,0.9,3.5,0.9s3.5-0.4,3.5-0.9v-1
			C793.1,339.9,791.5,339.5,789.5,339.5z"
          />
          <ellipse class="st0" cx="789.5" cy="340.4" rx="3.5" ry="0.9" />
          <line class="st0" x1="789.4" y1="340.3" x2="789.9" y2="319.5" />
        </g>
      </g>
      <g id="KK-2-3" class="hover-obj keid kejd" @click="$store.commit('setModal', '2.3')">
        <g id="_x32_-3_x5F_indvendige-fastmonterede-belysningsanlaeg_x5F_2">
          <g>
            <circle class="st0" cx="683.2" cy="210.7" r="15.9" />
          </g>
          <g>
            <path
              class="st1"
              d="M695.3,209.2c0-1.4-5.4-2.5-12.1-2.5c-6.7,0-12.1,1.1-12.1,2.5v3.1h4.5c2.1,0.4,4.7,0.6,7.6,0.6
				c2.9,0,5.5-0.2,7.6-0.6h4.5V209.2z"
            />
            <ellipse class="st1" cx="683.2" cy="212.3" rx="12.1" ry="2.5" />
            <ellipse class="st1" cx="683.2" cy="212.3" rx="10.4" ry="1.7" />
          </g>
        </g>
        <g id="_x32_-3_x5F_indvendige-fastmonterede-belysningsanlaeg_x5F_1">
          <g>
            <circle class="st0" cx="552.6" cy="297.7" r="15.9" />
          </g>
          <g>
            <path
              class="st1"
              d="M552.6,292.4c-3,0-5.5,0.4-5.5,0.9v2.9h11v-2.9C558.1,292.8,555.6,292.4,552.6,292.4z"
            />
            <path
              class="st1"
              d="M564.7,297.3c0-1.4-5.4-2.5-12.1-2.5c-6.7,0-12.1,1.1-12.1,2.5v3.1h4.5c2.1,0.4,4.7,0.6,7.6,0.6
				c2.9,0,5.5-0.2,7.6-0.6h4.5V297.3z"
            />
            <ellipse class="st1" cx="552.6" cy="300.4" rx="12.1" ry="2.5" />
            <ellipse class="st1" cx="552.6" cy="300.4" rx="10.4" ry="1.7" />
          </g>
        </g>
      </g>
      <g id="KK-3-10" class="hover-obj keid kejd" @click="$store.commit('setModal', '3.10')">
        <g id="_x33_.9_x5F_el-installation_x5F_1_10_">
          <circle class="st0" cx="358.6" cy="219.4" r="15.9" />
        </g>
        <line class="st0" x1="350.5" y1="220.8" x2="354" y2="220.9" />
        <line class="st0" x1="350.8" y1="219.6" x2="354.2" y2="219.7" />
        <line class="st0" x1="350.5" y1="218.5" x2="354" y2="218.6" />
        <path
          class="st0"
          d="M353,217.7l-3.5-0.1c0.7,0,1.3,0.9,1.3,2.1s-0.6,2.1-1.3,2.1l3.5,0.1c0.7,0,1.3-0.9,1.3-2.1
		S353.7,217.7,353,217.7z"
        />
        <polygon class="st0" points="359.5,214.4 359.5,225.6 366.3,226.1 366.3,214.7 	" />
        <path
          class="st0"
          d="M363.8,218.5l-2.1-0.1c-0.6,0-1.1,0.8-1.1,1.8c0,1,0.5,1.8,1.1,1.8l2.1,0.1c0.6,0,1.1-0.8,1.1-1.8
		C364.8,219.3,364.4,218.5,363.8,218.5z"
        />
        <ellipse class="st0" cx="361.6" cy="220.2" rx="1.1" ry="1.8" />
        <path
          class="st0"
          d="M349.4,217.6c-0.5,0-1,0.5-1.2,1.2h1.2c0.3,0,0.5,0.4,0.5,0.9s-0.2,0.9-0.5,0.9h-1.2c0.2,0.7,0.6,1.2,1.2,1.2
		c0.7,0,1.3-0.9,1.3-2.1S350.1,217.6,349.4,217.6z"
        />
        <path
          class="st0"
          d="M349.4,218.8h-6.7c0,0.2,0,0.4,0,0.6c0,0.4,0,0.7,0,1.1h6.7c0.3,0,0.5-0.4,0.5-0.9S349.7,218.8,349.4,218.8z"
        />
        <path
          class="st0"
          d="M351.7,214v3.6l1.3,0c0.7,0,1.3,0.9,1.3,2.1s-0.6,2.1-1.3,2.1l-1.3,0v3.3l6.7,0.5v-11.1L351.7,214z"
        />
        <path
          class="st0"
          d="M355.3,218.1l-1.6-0.1c0.4,0.4,0.6,1,0.6,1.7c0,0.8-0.3,1.4-0.6,1.8l1.7,0.1c0.6,0,1.1-0.8,1.1-1.8
		C356.3,218.9,355.9,218.1,355.3,218.1z"
        />
      </g>
      <g id="KK-3-5_1_" class="hover-obj keid kejd" @click="$store.commit('setModal', '3.5')">
        <g id="_x33_-5_x5F_ventilationsrist_x5F_2">
          <g>
            <polygon class="st1" points="445.8,284.1 445.8,292.3 432,289.8 432,281.6 			" />
          </g>
          <g>
            <polygon class="st1" points="444.3,284.7 444.3,291.1 433.5,289.2 433.5,282.8 			" />
          </g>
          <line class="st1" x1="433.5" y1="282.8" x2="433.5" y2="289.2" />
          <line class="st1" x1="434.8" y1="283" x2="434.8" y2="289.4" />
          <line class="st1" x1="436.2" y1="283.2" x2="436.2" y2="289.7" />
          <line class="st1" x1="437.6" y1="283.5" x2="437.6" y2="289.9" />
          <line class="st1" x1="438.9" y1="283.7" x2="438.9" y2="290.1" />
          <line class="st1" x1="440.3" y1="284" x2="440.3" y2="290.4" />
          <line class="st1" x1="441.7" y1="284.2" x2="441.7" y2="290.6" />
          <line class="st1" x1="443" y1="284.5" x2="443" y2="290.9" />
        </g>
        <g id="_x33_-5_x5F_ventilationsrist_x5F_1">
          <g>
            <polygon class="st1" points="445.8,202.9 445.8,211.1 432,210.1 432,201.9 			" />
          </g>
          <g>
            <polygon class="st1" points="444.3,203.7 444.3,210 433.5,209.2 433.5,203 			" />
          </g>
          <line class="st1" x1="434.9" y1="203.1" x2="434.9" y2="209.3" />
          <line class="st1" x1="436.2" y1="203.2" x2="436.2" y2="209.4" />
          <line class="st1" x1="437.6" y1="203.3" x2="437.6" y2="209.5" />
          <line class="st1" x1="439" y1="203.4" x2="439" y2="209.6" />
          <line class="st1" x1="440.3" y1="203.4" x2="440.3" y2="209.7" />
          <line class="st1" x1="441.7" y1="203.5" x2="441.7" y2="209.8" />
          <line class="st1" x1="443.1" y1="203.6" x2="443.1" y2="209.9" />
        </g>
        <g id="_x33_-5_x5F_ventilationsanlaeg_x5F_1">
          <path
            class="st1"
            d="M411.6,185.3v-0.7c0-1-0.8-1.8-1.8-1.8l-131.6,1.8l-3.4,2.5l74.3-0.9c0.2,0,0.4,0.2,0.4,0.4l0.2,8
			c0,0.3,0.6,0.5,1.3,0.5c0.7,0,1.3-0.2,1.3-0.5c0,0,0,0,0,0l-0.2-8.1c0-0.2,0.2-0.4,0.4-0.4l55.9-0.8c0.4,0,0.7,0.3,0.7,0.7v6.1
			c0,0.3,0.6,0.5,1.3,0.5c0.7,0,1.3-0.2,1.3-0.5c0,0,0,0,0,0L411.6,185.3L411.6,185.3z"
          />
          <path class="st1" d="M408.8,185.5c0,0,1.7-0.5,2.2-2.2" />
          <path class="st1" d="M409.1,186.3c0,0,1.5,0.6,2.5-0.4" />
          <path class="st1" d="M408.2,185.3c0,0,0.9-1.1,0.2-2.5" />
        </g>
        <g id="_x33_-5_x5F_punktsug">
          <g>
            <path
              class="st1"
              d="M411.8,208.5h-3.1v0.8c0,0.6-0.4,1.1-1,1.2c-0.2,0-0.5,0.1-0.8,0.2c0.2,1.1,0.5,2.4,0.7,3.3
				c1.5-0.3,2.6-0.6,2.6-0.6c0.7-0.2,1.2-0.5,1.5-1.1c0.1-0.2,0.1-0.3,0.1-0.5c0,0,0,0,0,0V208.5z"
            />
            <path
              class="st1"
              d="M399,212.3c-0.8,0.2-1.2,0.5-1.4,1.1c0,0.1-0.1,0.3-0.1,0.4c0,0,0,0,0,0v3.6l3-0.1v-1.2
				c0-0.5,0.3-0.9,0.8-0.9c0.4-0.1,0.9-0.2,1.5-0.3l-0.7-3.2C400.4,212,399.1,212.3,399,212.3z"
            />
            <circle class="st1" cx="399.4" cy="214.2" r="0.4" />
            <circle class="st1" cx="410" cy="211.6" r="0.4" />
          </g>
          <rect x="397.5" y="224.2" class="st1" width="3.2" height="0.7" />
          <polygon class="st1" points="397.5,225 392.1,229.8 402,230.6 400.8,225 		" />
          <polygon class="st1" points="400.8,225 405.5,228.6 402,230.6 		" />
          <g>
            <path
              class="st1"
              d="M407.1,211c-1.4,0.3-3.2,0.7-4.8,1l0.6,2.7c1.4-0.3,3.3-0.7,4.8-1C407.5,213,407.2,212,407.1,211z"
            />
            <polygon class="st1" points="411.6,208.5 411.6,198.6 409.1,198.6 409.1,208.5 			" />
            <path class="st1" d="M397.9,217.4v6.9c1.1,0,1.5,0,2.5,0v-7L397.9,217.4z" />
          </g>
        </g>
        <g id="_x33_-5_x5F_emhaette">
          <g>
            <g>
              <polygon class="st1" points="623.9,319.2 623.8,322.7 643,317 643,313.5 				" />
              <polygon
                class="st1"
                points="623.8,319.2 607.4,315.9 607.3,319.3 623.8,322.7 623.9,319.2 				"
              />
            </g>
            <path
              class="st1"
              d="M628.9,310.6l0,2.9c0,1.1-2.1,2-4.6,2c-2.6,0-4.6-1-4.6-2.1l0-1.3l-12.2,3.6l16.5,3.4l19.2-5.7L628.9,310.6z
				"
            />
          </g>
          <g>
            <path
              class="st1"
              d="M628.9,299.7l0,13.9c0,1.1-2.1,2-4.6,2c-2.6,0-4.6-1-4.6-2.1l0.1-14.9L628.9,299.7z"
            />
          </g>
        </g>
        <g id="_x33_-5_x5F_ventilationsgitter">
          <path
            class="st1"
            d="M383.2,203.7h-1c-1.7,0-3,2.2-3,4.9s1.3,4.9,3,4.9h1c1.7,0,3-2.2,3-4.9S384.9,203.7,383.2,203.7z"
          />
          <ellipse class="st1" cx="382.3" cy="208.6" rx="3" ry="4.9" />
          <ellipse class="st1" cx="382.3" cy="208.6" rx="1.5" ry="2.5" />
        </g>
        <g id="_x33_-5_x5F_ventilationsanlaeg_x5F_2">
          <polygon class="st0" points="700.1,140.2 699.1,182.9 780,183.7 781.7,138 		" />
          <path
            class="st0"
            d="M703.8,183v14.2c0,0.4,0.3,0.8,0.8,0.8h2.1c0.4,0,0.8-0.3,0.8-0.8l0.1-14.2L703.8,183z"
          />
          <path
            class="st0"
            d="M722.4,183.1l-0.2,13.2c0,0.5,0.4,0.8,0.8,0.8l2,0c0.4,0,0.8-0.3,0.8-0.8l0.4-13.3H722.4z"
          />
          <path
            class="st0"
            d="M774.6,183.6l-0.3,15.2c0,0.4,0.3,0.8,0.8,0.8l2.7,0.1c0.4,0,0.8-0.3,0.8-0.8l0.4-15.3L774.6,183.6z"
          />
          <path
            class="st0"
            d="M792.2,183.7l-0.4,14.2c0,0.4,0.3,0.7,0.7,0.7l2.9,0.1c0.4,0,0.7-0.3,0.7-0.7l0.4-14.3H792.2z"
          />
          <path
            class="st0"
            d="M794.6,161.8c0-8.5,3.8-15.2,7.8-15.2H793c-4,0-7.8,6.8-7.8,15.2c0,8.5,2.8,15.2,6.8,15.2h9.4
			C797.4,177,794.6,170.2,794.6,161.8z"
          />
          <line class="st0" x1="740.4" y1="139.1" x2="739.2" y2="183.3" />
          <polygon class="st0" points="737.6,155.9 737.4,164.2 736.4,164.2 736.6,155.9 		" />
          <polygon class="st0" points="702.2,156.2 701.9,164.5 700.9,164.6 701.1,156.3 		" />
          <polygon class="st0" points="743.1,164 743.3,155.7 742.3,155.7 742.1,164 		" />
          <polygon class="st0" points="779.3,164.2 779.5,155.9 778.5,155.9 778.3,164.2 		" />
          <g>
            <path
              class="st0"
              d="M812,91.5h-68.2c-2.6,0-4.7-2.1-4.7-4.6L739,75.6l0,0c0,2.4-1,5.2-8.4,8.5c-9,3.9-11.1-0.6-11.1-2.6l0,5.8
				c0,12.1,9.7,22,21.8,22.3l57.3,1.2l9.5,0.4c2.4,0.1,4.3,2.1,4.3,4.5l0.1,34.2l-1.3,3.1l-1.3,1.1l-7.9,0.6c-1.9,0-3.6,3.2-3.6,7.2
				s1.3,7.2,3.2,7.2l6.5,0l6.9-1.5l2.5-70.2C817.6,94.1,815.1,91.5,812,91.5z"
            />
            <g>
              <path class="st0" d="M742.2,91.2c0,0-8.5,7.5-4.3,17.9" />
              <path class="st0" d="M755.3,91.5c0,0-5.1,10.2-0.1,18.2" />
              <path class="st0" d="M771.8,91.5c0,0-5.3,9.9,0,18.7" />
              <path class="st0" d="M787.3,91.5c0,0-5.6,8.8-0.1,18.9" />
              <path class="st0" d="M802.6,91.5c0,0-6.1,9.8-0.2,19.4" />
              <path class="st0" d="M808.6,111.1c0,0-3.5-12.4,6.8-18.4" />
              <path class="st0" d="M812.1,114.1c0,0,0.7-5.1,4.9-6.3" />
              <path class="st0" d="M811.2,152.9c0,0,0.9,4.6,4.1,5.2" />
              <path class="st0" d="M809.9,154c0,0-1.2,7.3,5.1,13.4" />
              <path class="st0" d="M807.9,154.2c0,0-4.1,7.1,0.1,14.8" />
            </g>
            <path class="st0" d="M739.1,87.6c0,0-14.5,0.9-17,10.3" />
            <path
              class="st0"
              d="M738.9,76.3c0,4.3-9.2,9.3-14.9,9.3l0,0c-1.2,0-4.5-0.8-4.6-4.1l0,0c-0.1-4.7,8.2-10.8,15.1-10.8h0.3
				c2.6,0,4.1,2.3,4,4.9l0,0.3"
            />
          </g>
          <path
            class="st0"
            d="M794.6,161.8c0-8.5,3.8-15.2,7.8-15.2l0,0l0.2-7.1l-20.9-1.4l-1.7,45.6h21.1l0.2-6.7
			C797.4,176.9,794.6,170.2,794.6,161.8z"
          />
          <path
            class="st0"
            d="M801.5,168.9c-1.9,0-3.2-3.2-3.2-7.2s1.8-7.2,3.6-7.2l6-0.4c-1-4.6-2.9-7.7-5.6-7.7c-4,0-7.8,6.8-7.8,15.2
			c0,8.5,2.8,15.2,6.8,15.2c2.8,0,5.2-3.3,6.4-8.1L801.5,168.9z"
          />
          <g id="_x33_-5_x5F_ventilationsanlaeg_x5F_2_1_">
            <path
              class="st0"
              d="M699.6,163.2l-73.6-0.7c-3.4,0-6.3,2.7-6.3,6.2l-0.1,25c0,1.1,2,2.1,4.6,2.1c2.6,0,4.6-0.9,4.6-2l0.1-20.8
				c0-0.7,0.6-1.2,1.2-1.2l69.2,0.8L699.6,163.2z"
            />
            <path class="st0" d="M631.1,171.9c0,0-3.8-4.3-0.6-9.3" />
            <path class="st0" d="M620.9,165.1c0,0,1.3,5.5,8.3,7.1" />
            <path class="st0" d="M628.9,173.9c0,0-4.8,1.8-9.2,1.1" />
          </g>
        </g>
      </g>
      <g id="KK-3-9" class="hover-obj keid kejd" @click="$store.commit('setModal', '3.9')">
        <g id="_x33_-9_x5F_eltavle">
          <g>
            <polygon class="st1" points="443.1,379.7 443.4,409.1 432.4,406.2 432.4,377 			" />
            <polygon
              class="st1"
              points="443.4,409.1 443.1,379.7 432.4,377 434.5,376.3 444.6,379 444.9,408.4 			"
            />
            <polyline class="st1" points="432.4,377 443.1,379.7 444.6,379 			" />
          </g>
          <line class="st1" x1="432.4" y1="404.3" x2="443.4" y2="407.2" />
          <line class="st1" x1="432.4" y1="398.2" x2="443.3" y2="401" />
          <line class="st1" x1="432.4" y1="396.4" x2="443.3" y2="399.3" />
          <line class="st1" x1="432.4" y1="379.3" x2="443.2" y2="382.1" />
          <line class="st1" x1="432.4" y1="387" x2="443.2" y2="389.8" />
          <line class="st1" x1="432.4" y1="388.8" x2="443.2" y2="391.6" />
          <polygon class="st1" points="433.6,399.7 433.6,403.3 436,404 436,400.4 		" />
          <polygon class="st1" points="433.6,390.7 433.6,395.1 435.8,395.7 435.8,391.3 		" />
          <polygon class="st1" points="436.7,391.5 436.7,395.9 438.9,396.5 438.9,392.1 		" />
          <polygon class="st1" points="439.8,392.3 439.8,396.7 442,397.3 442,393 		" />
          <polygon class="st1" points="437.2,400.7 437.2,404.3 442.2,405.6 442.2,402 		" />
          <polygon class="st1" points="433.6,381.7 433.6,385.2 436,385.9 436,382.4 		" />
          <polygon class="st1" points="437.2,382.6 437.2,386.2 442.2,387.5 442.2,384 		" />
        </g>
        <g id="_x33_-9_x5F_el-installation_x5F_1">
          <g id="_x33_-9_x5F_el-installation_x5F_3">
            <circle class="st1" cx="357.9" cy="310.9" r="15.9" />
          </g>
          <polygon class="st1" points="354,312.2 362.4,309.5 364,300.4 355.7,303.2 		" />
          <polygon class="st1" points="352.6,322.3 353.9,322.8 354.1,302.4 352.7,302 		" />
          <polygon class="st1" points="362.2,320 353.9,322.8 354,312.2 362.4,309.5 		" />
          <polygon class="st1" points="362.5,299.7 364,300.4 355.7,303.2 354.1,302.4 		" />
          <polygon class="st1" points="361.2,299.2 362.5,299.7 354.1,302.4 352.7,302 		" />
          <g>
            <g>
              <path
                class="st1"
                d="M359.7,315.1c0.2,0,0.4,0.2,0.4,0.5c0,0.2-0.1,0.6-0.4,0.7s-0.5-0.2-0.5-0.6
					C359.2,315.4,359.4,315.2,359.7,315.1z"
              />
            </g>
            <g>
              <path
                class="st1"
                d="M356.8,316c0.2,0,0.4,0.2,0.4,0.5c0,0.2-0.1,0.6-0.4,0.7c-0.3,0.1-0.5-0.2-0.5-0.6
					C356.3,316.4,356.4,316.1,356.8,316z"
              />
            </g>
          </g>
          <polygon class="st1" points="354.1,302.4 355.7,303.2 354,312.2 		" />
        </g>
        <g id="_x33_-9_x5F_el-installation_x5F_2">
          <g id="_x33_-9_x5F_el-installation_x5F_3_3_">
            <circle class="st1" cx="608.7" cy="336.6" r="15.9" />
          </g>
          <polygon class="st1" points="603.3,348 604.6,348.5 604.8,328.2 603.5,327.7 		" />
          <polygon class="st1" points="613,345.7 604.6,348.5 604.7,337.9 613.1,335.2 		" />
          <polygon class="st1" points="613.2,325.4 614.8,326.2 606.4,328.9 604.8,328.2 		" />
          <polygon class="st1" points="604.7,337.9 613.1,335.2 614.8,326.2 606.4,328.9 		" />
          <polygon class="st1" points="611.9,324.9 613.2,325.4 604.8,328.2 603.5,327.7 		" />
          <g>
            <g>
              <path
                class="st1"
                d="M610.4,340.8c0.2,0,0.4,0.2,0.4,0.5c0,0.2-0.1,0.6-0.4,0.7c-0.3,0.1-0.5-0.2-0.5-0.6
					C610,341.2,610.1,340.9,610.4,340.8z"
              />
            </g>
            <g>
              <path
                class="st1"
                d="M607.5,341.8c0.2,0,0.4,0.2,0.4,0.5c0,0.2-0.1,0.6-0.4,0.7c-0.3,0.1-0.5-0.2-0.5-0.6
					S607.2,341.8,607.5,341.8z"
              />
            </g>
          </g>
          <polygon class="st1" points="604.8,328.2 606.4,328.9 604.7,337.9 		" />
        </g>
        <g id="_x33_-9_x5F_el-installation_x5F_3_1_">
          <g id="_x33_-9_x5F_el-installation_x5F_3_4_">
            <circle class="st1" cx="664.1" cy="251" r="15.9" />
          </g>
          <polygon class="st1" points="658.8,262.3 660.1,262.8 660.3,242.5 658.9,242 		" />
          <polygon class="st1" points="668.4,260 660.1,262.8 660.2,252.2 668.5,249.5 		" />
          <polygon class="st1" points="668.6,239.7 670.2,240.5 661.9,243.2 660.3,242.5 		" />
          <polygon class="st1" points="660.2,252.2 668.5,249.5 670.2,240.5 661.9,243.2 		" />
          <polygon class="st1" points="667.3,239.3 668.6,239.7 660.3,242.5 658.9,242 		" />
          <g>
            <g>
              <path
                class="st1"
                d="M665.9,255.1c0.2,0,0.4,0.2,0.4,0.5c0,0.2-0.1,0.6-0.4,0.7c-0.3,0.1-0.5-0.2-0.5-0.6
					C665.4,255.5,665.6,255.2,665.9,255.1z"
              />
            </g>
            <g>
              <path
                class="st1"
                d="M663,256.1c0.2,0,0.4,0.2,0.4,0.5c0,0.2-0.1,0.6-0.4,0.7c-0.3,0.1-0.5-0.2-0.5-0.6
					C662.5,256.4,662.6,256.2,663,256.1z"
              />
            </g>
          </g>
        </g>
        <polygon class="st1" points="660.3,242.5 661.9,243.2 660.2,252.2 	" />
      </g>
      <g id="KK-2-2" class="hover-obj lejer" @click="$store.commit('setModal', '2.2')">
        <path
          id="_x32_-2_x5F_gulv_x5F_10"
          class="st4"
          d="M566.9,332.3l9.1,1.5v6.4l-6.5,1.2v6.4l-6.5,1.2v6.4l-3.2,0.6l0-15.5
		c0-0.8,0.3-1.5,0.8-2L566.9,332.3z"
        />
        <path
          id="_x32_-2_x5F_gulv_x5F_9"
          class="st4"
          d="M565,332l-5.2-0.9l-111,21.9l152.3,31.7l0.1-11.5l-44.8-10v-6.4l1.7-0.3v-15.7
		c0-1.2,0.5-2.3,1.3-3.1L565,332z"
        />
        <path
          id="_x32_-2_x5F_gulv_x5F_8"
          class="st4"
          d="M413.7,428.3v1.7c1,0.1,1.7,0.4,1.8,0.7v0.7c0,0.4-1.2,0.7-2.7,0.7
		c-1.5,0-2.7-0.3-2.7-0.7v-0.7c0-0.3,0.7-0.6,1.8-0.7v-2.3l-2.4-0.7c-0.8,0.2-1.6,0.4-2.4,0.6v4.6c1,0.1,1.7,0.4,1.8,0.7v0.7
		c0,0.4-1.2,0.7-2.7,0.7c-1.5,0-2.7-0.3-2.7-0.7v-0.7c0-0.3,0.7-0.6,1.8-0.7v-4.4c-1.1,0.1-2.6,0.2-4.4,0c-0.4,0.2-1.2,0.3-2.1,0.3
		c-1.4,0-2.5-0.3-2.5-0.7v-0.7c-1.3-0.4-2.3-0.9-3.2-1.5v2.2c0.9,0.1,1.5,0.3,1.5,0.6v0.7c0,0.4-1.1,0.7-2.5,0.7
		c-1.4,0-2.5-0.3-2.5-0.7v-0.7c0-0.3,0.6-0.5,1.5-0.6v-3.6c-1.1-1-1.7-1.9-2.1-2.5c-0.1-0.2-0.2-0.4-0.2-0.7l-30.6-9.1l0,2
		c0,0.3-0.6,0.5-1.3,0.5c-0.7,0-1.3-0.2-1.3-0.5l-0.1-2.8l-8-2.4l-43.2,9.5l1.8,60.6L449.7,439L413.7,428.3z"
        />
        <path
          id="_x32_-2_x5F_gulv_x5F_7"
          class="st4"
          d="M559.8,261.6l-111,12.3l94.1,11.2c2.7-2.1,6-3.3,9.7-3.3c5,0,9.5,2.3,12.4,5.9
		l37,4.4l0.2-26.4L559.8,261.6z"
        />
        <path
          id="_x32_-2_x5F_gulv_x5F_6"
          class="st4"
          d="M810.5,289.4l-0.9-0.1l-0.5,15.1L808,305l-1.2-0.3l0.5-15.7l-2.3-0.3l-4.4,1.3
		l-0.2,18l-1.1,0.7l-1.3-0.1l0.2-18.5l-7.7-1.3l-0.1,17.5l-1.3,0.6l-1.3-0.2l0.3-18.2l0,0l0-1.4v-0.3l-0.8-0.1l-0.4,23.9l-1.9,0.9h0
		l-2-0.3l0.4-25l0,0v-2.2h0l4.7-1.3l-26.4-2.4l0,1.4c0.6,0.1,1,0.3,1,0.5c0,0.3-0.6,0.5-1.4,0.4c-0.8,0-1.4-0.3-1.3-0.6
		c0-0.2,0.5-0.4,1.1-0.4l0-1.5l-28-2.6v1.3c0.6,0.1,1,0.3,0.9,0.5c0,0.3-0.6,0.5-1.4,0.4c-0.8,0-1.4-0.3-1.3-0.6
		c0-0.2,0.5-0.4,1.1-0.4l0-1.4l-18.7-1.7L606,292.6l203.5,24l1.2-31.9l0,0L810.5,289.4z"
        />
        <g id="_x32_-2_x5F_gulv_x5F_5">
          <path
            class="st4"
            d="M448.8,189.3l-37.2,0.7v2.1c0,0,0,0,0,0c0,0.3-0.6,0.5-1.3,0.5c-0.7,0-1.3-0.2-1.3-0.5V190l-51.1,0.9l0,1.7
			l-5.8,0.2l0,1.8c0,0,0,0,0,0c0,0.3-0.6,0.5-1.3,0.5c-0.7,0-1.3-0.2-1.3-0.5l0-1.7l-15.1,0.4l-16.3-0.6l0-1.1l-20.4,0.4v5.6
			l149.9-3.9C447.8,192.1,448.2,190.7,448.8,189.3z"
          />
          <path
            class="st4"
            d="M478.3,189.6c0.6,1.5,0.9,3.1,1.1,4.7l25.8,0.6c2.7-2.8,6.5-4.5,10.7-4.7L478.3,189.6z"
          />
        </g>
        <path
          id="_x32_-2_x5F_gulv_x5F_4"
          class="st4"
          d="M428.1,348.7l0,1c0,0.1-0.2,0.2-0.4,0.2c-0.2,0-0.4-0.1-0.4-0.2l0-1.2l-1.4-0.3
		l0,1c0,0.1-0.2,0.2-0.4,0.2c-0.2,0-0.4-0.1-0.4-0.2l0-1.2l-12.7-2.6v1.5c0.7,0.1,1.1,0.3,1.1,0.5c0,0.3-0.8,0.5-1.7,0.5
		c-0.9,0-1.7-0.2-1.7-0.5c0-0.2,0.5-0.4,1.2-0.5v-1.7l-4.4-0.9l0,1.7c0,0.1-0.2,0.2-0.5,0.2s-0.5-0.1-0.5-0.2l0-1.9l-1.8-0.4l0,1.8
		c0,0.1-0.2,0.2-0.5,0.2s-0.5-0.1-0.5-0.2l0-2l-13.9-2.9c0.2,0.2,0.2,0.5-0.2,0.8c-2.6,1.4-8.9,2.2-11,2.3s-5.9,0-5.6-0.6
		c0.5-1.6,1.8-1.7,1.8-1.7c0-0.1,0-0.3,0.1-0.5c0,0,0-0.1,0-0.1c0-0.2,0-0.4,0.1-0.6c0,0,0-0.1,0-0.1c0-0.2,0.1-0.5,0.1-0.7
		c0,0,0-0.1,0-0.1c0-0.3,0.1-0.6,0.1-0.8c0,0,0,0,0,0c0-0.2,0-0.5,0.1-0.7l-13.4-2.8l-23.2,4.1l-13.8-3.7l0.1,1.3
		c0.6,0,1.1,0.2,1.1,0.4c0,0.3-0.6,0.5-1.4,0.6c-0.8,0-1.3-0.1-1.3-0.4c0-0.2,0.4-0.4,1-0.5l0-1.5l-1.4-0.4l-20,3.4l1.2,42.6
		L448.8,353L428.1,348.7z M425.8,353l7.4-1.5c0,0,7,1.5,7,1.5l-7.6,1.4L425.8,353z"
        />
        <g id="_x32_-2_x5F_gulv_x5F_3">
          <path
            class="st4"
            d="M798.5,393.1l-0.1,5.8l-1.1,0.6h0l-1.2-0.3l0.1-5.5l-3.3,0.9l-1,24.5l-1.9,0.9h0l-2-0.4l1.1-25l-1.1-0.2
			l-0.3,7.5l-1.1,0.6l-1.2-0.3l0.3-8.2l-7.5-1.6l-0.1,7.2l-1.1,0.6h0l-1.2-0.3l0.1-8l-2.5-0.5l-0.2,14.5l-1.1,0.6h0l-1.2-0.3
			l0.2-14.5l-8.1,2.3l-0.2,15l-1.1,0.6h0l-1.2-0.3l0.2-15.2l-6.6-1.7l-0.3,18.3l-1.9,0.9h0l-2-0.4l0.4-24.9l0,0v-2.2l0.4-0.1l0-2.9
			l-0.4,0.1L749,381l0.1-6.9l13.8-3.6l0.7,0.3v6.8l-1.1,0.3v2.9l13.1-3.6l0-3l-0.4,0.1l-0.7-0.3l0.1-6.9l1.9-0.5l-16.3-2.7l-0.1,1.9
			c0.6,0.1,1,0.4,1,0.6c0,0.3-0.7,0.4-1.4,0.3c-0.7-0.1-1.3-0.4-1.3-0.6c0-0.2,0.5-0.4,1.1-0.3l0.1-1.9l-27.7-4.5l-0.1,1.9
			c0.6,0.1,1,0.4,1,0.6c0,0.3-0.7,0.4-1.4,0.3c-0.7-0.1-1.3-0.4-1.3-0.6c0-0.2,0.5-0.4,1.1-0.3l0.1-2l-19.4-3.2l-24.9,6.9l-0.3,11.5
			l-56,16.6l175,36.4l1.3-36.7L798.5,393.1z"
          />
          <polygon class="st4" points="787.1,374 787.1,371.1 776.7,373.9 776.7,376.9 		" />
          <polygon
            class="st4"
            points="807.4,376.1 807.6,371.6 789.1,368.6 789.1,370.5 788.1,370.8 788.1,373.8 791.7,372.8 		"
          />
          <polygon class="st4" points="757.9,388.1 753.7,387.2 753.6,389.2 		" />
          <polygon class="st4" points="761.5,378 751.1,380.9 751.1,383.7 761.5,381 		" />
        </g>
        <path
          id="_x32_-2_x5F_gulv_x5F_2"
          class="st4"
          d="M796.2,195.6l-0.1,2.4c0,0.4-0.3,0.7-0.7,0.7l-2.9-0.1c-0.4,0-0.7-0.3-0.7-0.7
		l0.1-2.3l-13.2-0.3l-0.1,3.6c0,0.4-0.4,0.8-0.8,0.8l-2.7-0.1c-0.4,0-0.8-0.4-0.8-0.8l0.1-3.6l-48.4-0.9l-0.1,2.1
		c0,0.4-0.4,0.8-0.8,0.8l-2,0c-0.5,0-0.8-0.4-0.8-0.8l0-2.1l-14.7-0.3l0,3.3c0,0.4-0.4,0.8-0.8,0.8h-2.1c-0.4,0-0.8-0.3-0.8-0.8
		v-3.3l-74.9-1.4l0,1.4c0,1.1-2.1,2-4.6,2s-4.6-1-4.6-2.1l0-1.5l-101.8-1.9c4.1,0.4,7.8,2.3,10.4,5.2l144.2,3.5
		c2.8-2.6,6.6-4.2,10.7-4.2c4.4,0,8.4,1.8,11.3,4.7l119.2,2.9l0.2-6.4L796.2,195.6z"
        />
        <path
          id="_x32_-2_x5F_gulv_x5F_1"
          class="st4"
          d="M424.7,271.1l0,1.8c0.6,0.1,1.1,0.3,1.1,0.5c0,0.3-0.8,0.5-1.7,0.5
		s-1.7-0.2-1.7-0.5c0-0.2,0.5-0.4,1.2-0.5l0-1.9l-5.6-0.6l0.1,1.9c0,0.1-0.2,0.2-0.5,0.2s-0.5-0.1-0.5-0.2l-0.1-2l-1.8-0.2l0,1.8
		c0,0.1-0.2,0.2-0.5,0.2c-0.3,0-0.5-0.1-0.5-0.2l-0.1-1.9l-9.3-1.1l0,1.9c0,0.3-0.3,0.5-0.6,0.5c-0.3,0-0.6-0.2-0.6-0.5c0,0,0,0,0,0
		l0-2l-12.5-1.4l0.1,4.7c0,0.3-0.3,0.5-0.6,0.5c-0.3,0-0.6-0.2-0.6-0.5c0,0,0,0,0,0l-0.1-4.8l-6.9-0.8l0.6,5.3h0
		c0,0.2-0.2,0.4-0.4,0.4c-0.2,0-0.4-0.2-0.4-0.4c0,0,0,0,0,0l-0.7-5.4l-0.2,0l0.6,6.2h0c0,0.2-0.2,0.4-0.4,0.4
		c-0.2,0-0.4-0.2-0.4-0.4c0,0,0,0,0,0l-0.6-6.3l-6.2-0.7l-0.3,5.9c0,0,0-0.1,0,0c0,0.2-0.2,0.4-0.4,0.4c-0.2,0-0.4-0.2-0.4-0.4l0,0
		l0.2-6l0,0l-0.7,7c0,0,0,0,0,0c0,0.2-0.2,0.4-0.4,0.4c-0.2,0-0.4-0.2-0.4-0.4l0,0l0.7-7.1l-7.3-0.8l0.6,5.4h0
		c0,0.2-0.2,0.4-0.4,0.4c-0.2,0-0.4-0.2-0.4-0.4c0,0,0,0,0,0l-0.6-5.5l-0.2,0l0.5,6.3h0c0,0.2-0.2,0.4-0.4,0.4
		c-0.2,0-0.4-0.2-0.4-0.4c0,0,0,0,0,0l-0.6-6.4l-1.5-0.2l0,2c0,0.2-0.2,0.4-0.5,0.4c-0.3,0-0.5-0.2-0.5-0.4c0,0,0,0,0,0l0-2.1
		l-2.2-0.3l-1.3,0.1l-0.2,5.8c0,0,0,0,0,0c0,0.2-0.2,0.4-0.4,0.4c-0.2,0-0.4-0.2-0.4-0.4h0l0.2-5.8l0,0l-0.7,6.7c0,0,0,0,0,0
		c0,0.2-0.2,0.4-0.4,0.4c-0.2,0-0.4-0.2-0.4-0.4h0l0.6-6.7l-7.3,0.8l0,2.3c0,0.2-0.2,0.4-0.5,0.4c-0.3,0-0.5-0.2-0.5-0.4
		c0,0,0,0,0,0l0-2.2l-11.8,1.2l-13.7-1.8l0,0.6c0.6,0,1.1,0.2,1.1,0.4c0,0.3-0.6,0.5-1.3,0.6c-0.8,0-1.4-0.1-1.4-0.4
		c0-0.2,0.4-0.4,1-0.5l0-0.7l-1.4-0.2l-20.8,2.1l0.7,24.3l148.4-16.3L424.7,271.1z"
        />
      </g>
      <g id="KK-2-8" class="hover-obj lejer" @click="$store.commit('setModal', '2.8')">
        <g id="_x32_-8_x5F_produktions-og-anretterkoekken_x5F_3">
          <polygon class="st0" points="391.6,243.1 391.6,244.5 407.6,243.1 407.6,241.7 		" />
          <polygon class="st0" points="391.6,243.1 391.6,244.5 345.5,240.4 345.5,239 		" />
          <polygon class="st0" points="345.5,239 391.6,243.1 407.6,241.7 359.2,237.9 		" />
          <path
            class="st0"
            d="M391,244.4l-1.3-0.1l0.5,27.6c0,0,0,0,0,0c0,0.3,0.3,0.5,0.6,0.5c0.3,0,0.6-0.2,0.6-0.5L391,244.4z"
          />
          <path
            class="st0"
            d="M404.6,243.3l-1.3,0.1l0.5,27.2c0,0,0,0,0,0c0,0.3,0.3,0.5,0.6,0.5c0.3,0,0.6-0.2,0.6-0.5L404.6,243.3z"
          />
          <path
            class="st0"
            d="M348.9,240.7l-1.1-0.1l0.4,26.3c0,0,0,0,0,0c0,0.2,0.2,0.4,0.5,0.4c0.3,0,0.5-0.2,0.5-0.4L348.9,240.7z"
          />
          <g>
            <path class="st0" d="M361.6,255.9l1,0.6l-0.1-5.9c-0.5,0-0.9,0-0.9,0V255.9z" />
            <path
              class="st0"
              d="M361.6,257l0.2,8.8c0,0,0,0,0,0c0,0.2,0.2,0.4,0.5,0.4c0.3,0,0.5-0.2,0.5-0.4l-0.1-8.2L361.6,257z"
            />
            <path
              class="st0"
              d="M362.5,248.2l-0.1-6.3l-1.1-0.1l0.1,6.3C361.8,248.1,362.2,248.2,362.5,248.2z"
            />
          </g>
        </g>
        <g id="_x32_-8_x5F_produktions-og-anretterkoekken_x5F_2">
          <path
            class="st0"
            d="M383.8,271.5l-2.2-19.7l-0.9,0.1l2.3,19.6c0,0,0,0,0,0c0,0.2,0.2,0.4,0.4,0.4
			C383.6,271.9,383.8,271.7,383.8,271.5L383.8,271.5z"
          />
          <polygon
            class="st0"
            points="380.6,259.8 378.1,258.3 375.3,260 375.3,258.7 377.5,257.4 377.5,252 378.6,252 378.6,257.4 
			380.5,258.5 		"
          />
          <g>
            <path
              class="st0"
              d="M382.7,272.3l-1.9-20.4l-0.9,0l2,20.4c0,0,0,0,0,0c0,0.2,0.2,0.4,0.4,0.4
				C382.5,272.7,382.7,272.5,382.7,272.3L382.7,272.3z"
            />
            <path
              class="st0"
              d="M372.7,272.3l1.9-20.5l0.9,0.1l-2,20.4c0,0,0,0,0,0c0,0.2-0.2,0.4-0.4,0.4
				C372.9,272.7,372.7,272.5,372.7,272.3L372.7,272.3z"
            />
          </g>
          <path
            class="st0"
            d="M377.9,249.5c-2.8,0-5.1,0.3-5.1,0.6v1.2c0,0.3,2.3,0.6,5.1,0.6c2.8,0,5.1-0.3,5.1-0.6v-1.2
			C383.1,249.8,380.8,249.5,377.9,249.5z"
          />
          <ellipse class="st0" cx="377.9" cy="250.2" rx="5.1" ry="0.6" />
          <path
            class="st0"
            d="M375.6,251.9l-0.1,0l-1.3,12.9l-0.2,6.5h0c0,0.2,0.2,0.4,0.4,0.4c0.2,0,0.4-0.2,0.4-0.4c0,0,0,0,0,0
			L375.6,251.9z"
          />
        </g>
        <g id="_x32_-8_x5F_produktions-og-anretterkoekken_x5F_1">
          <path
            class="st0"
            d="M366.6,269.6l-2.1-19.3l-0.9,0.1l2.2,19.2c0,0,0,0,0,0c0,0.2,0.2,0.4,0.4,0.4
			C366.5,270,366.6,269.8,366.6,269.6L366.6,269.6z"
          />
          <polygon
            class="st0"
            points="363.6,258.3 361.1,256.7 358.4,258.5 358.4,257.2 360.6,255.8 360.6,250.5 361.6,250.5 361.6,255.9 
			363.5,257 		"
          />
          <g>
            <path
              class="st0"
              d="M365.6,270.4l-1.9-20l-0.9,0.1l2,19.9c0,0,0,0,0,0c0,0.2,0.2,0.4,0.4,0.4
				C365.4,270.8,365.6,270.6,365.6,270.4L365.6,270.4z"
            />
            <path
              class="st0"
              d="M355.9,270.4l1.9-20l0.9,0.1l-2,19.9c0,0,0,0,0,0c0,0.2-0.2,0.4-0.4,0.4C356,270.8,355.9,270.6,355.9,270.4
				L355.9,270.4z"
            />
          </g>
          <path
            class="st0"
            d="M361,248.1c-2.8,0-5,0.3-5,0.6v1.2c0,0.3,2.2,0.6,5,0.6c2.8,0,5-0.3,5-0.6v-1.2
			C366,248.4,363.7,248.1,361,248.1z"
          />
          <ellipse class="st0" cx="361" cy="248.7" rx="5" ry="0.6" />
          <path
            class="st0"
            d="M358.7,250.4l-0.9-0.1l0,0L358.7,250.4l-1.3,12.7l-0.2,6.3h0c0,0.2,0.2,0.4,0.4,0.4c0.2,0,0.4-0.2,0.4-0.4
			c0,0,0,0,0,0L358.7,250.4z"
          />
        </g>
      </g>
      <g id="KK-3-6_2_" class="hover-obj keid kejd" @click="$store.commit('setModal', '3.6')">
        <g>
          <path class="st0" d="M403.2,432.8v0.7c0,0.4,1.2,0.7,2.7,0.7c1.5,0,2.7-0.3,2.7-0.7v-0.7" />
          <path
            class="st0"
            d="M406.9,432.1c1,0.1,1.7,0.4,1.7,0.7c0,0.4-1.2,0.7-2.7,0.7c-1.5,0-2.7-0.3-2.7-0.7c0-0.3,0.7-0.6,1.7-0.7"
          />
          <path class="st0" d="M410,430.7v0.7c0,0.4,1.2,0.7,2.7,0.7c1.5,0,2.7-0.3,2.7-0.7v-0.7" />
          <path
            class="st0"
            d="M413.7,430c1,0.1,1.7,0.4,1.7,0.7c0,0.4-1.2,0.7-2.7,0.7s-2.7-0.3-2.7-0.7c0-0.3,0.7-0.6,1.7-0.7"
          />
          <path class="st0" d="M389.2,428.1v0.7c0,0.4,1.1,0.7,2.5,0.7c1.4,0,2.5-0.3,2.5-0.7v-0.7" />
          <path
            class="st0"
            d="M392.7,427.5c0.9,0.1,1.5,0.3,1.5,0.6c0,0.4-1.1,0.7-2.5,0.7c-1.4,0-2.5-0.3-2.5-0.7c0-0.3,0.6-0.5,1.5-0.6"
          />
          <path class="st0" d="M395.9,426.7v0.7c0,0.4,1.1,0.7,2.5,0.7c0.9,0,1.6-0.1,2.1-0.3" />
          <path class="st0" d="M404.9,427.7v4.8c0,0.1,0.4,0.3,1,0.3s1-0.1,1-0.3v-5.1" />
          <path class="st0" d="M411.7,426.1v4.2c0,0.1,0.4,0.3,1,0.3s1-0.1,1-0.3v-5.1" />
          <path class="st0" d="M390.7,423.8v4c0,0.1,0.4,0.3,1,0.3s1-0.1,1-0.3v-2.5" />
        </g>
        <path class="st0" d="M399.1,385.6c-4.8,0.4-8.4,1.9-9.3,2.4c1.4-0.6,3.6-1.1,7-0.6" />
        <path
          class="st0"
          d="M403.4,415.1c0,3.4-1.3,6.1-4.1,6.1c-2.2,0-3.8-2.7-3.8-6.1s1-6.1,3.8-6.1C401.6,409,403.4,411.7,403.4,415.1z
		"
        />
        <path
          class="st0"
          d="M408.2,375.3c0,0,0,5.1,0,6.8c0,1.7-1.4,1.9-1.4,1.9l-4.2,0.9l-1.5,0.3c-1.8,0.4-2.6,1.3-2.6,3.2v0.7v3V404
		c0,1.9,1.5,3.4,3.4,3.4h0.8c0,0,0,0,0,0c0.5,0,0.9-0.6,0.9-1.3c0-0.7-0.4-1.3-0.9-1.3l0,0h-0.8c-0.5,0-1-0.4-1-1V392v-3v-0.6
		c0-0.5,0.4-0.8,1-1c0,0,0.9-0.2,5.7-1.1c3.2-0.6,3.1-4.3,3.1-4.3v-7.2L408.2,375.3z"
        />
        <path
          class="st0"
          d="M400.8,388.6L400.8,388.6l0,0.4v3v11.9c0,0.5,0.4,1,1,1h0.8c0.5,0,0.9,0.6,0.9,1.3c0,0.7-0.4,1.3-0.9,1.3
		c0,0,0,0,0,0h-0.8c-1.9,0-3.4-1.5-3.4-3.4v-11.9v-3v-0.7c0-0.2,0-0.4,0-0.6l0,0c-0.6-0.2-1.2-0.3-1.8-0.4c-4.1-0.7-6.5,0.2-7.8,1
		c-0.8,0.5-1.2,1.3-1.2,2.2l0.6,29.7c0,0.4,0.1,0.8,0.3,1.1c0.6,1.2,2.5,3.8,7.2,5.4c4.3,1.5,7.8,1.2,9.5,0.9
		c0.7-0.1,1.2-0.7,1.2-1.4l0.2-32.1c0-0.4-0.1-0.8-0.4-1.2C405.8,392,403.8,390,400.8,388.6z"
        />
        <g>
          <path
            class="st0"
            d="M415,393.5c0-1.1-0.4-2.2-1.2-3c-1.2-1.3-3.3-3.1-6.4-4.1c-4.7,0.9-5.6,1.1-5.6,1.1c-0.6,0.2-1,0.4-1,1v0.2h0
			c3,1.4,4.9,3.4,5.6,4.4c0.2,0.4,0.4,0.8,0.4,1.2l-0.2,32.1c0,0.7-0.5,1.3-1.2,1.4l0.2,0c3.3-0.2,7.3-1.9,8.8-2.8
			c0.6-0.4,1-1,1-1.8L415,393.5z"
          />
        </g>
        <path
          class="st0"
          d="M401.1,385.1l1.5-0.3l0,0v-8.5l-3.6,0.7v9.2l0,0C399.5,385.7,400.2,385.3,401.1,385.1z"
        />
        <path
          class="st0"
          d="M401.1,385.1l1.5-0.3l0,0v-8.5l-3.6,0.7v9.2l0,0C399.5,385.7,400.2,385.3,401.1,385.1z"
        />
        <path
          class="st0"
          d="M398.5,387.7c0.1-0.6,0.3-1.1,0.6-1.5l0,0l0,0v-0.7l0,0c-4.8,0.4-8.4,1.9-9.3,2.4c1.4-0.6,3.6-1.1,7-0.6
		C396.7,387.3,397.9,387.6,398.5,387.7L398.5,387.7z"
        />
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  name: "AppSvg"
};
</script>

<style lang="scss" scoped>
.st0 {
  fill: none;
  stroke: #000000;
  stroke-width: 0.3;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
.st1 {
  fill: #ffffff;
  stroke: #000000;
  stroke-width: 0.3;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
.st2 {
  fill: #ffffff;
}
.st3 {
  stroke: #000000;
  stroke-width: 0.3;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
.st4 {
  fill: #efefef;
  stroke: #000000;
  stroke-width: 0.3;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}

polygon,
rect,
path,
polyline,
ellipse,
circle {
  pointer-events: all;
}
.hover-obj {
  cursor: pointer;
  &.keid kejd {
    &:hover {
      & polygon,
      rect,
      path,
      polyline,
      ellipse {
        //fill: rgba(0, 100, 117, 0.5);
        fill: #8eb1ba;
        transition: fill 0.1s linear;
      }
    }
  }

  &.kejd {
    &:hover {
      & polygon,
      rect,
      path,
      polyline,
      ellipse {
        //fill: rgba(0, 100, 117, 0.5);
        fill: #8eb1ba;
        transition: fill 0.1s linear;
      }
    }
  }

  &.tmf {
    &:hover {
      & polygon,
      rect,
      path,
      polyline,
      ellipse {
        //  fill: rgba(96, 170, 85, 0.5);
        fill: #b2d5ab;
        transition: fill 0.1s linear;
      }
    }
  }

  &.lejer {
    &:hover {
      & polygon,
      rect,
      path,
      polyline,
      ellipse {
        // fill: rgba(210, 77, 75, 0.5);
        fill: #e9a6a5;
        transition: fill 0.1s linear;
      }
    }
  }
}
</style>
<template>
  <div class="svg-stage">
    <svg
      version="1.1"
      id="Illustration"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 1830.95 510"
      style="enable-background:new 0 0 1830.95 510;"
      xml:space="preserve"
    >
      <g id="stol_00000097470962791812097340000011762049778119951239_">
        <path
          class="st0"
          d="M1553.16,383.51c0,0.62-4.37,1.12-9.77,1.12c-5.39,0-9.77-0.5-9.77-1.12v27.63c0,0.62,4.37,1.12,9.77,1.12
        c5.39,0,9.77-0.5,9.77-1.12V383.51L1553.16,383.51z"
        />
        <path
          class="st0"
          d="M1548.71,382.58c-0.36,0.3-2.59,0.53-5.32,0.53c-2.73,0-4.96-0.23-5.32-0.53c-2.67,0.2-4.45,0.54-4.45,0.94
        c0,0.62,4.37,1.12,9.77,1.12c5.39,0,9.77-0.5,9.77-1.12C1553.16,383.12,1551.38,382.78,1548.71,382.58z"
        />
        <path
          class="st0"
          d="M1537.99,371.24v11.25c0,0.34,2.42,0.62,5.4,0.62c2.98,0,5.4-0.28,5.4-0.62v-11.25H1537.99z"
        />
        <path
          class="st0"
          d="M1543.39,412.26c-2.81,0-5.34-0.14-7.12-0.35v23.27c0,0.45,3.19,0.81,7.12,0.81c3.93,0,7.12-0.36,7.12-0.81
        v-20.15v-3.12C1548.73,412.12,1546.2,412.26,1543.39,412.26z"
        />
        <path
          class="st0"
          d="M1455.2,200.72c0,0,10.6-5.86,18.23-2.79c4.84,8.09,5.95,34.51,4.19,42.79c5.77,3.44,6.88,27.53,6.88,27.53
        s12.37,19.53,12,28.84c2.98,4.93,7.16,19.07,6.6,26.05c2.7,10.51,1.67,23.16,1.67,23.16s5.86,7.56,6.33,10.24c0,2.69,0,14.7,0,14.7
        s-3.16,3.06-8.19,3.15c-5.02,0.09-8.56-1.95-8.56-1.95l-6.98-23.35l-1.86-23.26l-6.6-25.3l-11.44-29.12l-7.63-27.72
        C1459.85,243.7,1459.76,205.93,1455.2,200.72z"
        />
        <line class="st0" x1="1455.2" y1="200.72" x2="1444.59" y2="197.38" />
        <g>
          <circle class="st0" cx="1562.96" cy="364.71" r="1.38" />
          <path
            class="st0"
            d="M1561.57,364.71c0-0.76,0.62-1.38,1.38-1.38c0.2,0,0.39,0.04,0.56,0.12v0l-3.86-2.36
          c-0.02-0.01-0.04-0.02-0.06-0.04l-0.01-0.01l0,0c-0.21-0.13-0.45-0.2-0.71-0.2c-0.76,0-1.38,0.62-1.38,1.38
          c0,0.46,0.22,0.86,0.56,1.11l0,0l0.02,0.01c0.04,0.03,0.09,0.06,0.14,0.08l3.98,2.43l0,0
          C1561.81,365.59,1561.57,365.18,1561.57,364.71z"
          />
        </g>
        <circle class="st0" cx="1482.68" cy="457.01" r="5.3" />
        <path
          class="st0"
          d="M1533.48,440.02c0,0-17.02-1.12-28.84,0c-11.81,1.12-28.14,4.65-27.53,10.65c3.67,0.33,16.03-3.7,29.12-4.65
        c6.36-0.46,27.26-0.7,27.26-0.7V440.02z"
        />
        <path
          class="st0"
          d="M1545.43,442.4c0-0.63-0.51-1.15-1.15-1.15h-3.43c-0.26,0-0.48,0.1-0.67,0.24l-0.02,0
        c-0.01,0.01-0.03,0.03-0.04,0.05c-0.06,0.05-0.11,0.11-0.16,0.17c-1.93,2.11-5.84,6.81-6.49,13.08c-0.33,2.72,0.44,6.57,3.09,6.5
        c2.65-0.08,5.02-3.91,6-7.47c0.98-3.56,2.75-8.06,2.75-8.06l0-0.01c0.07-0.15,0.11-0.31,0.11-0.48V442.4z"
        />
        <path
          class="st0"
          d="M1569,316.91c-0.33,0.24-0.94,0.4-1.75,0.49l-0.05,0.09l3.42,2.44l-10.12,41.67l3.02,1.85l11.31-42.96
        L1569,316.91z"
        />
        <path
          class="st0"
          d="M1545.22,315.81c-0.8-0.08-1.76-0.15-2.84-0.2l-7.55,28.93h4.19l7.93-28.55
        C1546.32,315.92,1545.73,315.86,1545.22,315.81z"
        />
        <path
          class="st0"
          d="M1508.03,315.81c0.64,1.54,1.41,2.38,7.83,0.99s22.95-1.62,29.37-0.99c6.42,0.64,23.72,3.09,24.07,0.68
        c0.35-2.41-2.51-3.53-13.4-4.65s-20.02-1.47-28.47-1.19C1518.99,310.93,1506.69,311.75,1508.03,315.81z"
        />
        <path
          class="st0"
          d="M1542.99,303.57l-0.09,0.58c-0.11,0.4-0.27,0.94-1.28,1.18l2.91,2.06l-0.91,3.48c1.52,0.08,3.08,0.18,4.67,0.3
        l1.05-4.75L1542.99,303.57z"
        />
        <path
          class="st0"
          d="M1503.02,319.18l8.29-1.69c-2.34,0.1-2.84-0.63-3.28-1.69l-5.34,0.76
        C1502.83,317.47,1502.94,318.34,1503.02,319.18z"
        />
        <path
          class="st0"
          d="M1541.46,301.37c-10.88-3.14-35.51-3.56-44.05-2.57c0,0,0,0.01,0,0.01c0.66,1.37,1.34,3.05,2,4.93c0,0,0,0,0,0
        c11.95-2.23,35.63,1.19,39.33,1.6s3.98-0.56,4.16-1.2C1543.08,303.51,1543.2,301.86,1541.46,301.37z"
        />
        <g>
          <path
            class="st0"
            d="M1565.33,356.55h45.72c0,0-11.2-3.08-14.55-6.06c-3.35-2.98-7.91-5.95-10.05-5.95c-3.86,0-10.29,0-17.96,0
          L1565.33,356.55z"
          />
          <path
            class="st0"
            d="M1564.64,344.54c-20.52,0-47.74,0-59.76,0c-0.05,1.12-0.1,1.77-0.1,1.77s5.86,7.56,6.33,10.24h50.61
          L1564.64,344.54z"
          />
        </g>
        <g>
          <path
            class="st0"
            d="M1485.53,325.84l-6.6-25.3l-11.44-29.12l-7.63-27.72c0,0-0.09-37.77-4.65-42.98c0,0,10.6-5.86,18.23-2.79
          c-4.15-3.73-15.18-4.73-18.04-4.93c-0.13-0.01-0.27-0.01-0.4,0c-7.37,0.46-10.41,4.38-10.41,4.38c0,8.7,0.94,42.24,0.94,42.24
          l5.92,26.12l10.8,27.49l6.24,23.89l1.76,21.96l6.17,20.63c0.27,0.91,0.86,1.7,1.66,2.22l16.29,10.5l-6.98-23.35L1485.53,325.84z"
          />
        </g>
        <path
          class="st0"
          d="M1548.78,436.31v1.3c0,0.34-2.42,0.62-5.4,0.62s-5.4-0.28-5.4-0.62v-1.3c-2.71,0.36-4.51,0.98-4.51,1.69
        c0,1.12,4.44,2.02,9.91,2.02s9.91-0.9,9.91-2.02C1553.29,437.29,1551.5,436.67,1548.78,436.31z"
        />
        <path
          class="st0"
          d="M1543.39,440.02c-5.47,0-9.91-0.9-9.91-2.02v9.8c0,0.41,0.6,0.79,1.63,1.11c1.49-3.32,3.6-5.81,4.86-7.2
        c0.05-0.06,0.1-0.12,0.16-0.17c0.01-0.01,0.03-0.03,0.04-0.05l0.02,0c0.19-0.14,0.42-0.24,0.67-0.24h3.43
        c0.63,0,1.15,0.51,1.15,1.15v2.87c0,0.17-0.04,0.33-0.11,0.48l0,0.01c0,0-0.72,1.82-1.49,4.06c5.26-0.05,9.46-0.93,9.46-2.01V438
        C1553.29,439.12,1548.86,440.02,1543.39,440.02z"
        />
        <path
          class="st0"
          d="M1543.83,449.81c-0.29,0.84-0.59,1.74-0.86,2.62c0.14,0,0.27,0,0.41,0c2.98,0,5.4-0.28,5.4-0.62v-2.33
        C1547.35,449.68,1545.65,449.8,1543.83,449.81z"
        />
        <path
          class="st0"
          d="M1542.35,460.86h-1.38h-2.93c-0.48,0.26-0.97,0.41-1.47,0.43c-0.55,0.02-1.02-0.15-1.42-0.43h-1.93h-1.38
        c-0.4,0-0.73,2.36-0.73,5.27c0,0.29,0.01,0.57,0.01,0.85h2.81h6.31h2.81c0.01-0.28,0.01-0.56,0.01-0.85
        C1543.08,463.22,1542.76,460.86,1542.35,460.86z"
        />
        <path
          class="st0"
          d="M1540.26,466.98c0.06,2.5,0.35,4.42,0.72,4.42h1.38c0.36,0,0.66-1.92,0.72-4.42H1540.26z"
        />
        <g>
          <path
            class="st0"
            d="M1531.13,466.98c0.06,2.5,0.35,4.42,0.72,4.42h1.38c0.36,0,0.66-1.92,0.72-4.42H1531.13z"
          />
        </g>
        <g>
          <path
            class="st0"
            d="M1486.21,453.08l0.01-0.01l-0.34-0.26C1485.99,452.89,1486.1,452.98,1486.21,453.08z"
          />
          <path
            class="st0"
            d="M1477.38,457.01c0-2.93,2.37-5.3,5.3-5.3c1.07,0,2.06,0.32,2.89,0.87l-1.18-0.9
          c-0.34-0.35-0.74-0.64-1.17-0.89l-0.13-0.1l-0.02,0.02c-0.46-0.25-0.97-0.43-1.49-0.54c-1.36,0.25-2.49,0.43-3.39,0.49
          c-1.72,0.88-2.91,2.64-2.91,4.7c0,1.18,0.4,2.26,1.05,3.14l-0.01,0.01l0.05,0.05c0.44,0.58,1.01,1.06,1.65,1.42l0.86,0.74
          C1477.96,459.77,1477.38,458.46,1477.38,457.01z"
          />
          <path
            class="st0"
            d="M1479.16,460.95l0.39,0.34l0.01-0.01C1479.42,461.18,1479.29,461.06,1479.16,460.95z"
          />
        </g>
        <path
          class="st0"
          d="M1511.88,445.76c0.29,0.69,0.47,1.47,0.47,2.32c0,2.61-1.59,4.73-3.54,4.73h4.75c1.96,0,3.54-2.12,3.54-4.73
        c0-0.9-0.2-1.73-0.53-2.45C1514.94,445.67,1513.35,445.71,1511.88,445.76z"
        />
        <path
          class="st0"
          d="M1506.22,446.02c-0.21,0.02-0.41,0.04-0.61,0.05c-0.21,0.61-0.34,1.28-0.34,2c0,2.61,1.59,4.73,3.54,4.73
        s3.54-2.12,3.54-4.73c0-0.85-0.18-1.63-0.47-2.32C1509.57,445.84,1507.57,445.93,1506.22,446.02z"
        />
        <path
          class="st0"
          d="M1535.38,436.81c0,0-7.71-3.37-16.99-2.57c-9.28,0.8-10.92,3.75-11.13,5.57c0,0,0,0,0,0
        c11.55-0.75,26.21,0.21,26.21,0.21v-1.92c0,0,0-0.03,0-0.1C1533.48,437.56,1534.19,437.15,1535.38,436.81L1535.38,436.81z"
        />
        <path
          class="st0"
          d="M1589.01,445.68C1589.01,445.68,1589.01,445.68,1589.01,445.68c0.53-4.65-3.77-7.16-13.36-8.4
        c-12.45-1.6-22.35,0.93-22.35,0.72v2.81c0,0,15.7,0.41,27.35,2.68C1583.19,443.99,1586.08,444.74,1589.01,445.68z"
        />
        <path
          class="st0"
          d="M1582.06,450.25c-0.21,0.53-0.33,1.12-0.33,1.74c0,2.45,1.83,4.43,4.09,4.43c2.05,0,3.74-1.64,4.03-3.78
        C1587.34,451.78,1584.72,450.96,1582.06,450.25z"
        />
        <path
          class="st0"
          d="M1581.74,451.98c0-0.62,0.12-1.2,0.33-1.74c-1-0.27-2-0.52-3.01-0.75c-0.18-0.04-0.37-0.08-0.58-0.12
        c-0.25,0.57-0.4,1.2-0.4,1.88c0,2.11,1.37,3.87,3.2,4.32l0,0.01l0.05,0.01c0.02,0.01,0.04,0.01,0.07,0.01l3.55,0.71
        C1583.11,455.87,1581.74,454.1,1581.74,451.98z"
        />
        <path
          class="st0"
          d="M1553.29,440.81c0,0,15.7,0.41,27.35,2.68c11.07,2.16,28.56,9.12,27.96,15.12
        c-3.67,0.33-16.65-6.18-29.54-9.12c-6.22-1.42-25.76-3.38-25.76-3.38V440.81z"
        />
        <g>
          <path
            class="st0"
            d="M1596.43,459.99c0.11-0.09,0.21-0.18,0.33-0.27l-0.34,0.26L1596.43,459.99z"
          />
          <path
            class="st0"
            d="M1603.07,468.19L1603.07,468.19l0.4-0.33C1603.34,467.98,1603.21,468.09,1603.07,468.19z"
          />
          <path
            class="st0"
            d="M1607.34,462.28c0-1.75-0.86-3.3-2.17-4.26c-0.94-0.27-2.01-0.62-3.19-1.04c-0.88,0.01-1.7,0.25-2.43,0.64
          l-0.02-0.02l-0.13,0.1c-0.43,0.25-0.82,0.54-1.17,0.89l-1.18,0.9c0.83-0.54,1.82-0.87,2.89-0.87c2.93,0,5.3,2.37,5.3,5.3
          c0,1.45-0.58,2.76-1.53,3.72l0.86-0.74c0.65-0.36,1.21-0.84,1.65-1.42l0.05-0.05l-0.01-0.01
          C1606.94,464.54,1607.34,463.46,1607.34,462.28z"
          />
        </g>
        <circle class="st0" cx="1599.96" cy="463.92" r="5.3" />
        <path
          class="st0"
          d="M1543.39,435.98c-2.16,0-4.09-0.11-5.4-0.28v1.91c0,0.34,2.42,0.62,5.4,0.62c2.98,0,5.4-0.28,5.4-0.62v-1.91
        C1547.48,435.87,1545.55,435.98,1543.39,435.98z"
        />
        <path
          class="st0"
          d="M1611.05,356.55h-45.72l-1.82,6.9h0l0,0c0,0,0,0,0,0c0.49,0.21,0.83,0.7,0.83,1.27c0,0.76-0.62,1.38-1.38,1.38
        c-0.29,0-0.57-0.09-0.79-0.25c0,0,0,0,0,0l0,0l-4.13-2.52l0,0c-0.34-0.25-0.56-0.65-0.56-1.11c0-0.76,0.62-1.38,1.38-1.38
        c0.26,0,0.5,0.08,0.71,0.2l0,0l2.09,1.28l-1.17-0.72l1.23-5.05h-50.61v14.7h99.05c1.04,0,1.88-0.84,1.88-1.88v-11.83
        C1612.04,356.99,1611.6,356.55,1611.05,356.55z"
        />
      </g>
      <g id="gulve_00000125597617630681664190000016740611343364518318_">
        <g>
          <path
            class="st1"
            d="M1536.57,461.29C1536.57,461.29,1536.58,461.29,1536.57,461.29C1536.58,461.29,1536.57,461.29,1536.57,461.29
          c-0.15,0-0.29-0.01-0.43-0.03C1536.28,461.28,1536.42,461.29,1536.57,461.29z"
          />
          <path
            class="st1"
            d="M1776.35,464.61v6.57c0,0.72-2.83,1.3-6.33,1.3c-3.49,0-6.33-0.58-6.33-1.3v-12.92l-4.87-2.45v3.29
          c1.55,0.24,2.63,0.75,2.63,1.34c0,0.82-2.06,1.49-4.6,1.49c-2.54,0-4.6-0.67-4.6-1.49c0-0.59,1.08-1.1,2.63-1.34v-5.22h-0.93
          l-48.72-24.85v3.15c1.55,0.24,2.63,0.75,2.63,1.34c0,0.82-2.06,1.49-4.6,1.49c-2.54,0-4.6-0.67-4.6-1.49
          c0-0.59,1.08-1.1,2.63-1.34v-5.16l-11.09-5.65v-1.47h-5.92v3.2c0,0.66-2.6,1.2-5.82,1.2s-5.82-0.54-5.82-1.2v-3.2h-33.24v51.28
          c0,0.72-2.83,1.3-6.33,1.3s-6.33-0.58-6.33-1.3v-51.28h-63.63v3.2c0,0.66-2.6,1.2-5.82,1.2c-3.21,0-5.82-0.54-5.82-1.2v-3.2h-0.99
          v15.26c0,0.2-0.66,0.39-1.74,0.53c0,0,0.01,0,0.01,0v0.61c2.71,0.36,4.51,0.98,4.51,1.69c0,0.21,9.9-2.33,22.35-0.72
          c9.59,1.24,13.89,3.75,13.36,8.4c0,0,0,0,0,0c9.8,3.13,20.06,8.32,19.59,12.94c-0.76,0.07-1.95-0.17-3.43-0.6
          c1.31,0.97,2.17,2.51,2.17,4.26c0,1.18-0.4,2.26-1.05,3.14l0.01,0.01l-0.05,0.05c-0.45,0.58-1.01,1.06-1.65,1.42l-0.86,0.74
          c0,0,0,0,0,0c-0.13,0.13-0.29,0.25-0.43,0.37c0.06-0.05,0.11-0.09,0.17-0.14l-0.39,0.33l-0.01-0.01
          c-0.88,0.64-1.95,1.03-3.12,1.03c-2.93,0-5.3-2.37-5.3-5.3c0-1.64,0.76-3.08,1.92-4.05c-0.05,0.04-0.1,0.08-0.14,0.12l-0.01-0.01
          l0.34-0.26c0.15-0.11,0.3-0.22,0.46-0.31c-0.05,0.03-0.1,0.05-0.15,0.08l1.18-0.9c0.34-0.35,0.74-0.64,1.17-0.89l0.13-0.1
          l0.02,0.02c0.73-0.39,1.55-0.63,2.43-0.64c-3.35-1.17-7.54-2.79-12.11-4.35c-0.29,2.14-1.98,3.78-4.03,3.78
          c-0.3,0-0.6-0.04-0.88-0.11l-3.55-0.71c-0.02,0-0.04-0.01-0.07-0.01l-0.05-0.01l0-0.01c-1.83-0.44-3.2-2.2-3.2-4.32
          c0-0.67,0.15-1.3,0.4-1.87c-6.75-1.41-25.19-3.26-25.19-3.26v1.69c0,0.72-1.88,1.36-4.69,1.71c0.06-0.01,0.12-0.01,0.18-0.02v2.33
          c0,0.34-2.42,0.62-5.4,0.62c-0.14,0-0.27,0-0.41,0c-0.14,0.47-0.28,0.93-0.4,1.38c-0.79,2.88-2.5,5.94-4.53,7.05h2.93h1.38
          c0.4,0,0.73,2.36,0.73,5.27c0,0.29-0.01,0.57-0.01,0.85h0c-0.06,2.5-0.35,4.42-0.72,4.42h-1.38c-0.36,0-0.66-1.92-0.72-4.42h-6.31
          c-0.06,2.5-0.35,4.42-0.72,4.42h-1.38c-0.36,0-0.66-1.92-0.72-4.42c-0.01-0.28-0.01-0.56-0.01-0.85c0-2.91,0.33-5.27,0.73-5.27
          h1.38h1.93c-1.5-1.06-1.94-3.92-1.68-6.07c0.22-2.16,0.84-4.14,1.63-5.88c-1.03-0.32-1.63-0.7-1.63-1.11v-2.47
          c0,0-8.94,0.1-16.91,0.3c0.33,0.72,0.53,1.55,0.53,2.45c0,2.61-1.59,4.73-3.54,4.73h-4.75c-1.96,0-3.54-2.12-3.54-4.73
          c0-0.72,0.13-1.39,0.34-2c-9.47,0.76-18.48,3.06-24.02,4.1c0.53,0.11,1.03,0.29,1.49,0.54l0.02-0.02l0.13,0.1
          c0.43,0.25,0.82,0.54,1.17,0.89l1.18,0.9c-0.01-0.01-0.03-0.02-0.05-0.03c0.13,0.08,0.25,0.18,0.37,0.27
          c-0.01-0.01-0.01-0.01-0.02-0.02l0.08,0.06c0.06,0.05,0.12,0.09,0.18,0.14l0.08,0.06l-0.01,0.01c1.08,0.97,1.77,2.37,1.77,3.94
          c0,2.93-2.37,5.3-5.3,5.3c-1.21,0-2.31-0.42-3.2-1.1c0.03,0.02,0.05,0.05,0.08,0.07l-0.01,0.01l-0.39-0.34
          c-0.09-0.08-0.18-0.14-0.26-0.22l-0.86-0.74c-0.65-0.36-1.21-0.84-1.65-1.42l-0.05-0.05l0.01-0.01c-0.65-0.88-1.05-1.96-1.05-3.14
          c0-2.06,1.19-3.83,2.91-4.7c-0.41,0.03-0.79,0.04-1.09,0.01c-0.6-6,15.72-9.53,27.53-10.65c0.87-0.08,1.78-0.15,2.7-0.21
          c-0.02,0-0.05,0-0.07,0c0,0,0,0,0,0c0.21-1.82,1.85-4.77,11.13-5.57c9.28-0.8,16.99,2.57,16.99,2.57l0,0c0,0,0,0,0,0
          c0.71-0.2,1.6-0.37,2.61-0.5v-0.61c0,0,0.01,0,0.01,0c-1.08-0.14-1.74-0.33-1.74-0.53v-15.26h-153.22v30.95h-27.53l-30.33-30.95
          h-176.25v16.92c0,0.81-3.17,1.46-7.08,1.46s-7.08-0.65-7.08-1.46v-8.44c-2.52-0.22-4.28-0.74-4.28-1.34v-7.14h-77.1v16.92
          c0,0.81-3.17,1.46-7.08,1.46s-7.08-0.65-7.08-1.46v-8.51c-1.38-0.27-2.26-0.65-2.26-1.07v-7.34H834.3v2.43
          c0,0.24-0.72,0.45-1.77,0.55V430c0,0.37-1.55,0.66-3.45,0.66c-1.91,0-3.45-0.3-3.45-0.66v-10.09H608.9v2.43
          c0,0.34-1.43,0.61-3.2,0.61c-1.77,0-3.2-0.28-3.2-0.61V430c0,0.37-1.55,0.66-3.45,0.66c-1.91,0-3.45-0.3-3.45-0.66v-10.09h-92.44
          L440.91,477h1360.11L1776.35,464.61z"
          />
          <path
            class="st1"
            d="M1532.35,439.96c0.5,0.03,0.84,0.05,1,0.06C1533.19,440.01,1532.86,439.98,1532.35,439.96z"
          />
          <path
            class="st1"
            d="M1535.74,439.29c1.21,0.3,2.84,0.53,4.7,0.64C1538.58,439.81,1536.95,439.59,1535.74,439.29z"
          />
          <path
            class="st1"
            d="M1599.96,458.62c-0.32,0-0.64,0.04-0.95,0.1C1599.32,458.66,1599.63,458.62,1599.96,458.62z"
          />
          <path
            class="st1"
            d="M1543.41,451.08c0.14-0.43,0.28-0.85,0.43-1.26C1543.69,450.23,1543.55,450.65,1543.41,451.08z"
          />
          <path
            class="st1"
            d="M1512.35,448.08c0-0.85-0.18-1.63-0.47-2.32C1512.17,446.45,1512.35,447.23,1512.35,448.08z"
          />
          <path
            class="st1"
            d="M1543.39,451.12c-0.14,0.44-0.28,0.88-0.42,1.31C1543.11,451.99,1543.25,451.55,1543.39,451.12z"
          />
          <path
            class="st1"
            d="M1545.43,442.4c0-0.16-0.03-0.31-0.09-0.45C1545.4,442.09,1545.43,442.24,1545.43,442.4l0,2.87
          c0,0.17-0.04,0.33-0.11,0.48l0,0.01c0,0-0.71,1.8-1.48,4.02c0.77-2.22,1.48-4.02,1.48-4.02l0-0.01c0.07-0.15,0.11-0.31,0.11-0.48
          V442.4z"
          />
          <path
            class="st1"
            d="M1577.99,443.02c-3.15-0.52-6.48-0.92-9.64-1.23C1571.51,442.1,1574.83,442.5,1577.99,443.02z"
          />
          <path
            class="st1"
            d="M1568.35,441.79c-1.8-0.18-3.55-0.32-5.18-0.44C1564.8,441.46,1566.55,441.61,1568.35,441.79z"
          />
          <path
            class="st1"
            d="M1599.96,458.62c0.55,0,1.08,0.08,1.58,0.24C1601.03,458.7,1600.51,458.62,1599.96,458.62z"
          />
          <path
            class="st1"
            d="M1477.38,457.01c0,0.36,0.04,0.72,0.11,1.06C1477.42,457.73,1477.38,457.37,1477.38,457.01
          c0-0.73,0.15-1.43,0.42-2.06C1477.53,455.58,1477.38,456.28,1477.38,457.01z"
          />
          <path
            class="st1"
            d="M1509.64,445.84c0.64-0.03,1.33-0.05,2.04-0.08C1510.96,445.79,1510.28,445.82,1509.64,445.84z"
          />
          <path
            class="st1"
            d="M1580.64,443.49c-0.86-0.17-1.75-0.33-2.65-0.48C1578.89,443.17,1579.78,443.32,1580.64,443.49z"
          />
          <path
            class="st1"
            d="M1483.63,451.8c-0.31-0.06-0.62-0.1-0.95-0.1C1483,451.71,1483.32,451.75,1483.63,451.8z"
          />
        </g>
        <path
          class="st1"
          d="M253.44,419.91v2.64h0c2.09,0.34,3.53,1.07,3.53,1.92c0,1.17-2.78,2.13-6.21,2.13s-6.21-0.95-6.21-2.13
        c0-0.88,1.56-1.64,3.79-1.96l0.01,0v-2.6H148.26c0,5.5,0,8.81,0,8.81c0,0.64-1.94,1.16-4.33,1.16c-2.39,0-4.33-0.52-4.33-1.16
        v-6.58l-4.18,2.09v10c0,0.54-1.77,0.98-3.95,0.98c-0.06,0-0.12,0-0.19,0v1.61c0,0.54-1.77,0.98-3.95,0.98
        c-2.18,0-3.95-0.44-3.95-0.98v-6.55l-51.58,25.8v3.47c0,0.33-1,0.59-2.23,0.59c-0.67,0-1.27-0.08-1.67-0.2v1.66
        c0,0.33-1,0.59-2.23,0.59c-1.23,0-2.23-0.27-2.23-0.59v-1.33L29.93,477h381.95v-57.09H253.44z M143.09,466.02h-51l15.98-8.9h50.58
        L143.09,466.02z"
        />
      </g>
      <g id="badevaerelse_00000030489364173683352320000004477540366362444929_">
        <g id="bruser_00000005256149137906949950000003161734564378214792_">
          <g>
            <rect x="52.07" y="289.37" class="st0" width="17.77" height="18.05" />
            <polyline
              class="st0"
              points="70.95,284.54 86.58,284.54 69.84,289.37 52.07,289.37 65.51,285.17 			"
            />
            <polygon class="st0" points="69.84,307.42 69.84,289.37 86.58,284.54 86.58,301.62 			" />
          </g>
        </g>
        <g id="vandhane_00000092434081258978314230000005821967102705031312_">
          <g>
            <g>
              <path
                class="st0"
                d="M132.16,259.42l-5.97,1.7v2.55h-0.05c-0.26,0.31-1.78,0.55-3.63,0.55c-1.86,0-3.38-0.24-3.63-0.55h-0.05
              v-0.45l-24.15,6.89h72.65l30.6-10.7H132.16z"
              />
              <rect x="94.68" y="270.12" class="st0" width="72.7" height="24.56" />
              <polygon
                class="st0"
                points="167.33,270.12 197.93,259.42 197.93,283.93 167.37,294.67 				"
              />
              <polygon
                class="st0"
                points="147.23,259.42 189.93,259.42 159.51,270.12 113.49,270.12 				"
              />
            </g>
            <line class="st0" x1="147.23" y1="259.42" x2="147.23" y2="270.12" />
          </g>
          <g>
            <path
              class="st0"
              d="M139.61,328.73v99.99c0,0.64,1.94,1.16,4.33,1.16c2.39,0,4.33-0.52,4.33-1.16c0,0,0-106.05,0-114.98
            c0-8.93-2.79-10.93-5.49-10.93c-1.11,0-1.95,0.22-2.56,0.48"
            />
            <path class="st0" d="M144.07,318.12c0,12.84-8.65,11.44-8.65,11.44v-34.88h4.79v24.42" />
          </g>
        </g>
        <g id="afloeb_00000032620278815899942450000006664260882452611262_">
          <g>
            <rect x="103.05" y="477" class="st0" width="24.96" height="3.81" />
            <path
              class="st0"
              d="M112.88,480.81v13.09c0,2.04-1.65,3.69-3.69,3.69h-0.65c-2.04,0-3.69-1.65-3.69-3.69v-0.7
            c0-4.72-3.83-8.55-8.55-8.55H0.35v4.89h95.58c2.23,0,4.04,1.81,4.04,4.04l0,0c0,4.91,3.98,8.9,8.9,8.9h0c4.91,0,8.9-3.98,8.9-8.9
            v-12.76H112.88z"
            />
          </g>
          <g>
            <polygon
              class="st0"
              points="108.07,457.13 92.09,466.02 143.09,466.02 158.65,457.13 			"
            />
            <line class="st0" x1="104.4" y1="466.02" x2="121.14" y2="457.13" />
            <line class="st0" x1="118.44" y1="466.02" x2="134.02" y2="457.13" />
            <line class="st0" x1="130.68" y1="466.02" x2="146.95" y2="457.13" />
            <line class="st0" x1="97.86" y1="462.81" x2="148.74" y2="462.81" />
            <line class="st0" x1="103.54" y1="459.67" x2="154.19" y2="459.67" />
          </g>
        </g>
        <g id="spejl_00000103238825628262685000000012590677895613018792_">
          <rect x="94.3" y="206.68" class="st0" width="21.4" height="5.67" />
          <polygon class="st0" points="115.7,206.68 151.7,198.68 151.7,204.16 115.7,212.35 		" />
          <polygon class="st0" points="98.86,206.68 131.05,198.68 151.7,198.68 115.7,206.68 		" />
          <path
            class="st0"
            d="M98.86,103.7v18.34c0.29,0.64,0.54,1.3,0.73,1.98l1.32,5.38c0,0-0.01,0-0.01,0
          c7.43-0.89,13.24-1.18,13.31-0.63l0.5,3.92c0.07,0.59-6.55,1.92-14.79,2.97c-0.36,0.05-0.71,0.09-1.07,0.13v70.88l32.19-8h4.37
          v-97.88L98.86,103.7z"
          />
          <g>
            <path
              class="st0"
              d="M94.61,126c-0.07-0.43-0.18-0.84-0.31-1.25v5.54c0.42-0.06,0.85-0.13,1.28-0.19L94.61,126z"
            />
            <path
              class="st0"
              d="M98.86,122.04V103.7H94.3v12.6C96.27,117.79,97.84,119.76,98.86,122.04z"
            />
            <path
              class="st0"
              d="M94.3,136.3v70.38h4.56v-70.88C97.26,135.99,95.73,136.16,94.3,136.3z"
            />
          </g>
        </g>
      </g>
      <g id="vaegge_00000039851498241833125750000007047649509449908158_">
        <g>
          <path
            class="st0"
            d="M229.62,307.47l8.74-2.65h173.52V29H143.93v169.67h7.77v5.49l-7.77,1.77v25.62l2.79-0.41v5.16l-1.65,0.29
          v3.61h1.23v3.86c0,0.25-1.01,0.46-2.37,0.52v14.83h3.3h42.7h8v24.51l-30.56,10.74h-23.44v8.29c2.28,0.58,4.33,3.14,4.33,10.78
          c0,7.35,0,80.54,0,106.16h100.09v-9.37h-18.72v-66.94c-1.05,1.66-2.89,2.76-4.99,2.76c-3.26,0-5.91-2.64-5.91-5.91
          c0-2.49,1.54-4.61,3.71-5.48l-0.02,0l7.21-2.75V307.47z"
          />
          <g>
            <path
              class="st0"
              d="M65.51,285.17l0-153.79c0-10,8.1-18.1,18.1-18.1h1.73c3.37,0,6.47,1.12,8.96,3.02v-12.6h4.56l36.56-2.91
            v97.88h8.65V29H29.93v448l33.49-16.75V307.42H52.07v-18.05L65.51,285.17z"
            />
            <path
              class="st0"
              d="M144.07,231.53V205.9l-28.37,6.45H94.3v-5.67V136.3c-5.48,0.54-9.38,0.65-9.43,0.21l-0.5-3.92
            c-0.06-0.46,4.1-1.4,9.94-2.29v-5.54c-1.12-3.5-4.39-6.03-8.26-6.03h-4.17c-6.04,0-10.93,4.89-10.93,10.93v154.89h15.63v17.08
            l-16.74,5.8l1.95-0.68v149.32l51.58-25.8V294.67H94.68v-24.56l24.15-6.89v-16.36c-0.92-0.11-1.49-0.26-1.49-0.42v-6.49
            c0-0.17,0.6-0.32,1.57-0.43l0.92-4.94v-6.12c0,0-1.66-1.93,0-3.6c7.48-6.51,21.22-5.21,21.57-4.65s-0.4,0.98-1.7,1.26
            c-17.49,3.3-16.49,7-16.49,7v6.12L144.07,231.53z"
            />
            <path
              class="st0"
              d="M135.42,329.56v94.68l4.18-2.09v-93.41C137.49,329.89,135.42,329.56,135.42,329.56z"
            />
          </g>
          <rect x="253.44" y="410.54" class="st0" width="158.45" height="9.37" />
          <path
            class="st0"
            d="M140.21,303.3L140.21,303.3c0.61-0.26,1.45-0.48,2.56-0.48c0.39,0,0.78,0.05,1.16,0.15v-8.29h-3.72V303.3z"
          />
        </g>
        <g>
          <polygon
            class="st0"
            points="1801.02,477 1776.35,464.29 1776.35,338.58 1798.3,338.58 1798.3,327.98 1757.79,313.56 
          1757.79,291.33 1731.83,282.7 1731.83,271.93 1738.32,271.93 1742.23,194.04 1703.02,185.63 1694.23,185.63 1691.09,259.93 
          1715.93,267.59 1715.93,280.37 1690.21,280.37 1690.21,280.37 1690.21,29 1801.02,29 		"
          />
          <path
            class="st0"
            d="M1536.26,411.9c-1.43-0.17-2.38-0.4-2.6-0.65c-0.03-0.04-0.05-0.07-0.05-0.11v-27.63
          c0-0.2,0.44-0.38,1.22-0.54c0.78-0.16,1.89-0.3,3.23-0.4c-0.03-0.03-0.07-0.06-0.07-0.08v-11.25h-26.88c0,0-3.16,3.06-8.19,3.15
          c-0.31,0.01-0.62,0-0.92-0.01c-1.42-0.05-2.69-0.26-3.78-0.52c-0.22-0.05-0.43-0.11-0.63-0.16c-0.12-0.03-0.23-0.07-0.34-0.1
          c-0.64-0.19-1.19-0.4-1.63-0.58c-0.3-0.12-0.54-0.23-0.73-0.32c-0.14-0.07-0.26-0.13-0.34-0.17c-0.01,0-0.01-0.01-0.02-0.01
          c-0.09-0.05-0.15-0.08-0.15-0.08v0l-16.29-10.5c-0.8-0.52-1.39-1.3-1.66-2.22l-6.17-20.63l-1.76-21.96l-6.24-23.89l-10.8-27.49
          l-5.92-26.12c0,0,0-0.03,0-0.1c-0.01-0.19-0.02-0.7-0.04-1.46c-0.01-0.27-0.02-0.59-0.03-0.92c-0.02-0.77-0.05-1.7-0.07-2.75
          c0-0.1-0.01-0.2-0.01-0.3c-0.02-0.76-0.04-1.57-0.06-2.44c-0.03-1.13-0.06-2.34-0.09-3.61c-0.01-0.27-0.01-0.54-0.02-0.82
          c0-0.18-0.01-0.36-0.01-0.54c0-0.19-0.01-0.38-0.01-0.58c-0.01-0.48-0.02-0.96-0.04-1.45c-0.01-0.22-0.01-0.45-0.02-0.68
          c-0.01-0.24-0.01-0.48-0.02-0.72c-0.01-0.22-0.01-0.45-0.02-0.67c-0.01-0.42-0.02-0.84-0.03-1.27c-0.01-0.41-0.02-0.82-0.03-1.23
          c0-0.06,0-0.12,0-0.18c-0.01-0.55-0.03-1.1-0.04-1.66c0-0.12-0.01-0.23-0.01-0.35c-0.01-0.35-0.02-0.7-0.02-1.04
          c-0.01-0.26-0.01-0.52-0.02-0.78c-0.01-0.42-0.02-0.85-0.03-1.27c-0.01-0.23-0.01-0.45-0.02-0.68c-0.01-0.32-0.01-0.65-0.02-0.97
          c0-0.18-0.01-0.37-0.01-0.55c0-0.04,0-0.08,0-0.12h-61.79v207.4h153.22V411.9z"
          />
          <polygon
            class="st0"
            points="1684.29,338.58 1690.21,338.58 1690.21,419.91 1684.25,419.91 		"
          />
          <polygon class="st0" points="1763.7,338.58 1713.82,338.58 1763.7,359.66 		" />
          <rect x="1639.42" y="338.58" class="st0" width="33.24" height="81.33" />
          <g>
            <path
              class="st0"
              d="M1570.62,319.93l-3.42-2.44l0.05-0.09c-1.07,0.13-2.49,0.15-4.11,0.09v27.04c0.5,0,1.01,0,1.5,0
            L1570.62,319.93z"
            />
            <path
              class="st0"
              d="M1563.87,312.92l-0.73-0.33v0.2C1563.38,312.83,1563.64,312.87,1563.87,312.92z"
            />
            <path
              class="st0"
              d="M1621.18,338.58l-52.06-23.31c0.18,0.37,0.23,0.77,0.17,1.21c-0.02,0.16-0.13,0.3-0.3,0.42l5.83,3.58
            l-8.3,31.51l1.97-7.47c7.67,0,14.11,0,17.96,0c2.14,0,6.7,2.98,10.05,5.95c3.35,2.98,14.55,6.06,14.55,6.06
            c0.55,0,0.99,0.44,0.99,0.99v11.83c0,1.04-0.84,1.88-1.88,1.88h-47.02v48.66h63.63v-81.33H1621.18z"
            />
          </g>
          <path
            class="st0"
            d="M1548.71,382.58c1.11,0.08,2.06,0.19,2.8,0.32v-11.65h-2.8L1548.71,382.58L1548.71,382.58z"
          />
          <polygon
            class="st0"
            points="1539.02,344.54 1551.5,344.54 1551.5,316.5 1546.95,315.99 		"
          />
          <polygon
            class="st0"
            points="1548.29,311.16 1551.5,311.42 1551.5,307.38 1549.34,306.41 		"
          />
          <g>
            <path
              class="st0"
              d="M1538.74,305.35c-3.7-0.42-27.38-3.84-39.33-1.6c0,0,0,0,0,0c1.38,3.93,2.63,8.65,3.28,12.82l5.34-0.76
            c0,0,0,0,0,0c0,0,0,0,0,0c-1.34-4.06,10.96-4.88,19.41-5.15c5.07-0.17,10.4-0.1,16.2,0.21c-0.01,0-0.01,0-0.02,0l0.91-3.48
            l-2.91-2.06C1541.01,305.47,1540.11,305.51,1538.74,305.35z"
            />
            <path
              class="st0"
              d="M1534.83,344.54l7.55-28.93c-7.55-0.36-20.91-0.04-26.53,1.18c-2.02,0.44-3.47,0.66-4.55,0.7l-8.29,1.69
            c0,0,0,0,0,0c0.14,1.46,0.19,2.82,0.09,3.97c2.09,8.14,1.95,17.56,1.78,21.4C1511.49,344.54,1522.68,344.54,1534.83,344.54z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M1592.11,287.57c-1.6,0-2.89,1.25-2.98,2.83h3.67l-0.21-2.78C1592.43,287.59,1592.27,287.57,1592.11,287.57z
            "
            />
            <path
              class="st0"
              d="M1383.04,29v22.39h262.48v161.12H1477.3c1.4,10.51,1.44,22.98,0.32,28.21c5.77,3.44,6.88,27.53,6.88,27.53
            s12.37,19.53,12,28.84c0.29,0.48,0.6,1.07,0.9,1.72c0,0,0-0.01,0-0.01c7.13-0.83,25.5-0.67,37.7,1.24l-1.18-0.53v-9.12h52.95
            c0.09-2.82,2.39-5.08,5.23-5.08c0.11,0,0.21,0.03,0.32,0.03l0.15,1.97l-0.38-5.07c0-0.58,3.31-1.05,7.4-1.05
            c4.08,0,7.4,0.47,7.4,1.05l-0.61,8.14h61.41v-10.02h22.42V29H1383.04z"
            />
          </g>
        </g>
        <g>
          <path
            class="st0"
            d="M914.39,230.21c-0.97,4.1-3.66,14.99-3.84,19.2c-0.08,1.81-0.43,6.36,2.06,6.38c3.99,0.03,3.81-8.7,3.9-11.3
          c0.1-2.81,0.16-5.62-0.1-8.42l-0.83-2.42h-0.81v-3.44H914.39z"
          />
          <rect x="608.9" y="416.07" class="st0" width="216.73" height="3.84" />
          <path
            class="st0"
            d="M869.26,330.74c0-3.73,2.13-6.95,5.23-8.54V201.6v-15.28c-5.52-1.67-10.11-4.73-10.11-10.3
          c0-5.37,4.59-8.67,10.11-10.72V74.01c-3.1-1.59-5.23-4.81-5.23-8.54c0-3.73,2.13-6.95,5.23-8.54V29H551.33v271.67
          c0,7.61,5.43,13.96,12.63,15.37c0.37-5.09,0.99-8.44,1.69-8.44h11.12v-26.33l10.56-7.63h264.56c0,0-0.19,60.79-0.27,99.77
          c0,0,0,0,0,0c0.11-0.13,0.23-0.2,0.35-0.2h22.53v-33.95C871.39,337.69,869.26,334.46,869.26,330.74z"
          />
          <path
            class="st0"
            d="M897,391.61v-3.81l0.02-0.02h-45.07c-0.13,0-0.26-0.09-0.38-0.23c0,1.51-0.01,2.94-0.01,4.3
          c0.12-0.15,0.25-0.24,0.38-0.24H897z"
          />
          <path
            class="st0"
            d="M1034.2,391.61c-8.2-1.08-14.74-2.38-19.1-3.83c0,0,0.01,0,0.01,0h-53.65v3.82h72.72
          C1034.19,391.61,1034.2,391.61,1034.2,391.61z"
          />
          <path
            class="st0"
            d="M1009.06,383.6L1009.06,383.6c0,0.19,0.04,0.38,0.11,0.56C1009.1,383.97,1009.06,383.78,1009.06,383.6z"
          />
          <polygon
            class="st0"
            points="916.26,391.61 916.26,387.79 905.7,387.79 905.72,387.8 905.72,391.61 		"
          />
          <path
            class="st0"
            d="M979.98,248.82v124.41h10.18v-44.25c0-9.21,7.47-16.68,16.68-16.68h2.23v-70.51H987
          C983.12,241.79,979.98,244.94,979.98,248.82z"
          />
          <path
            class="st0"
            d="M1010.75,201.65c0.38,0.16,0.84,0.31,1.34,0.47C1011.59,201.97,1011.13,201.81,1010.75,201.65z"
          />
          <path
            class="st0"
            d="M1009.06,321.02h-3.15c-3.88,0-7.03,3.15-7.03,7.03v45.18h10.18V321.02z"
          />
          <path
            class="st0"
            d="M576.77,374.66v-28.73h-11.12c-0.74,0-1.39-3.7-1.75-9.23c-18.48-1.38-33.05-16.8-33.05-35.63V29h-27.72
          v345.66H576.77z"
          />
          <polygon
            class="st0"
            points="595.58,416.07 576.77,416.07 576.77,407.61 503.14,407.61 503.14,419.91 595.58,419.91 		"
          />
          <rect x="503.14" y="389.22" class="st0" width="73.63" height="3.82" />
          <path
            class="st0"
            d="M851.96,406.17c-0.17,0-0.33-0.14-0.48-0.38c0,0,0,0,0,0L849,416.07v0h-14.7v3.84h81.95v-13.74H851.96z"
          />
          <rect x="961.46" y="406.17" class="st0" width="75.51" height="13.74" />
          <rect x="1148.94" y="406.17" class="st0" width="176.25" height="13.74" />
          <path
            class="st0"
            d="M1325.18,387.79h-155.53c-4.35,1.45-10.89,2.75-19.07,3.82h174.6V387.79z"
          />
          <path
            class="st0"
            d="M1175.71,383.6L1175.71,383.6c0,0.19-0.04,0.38-0.11,0.56C1175.67,383.97,1175.71,383.78,1175.71,383.6z"
          />
          <path
            class="st0"
            d="M1130.49,408.45c-8.86,1.52-22.63,2.5-38.11,2.5c-15.99,0-30.17-1.04-38.99-2.65v11.6h77.1V408.45z"
          />
          <path
            class="st0"
            d="M1255.6,283.44v8.01v12.41c0,9.21-7.47,16.68-16.68,16.68h-26.05c0,0,0,0,0,0c-1.39,5.85-6.63,10.2-12.9,10.2
          c-6.27,0-11.52-4.35-12.9-10.2h-11.36v52.69h149.48V29h-69.58V283.44z"
          />
          <path
            class="st0"
            d="M1246.88,304.79v-13.04v-8.61V29h-13.26v196.16c0,9.21-7.47,16.68-16.68,16.68h-4.12
          c-1.47,5.74-6.66,9.98-12.85,9.98s-11.38-4.25-12.85-9.98h-11.42v69.98h12.26c0,0,0,0,0,0c2.12-4.51,6.69-7.63,12-7.63
          c5.31,0,9.88,3.13,12,7.63c0,0,0,0,0,0h27.87C1243.74,311.81,1246.88,308.67,1246.88,304.79z"
          />
          <path
            class="st0"
            d="M897,56.94V29h-13.79v27.94c3.1,1.59,5.23,4.81,5.23,8.54c0,3.73-2.13,6.95-5.23,8.54v89.03
          c5.52-0.95,10.11-1.04,10.11-1.04v26.17c0,0-4.47,0.07-4.41,0.07v4.18h-3.61v-4.32c0.1,0.01-1.28-0.09-2.09-0.17v13.66v9.37h2.12
          v-1.8h3.82v1.8h1.9v-1.8h3.82v1.8H897V74.01c-3.1-1.59-5.23-4.81-5.23-8.54C891.77,61.75,893.9,58.53,897,56.94z"
          />
          <path
            class="st0"
            d="M905.72,29v27.94c3.1,1.59,5.23,4.81,5.23,8.54c0,3.73-2.13,6.95-5.23,8.54V322.2
          c3.1,1.59,5.23,4.81,5.23,8.54c0,3.73-2.13,6.95-5.23,8.54v33.95h10.53v-31.99l0.6-5.12h43.77l0.84,5.12v31.99h9.79V249.75
          c0-9.21,7.47-16.68,16.68-16.68h21.13v-32.86c0,0.05,0.03,0.11,0.05,0.16c-0.01-0.05-0.05-0.11-0.05-0.16
          c0-0.03,0.02-0.06,0.03-0.09c0-0.14-0.03-0.28-0.03-0.41c0-1.59,31.94-33.94,83.32-33.94c49.47,0,83.32,30.4,83.32,33.94
          c0,0.14-0.02,0.28-0.03,0.41c0,0.03,0.03,0.06,0.03,0.09c0,0.05-0.03,0.11-0.05,0.16c0.01-0.05,0.05-0.11,0.05-0.16v32.91h12.16
          c0,0,0,0,0,0c2.08-4.63,6.71-7.85,12.11-7.85c5.4,0,10.03,3.23,12.11,7.85h5.79c3.88,0,7.03-3.15,7.03-7.03V29H905.72z
          M918.02,233.65h-2.44l0.83,2.42c0.26,2.8,0.2,5.61,0.1,8.42c-0.09,2.59,0.09,11.33-3.9,11.3c-2.49-0.02-2.14-4.56-2.06-6.38
          c0.19-4.21,2.88-15.1,3.84-19.2h-6.02v-6.22h-2.65v-7.69h2.65v-0.97h6.39v5.86h3.26V233.65z"
          />
          <path
            class="st0"
            d="M897,322.2v-105.9h-13.79v105.9c3.1,1.59,5.23,4.81,5.23,8.54c0,3.73-2.13,6.95-5.23,8.54v33.95H897v-33.95
          c-3.1-1.59-5.23-4.81-5.23-8.54C891.77,327.01,893.9,323.79,897,322.2z"
          />
        </g>
        <rect x="0.35" y="29" class="st0" width="29.58" height="448" />
        <rect x="1801.02" y="29" class="st0" width="29.58" height="448" />
        <rect x="1355.51" y="29" class="st0" width="27.53" height="421.86" />
        <rect x="411.88" y="29" class="st0" width="29.02" height="448" />
        <path
          class="st0"
          d="M1325.18,29v344.22h2.09c0.84,0,1.51,3.26,1.51,7.28s-0.68,7.28-1.51,7.28h-2.09v3.82h2.09
        c0.84,0,1.51,3.26,1.51,7.28s-0.68,7.28-1.51,7.28h-2.09v13.74l30.33,30.95V29H1325.18z"
        />
        <path
          class="st0"
          d="M501.96,374.66h1.18V29h-62.23v448l18.73-17.18l-0.12-374.63l29.58-3.53v351.14l-10.77,9.88l24.82-22.77v-12.3
        h-1.18c-0.84,0-1.51-3.26-1.51-7.28s0.68-7.28,1.51-7.28h1.18v-3.82h-1.18c-0.84,0-1.51-3.26-1.51-7.28
        S501.12,374.66,501.96,374.66z"
        />
        <path
          class="st0"
          d="M143.23,244.61c-1.7,0-3.07-0.24-3.07-0.53v-3.86h1.23v-2.95l2.67-0.48v0l-16.71,2.97
        c0.21,0.06,0.32,0.13,0.32,0.21v6.49c0,0.17-0.57,0.31-1.49,0.42c0,0,0,0,0,0v14.26l5.97-1.7h11.91v-14.84
        C143.8,244.6,143.53,244.61,143.23,244.61z"
        />
        <path
          class="st0"
          d="M565.65,345.93c-0.74,0-1.39-3.7-1.75-9.23l0,0c-18.48-1.38-33.05-16.8-33.05-35.63V29h-27.72v345.66h73.63
        v-28.73H565.65z"
        />
        <rect x="0.35" y="0.35" class="st0" width="1830.25" height="28.65" />
        <path
          class="st0"
          d="M1550.51,411.9v3.12v4.88h0.99v-8.15C1551.21,411.81,1550.88,411.86,1550.51,411.9z"
        />
        <polygon class="st0" points="1758.82,453.88 1758.82,455.82 1763.7,458.26 1763.7,453.88 	" />
        <g>
          <path
            class="st0"
            d="M104.86,493.2v0.7c0,2.04,1.65,3.69,3.69,3.69h0.65c2.04,0,3.69-1.65,3.69-3.69v-13.09h-9.83V477H0.35v7.65
          h95.96C101.03,484.65,104.86,488.48,104.86,493.2z"
          />
          <path
            class="st0"
            d="M128,477v3.81h-10.24v12.76c0,4.91-3.98,8.9-8.9,8.9h0c-4.91,0-8.9-3.98-8.9-8.9c0-2.23-1.81-4.04-4.04-4.04
          H0.35v20.11H1830.6V477H128z"
          />
        </g>
        <g id="doer_00000083079516730531827770000001405144831769121184_">
          <polygon class="st0" points="459.64,459.82 459.51,85.19 489.09,81.65 489.09,432.79 		" />
          <g>
            <circle class="st0" cx="470.63" cy="280.35" r="2.98" />
            <path
              class="st0"
              d="M465.08,279.65c0-3.62,1.05-6.56,2.35-6.56h-2.84c-1.3,0-2.35,2.94-2.35,6.56s1.05,6.56,2.35,6.56h2.84
            C466.13,286.21,465.08,283.27,465.08,279.65z"
            />
            <path
              class="st0"
              d="M465.28,281.93h2.84c-0.29-0.46-0.46-1-0.46-1.58c0-1.64,1.33-2.98,2.98-2.98h-5.35"
            />
            <path
              class="st0"
              d="M467.65,280.35c0-1.3,0.84-2.39,2-2.8c-0.31-2.59-1.19-4.46-2.22-4.46c-1.3,0-2.35,2.94-2.35,6.56
            s1.05,6.56,2.35,6.56c0.85,0,1.59-1.26,2-3.14C468.38,282.61,467.65,281.57,467.65,280.35z"
            />
            <g>
              <path
                class="st0"
                d="M472.02,282.97l0.01,0.01l0.07-0.05C472.07,282.94,472.04,282.95,472.02,282.97z"
              />
              <path
                class="st0"
                d="M477.27,276.18l-0.83-0.94c-0.69-0.78-1.84-0.93-2.7-0.37l-4.09,2.68c0.31-0.11,0.63-0.18,0.98-0.18
              c1.64,0,2.98,1.33,2.98,2.98c0,0.8-0.32,1.53-0.84,2.06l4.21-3.2C477.94,278.48,478.08,277.08,477.27,276.18z"
              />
            </g>
          </g>
        </g>
      </g>
      <g id="KK-7-0" class="poster-obj" @click="$store.commit('setModal', 'S.6.0')">
        <path
          id="plakat_00000129174026689091677560000015349116550490006161_"
          class="st0 poster"
          d="M1383.04,51.39v161.12h61.79
        c-0.14-6.38-0.25-12.18-0.25-15.12c0,0,3.04-3.92,10.41-4.38c0.14-0.01,0.27-0.01,0.4,0c2.86,0.2,13.88,1.19,18.04,4.93
        c1.13,1.01,1.66,2.45,1.75,4.17c0.9,2.89,1.6,6.51,2.12,10.4h168.23V51.39H1383.04z"
        />
        <g id="diagram_00000019664780010659298800000007107253021721370246_">
          <line class="st0" x1="1598.64" y1="159.63" x2="1611.46" y2="159.63" />
          <line class="st0" x1="1541.15" y1="159.63" x2="1585.82" y2="159.63" />
          <line class="st0" x1="1483.86" y1="159.63" x2="1528.7" y2="159.63" />
          <line class="st0" x1="1440.35" y1="159.63" x2="1470.14" y2="159.63" />
          <line class="st0" x1="1425.92" y1="159.63" x2="1433.98" y2="159.63" />
          <line class="st0" x1="1397.15" y1="159.63" x2="1405.93" y2="159.63" />
          <polygon class="st0" points="1397.15,165.2 1397.15,154.06 1392.45,159.63 		" />
          <polygon class="st0" points="1405.93,156.27 1405.93,162.98 1416.05,159.63 		" />
          <polygon class="st0" points="1425.92,162.97 1425.92,156.27 1415.8,159.62 		" />
          <g>
            <polygon class="st0" points="1492.61,136.72 1485.91,136.72 1489.26,144.23 			" />
            <polygon class="st0" points="1485.92,151.56 1492.62,151.56 1489.27,144.05 			" />
          </g>
          <polygon class="st0" points="1631.44,162.98 1631.44,156.27 1621.33,159.63 		" />
          <polygon class="st0" points="1611.46,156.28 1611.46,162.99 1621.57,159.64 		" />
          <circle class="st0" cx="1437.16" cy="159.63" r="3.19" />
          <g>
            <line class="st0" x1="1605.62" y1="152.2" x2="1605.62" y2="159.63" />
            <circle class="st0" cx="1605.62" cy="143.15" r="9.06" />
            <g>
              <path d="M1602.09,140.76h-2.19v-0.71h5.22v0.71h-2.2v6.13h-0.83V140.76z" />
              <path d="M1608.31,140.76h-2.19v-0.71h5.22v0.71h-2.2v6.13h-0.83V140.76z" />
            </g>
          </g>
          <g>
            <line class="st0" x1="1579.78" y1="152.2" x2="1579.78" y2="159.63" />
            <circle class="st0" cx="1579.78" cy="143.15" r="9.06" />
            <g>
              <path d="M1575.89,140.76h-2.19v-0.71h5.22v0.71h-2.2v6.13h-0.83V140.76z" />
              <path
                d="M1580.8,140.04h2.62c1.63,0,2.4,0.87,2.4,2.07c0,1.31-0.89,2.33-2.62,2.33c-0.56,0-1.19-0.15-1.59-0.25v2.7h-0.81
              V140.04z M1584.99,142.14c0-0.8-0.52-1.4-1.6-1.4h-1.78v2.69c0.36,0.13,1.07,0.28,1.58,0.28
              C1584.43,143.72,1584.99,142.95,1584.99,142.14z"
              />
            </g>
          </g>
          <g>
            <line class="st0" x1="1557.8" y1="152.2" x2="1557.8" y2="159.63" />
            <circle class="st0" cx="1557.8" cy="143.15" r="9.06" />
            <g>
              <path d="M1554.27,140.76h-2.19v-0.71h5.22v0.71h-2.2v6.13h-0.83V140.76z" />
              <path d="M1560.49,140.76h-2.19v-0.71h5.22v0.71h-2.2v6.13h-0.83V140.76z" />
            </g>
          </g>
          <g>
            <line class="st0" x1="1470.14" y1="152.2" x2="1470.14" y2="159.63" />
            <circle class="st0" cx="1470.14" cy="143.15" r="9.06" />
            <g>
              <path d="M1467.93,140.76h-2.19v-0.71h5.22v0.71h-2.2v6.13h-0.83V140.76z" />
              <path d="M1472.84,140.04h0.82v6.84h-0.82V140.04z" />
            </g>
          </g>
          <g>
            <line class="st0" x1="1510.36" y1="152.2" x2="1510.36" y2="159.63" />
            <circle class="st0" cx="1510.36" cy="143.15" r="9.06" />
            <g>
              <path d="M1505.42,140.04h4.44v0.71h-3.62v2.28h3.04v0.71h-3.04v3.14h-0.81V140.04z" />
              <path d="M1513.14,140.76h-2.19v-0.71h5.22v0.71h-2.2v6.13h-0.83V140.76z" />
            </g>
          </g>
          <line class="st0" x1="1489.26" y1="159.62" x2="1489.26" y2="151.56" />
          <line class="st0" x1="1489.26" y1="136.72" x2="1489.26" y2="127.46" />
          <circle class="st0" cx="1489.26" cy="118.37" r="9.06" />
          <g>
            <path
              d="M1485.38,115.27h2.62c1.63,0,2.4,0.87,2.4,2.07c0,1.31-0.89,2.33-2.62,2.33c-0.56,0-1.19-0.15-1.59-0.25v2.7h-0.81
            V115.27z M1489.57,117.37c0-0.8-0.52-1.4-1.6-1.4h-1.78v2.69c0.36,0.13,1.07,0.28,1.58,0.28
            C1489.01,118.95,1489.57,118.18,1489.57,117.37z"
            />
            <path d="M1492.32,115.27h0.82v6.84h-0.82V115.27z" />
          </g>
          <g>
            <g>
              <line class="st2" x1="1455.65" y1="159.62" x2="1455.65" y2="157.58" />
              <line class="st3" x1="1455.65" y1="153.21" x2="1455.65" y2="102.92" />
              <polyline class="st2" points="1455.65,100.74 1455.65,98.7 1457.69,98.7 				" />
              <line class="st4" x1="1461.61" y1="98.7" x2="1553.81" y2="98.7" />
              <polyline class="st2" points="1555.77,98.7 1557.8,98.7 1557.8,100.74 				" />
              <line class="st5" x1="1557.8" y1="105.21" x2="1557.8" y2="129.82" />
              <line class="st2" x1="1557.8" y1="132.05" x2="1557.8" y2="134.09" />
            </g>
          </g>
          <polyline
            class="st6"
            points="1459.82,159.62 1459.82,103.59 1510.36,103.59 1510.36,134.09 		"
          />
          <line class="st0" x1="1475.73" y1="159.63" x2="1478.84" y2="159.63" />
          <line class="st0" x1="1533.19" y1="159.63" x2="1536.3" y2="159.63" />
          <line class="st0" x1="1591.09" y1="159.63" x2="1594.2" y2="159.63" />
        </g>
      </g>
      <g id="KK-6-0" class="hover-obj" @click="$store.commit('setModal', 'S.5.0')">
        <g id="tastatur_00000054949040922521316630000001553678763732403846_">
          <polygon
            class="st0"
            points="1677.23,306.88 1677.23,312.61 1643.12,312.61 1643.12,309.74 		"
          />
          <polygon
            class="st0"
            points="1643.12,309.74 1619.12,300.05 1619.12,302.77 1643.12,312.61 		"
          />
          <polygon
            class="st0"
            points="1677.23,306.88 1650.86,296.97 1619.12,300.05 1643.12,309.74 		"
          />
        </g>
        <g id="computer_00000090260770713737605330000007814301746648509348_">
          <rect x="1700.72" y="291.33" class="st0" width="57.07" height="22.23" />
          <polygon
            class="st0"
            points="1723.6,286.93 1731.83,286.93 1731.83,282.7 1757.79,291.33 1700.72,291.33 1667.79,280.37 
          1715.93,280.37 1715.93,284.14 		"
          />
          <polygon
            class="st0"
            points="1723.6,269.96 1723.6,286.93 1715.93,284.14 1715.93,267.59 		"
          />
          <polygon
            class="st0"
            points="1731.83,286.93 1723.6,286.93 1723.6,269.96 1729.95,271.93 1731.83,271.93 		"
          />
          <path
            class="st0"
            d="M1729.95,271.93c-0.35-0.14-38.86-12-38.86-12l3.14-74.3l39.42,8.37L1729.95,271.93z"
          />
          <polyline
            class="st0"
            points="1733.65,194 1742.23,194.04 1738.32,271.93 1729.95,271.93 1733.65,194 		"
          />
          <polygon
            class="st0"
            points="1694.23,185.63 1703.02,185.63 1742.23,194.04 1733.65,194 		"
          />
          <path
            class="st0"
            d="M1667.79,280.37v21l32.93,12.19v-22.23C1700.72,291.33,1668.21,280.44,1667.79,280.37z M1671.19,295.24
          c-0.81,0-1.47-1.44-1.47-3.22c0-1.78,0.66-3.22,1.47-3.22s1.47,1.44,1.47,3.22C1672.66,293.8,1672,295.24,1671.19,295.24z"
          />
          <ellipse class="st0" cx="1671.19" cy="292.02" rx="1.47" ry="3.22" />
        </g>
        <g id="kaffekop_00000143575643990675866030000003432872777953061788_">
          <ellipse class="st0" cx="1599.59" cy="282.26" rx="7.4" ry="1.05" />
          <path
            class="st0"
            d="M1599.59,283.3c4.08,0,7.4-0.47,7.4-1.05l-1.3,17.42c0,0.48-2.73,0.86-6.1,0.86s-6.1-0.39-6.1-0.86
          l-1.3-17.42C1592.19,282.83,1595.51,283.3,1599.59,283.3z"
          />
          <path
            class="st0"
            d="M1592.11,285.32c0.11,0,0.21,0.03,0.32,0.03l0.17,2.27c-0.16-0.03-0.32-0.05-0.48-0.05c-1.66,0-3,1.34-3,3
          c0,1.66,1.34,3,3,3c0.32,0,0.63-0.06,0.92-0.16l0.17,2.29c-0.35,0.07-0.71,0.12-1.09,0.12c-2.9,0-5.25-2.35-5.25-5.25
          S1589.21,285.32,1592.11,285.32z"
          />
        </g>
        <g id="bord_00000045583920849211480730000007580796718132550077_">
          <rect x="1621.18" y="327.98" class="st0" width="177.12" height="10.6" />
          <path
            class="st0"
            d="M1626.77,338.58v132.6c0,0.72,2.83,1.3,6.33,1.3s6.33-0.58,6.33-1.3v-132.6H1626.77z"
          />
          <path
            class="st0"
            d="M1763.7,338.58v132.6c0,0.72,2.83,1.3,6.33,1.3s6.33-0.58,6.33-1.3v-132.6H1763.7z"
          />
          <path
            class="st0"
            d="M1672.66,338.58v84.53c0,0.66,2.6,1.2,5.82,1.2s5.82-0.54,5.82-1.2v-84.53H1672.66z"
          />
          <g>
            <path
              class="st0"
              d="M1551.5,344.54c3.99,0,7.91,0,11.63,0v-27.04c-3.46-0.12-7.83-0.56-11.63-0.99V344.54z"
            />
            <path
              class="st0"
              d="M1555.9,311.84c2.99,0.31,5.36,0.61,7.24,0.95v-0.2l-11.63-5.21v4.04
            C1552.94,311.55,1554.4,311.68,1555.9,311.84z"
            />
          </g>
          <path
            class="st0"
            d="M1533.93,290.4v9.12l1.18,0.53c2.4,0.37,4.56,0.82,6.35,1.33c1.34,0.38,1.57,1.43,1.53,2.2l20.88,9.35
          c3.21,0.63,4.75,1.36,5.26,2.35l52.06,23.31v-10.6L1533.93,290.4z"
          />
          <path
            class="st0"
            d="M1757.79,313.56h-57.07l-32.93-12.19V290.4h-61.41l-0.69,9.28c0,0.48-2.73,0.86-6.1,0.86s-6.1-0.39-6.1-0.86
          l-0.3-3.97c-0.35,0.07-0.71,0.12-1.09,0.12c-2.9,0-5.25-2.35-5.25-5.25c0-0.06,0.02-0.11,0.02-0.17h-52.95l87.26,37.58h177.12
          L1757.79,313.56z M1677.23,312.61h-34.12l-24-9.84v-2.72l31.74-3.08l26.37,9.92V312.61z"
          />
          <g>
            <path
              class="st0"
              d="M1553.16,383.51c0,0.03-0.02,0.07-0.05,0.1C1553.14,383.58,1553.16,383.55,1553.16,383.51z"
            />
            <path
              class="st0"
              d="M1551.5,371.24v11.65c1.03,0.18,1.65,0.39,1.65,0.62v27.63c0,0.23-0.61,0.44-1.65,0.62v11.35
            c0,0.66,2.6,1.2,5.82,1.2c3.21,0,5.82-0.54,5.82-1.2v-51.86H1551.5z"
            />
            <path class="st0" d="M1552.93,383.75" />
          </g>
        </g>
        <path
          class="st0"
          d="M1592.8,290.4h-3.67c0,0.06-0.02,0.11-0.02,0.17c0,1.66,1.34,3,3,3c0.32,0,0.63-0.06,0.92-0.16L1592.8,290.4z"
        />
      </g>
      <g id="KK-5-0" class="hover-obj" @click="$store.commit('setModal', 'E.4.1')">
        <path
          class="st0"
          d="M565.65,307.61c-1.13,0-2.04,8.58-2.04,19.16c0,10.58,0.92,19.16,2.04,19.16h11.12v-38.33H565.65z"
        />
        <path
          class="st0"
          d="M563.61,326.77c0-3.97,0.13-7.66,0.35-10.72c-7.2-1.42-12.63-7.76-12.63-15.37V29h-20.47v272.07
        c0,18.83,14.57,34.25,33.05,35.63C563.72,333.8,563.61,330.41,563.61,326.77z"
        />
        <g>
          <path
            class="st0"
            d="M595.58,416.07V430c0,0.37,1.55,0.66,3.45,0.66c1.91,0,3.45-0.3,3.45-0.66v-13.93H595.58z"
          />
          <path
            class="st0"
            d="M602.49,416.07v6.27c0,0.34,1.43,0.61,3.2,0.61c1.77,0,3.2-0.28,3.2-0.61v-6.27H602.49z"
          />
          <path
            class="st0"
            d="M825.63,416.07V430c0,0.37,1.55,0.66,3.45,0.66c1.91,0,3.45-0.3,3.45-0.66v-13.93H825.63z"
          />
          <path class="st0" d="M832.53,416.07v6.82c1.04-0.1,1.77-0.31,1.77-0.55v-6.27H832.53z" />
          <polygon class="st0" points="576.77,281.28 587.33,273.65 851.88,273.65 849,281.28 		" />
          <path
            class="st0"
            d="M850.44,398.89c0-3.39,0.48-6.22,1.13-7.04c0-1.36,0-2.79,0.01-4.3c-0.65-0.8-1.14-3.65-1.14-7.05
          c0-3.45,0.5-6.33,1.17-7.08c0.09-38.98,0.27-99.77,0.27-99.77l-2.88,7.63v134.79l2.48-10.28
          C850.88,404.83,850.44,402.11,850.44,398.89z"
          />
          <rect x="698.71" y="336.81" class="st0" width="7.16" height="21.49" />
          <rect x="591.23" y="336.81" class="st0" width="7.16" height="21.49" />
          <rect x="719.88" y="336.81" class="st0" width="7.16" height="21.49" />
          <path
            class="st0"
            d="M576.77,281.28v134.79H849V281.28H576.77z M598.4,358.3h-7.16v-21.49h7.16V358.3z M705.87,358.3h-7.16v-21.49
          h7.16V358.3z M727.05,358.3h-7.16v-21.49h7.16V358.3z M833.65,358.3h-7.16v-21.49h7.16V358.3z"
          />
          <rect x="826.49" y="336.81" class="st0" width="7.16" height="21.49" />
        </g>
        <line class="st0" x1="548.84" y1="332.08" x2="559.45" y2="314.4" />
        <line class="st0" x1="537" y1="321.1" x2="552.31" y2="306.14" />
        <line class="st0" x1="530.86" y1="301.07" x2="551.33" y2="299" />
        <line class="st0" x1="530.86" y1="286.21" x2="551.33" y2="284.77" />
        <line class="st0" x1="530.86" y1="271.46" x2="551.33" y2="270.01" />
        <line class="st0" x1="530.86" y1="256.7" x2="551.33" y2="255.26" />
        <line class="st0" x1="530.86" y1="241.95" x2="551.33" y2="240.51" />
        <line class="st0" x1="530.86" y1="227.19" x2="551.33" y2="225.75" />
        <line class="st0" x1="530.86" y1="212.44" x2="551.33" y2="211" />
        <line class="st0" x1="530.86" y1="197.68" x2="551.33" y2="196.24" />
        <line class="st0" x1="530.86" y1="182.93" x2="551.33" y2="181.49" />
        <line class="st0" x1="530.86" y1="168.18" x2="551.33" y2="166.73" />
        <line class="st0" x1="530.86" y1="153.42" x2="551.33" y2="151.98" />
        <line class="st0" x1="530.86" y1="138.67" x2="551.33" y2="137.23" />
        <line class="st0" x1="530.86" y1="123.91" x2="551.33" y2="122.47" />
        <line class="st0" x1="530.86" y1="109.16" x2="551.33" y2="107.72" />
        <line class="st0" x1="530.86" y1="94.41" x2="551.33" y2="92.96" />
        <line class="st0" x1="530.86" y1="79.65" x2="551.33" y2="78.21" />
        <line class="st0" x1="530.86" y1="64.9" x2="551.33" y2="63.46" />
        <line class="st0" x1="530.86" y1="50.14" x2="551.33" y2="48.7" />
        <line class="st0" x1="530.86" y1="35.39" x2="551.33" y2="33.95" />
        <line class="st0" x1="712.88" y1="281.93" x2="712.88" y2="416.07" />
      </g>
      <g id="KK-3-2" class="hover-obj" @click="$store.commit('setModal', 'E.3.1')">
        <g>
          <path
            class="st0"
            d="M1092.38,207.36c-46.02,0-83.32-3.2-83.32-7.15V383.6c0,6.19,37.31,11.2,83.32,11.2s83.32-5.02,83.32-11.2
          V200.21C1175.71,204.16,1138.4,207.36,1092.38,207.36z M1098.21,218.82c7.33,0,13.28,5.94,13.28,13.28s-5.94,13.28-13.28,13.28
          c-7.33,0-13.28-5.94-13.28-13.28S1090.87,218.82,1098.21,218.82z M1076.07,266.37h43.72v21.58h-43.72V266.37z M1098.21,380.99
          c-15.08,0-28.11-8.79-34.23-21.53c-3-5.43-4.7-11.67-4.7-18.31c0-20.97,17-37.97,37.97-37.97c15.08,0,28.11,8.79,34.23,21.53
          c0,0,0,0,0,0c3,5.43,4.71,11.67,4.71,18.31C1136.17,363.99,1119.18,380.99,1098.21,380.99z"
          />
        </g>
        <circle class="st0" cx="1098.21" cy="343.02" r="37.97" />
        <path
          class="st0"
          d="M1060.24,343.02c0-20.97,17-37.97,37.97-37.97c14.33,0,26.8,7.94,33.26,19.66
        c-6.13-12.74-19.15-21.53-34.23-21.53c-20.97,0-37.97,17-37.97,37.97c0,6.64,1.71,12.88,4.7,18.31
        C1061.58,354.49,1060.24,348.91,1060.24,343.02z"
        />
        <path
          class="st0"
          d="M1175.68,200.12c0-0.14,0.03-0.28,0.03-0.41c0-3.55-33.86-33.94-83.32-33.94c-51.38,0-83.32,32.35-83.32,33.94
        c0,0.14,0.02,0.28,0.03,0.41c0,0.03-0.03,0.06-0.03,0.09c0,3.95,37.31,7.15,83.32,7.15s83.32-3.2,83.32-7.15
        C1175.71,200.18,1175.69,200.15,1175.68,200.12z"
        />
        <path
          class="st0"
          d="M1092.38,394.8c-14.09,0-27.36-0.47-38.99-1.3v14.81c8.82,1.61,23,2.65,38.99,2.65
        c18.24,0,34.13-1.36,42.38-3.36v-14.36C1122.35,394.23,1107.87,394.8,1092.38,394.8z"
        />
        <g>
          <path
            class="st0"
            d="M1130.49,427.05c0,0.6,1.76,1.12,4.28,1.34v-20.8c-1.25,0.3-2.7,0.59-4.28,0.86V427.05z"
          />
        </g>
        <path
          class="st0"
          d="M1039.23,392.22v44.6c0,0.81,3.17,1.46,7.08,1.46s7.08-0.65,7.08-1.46V393.5
        C1048.34,393.14,1043.59,392.71,1039.23,392.22z"
        />
        <path
          class="st0"
          d="M1134.77,393.24v43.59c0,0.81,3.17,1.46,7.08,1.46s7.08-0.65,7.08-1.46v-45.01
        C1144.61,392.36,1139.87,392.83,1134.77,393.24z"
        />
        <path
          class="st0"
          d="M1036.97,391.96v35.29c0,0.42,0.87,0.8,2.26,1.07v-36.09C1038.46,392.14,1037.71,392.05,1036.97,391.96z"
        />
        <g id="roer_00000021809469728247383970000011814985219304842635_">
          <g>
            <path
              class="st0"
              d="M1186.7,238.54c0-1.93,0.42-3.77,1.17-5.43h-12.16v8.72h11.42C1186.86,240.78,1186.7,239.68,1186.7,238.54z"
            />
            <path
              class="st0"
              d="M1224.9,29v197.09c0,3.88-3.15,7.03-7.03,7.03h-5.79c0.74,1.66,1.17,3.49,1.17,5.43
            c0,1.14-0.16,2.24-0.43,3.3h4.12c9.21,0,16.68-7.47,16.68-16.68V29H1224.9z"
            />
          </g>
          <path
            class="st0"
            d="M1186.7,317.46c0-2.02,0.46-3.93,1.27-5.64h-12.26v8.72h11.36C1186.84,319.55,1186.7,318.52,1186.7,317.46z"
          />
          <path
            class="st0"
            d="M1246.88,29v254.14v8.61v13.04c0,3.88-3.15,7.03-7.03,7.03h-27.87c0.81,1.72,1.27,3.62,1.27,5.65
          c0,1.06-0.14,2.09-0.37,3.08h26.05c9.21,0,16.68-7.47,16.68-16.68v-12.41v-8.01V29H1246.88z"
          />
          <path
            class="st0"
            d="M1199.98,225.27c-7.33,0-13.28,5.94-13.28,13.28c0,7.33,5.94,13.28,13.28,13.28
          c7.33,0,13.28-5.94,13.28-13.28C1213.25,231.21,1207.31,225.27,1199.98,225.27z M1198.7,247.85l-2.22-0.72l6.27-18.64
          L1198.7,247.85z"
          />
          <polygon class="st0" points="1202.75,228.49 1196.48,247.13 1198.7,247.85 		" />
          <path
            class="st0"
            d="M1199.98,304.18c-7.33,0-13.28,5.94-13.28,13.28c0,7.33,5.94,13.28,13.28,13.28
          c7.33,0,13.28-5.94,13.28-13.28C1213.25,310.13,1207.31,304.18,1199.98,304.18z M1202.76,326.45l-7.52-18.17l9.67,17.26
          L1202.76,326.45z"
          />
          <polygon class="st0" points="1195.23,308.28 1202.76,326.45 1204.9,325.54 		" />
        </g>
        <g>
          <rect x="119.83" y="228.47" class="st0" width="3.38" height="6.12" />
          <polygon class="st0" points="145.07,240.21 141.4,240.21 141.4,237.26 145.07,236.6 		" />
          <path
            class="st0"
            d="M140.16,240.21v3.86c0,0.3,1.37,0.53,3.07,0.53s3.07-0.24,3.07-0.53v-3.86H140.16z"
          />
          <path
            class="st0"
            d="M122.51,240.56c-2.86,0-5.17-0.27-5.17-0.6v6.49c0,0.33,2.32,0.6,5.17,0.6c2.86,0,5.17-0.27,5.17-0.6v-6.49
          C127.69,240.29,125.37,240.56,122.51,240.56z"
          />
          <g>
            <polygon class="st0" points="125.95,239.51 126.2,239.95 125.95,239.51 			" />
            <path
              class="st0"
              d="M127.36,239.75l-1.16,0.21l-3-5.37h-3.37l-0.92,4.94c-0.97,0.11-1.57,0.26-1.57,0.43
            c0,0.33,2.32,0.6,5.17,0.6c2.86,0,5.17-0.27,5.17-0.6C127.69,239.88,127.57,239.81,127.36,239.75z"
            />
          </g>
          <polygon class="st0" points="123.21,234.58 146.72,231.14 146.72,236.3 126.2,239.95 		" />
          <path
            class="st0"
            d="M122.51,247.05c-1.44,0-2.75-0.07-3.69-0.18v16.81h0.05c0.26,0.31,1.78,0.55,3.63,0.55
          c1.86,0,3.38-0.24,3.63-0.55h0.05v-16.81C125.26,246.98,123.96,247.05,122.51,247.05z"
          />
          <path
            class="st0"
            d="M123.21,228.47c0,0-1-3.7,16.49-7c1.3-0.28,2.05-0.7,1.7-1.26c-0.35-0.56-14.09-1.86-21.57,4.65
          c-1.66,1.67,0,3.6,0,3.6H123.21z"
          />
        </g>
        <path
          class="st0"
          d="M123.37,294.67v142.14c0,0.54,1.77,0.98,3.95,0.98c2.18,0,3.95-0.44,3.95-0.98V294.67H123.37z"
        />
        <path
          class="st0"
          d="M131.28,294.67v140.53c0.06,0,0.12,0,0.19,0c2.18,0,3.95-0.44,3.95-0.98V294.67H131.28z"
        />
        <g>
          <path
            class="st0"
            d="M63.42,307.42v154.16c0,0.33,1,0.59,2.23,0.59c1.23,0,2.23-0.27,2.23-0.59V307.42H63.42z"
          />
          <path
            class="st0"
            d="M69.84,307.42l-1.95,0l0,0v152.5c0.41,0.12,1,0.2,1.67,0.2c1.23,0,2.23-0.27,2.23-0.59V306.74L69.84,307.42z"
          />
        </g>
        <path
          class="st0"
          d="M99.16,129.62c-8.24,1.05-14.86,2.38-14.79,2.97l0.5,3.92c0.07,0.59,6.82,0.21,15.06-0.85
        c8.24-1.05,14.86-2.38,14.79-2.97l-0.5-3.92C114.14,128.18,107.4,128.56,99.16,129.62z"
        />
        <path
          class="st0"
          d="M65.51,285.17v1.06c0,0.4,1.22,0.72,2.72,0.72c1.5,0,2.72-0.32,2.72-0.72v-1.7"
        />
        <path
          class="st0"
          d="M70.95,284.54V129.65c0-6.04,4.89-10.93,10.93-10.93h4.17c4.32,0,7.9,3.15,8.56,7.28l0.97,4.11
        c1.15-0.17,2.34-0.33,3.57-0.49c0.6-0.08,1.18-0.15,1.76-0.22l-1.32-5.38c-1.77-6.2-7.48-10.74-14.25-10.74h-1.73
        c-10,0-18.1,8.1-18.1,18.1l0,153.79"
        />
        <rect x="1076.07" y="266.37" class="st0" width="43.72" height="21.58" />
        <path
          class="st0"
          d="M1098.21,218.82c-7.33,0-13.28,5.94-13.28,13.28s5.94,13.28,13.28,13.28c7.33,0,13.28-5.94,13.28-13.28
        S1105.54,218.82,1098.21,218.82z M1105.15,238.25l-15.5-12.11l16.92,10.25L1105.15,238.25z"
        />
        <polygon class="st0" points="1106.57,236.39 1089.65,226.14 1105.15,238.25 	" />
      </g>
      <g id="KK-3-1" class="hover-obj" @click="$store.commit('setModal', 'E.2.1')">
        <g id="_x33_-0_x5F_radiator_x5F_2_00000067219628105096228600000002366037595190177194_">
          <rect x="229.62" y="307.47" class="st0" width="182.26" height="103.07" />
          <line class="st0" x1="240.79" y1="314.4" x2="240.79" y2="403.61" />
          <line class="st0" x1="390.9" y1="314.4" x2="390.9" y2="403.61" />
          <line class="st0" x1="369.45" y1="314.4" x2="369.45" y2="403.61" />
          <line class="st0" x1="348.01" y1="314.4" x2="348.01" y2="403.61" />
          <line class="st0" x1="326.56" y1="314.4" x2="326.56" y2="403.61" />
          <line class="st0" x1="305.12" y1="314.4" x2="305.12" y2="403.61" />
          <line class="st0" x1="283.68" y1="314.4" x2="283.68" y2="403.61" />
          <line class="st0" x1="262.23" y1="314.4" x2="262.23" y2="403.61" />
          <line class="st0" x1="251.51" y1="314.4" x2="251.51" y2="403.61" />
          <line class="st0" x1="272.95" y1="314.4" x2="272.95" y2="403.61" />
          <line class="st0" x1="294.4" y1="314.4" x2="294.4" y2="403.61" />
          <line class="st0" x1="315.84" y1="314.4" x2="315.84" y2="403.61" />
          <line class="st0" x1="337.29" y1="314.4" x2="337.29" y2="403.61" />
          <line class="st0" x1="358.73" y1="314.4" x2="358.73" y2="403.61" />
          <line class="st0" x1="380.18" y1="314.4" x2="380.18" y2="403.61" />
          <line class="st0" x1="401.62" y1="314.4" x2="401.62" y2="403.61" />
          <polygon class="st0" points="229.62,307.47 238.37,304.81 411.88,304.81 411.88,307.47 		" />
          <path
            class="st0"
            d="M248.34,410.54v13.45c0,0.27,1.14,0.49,2.55,0.49c1.41,0,2.55-0.22,2.55-0.49v-13.45H248.34z"
          />
          <g>
            <path
              class="st0"
              d="M229.62,337.29c-1.05-1.66-2.89-2.76-4.99-2.76c-3.26,0-5.91,2.64-5.91,5.91s2.64,5.91,5.91,5.91
            c2.1,0,3.95-1.1,4.99-2.76V337.29z"
            />
            <path
              class="st0"
              d="M222.41,334.97L222.41,334.97c0.68-0.27,1.43-0.43,2.22-0.43c2.1,0,3.95,1.1,4.99,2.76v-5.08L222.41,334.97z
            "
            />
          </g>
          <path
            class="st0"
            d="M253.44,422.55L253.44,422.55l0,1.43c0,0.27-1.14,0.49-2.55,0.49s-2.55-0.22-2.55-0.49v-1.47l-0.01,0
          c-2.23,0.32-3.79,1.08-3.79,1.96c0,1.17,2.78,2.13,6.21,2.13s6.21-0.95,6.21-2.13C256.97,423.62,255.53,422.89,253.44,422.55z"
          />
        </g>
        <g id="_x33_-0_x5F_radiator_x5F_1_00000095328033250583452620000017355921757772053391_">
          <path
            class="st0"
            d="M1752.8,387.43c0-2.4,0.46-4.5,1.15-5.63v0v-19.52l-55.95-23.7h-7.8v82.79l63.74,32.51v-60.82v0
          C1753.26,391.93,1752.8,389.83,1752.8,387.43z"
          />
          <polygon
            class="st0"
            points="1763.7,359.66 1713.82,338.58 1698,338.58 1753.95,362.28 1763.7,362.28 		"
          />
          <path
            class="st0"
            d="M1758.82,459.1v0.78c0,0.21-0.88,0.38-1.97,0.38c-1.09,0-1.97-0.17-1.97-0.38v-0.78
          c-1.56,0.24-2.63,0.75-2.63,1.35c0,0.82,2.06,1.49,4.6,1.49c2.54,0,4.6-0.67,4.6-1.49
          C1761.46,459.85,1760.38,459.34,1758.82,459.1z"
          />
          <path
            class="st0"
            d="M1705.24,432.18L1705.24,432.18l0,0.78c0,0.21-0.88,0.38-1.97,0.38c-1.09,0-1.97-0.17-1.97-0.38v-0.78h0
          c-1.56,0.24-2.63,0.75-2.63,1.35c0,0.82,2.06,1.49,4.6,1.49c2.54,0,4.6-0.67,4.6-1.49
          C1707.87,432.93,1706.79,432.42,1705.24,432.18z"
          />
          <line class="st0" x1="1746.58" y1="366.12" x2="1746.58" y2="444.07" />
          <line class="st0" x1="1739.88" y1="363.12" x2="1739.88" y2="441.07" />
          <line class="st0" x1="1733.18" y1="360.23" x2="1733.18" y2="438.19" />
          <line class="st0" x1="1726.49" y1="357.44" x2="1726.49" y2="435.4" />
          <line class="st0" x1="1719.79" y1="354.56" x2="1719.79" y2="432.51" />
          <line class="st0" x1="1713.09" y1="351.72" x2="1713.09" y2="429.19" />
          <line class="st0" x1="1706.39" y1="348.88" x2="1706.39" y2="425.98" />
          <line class="st0" x1="1699.7" y1="345.65" x2="1699.7" y2="422.28" />
          <line class="st0" x1="1693.3" y1="340.27" x2="1693.3" y2="418.22" />
          <path
            class="st0"
            d="M1755.13,380.89c1.28,0,2.32,2.93,2.32,6.54c0,3.61-1.04,6.54-2.32,6.54h8.57v-13.08H1755.13z"
          />
          <ellipse class="st0" cx="1755.13" cy="387.43" rx="2.32" ry="6.54" />
          <path
            class="st0"
            d="M1753.95,362.28v19.52v0c0.35-0.57,0.75-0.91,1.18-0.91h8.57v13.08h-8.57c-0.43,0-0.83-0.33-1.18-0.91v0
          v60.82h9.74v-91.6H1753.95z"
          />
          <path
            class="st0"
            d="M1754.88,453.88v6c0,0.21,0.88,0.38,1.97,0.38c1.09,0,1.97-0.17,1.97-0.38v-6H1754.88z"
          />
          <path
            class="st0"
            d="M1701.29,427.03v5.94c0,0.21,0.88,0.38,1.97,0.38s1.97-0.17,1.97-0.38v-3.93L1701.29,427.03z"
          />
        </g>
      </g>
      <g id="KK-3-0" class="hover-obj" @click="$store.commit('setModal', 'E.1.1')">
        <g>
          <g>
            <g>
              <rect x="883.21" y="210.97" class="st0" width="13.79" height="5.32" />
              <rect x="885.33" y="209.18" class="st0" width="3.82" height="1.8" />
              <rect x="891.06" y="209.18" class="st0" width="3.82" height="1.8" />
            </g>
            <g>
              <rect x="908.37" y="215.33" class="st0" width="6.39" height="14.88" />
              <rect x="905.72" y="216.3" class="st0" width="2.65" height="7.69" />
              <rect x="914.77" y="221.19" class="st0" width="3.26" height="12.47" />
              <path
                class="st0"
                d="M914.39,230.21c-0.97,4.1-3.66,14.99-3.84,19.2c-0.08,1.81-0.43,6.36,2.06,6.38
              c3.99,0.03,3.81-8.7,3.9-11.3c0.1-2.81,0.16-5.62-0.1-8.42l-0.83-2.42"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                class="st0"
                d="M878.85,55.88c1.57,0,3.05,0.39,4.36,1.06V29h-8.72v27.94C875.8,56.27,877.28,55.88,878.85,55.88z"
              />
              <path
                class="st0"
                d="M878.85,75.07c-1.57,0-3.05-0.39-4.36-1.06v91.28c2.83-1.05,5.89-1.76,8.72-2.25V74.01
              C881.9,74.68,880.42,75.07,878.85,75.07z"
              />
            </g>
            <g>
              <path
                class="st0"
                d="M878.85,340.33c-1.57,0-3.05-0.39-4.36-1.06v38.77l4.36,3.28l4.36-3.28v-38.77
              C881.9,339.94,880.42,340.33,878.85,340.33z"
              />
              <path
                class="st0"
                d="M878.85,321.14c1.57,0,3.05,0.39,4.36,1.06V201.6v-13.66c-2.83-0.27-5.89-0.76-8.72-1.62v15.28v120.6
              C875.8,321.53,877.28,321.14,878.85,321.14z"
              />
            </g>
          </g>
          <path
            class="st0"
            d="M901.36,55.88c1.57,0,3.05,0.39,4.36,1.06V29H897v27.94C898.31,56.27,899.79,55.88,901.36,55.88z"
          />
          <g>
            <path
              class="st0"
              d="M864.38,176.02c0,14.01,28.94,12.16,28.94,12.16v-26.17C893.32,162.01,864.38,162.54,864.38,176.02z
            M873.51,183.49c-4.2,0-7.61-3.41-7.61-7.61c0-4.2,3.41-7.61,7.61-7.61c4.2,0,7.61,3.41,7.61,7.61
            C881.12,180.08,877.71,183.49,873.51,183.49z"
            />
            <path
              class="st0"
              d="M885.3,188.1v4.32h3.61v-4.18C887.84,188.23,886.62,188.19,885.3,188.1z"
            />
            <circle class="st0" cx="873.51" cy="175.88" r="7.61" />
          </g>
          <g>
            <path
              class="st0"
              d="M901.36,321.14c1.57,0,3.05,0.39,4.36,1.06V74.01c-1.31,0.67-2.79,1.06-4.36,1.06s-3.05-0.39-4.36-1.06
            V322.2C898.31,321.53,899.79,321.14,901.36,321.14z"
            />
            <path
              class="st0"
              d="M901.36,340.33c-1.57,0-3.05-0.39-4.36-1.06v38.77l4.36,3.28l4.36-3.28v-38.77
            C904.41,339.94,902.93,340.33,901.36,340.33z"
            />
          </g>
          <path
            class="st0"
            d="M901.36,321.14c-5.3,0-9.59,4.29-9.59,9.59c0,5.3,4.29,9.59,9.59,9.59s9.59-4.3,9.59-9.59
          C910.95,325.44,906.66,321.14,901.36,321.14z M906.99,334.27l-12.7-6.37l13.44,4.86L906.99,334.27z"
          />
        </g>
        <polygon
          id="roer_00000128487078550285696270000004254454127974114486_"
          class="st0"
          points="905.72,391.61 901.36,394.73 
        897,391.61 897,387.8 901.36,384.79 905.72,387.8 	"
        />
        <path
          class="st0"
          d="M905.72,391.61l-4.36,3.12l-4.36-3.12h-45.04c-0.84,0-1.51,3.26-1.51,7.28c0,4.02,0.68,7.28,1.51,7.28h64.3
        v-14.56H905.72z"
        />
        <path
          class="st0"
          d="M905.72,373.23v4.82l-4.36,3.28l-4.36-3.28v-4.82h-13.79v4.82l-4.36,3.28l-4.36-3.28v-4.82h-22.53
        c-0.84,0-1.51,3.26-1.51,7.28c0,4.02,0.68,7.28,1.51,7.28h45.07l4.34-3l4.34,3h10.56v-14.56H905.72z"
        />
        <g id="veksel_00000078012703302849936610000014132398318715023012_">
          <rect x="916.26" y="341.23" class="st0" width="45.21" height="78.67" />
          <polygon class="st0" points="916.86,336.12 916.26,341.23 961.46,341.23 960.63,336.12 		" />
          <path
            id="roer_00000152265461254521119320000009619654405658992800_"
            class="st0"
            d="M1034.18,391.61h-72.72v14.56h75.51v-14.21
          C1036.02,391.85,1035.09,391.73,1034.18,391.61z"
          />
          <path
            id="roer_00000031185816652753563160000007219039168634819492_"
            class="st0"
            d="M1327.28,373.23h-151.57v10.37
          c0,1.48-2.16,2.9-6.05,4.19h157.62c0.84,0,1.51-3.26,1.51-7.28S1328.11,373.23,1327.28,373.23z"
          />
          <path
            id="roer_00000081646960787197740880000008363165857654700945_"
            class="st0"
            d="M1327.28,391.61h-176.69
          c-0.54,0.07-1.09,0.14-1.65,0.21v14.35h178.34c0.84,0,1.51-3.26,1.51-7.28S1328.11,391.61,1327.28,391.61z"
          />
          <path
            class="st0"
            d="M1009.06,383.6v-10.37h-10.18v7.55c0,0.61-1.95,1.1-4.36,1.1c-2.41,0-4.36-0.49-4.36-1.1v-7.55h-10.18v7.55
          c0,0.61-1.95,1.1-4.36,1.1s-4.36-0.49-4.36-1.1v-7.55h-9.79v14.56h53.65C1011.22,386.49,1009.06,385.08,1009.06,383.6z"
          />
        </g>
        <g id="roer_00000155104908259834720460000005092785215980628622_">
          <path
            id="roer_00000160169960144766840950000018111124223759360424_"
            class="st0"
            d="M501.96,374.66h74.81v14.56h-74.81
          c-0.84,0-1.51-3.26-1.51-7.28C500.44,377.92,501.12,374.66,501.96,374.66z"
          />
          <path
            id="roer_00000014624936275628218090000015211084013485246860_"
            class="st0"
            d="M501.96,393.04h74.81v14.56h-74.81
          c-0.84,0-1.51-3.26-1.51-7.28C500.44,396.3,501.12,393.04,501.96,393.04z"
          />
        </g>
        <g>
          <path
            class="st0"
            d="M1006.83,312.3c-9.21,0-16.68,7.47-16.68,16.68v51.8c0,0.61,1.95,1.1,4.36,1.1c2.41,0,4.36-0.49,4.36-1.1
          v-52.73c0-3.88,3.15-7.03,7.03-7.03h3.15v-8.72H1006.83z"
          />
          <path
            class="st0"
            d="M987.93,233.07c-9.21,0-16.68,7.47-16.68,16.68v131.03c0,0.61,1.95,1.1,4.36,1.1s4.36-0.49,4.36-1.1V248.82
          c0-3.88,3.15-7.03,7.03-7.03h22.06v-8.72H987.93z"
          />
        </g>
        <path
          class="st0"
          d="M878.85,55.88c-5.3,0-9.59,4.29-9.59,9.59c0,5.3,4.29,9.59,9.59,9.59s9.59-4.29,9.59-9.59
        C888.44,60.18,884.15,55.88,878.85,55.88z M883.14,70.65l-10.08-10.01l11.26,8.8L883.14,70.65z"
        />
        <polygon class="st0" points="873.06,60.63 883.14,70.65 884.31,69.44 	" />
        <path
          class="st0"
          d="M901.36,55.88c-5.3,0-9.59,4.29-9.59,9.59c0,5.3,4.29,9.59,9.59,9.59s9.59-4.29,9.59-9.59
        C910.95,60.18,906.66,55.88,901.36,55.88z M906.05,70.26l-10.88-9.14l11.95,7.84L906.05,70.26z"
        />
        <polygon class="st0" points="895.17,61.12 906.05,70.26 907.12,68.95 	" />
        <polygon class="st0" points="894.28,327.89 906.99,334.27 907.73,332.75 	" />
        <path
          class="st0"
          d="M878.85,321.14c-5.3,0-9.59,4.29-9.59,9.59c0,5.3,4.29,9.59,9.59,9.59s9.59-4.3,9.59-9.59
        C888.44,325.44,884.15,321.14,878.85,321.14z M884.52,334.19l-12.79-6.19l13.51,4.67L884.52,334.19z"
        />
        <polygon class="st0" points="871.73,327.99 884.52,334.19 885.24,332.66 	" />
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  name: "AppSvg"
};
</script>


<style lang="scss" scoped>
//class="hover-obj" @click="$store.commit('setModal', '1.10')"
.st0 {
  fill: none;
  stroke: #000000;
  stroke-width: 0.7;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
.st1 {
  fill: #efefef;
  stroke: #000000;
  stroke-width: 0.7;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
.st2 {
  fill: none;
  stroke: #000000;
  stroke-width: 0.7;
  stroke-linecap: round;
  stroke-linejoin: round;
}
.st3 {
  fill: none;
  stroke: #000000;
  stroke-width: 0.7;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 4.3728, 4.3728;
}
.st4 {
  fill: none;
  stroke: #000000;
  stroke-width: 0.7;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 3.9232, 3.9232;
}
.st5 {
  fill: none;
  stroke: #000000;
  stroke-width: 0.7;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 4.4738, 4.4738;
}

.st6 {
  fill: none;
  stroke: #000000;
  stroke-width: 0.7;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
  stroke-dasharray: 4.0732;
}

polygon,
rect,
path,
polyline,
ellipse,
circle {
  pointer-events: all;
}

.hover-obj {
  cursor: pointer;
  &:hover {
    & polygon,
    & rect,
    & path,
    & polyline,
    & ellipse,
    & circle {
      //fill: rgba(0, 100, 117, 0.5);
      fill: #e39193;
      transition: fill 0.1s linear;
    }
  }
}

.poster-obj {
  cursor: pointer;
  &:hover {
    & .poster {
      //fill: rgba(0, 100, 117, 0.5);
      fill: #e39193;
      transition: fill 0.1s linear;
    }
  }
}
</style>
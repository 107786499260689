<template>
  <h3>{{ blok.title }}</h3>
  <app-rich-text v-if="blok.text" :text="blok.text" />
</template>

<script>
export default {
  name: "DownloadBox",
  props: {
    blok: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
<template>
  <picture :class="lazyClass">
    <source
      v-for="item in imgType[type].ratio"
      :key="item.id"
      type="image/webp"
      :media="item.media"
      :srcset="transformImage(img, item.size + '/filters:format(webp)')"
    />
    <source
      v-for="item in imgType[type].ratio"
      :key="item.id"
      type="image/jpeg"
      :media="item.media"
      :srcset="transformImage(img, item.size)"
    />
    <img
      :src="transformImage(img, imgType[type].imgWidth + 'x' + imgCalcHeight)"
      :alt="altText"
      :class="[classes, lazyClass]"
      :width="imgType[type].imgWidth"
      :height="imgCalcHeight"
      :loading="imgType[type].lazyLoad"
    />
  </picture>
</template>
<script>
export default {
  name: "AppImage",
  props: {
    img: {
      type: String,
      default: ""
    },
    altText: {
      type: String,
      default: ""
    },
    classes: {
      type: String,
      default: ""
    },
    type: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      lazyClass: "lazy-image",
      imgType: {
        fullImg: {
          id: "full-img",
          imgWidth: "100%",
          imgHeight: false,
          lazyLoad: "lazy",
          ratio: [
            {
              size: "767x420/smart",
              media: "(max-width: 767px)"
            },
            {
              size: "1920x580/smart",
              media: "(min-width: 1900px)"
            },
            {
              size: "1260x350/smart",
              media: "(min-width: 1220px)"
            },
            {
              size: "1024x360/smart",
              media: "(min-width: 768px)"
            }
          ]
        },
        blueImg: {
          id: "blue-img",
          imgWidth: 310,
          imgHeight: 420,
          lazyLoad: "lazy",
          ratio: [
            {
              size: "520",
              media: "(max-width: 767px)"
            },
            {
              size: "768x0",
              media: "(min-width: 768px)"
            }
          ]
        },
        introImg: {
          id: "intro-img",
          imgWidth: 1680,
          imgHeight: 350,
          lazyLoad: "lazy",
          ratio: [
            {
              size: "767x420/smart",
              media: "(max-width: 767px)"
            },
            {
              size: "1024x300/smart",
              media: "(max-width: 1023px)"
            },
            {
              size: "1680x275",
              media: "(min-width: 1600px)"
            },
            {
              size: "1680x350",
              media: "(min-width: 1024px)"
            }
          ]
        }
      }
    };
  },
  computed: {
    imgCalcHeight() {
      let newHeight = this.imgType[this.type].imgHeight;
      if (!newHeight) {
        newHeight =
          (this.img.split("/")[5].split("x")[1] /
            this.img.split("/")[5].split("x")[0]) *
          this.imgType[this.type].imgWidth;
      }
      return Math.round(newHeight);
    }
  },
  methods: {
    transformImage(image, option) {
      if (!image) return "";
      if (!option) return "";

      let imageService = "//img2.storyblok.com/";
      let path = image.replace("//a.storyblok.com", "");
      return imageService + option + path;
    }
  }
};
</script>

<style lang="scss" scoped>
img {
  max-width: 100%;
  height: auto;
}
</style>
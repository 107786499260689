<template>
  <div>
    <!-- <s-t-c :blok="blok.content">
      <section class="intro">
        <h1>{{ blok.content.title }}</h1>
        <app-rich-text v-if="blok.content.text" :text="blok.content.text" />
      </section>
    </s-t-c>-->
    <div v-for="item in blok.content.body" :key="item.id">
      <s-t-c :blok="item">
        <component :is="item.component" :blok="item" />
      </s-t-c>
    </div>
  </div>
</template>

<script>
export default {
  name: "Page",
  props: {
    blok: {
      type: Object,
      default: () => {}
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
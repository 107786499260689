import { createStore, Store } from 'vuex'

import StoryblokClient from "storyblok-js-client";
const token = "bap5OPkhOLv0R9kXgDQAhQtt";
const Storyblok = new StoryblokClient({
  accessToken: token,
  version: "public",
  cache: {
    clear: 'auto',
    type: 'memory'
  }
})

export default createStore({
  state: {
    pageNavigation: [],
    pageFooter: [],
    pageAll: [],
    pageFrontpage: "",
    pageResponsibility: "",
    pageFrontpageEnergi: "",
    pageEnergi: "",
    pageEnergiMerge: "",
    modalActive: false,
    modalIntroActive: false,
    modalType: "",
    modalContent: {}
  },
  getters: {
    mergeSearchData: (state) => {
      return [...state.pageFrontpage, ...state.pageEnergi]
    },
    // searchFilter: (state, getters) => {
    //   return getters.mergeSearchData.filter(item => {
    //     console.log(item.title)
    //     let tempVal = item.title.toLowerCase();
    //     if (item.text) {
    //       tempVal =
    //         tempVal +
    //         " , " +
    //         Storyblok.richTextResolver.render(item.text).toLowerCase();
    //     }
    //     if (item.search) {
    //       tempVal = tempVal + "," + item.search.toLowerCase();
    //     }

    //     return tempVal.includes(item.orderBy.toLowerCase());
    //   });
    // }
  },
  mutations: {
    setFooter(state, payload) {
      state.pageFooter = payload
    },
    setNavigation(state, payload) {
      state.pageNavigation = payload.story.content.list.map((item) => {
        return {
          link: item.link.cached_url,
          text: item.name
        }
      });
    },
    setFrontPage(state, payload) {
      const data = payload.filter((item) => item.full_slug === "site/")[0].content.body
      const topCat = [...new Set(data.map((item) => item.topCategory))];

      const topArray = topCat.map((top) => {
        let subArray = data
          .filter((item) => {
            return item.topCategory === top;
          })
          .map((el) => {
            return el.subCategory;
          });
        let newSub = [...new Set(subArray)];

        const setData = newSub.map((sub) => {
          let subArray = data
            .filter((item) => {
              return item.subCategory === sub && item.topCategory === top;
            })
            .map((el) => {
              return el;
            });
          return subArray;
        });
        let makeObject = {
          top: top,
          type: "ansvar",
          sub: newSub,
          data: setData,
        };

        return makeObject;
      });

      state.pageResponsibility = topArray;
      state.pageFrontpage = data;
    },
    setFrontPageEnergi(state, payload) {
      const data = payload.filter((item) => item.full_slug === "site/energirigtig-drift")[0].content.bodyEnergi
      const topCat = [...new Set(data.map((item) => item.top))];

      const topArray = topCat.map((top) => {
        let subArray = data
          .filter((item) => {
            return item.top === top;
          })
          .map((el) => {
            return el;
          });


        let makeObject = {
          top: top,
          type: "energi",
          data: subArray,
        };

        return makeObject;
      });





      state.pageFrontpageEnergi = topArray;
      state.pageEnergi = data;
    },
    setFrontPageEnergiMerge(state, payload) {
      const data = payload.filter((item) => item.full_slug === "site/energirigtig-drift")[0].content.bodyEnergiMerge
      state.pageEnergiMerge = data;
    },
    setAllPages(state, payload) {
      state.pageAll = payload
    },
    setModal(state, payload) {

      if (payload.component == 'appFrontEnergi' || payload.includes('E.')) {
        state.modalActive = true;
        state.modalType = "energi";
        document.querySelector('body').style.overflow = "hidden";
        if (payload.component == 'appFrontEnergi') {
          state.modalContent = state.pageEnergi.filter((item) => {
            return item.id === payload.id
          })[0]
        }
        else {
          state.modalContent = state.pageEnergi.filter((item) => {
            return item.id === payload
          })[0]
        }
      }
      else if (payload.includes('S.')) {
        // HANDLE MERGE AppModalEnergi
        const mergedData = state.pageEnergiMerge
        const filterData = mergedData.filter((item) => item.groupID === payload)[0]

        let array = [];
        filterData.body.forEach(merge => {
          const energiData = state.pageEnergi.filter((item) => item.id == merge.id);

          energiData[0].videos.forEach((item) => {
            array.push(item);
          })
        });

        filterData['videos'] = array;

        state.modalContent = filterData;
        state.modalActive = true;
        state.modalType = "energi";
        document.querySelector('body').style.overflow = "hidden";

      } else {
        state.modalActive = true;
        state.modalType = "ansvar";
        document.querySelector('body').style.overflow = "hidden";
        document.querySelector('#search').classList.add("modalActive");
        state.modalContent = state.pageFrontpage.filter((item) => {

          return item.id === payload
        })[0]
      }
    },
    setModalIntro(state) {
      state.modalIntroActive = true;
      document.querySelector('body').style.overflow = "hidden";
      document.querySelector('#search').classList.add("modalActive");
    },
    closeModal(state) {
      state.modalActive = false;
      state.modalIntroActive = false;
      document.querySelector('body').style.overflow = "auto";
      document.querySelector('#search').classList.remove("modalActive");
      state.modalContent = {};
    }
  },
  actions: {
    getPages({
      commit
    }) {
      return new Promise((resolve, reject) => {
        Storyblok.getAll('cdn/stories', {
          starts_with: 'site/'
        })
          .then((stories) => {
            commit("setAllPages", stories);
            commit("setFrontPage", stories);
            commit("setFrontPageEnergi", stories);
            commit("setFrontPageEnergiMerge", stories);
            resolve(stories);
          })
          .catch((error) => {
            reject(error);
          })
      })
    },
    getNavigation({
      commit
    }) {
      return new Promise((resolve, reject) => {
        Storyblok.get('cdn/stories/navigation/')
          .then(response => {
            commit("setNavigation", response.data);
            resolve(response);
          }).catch(error => {
            console.log(error)
            reject(error);
          })
      })
    },
    getFooter({
      commit
    }) {
      return new Promise((resolve, reject) => {
        Storyblok.get('cdn/stories/footer/')
          .then(response => {
            commit("setFooter", response.data);
            resolve(response);
          }).catch(error => {
            console.log(error)
            reject(error);
          })
      })
    }
  }
})



<template>
  <footer class="footer" v-if="footer.content">
    <div class="cssgrid">
      <div class="cssgrid-item">
        <app-rich-text :text="footer.content.address" />
      </div>
      <div class="cssgrid-item">
        <app-rich-text :text="footer.content.shortCut" />
        <p @click="cookiePopup = !cookiePopup" class="cookie-toggle">Cookieindstillinger</p>
      </div>
      <div class="cssgrid-item">
        <router-link to="/" class>
          <img src="@/assets/logo.png" class="logo" alt="KK" />
        </router-link>
      </div>
    </div>
    <transition name="cookie">
      <div v-if="cookiePopup" class="cookie-popup">
        <div class="grid">
          <h3>{{ footer.content.cookieHeadline }}</h3>
          <app-rich-text :text="footer.content.cookieText" />

          <router-link to="/cookiepolitik">
            <div class="btn invert">
              <span>Jeg vil vide mere</span>
            </div>
          </router-link>
          <div>
            <div class="btn" @click="allowCookie()">Tillad cookies</div>
            <div class="btn" @click="declineCookie()">Afvis cookies</div>
          </div>
        </div>
      </div>
    </transition>
  </footer>
</template>

<script>
import { useCookies } from "vue3-cookies";
export default {
  name: "Footer",
  data() {
    return {
      cookiePopup: false,
    };
  },
  setup() {
    const { cookies } = useCookies();
    return { cookies };
  },
  computed: {
    footer() {
      return this.$store.state.pageFooter.story;
    },
  },
  mounted() {
    if (window.location.href.indexOf("editor?path") != -1) {
      this.removeSiteImprove();
      return (this.cookiePopup = false);
    }
    // Check cookie
    let cookie = this.cookies.get("kk-cookie-gdpr");
    if (cookie == null) {
      return (this.cookiePopup = true);
    } else {
      if (cookie == "true") {
        this.injectSiteImprove();
      } else {
        this.removeSiteImprove();
      }
    }
  },
  methods: {
    allowCookie() {
      this.cookies.set("kk-cookie-gdpr", "true", 60 * 60 * 24 * 365); // Set cookie to a year
      this.injectSiteImprove();
      return (this.cookiePopup = false);
    },
    declineCookie() {
      this.cookies.set("kk-cookie-gdpr", "false", 60 * 60 * 24 * 365); // Set cookie to a year
      this.removeSiteImprove();
      return (this.cookiePopup = false);
    },
    injectSiteImprove() {
      const script = document.querySelector(".siteImprove");
      if (script == null) {
        var sz = document.createElement("script");
        sz.type = "text/javascript";
        sz.classList.add("siteImprove");
        sz.async = true;
        sz.src = "//siteimproveanalytics.com/js/siteanalyze_259887.js";
        var s = document.getElementsByTagName("script")[0];
        s.parentNode.insertBefore(sz, s);
      } else {
        return;
      }
    },
    removeSiteImprove() {
      if (this.cookies.get("nmstat") == null) {
        return;
      } else {
        this.cookies.remove("nmstat");
        document.cookie = "nmstat" + "=;expires=Thu, 01 Jan 1970 00:00:01 GMT;domain=" + window.location.host;
        const script = document.querySelector(".siteImprove");
        if (script != null) {
          script.parentNode.removeChild(script);
          window.location.reload();
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.footer {
  line-height: 200%;
  border-top: 1px solid rgba(0, 12, 46, 0.2);
  & ::v-deep a {
    color: inherit;
    font-family: var(--font-family);
    text-decoration: none;
    &:hover {
      text-decoration: underline;
      color: var(--font-color);
    }
  }
}

.cssgrid {
  display: flex;
  justify-content: space-between;
  padding: 0px var(--spacing-md-3);
  box-sizing: border-box;
  &-item {
    padding: var(--spacing-md-3);
    &:nth-child(2) {
      margin-right: auto;
    }
    &:first-child {
      padding-left: 0px;
    }
    &:last-child {
      padding-right: 0px;
      width: 70px;
      & img {
        width: 100%;
      }
    }

    & :deep(p) {
      margin-bottom: 0px;
    }

    & .cookie-toggle {
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .cssgrid {
    display: block;
    padding: calc(var(--spacing-md-3) * 2) var(--spacing-md-3);
    &-item {
      padding: 0px;
      padding-bottom: var(--spacing-md-1);
      display: inline-block;
      &:nth-child(2) {
        width: calc(100% - 60px);
      }
      &:first-child {
        width: 100%;
      }
      &:last-child {
        width: 60px;

        & img {
          width: 100%;
          vertical-align: baseline;
        }
      }
    }
  }
}

.cookie-popup {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: var(--spacing-md-3);
  background: var(--blue-color);
  z-index: 10000;
  color: #ffffff;
  box-sizing: border-box;
  max-height: 100vh;
  overflow-y: auto;

  & .btn {
    margin-right: 1rem;

    @media screen and (max-width: 767px) {
      padding: 0.75rem;
      margin-right: 0.5rem;
    }
  }

  & .invert {
    background: transparent;
    text-decoration: none;
    border: 1px solid #ffffff;
    margin-bottom: 1rem;
    margin-top: 1rem;
  }
  & p {
    margin-bottom: 3rem;
    max-width: 960px;
  }
}
</style>

<template>
  <div v-if="show" role="dialog" class="modal">
    <div class="modal-inner">
      <div
        v-if="$store.state.modalContent.responsibility != 'undefined'"
        class="modal-content animate"
        :class="$store.state.modalContent.responsibility"
        ref="modal"
        @keydown.esc="$store.commit('closeModal')"
        tabindex="0"
      >
        <div class="modal-content-headline">
          <DefaultSvgClose class="close-handle" @click="$emit('close')" />
          <h4 v-if="$store.state.modalContent.title">{{ $store.state.modalContent.title }}</h4>
        </div>
        <div class="modal-content-maintaince">
          <p>
            Vedligehold:
            <b
              :class="$store.state.modalContent.responsibility"
            >{{ $store.state.modalContent.responsibility }}</b>
          </p>
          <div
            class="anmeld"
            v-if="
                $store.state.modalContent.helpdiskLinkText ||
                $store.state.modalContent.tmfText
              "
          >
            <div>
              <p>Anmeld:</p>
            </div>
            <div>
              <p class="anmeld-link" v-if="$store.state.modalContent.helpdiskLink">
                <b>
                  <a :href="$store.state.modalContent.helpdiskLink">
                    {{ $store.state.modalContent.helpdiskLinkText }}
                    <DefaultSvgArrowRight class="arrow" />
                  </a>
                </b>
              </p>
              <p class="anmeld-link" v-if="$store.state.modalContent.tmfText">
                <b>
                  <a :href="$store.state.modalContent.tmfLink">
                    {{ $store.state.modalContent.tmfText }}
                    <DefaultSvgArrowRight class="arrow" />
                  </a>
                </b>
              </p>
            </div>
          </div>
        </div>
        <app-rich-text
          class="modal-content-text"
          v-if="$store.state.modalContent.text"
          :text="$store.state.modalContent.text"
        />
        <div class="modal-content-relations" v-if="$store.state.modalContent.relationsText">
          <p>{{ $store.state.modalContent.relationsText }}</p>
          <div v-for="item in $store.state.modalContent.relations" :key="item.id">
            <button
              class="reset-btn"
              @click="$store.commit('setModal', item.id); "
              v-if="item.id"
            >{{ item.title }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppRichText from "@/components/AppRichText.vue";
import DefaultSvgClose from "@/components/svg/DefaultSvgClose.vue";
import DefaultSvgArrowRight from "@/components/svg/DefaultSvgArrowRight.vue";

export default {
  name: "Modal",
  props: {
    show: {
      type: Boolean,
      default: false
    },
    backdrop: {
      type: String,
      default: "grey"
    }
  },
  watch: {
    show: function(val) {
      if (val) {
        setTimeout(() => {
          this.$refs.modal.focus();
        }, 10);
      }
    }
  },
  components: {
    AppRichText,
    DefaultSvgClose,
    DefaultSvgArrowRight
  },
  emits: ["close"],
  mounted() {}
};
</script>

<style lang="scss" scoped>
.modal {
  position: fixed;
  width: calc(100%);
  max-height: calc(100% - var(--spacing-md-3) - var(--spacing-md-3));
  display: flex;
  justify-content: center;
  align-items: center;
  top: 50%;
  transform: translateY(-50%);
  margin-top: 0rem;
  left: 0;
  pointer-events: none;
  overflow: auto;
  z-index: 9999;
  margin: auto;
  &-inner {
    background-color: #fff;
    width: 420px;
    max-width: 767px;
    max-height: 90vh;
    position: relative;
    overflow: auto;
    pointer-events: visible;
  }
  &-content {
    outline: 0;
    max-width: 420px;
    &.lejer {
      border-top: 8px solid var(--red-color);
    }
    &.TMF {
      border-top: 8px solid var(--green-color);
    }
    &.KEID {
      border-top: 8px solid var(--lightblue-color);
    }
    &.KEJD {
      border-top: 8px solid var(--lightblue-color);
    }
    &-headline {
      padding: var(--spacing-md-2);
      padding-top: var(--spacing-md-3);
      width: 100%;
      box-sizing: border-box;
      border-bottom: 1px solid rgba(0, 12, 46, 0.1);
      margin: 0px;

      & .close-handle {
        width: 1rem;
        padding: 1rem;
        position: absolute;
        right: 0px;
        top: 0.5rem;
        cursor: pointer;
      }
    }
    &-maintaince {
      padding: var(--spacing-md-2);
      width: 100%;
      box-sizing: border-box;
      border-bottom: 1px solid rgba(0, 12, 46, 0.1);
      margin: 0px;

      & p:last-child {
        margin-bottom: 0px;
      }
      & .anmeld {
        & div {
          display: inline-block;
          vertical-align: top;
          &:first-child {
            margin-right: 5px;
          }
          & b {
            font-family: var(--font-family-semi-header);
            margin-left: 2px;
            & a {
              color: var(--font-color);
              text-decoration: none;
              & .arrow {
                margin-left: 5px;
                width: 1rem;
                height: 1rem;
                display: inline-block;
                position: relative;
                left: 0px;
                transition: left 0.25s ease-out;
              }
              &:hover {
                & .arrow {
                  left: 8px;
                  transition: left 0.5s ease-out;
                }
              }
            }
          }
        }
      }

      & .lejer {
        color: var(--red-color);
        font-family: var(--font-family-semi-header);
      }
      & .TMF {
        color: var(--green-color);
        font-family: var(--font-family-semi-header);
      }
      & .KEID {
        color: var(--lightblue-color);
        font-family: var(--font-family-semi-header);
      }

      & .KEJD {
        color: var(--lightblue-color);
        font-family: var(--font-family-semi-header);
      }
    }
    &-text {
      padding: var(--spacing-md-2);
      width: 100%;
      box-sizing: border-box;
      margin: 0px;
      & b {
        font-family: var(--font-family-semi-header);
        color: #000000;
      }
    }
    &-relations {
      padding: var(--spacing-md-2);
      padding-top: 0px;
      width: 100%;
      box-sizing: border-box;
      margin: 0px;
      & button {
        font-size: 1rem;
        text-decoration: underline;
        display: inline-block;
        &::before {
          content: ",";
          margin-right: 3px;
          margin-left: 3px;
        }
        &:first-child::before {
          content: "";
          margin-right: 0px;
          margin-left: 0px;
        }
        &:hover {
          color: var(--link-active-color);
        }
      }
    }
  }
  &-backdrop {
    position: fixed;
    width: 100%;
    height: 100%;
    opacity: 0.15;
    top: 0;
    left: 0;
    z-index: 5;
    background-color: rgba(0, 12, 46, 1);
  }
}
.fade-in-enter-active,
.fade-in-leave-active {
  --fade-in-time: 0.3s ease;
}

@media screen and (max-width: 479px) {
  .modal {
    margin: 0px;
    width: 100%;
    height: calc(100vh - calc(100vh - 100%));
    max-height: unset;
    padding-bottom: 60px;
    box-sizing: border-box;
    &-inner {
      height: calc(100vh - calc(100vh - 100%));
      max-height: unset;
      width: 100%;
    }
    &-content {
      width: 100%;
      max-width: unset;
      &-headline {
        padding: 2.8rem var(--spacing-md-2) var(--spacing-md-2)
          var(--spacing-md-2);
      }
      &-maintaince {
        padding: var(--spacing-md-3) var(--spacing-md-2) var(--spacing-md-2)
          var(--spacing-md-2);
      }
      &-text {
        padding: var(--spacing-md-3) var(--spacing-md-2) var(--spacing-md-2)
          var(--spacing-md-2);
      }
    }
  }
}
</style>

<style lang="scss">
.close-handle {
  &:hover {
    & svg {
      path {
        stroke: var(--link-active-color);
      }
    }
  }
}
</style>
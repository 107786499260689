<template>
  <default-navigation />

  <main id="main" :class="{loaded: pageLoaded}">
    <router-view :key="$route.fullPath" />
  </main>
  <transition name="fade-in" v-if="pageFooterLoaded">
    <default-footer />
  </transition>
</template>
<script>
import DefaultNavigation from "@/components/DefaultNavigation.vue";
import DefaultFooter from "@/components/DefaultFooter.vue";
export default {
  name: "App",
  data() {
    return {
      parentIsActive: true,
      pageLoaded: false,
      pageFooterLoaded: false
    };
  },
  components: {
    DefaultNavigation,
    DefaultFooter
  },
  methods: {
    setData() {
      this.$store
        .dispatch("getNavigation")
        .then(response => {
          this.pageLoaded = true;
        })
        .catch(error => {
          console.log(error);
        });

      this.$store
        .dispatch("getFooter")
        .then(response => {
          this.pageFooterLoaded = true;
        })
        .catch(error => {
          console.log(error);
        });
    }
  },
  mounted() {
    this.setData();
  }
};
</script>

<style lang="scss">
@import "@/assets/style/main.scss";
</style>

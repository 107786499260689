<template>
  <div class="box box-blue">
    <div class="grid row">
      <h2 class="desktop-only">{{ title }}</h2>
      <div class="css-grid">
        <div v-for="top in $store.state.pageResponsibility" :key="top.id" class="grid-group">
          <h3>{{ top.top }}</h3>
          <div v-for="(sub, index) in top.sub" :key="sub.id" class="grid-group-item">
            <div class="fp-accordion">
              <button
                :class="[
                'accordion-link reset-btn h4 ',
                { 'is-active': activeEl === sub+top.top },
              ]"
                :id="'header-' + sub+top.top"
                :aria-controls="'panel-' + sub+top.top"
                :aria-expanded="activeEl === sub+top.top"
                @click="toggle(sub+top.top)"
              >{{ sub }}</button>
              <transition name="fadeHeight" mode="out-in">
                <section
                  :key="sub+top.top"
                  v-if="activeEl === sub+top.top"
                  :id="'panel-' + sub+top.top"
                  :aria-labelledby="'header-' + sub+top.top"
                >
                  <div
                    v-for="item in top.data[index]"
                    :key="item.id"
                    class="accordion-inner-wrapper"
                  >
                    <button
                      class="reset-btn accordion-inner-link"
                      @click="$store.commit('setModal', item.id)"
                    >{{ item.title }}</button>
                  </div>
                </section>
              </transition>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "AppFrontAccordion",
  props: {
    title: {
      type: String,
      default: () => {}
    }
  },
  data() {
    return {
      activeEl: false
    };
  },
  methods: {
    toggle(id) {
      if (this.activeEl === id) {
        this.activeEl = "";
      } else {
        this.activeEl = id;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.box-blue {
  background: var(--blue-color);
  width: 100%;
  border: var(--spacing-md-3) solid #ffffff;
  border-top: 0px;
  box-sizing: border-box;
  color: #ffffff;
  padding: var(--spacing-md-2) 0px;

  .css-grid {
    margin-top: var(--spacing-md-3);
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 20px;
    &-group {
      margin-bottom: 20px;
      &-item {
        margin-bottom: 20px;
      }
      &-link {
        margin-bottom: 5px;
      }
    }
  }
  & h3 {
    margin-bottom: 1rem;
  }
  & .accordion-link {
    margin-bottom: 1rem;
    text-align: left;
    font-size: var(--font-h5);
    &:hover {
      color: var(--link-active-color);
    }
    &:after {
      content: " +";
      color: var(--link-active-color);
    }
    &.is-active {
      &:after {
        content: " -";
        color: var(--link-active-color);
      }
    }
  }
}
.accordion-inner-wrapper {
  &:last-child {
    margin-bottom: var(--spacing-md-1);
  }
  & .accordion-inner-link {
    margin-left: var(--spacing-md-1);
    text-align: left;
    font-size: 1rem;
    padding: 0.5rem 0px;
    &:hover {
      color: var(--link-active-color);
    }
  }
}
@media screen and (max-width: 1023px) {
  .box-blue {
    border: 0px solid #ffffff;
    & .css-grid {
      margin-top: 0px;
    }
  }
  .desktop-only {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .box-blue {
    padding: calc(var(--spacing-md-3) * 2) 0px;
    & .grid {
      padding: 0px var(--spacing-md-1);
      box-sizing: border-box;
    }
    & .css-grid {
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    }
  }
}
</style>
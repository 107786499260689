
<template>
  <div class="richtext" v-html="richtext"></div>
</template>

<script>
import StoryblokClient from "storyblok-js-client";
import RichTextResolver from "storyblok-js-client/source/richTextResolver";
import richtextSchema from "../helpers/richtextSchema.js";

const token = "bap5OPkhOLv0R9kXgDQAhQtt";
const Storyblok = new StoryblokClient({
  accessToken: token,
  cache: {
    clear: "auto",
    type: "memory"
  }
});
Storyblok.richTextResolver = new RichTextResolver(richtextSchema);
export default {
  props: ["text"],
  computed: {
    richtext() {
      return this.text ? Storyblok.richTextResolver.render(this.text) : "";
    }
  },
  methods: {
    handleRoute(e) {
      e.preventDefault();
      this.$router.push(e.target.getAttribute("href"));
    }
  },
  mounted() {
    setTimeout(() => {
      const routing = document.querySelectorAll(".router-listener");
      routing.forEach(item => {
        item.addEventListener("click", this.handleRoute);
      });
    }, 100);
  }
};
</script>


<style lang="scss" scoped>
</style>


<template>
  <div v-if="show" role="dialog" class="modal">
    <div class="modal-inner">
      <div
        class="modal-content animate"
        :class="$store.state.modalContent.id"
        ref="modal"
        @keydown.esc="$store.commit('closeModal')"
        tabindex="0"
      >
        <div class="modal-content-headline">
          <DefaultSvgClose class="close-handle" @click="$emit('close')" />
          <div class="modal-back" @click="showVideoPage(0)" v-if="showVideo">
            <span>Tilbage</span>
          </div>
          <h3 v-if="$store.state.modalContent.title">{{ $store.state.modalContent.title }}</h3>
        </div>
        <div>
          <transition name="overview">
            <div class="modal-overview" v-if="!showVideo">
              <div
                class="modal-overview-top"
                :class="{'intro' : $store.state.modalContent.introText}"
              >
                <div v-if="$store.state.modalContent.introText">
                  <app-rich-text :text="$store.state.modalContent.introText" />
                </div>
                <div class="modal-overview-top-maintaince">
                  <p v-if="$store.state.modalContent.ansvar">
                    Drift:
                    <b
                      :class="$store.state.modalContent.ansvar"
                    >{{ $store.state.modalContent.ansvar }}</b>
                  </p>
                  <div class="anmeld" v-if="$store.state.modalContent.helpdisklink">
                    <div>
                      <p>For hjælp:</p>
                    </div>
                    <div>
                      <p class="anmeld-link" v-if="$store.state.modalContent.helpdisklink">
                        <b>
                          <a :href="$store.state.modalContent.helpdisklink">
                            Gå til helpdisk
                            <DefaultSvgArrowRight class="arrow" />
                          </a>
                        </b>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div v-for="(item, index) in $store.state.modalContent.videos" :key="item.id">
                <div class="modal-overview-item">
                  <div
                    class="modal-overview-item-image"
                    @click="showVideoPage(index)"
                    v-if="item.videoPoster"
                  >
                    <span>
                      <img :src="item.videoPoster.filename" />
                    </span>
                  </div>
                  <div class="modal-overview-item-content">
                    <h4>{{item.videoTitle}}</h4>
                    <app-rich-text v-if="item.videoDescription" :text="item.videoDescription" />
                    <div class="modal-overview-item-content-linkwrap">
                      <button @click="showVideoPage(index)" class="download-link watch">
                        <span>Se video</span>
                      </button>

                      <a :href="item.videoLink" target="_blank">
                        <button class="download-link">
                          <span>Download video</span>
                        </button>
                      </a>
                      <a
                        :href="download.PDF.filename"
                        v-for="download in item.downloads"
                        v-bind:key="download"
                        target="_blank"
                      >
                        <button class="download-link" v-if="download.PDF.filename">
                          <span>{{download.pdfName}}</span>
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </transition>

          <!-- video player -->

          <transition
            name="video"
            v-for="(item, index) in $store.state.modalContent.videos"
            :key="item.id"
          >
            <AppVideoPlayer
              v-if="showVideo && videoClickedIndex == index"
              :link="item.videoLink"
              :chapters="item.chapters"
              :poster="item.videoPoster"
              :subtitle="item.subtitle"
            />
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DefaultSvgClose from "@/components/svg/DefaultSvgClose.vue";
import DefaultSvgArrowRight from "@/components/svg/DefaultSvgArrowRight.vue";
import AppVideoPlayer from "@/components/AppVideoPlayer.vue";

export default {
  name: "Modal",
  data() {
    return {
      showVideo: false,
      videoClickedIndex: Number
    };
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    backdrop: {
      type: String,
      default: "grey"
    }
  },
  components: { DefaultSvgClose, DefaultSvgArrowRight, AppVideoPlayer },
  methods: {
    showVideoPage(index) {
      document.querySelector(".modal-inner").scrollTop = 0;
      return (
        (this.showVideo = !this.showVideo), (this.videoClickedIndex = index)
      );
    }
  },
  emits: ["close"],
  mounted() {}
};
</script>

<style lang="scss" scoped>
.modal-content {
  position: relative;
}
.modal-overview {
  &-top {
    padding: var(--spacing-md-2);
    &.intro {
      display: grid;
      grid-template-columns: 1fr 40%;
      grid-gap: var(--spacing-md-3);

      @media screen and (max-width: 768px) {
        display: block;
      }
    }

    &-maintaince {
      width: 100%;
      box-sizing: border-box;
      margin: 0px;

      @media screen and (max-width: 768px) {
        padding: 0.5rem 0;
      }

      & p:last-child {
        margin-bottom: 0px;
      }
      & .anmeld {
        & div {
          display: inline-block;
          vertical-align: top;
          &:first-child {
            margin-right: 5px;
          }
          & b {
            font-family: var(--font-family-semi-header);
            margin-left: 2px;
            & a {
              color: var(--font-color);
              text-decoration: none;
              & .arrow {
                margin-left: 5px;
                width: 1rem;
                height: 1rem;
                display: inline-block;
                position: relative;
                left: 0px;
                transition: left 0.25s ease-out;
              }
              &:hover {
                & .arrow {
                  left: 8px;
                  transition: left 0.5s ease-out;
                }
              }
            }
          }
        }
      }

      & .Lejer {
        color: var(--red-color);
        font-family: var(--font-family-semi-header);
      }
      & .TMF {
        color: var(--green-color);
        font-family: var(--font-family-semi-header);
      }
      & .KEID {
        color: var(--lightblue-color);
        font-family: var(--font-family-semi-header);
      }
      & .KEJD {
        color: var(--lightblue-color);
        font-family: var(--font-family-semi-header);
      }
    }
  }
  &-item {
    display: grid;
    grid-template-columns: 1fr 40%;
    grid-gap: var(--spacing-md-3);
    padding: var(--spacing-md-2);
    border-top: 1px solid rgba(0, 12, 46, 0.1);

    @media screen and (max-width: 767px) {
      display: block;
      padding: 0;
    }

    &-image {
      position: relative;
      & span {
        position: relative;
        display: block;
        &::after {
          content: "";
          position: absolute;
          z-index: 2;
          width: 3rem;
          height: 3rem;
          top: 50%;
          left: 50%;
          transform: translateX(-50%) translateY(-50%);
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='120' height='112' viewBox='0 0 120 112' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16.2243 107.143C15.7821 107.143 15.3443 107.056 14.9357 106.887C14.5271 106.718 14.1559 106.47 13.8432 106.157C13.5305 105.844 13.2825 105.473 13.1132 105.065C12.944 104.656 12.8569 104.218 12.8569 103.776V7.6534C12.857 7.06324 13.0121 6.48345 13.3068 5.97212C13.6014 5.4608 14.0253 5.03589 14.5359 4.73999C15.0466 4.44409 15.626 4.28758 16.2162 4.28614C16.8063 4.28471 17.3864 4.43839 17.8985 4.7318L101.806 52.8137C102.321 53.1086 102.749 53.5341 103.046 54.0472C103.343 54.5603 103.5 55.1429 103.5 55.736C103.5 56.329 103.343 56.9115 103.045 57.4246C102.748 57.9376 102.32 58.363 101.805 58.6576L17.8975 106.698C17.3881 106.99 16.8113 107.143 16.2243 107.143Z' fill='white'/%3E%3C/svg%3E");
        }
        & img {
          width: 100%;
        }
      }
    }

    &-content {
      @media screen and (max-width: 767px) {
        padding: var(--spacing-md-2);
        & h4 {
          font-size: var(--font-h3);
        }
      }

      &-linkwrap {
        margin-top: 2.2rem;
        padding-top: 1rem;
        border-top: 1px solid #000;

        & a {
          text-decoration: none;
        }

        & .download-link {
          color: var(--font-color);
          text-decoration: none;
          margin: 1rem 0;
          display: flex;
          align-content: center;
          font-size: var(--font);
          & span {
            position: relative;
            font-family: var(--font-family-semi-header);
            padding-left: 2rem;
            &::before {
              content: "";
              position: absolute;
              width: 18px;
              height: 18px;
              top: 50%;
              transform: translateY(-50%);
              left: 0;
              background-size: cover;
              background-position: center;
              background-repeat: no-repeat;
              background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='28' height='26' viewBox='0 0 28 26' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.4348 20.7994C13.59 20.9546 13.8006 21.0418 14.0201 21.0418C14.2397 21.0418 14.4502 20.9546 14.6055 20.7994L19.6487 15.7562C19.8039 15.601 19.8911 15.3904 19.8911 15.1709C19.8911 14.9513 19.8039 14.7408 19.6487 14.5855C19.4934 14.4303 19.2829 14.343 19.0633 14.343C18.8437 14.343 18.6332 14.4303 18.4779 14.5855L14.8146 18.2488V1.82781C14.8146 1.60826 14.7274 1.39771 14.5722 1.24246C14.417 1.08722 14.2064 1 13.9868 1C13.7673 1 13.5567 1.08722 13.4015 1.24246C13.2463 1.39771 13.159 1.60826 13.159 1.82781V18.1822L9.49573 14.5189C9.34049 14.3636 9.12992 14.2764 8.91037 14.2764C8.69081 14.2764 8.48025 14.3636 8.325 14.5189C8.16975 14.6741 8.08252 14.8847 8.08252 15.1043C8.08252 15.3238 8.16975 15.5344 8.325 15.6896L13.4348 20.7994Z' fill='%23000C2E'/%3E%3Cpath d='M23.1459 23.3438H4.82781C4.60826 23.3438 4.39773 23.431 4.24248 23.5862C4.08724 23.7415 4 23.952 4 24.1716C4 24.3911 4.08724 24.6017 4.24248 24.7569C4.39773 24.9121 4.60826 24.9994 4.82781 24.9994H23.1459C23.3654 24.9994 23.576 24.9121 23.7312 24.7569C23.8865 24.6017 23.9737 24.3911 23.9737 24.1716C23.9737 23.952 23.8865 23.7415 23.7312 23.5862C23.576 23.431 23.3654 23.3438 23.1459 23.3438Z' fill='%23000C2E'/%3E%3C/svg%3E");
            }
          }

          &.watch {
            cursor: pointer;
            & span::before {
              background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='28' height='26' viewBox='0 0 28 26' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14 25C7.38317 25 2 19.6168 2 13C2 6.38318 7.38317 1 14 1C20.6168 1 26 6.38318 26 13C26 19.6168 20.6168 25 14 25ZM14 2.65561C11.954 2.65561 9.95407 3.2623 8.25294 4.39895C6.55181 5.53561 5.22595 7.15117 4.443 9.04136C3.66006 10.9316 3.45522 13.0115 3.85436 15.0181C4.2535 17.0247 5.23867 18.8679 6.68536 20.3146C8.13205 21.7613 9.97526 22.7465 11.9819 23.1456C13.9885 23.5448 16.0684 23.3399 17.9586 22.557C19.8488 21.774 21.4644 20.4482 22.601 18.747C23.7377 17.0459 24.3443 15.0459 24.3443 13C24.3412 10.2574 23.2504 7.62812 21.3111 5.68884C19.3718 3.74957 16.7425 2.65872 14 2.65561Z' fill='%23000C2E'/%3E%3Cpath d='M11.2917 18.5294C11.183 18.5294 11.0753 18.508 10.9749 18.4664C10.8745 18.4248 10.7832 18.3638 10.7063 18.287C10.6294 18.2101 10.5685 18.1189 10.5269 18.0184C10.4853 17.918 10.4639 17.8103 10.4639 17.7016V8.29655C10.4639 8.15147 10.502 8.00894 10.5744 7.88323C10.6469 7.75753 10.7511 7.65307 10.8766 7.58032C11.0021 7.50758 11.1446 7.4691 11.2897 7.46875C11.4347 7.4684 11.5774 7.50619 11.7032 7.57832L19.9132 12.2829C20.0397 12.3554 20.1448 12.46 20.2179 12.5861C20.291 12.7123 20.3295 12.8555 20.3295 13.0013C20.3295 13.1471 20.2909 13.2903 20.2178 13.4164C20.1447 13.5425 20.0395 13.6471 19.913 13.7195L11.703 18.42C11.5778 18.4917 11.436 18.5295 11.2917 18.5294ZM12.1195 9.725V16.2738L17.8361 13.0008L12.1195 9.725Z' fill='%23000C2E'/%3E%3C/svg%3E");
            }
          }
        }
      }
    }
  }
}
.modal {
  position: fixed;
  width: calc(100%);
  max-height: calc(100% - var(--spacing-md-3) - var(--spacing-md-3));
  display: flex;
  justify-content: center;
  align-items: center;
  top: 50%;
  transform: translateY(-50%);
  margin-top: 0rem;
  left: 0;
  pointer-events: none;
  z-index: 9999;
  margin: auto;
  transition: height 0.25s ease-out;
  &-inner {
    background-color: #fff;
    width: 90%;
    max-width: 1260px;
    height: 85vh;
    max-height: 710px;
    position: relative;
    overflow: auto;
    pointer-events: visible;
    color: var(--blue-color);

    @media screen and (max-width: 1023px) {
      height: 100vh;
      width: 100%;
    }
  }
  &-content {
    outline: 0;
    position: relative;

    &-headline {
      padding: var(--spacing-md-2);
      width: 100%;
      box-sizing: border-box;
      border-bottom: 1px solid rgba(0, 12, 46, 0.1);
      margin: 0px;
      display: flex;
      background: white;
      position: sticky;
      top: 0;
      left: 0;
      border-top: 8px solid var(--red-color);
      z-index: 10000;

      & .modal-back {
        display: inline-block;
        margin-right: 3rem;
        align-self: center;
        cursor: pointer;

        @media screen and (max-width: 768px) {
          margin-right: 2rem;
        }

        & span {
          position: relative;
          font-family: var(--font);
          padding: 0.2em;
          padding-left: 2rem;
          &::before {
            content: "";
            position: absolute;
            width: 16px;
            height: 16px;
            left: 0;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='28' height='26' viewBox='0 0 28 26' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M19.9887 25C19.8798 25.0001 19.772 24.9787 19.6714 24.937C19.5708 24.8953 19.4795 24.8341 19.4027 24.757L8.24188 13.5734C8.08689 13.4181 7.99989 13.2076 8 12.9882C8.00011 12.7688 8.08731 12.5584 8.24244 12.4033L19.4033 1.24247C19.4802 1.1656 19.5714 1.10462 19.6719 1.06302C19.7723 1.02141 19.88 1 19.9887 1C20.0974 1 20.205 1.02141 20.3055 1.06302C20.4059 1.10462 20.4972 1.1656 20.574 1.24247C20.6509 1.31934 20.7119 1.4106 20.7535 1.51103C20.7951 1.61147 20.8165 1.71913 20.8165 1.82784C20.8165 1.93655 20.7951 2.0442 20.7535 2.14463C20.7119 2.24507 20.6509 2.33633 20.574 2.41321L9.9979 12.9892L20.5746 23.5874C20.6902 23.7033 20.7689 23.8508 20.8007 24.0113C20.8325 24.1718 20.816 24.3382 20.7533 24.4893C20.6906 24.6405 20.5845 24.7697 20.4484 24.8606C20.3123 24.9515 20.1523 25 19.9887 25Z' fill='%23000C2E'/%3E%3C/svg%3E");
          }

          @media screen and (max-width: 768px) {
            font-size: 0;
            padding-left: 0rem;
          }
        }
      }

      & h3 {
        margin-bottom: 0;
        display: inline-block;
        font-family: var(--font-family-header);
      }

      & .close-handle {
        width: 1rem;
        padding: 1rem;
        position: absolute;
        right: 1rem;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        @media screen and (max-width: 1023px) {
          right: 0rem;
        }
      }
    }
  }
}

@media screen and (max-width: 479px) {
  .modal {
    margin: 0px;
    width: 100%;
    height: calc(100vh - calc(100vh - 100%));
    max-height: unset;
    box-sizing: border-box;
    &-inner {
      height: calc(100vh - calc(100vh - 100%));
      max-height: unset;
      width: 100%;
    }
    &-content {
      width: 100%;
      max-width: unset;
      @media screen and (max-width: 768px) {
        height: 65px;
      }
    }
  }
}
</style>

<template>
  <teleport to="body">
    <transition name="fade-in">
      <div v-if="show" :style="'--backdrop:' + backdrop" class="modal-backdrop" @click="closeModal"></div>
    </transition>
    <transition name="modal" :show="modalType == 'ansvar'">
      <AppModalAnsvar v-if="show" role="dialog" class="modal" @close="closeModal"></AppModalAnsvar>
    </transition>
    <transition name="modal" :show="modalType == 'energi'">
      <AppModalEnergi v-if="show" role="dialog" class="modal" @close="closeModal"></AppModalEnergi>
    </transition>
  </teleport>
</template>

<script>
import AppModalAnsvar from "@/components/AppModalAnsvar.vue";
import AppModalEnergi from "@/components/AppModalEnergi.vue";
export default {
  name: "Modal",
  props: {
    show: {
      type: Boolean,
      default: false
    },
    backdrop: {
      type: String,
      default: "grey"
    },
    modalType: {
      type: String,
      default: ""
    }
  },
  components: { AppModalAnsvar, AppModalEnergi },
  methods: {
    closeModal() {
      this.$store.commit("closeModal");
    }
  }
  // watch: {
  //   show: function(val) {
  //     if (val) {
  //       setTimeout(() => {
  //         this.$refs.modal.focus();
  //       }, 10);
  //     }
  //   }
  // }
};
</script>

<style lang="scss" scoped>
.modal {
  &-backdrop {
    position: fixed;
    width: 100%;
    height: 100%;
    opacity: 0.15;
    top: 0;
    left: 0;
    z-index: 5;
    background-color: rgba(0, 12, 46, 1);
  }
}
.fade-in-enter-active,
.fade-in-leave-active {
  --fade-in-time: 0.3s ease;
}
</style>

<style lang="scss">
.close-handle {
  &:hover {
    & svg {
      path {
        stroke: var(--link-active-color);
      }
    }
  }
}
</style>
<template>
  <div>
    <svg width="28" height="26" viewBox="0 0 28 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14 25C7.38317 25 2 19.6168 2 13C2 6.38318 7.38317 1 14 1C20.6168 1 26 6.38318 26 13C26 19.6168 20.6168 25 14 25ZM14 2.65561C11.954 2.65561 9.95407 3.2623 8.25294 4.39895C6.55181 5.53561 5.22595 7.15117 4.443 9.04136C3.66006 10.9316 3.45522 13.0115 3.85436 15.0181C4.2535 17.0247 5.23867 18.8679 6.68536 20.3146C8.13205 21.7613 9.97526 22.7465 11.9819 23.1456C13.9885 23.5448 16.0684 23.3399 17.9586 22.557C19.8488 21.774 21.4644 20.4482 22.601 18.747C23.7377 17.0459 24.3443 15.0459 24.3443 13C24.3412 10.2574 23.2504 7.62812 21.3111 5.68884C19.3718 3.74957 16.7425 2.65872 14 2.65561Z"
        fill="white"
      />
      <path
        d="M11.2917 18.5294C11.183 18.5294 11.0753 18.508 10.9749 18.4664C10.8745 18.4248 10.7832 18.3638 10.7063 18.287C10.6294 18.2101 10.5685 18.1189 10.5269 18.0184C10.4853 17.918 10.4639 17.8103 10.4639 17.7016V8.29655C10.4639 8.15147 10.502 8.00894 10.5744 7.88323C10.6469 7.75753 10.7511 7.65307 10.8766 7.58032C11.0021 7.50758 11.1446 7.4691 11.2897 7.46875C11.4347 7.4684 11.5774 7.50619 11.7032 7.57832L19.9132 12.2829C20.0397 12.3554 20.1448 12.46 20.2179 12.5861C20.291 12.7123 20.3295 12.8555 20.3295 13.0013C20.3295 13.1471 20.2909 13.2903 20.2178 13.4164C20.1447 13.5425 20.0395 13.6471 19.913 13.7195L11.703 18.42C11.5778 18.4917 11.436 18.5295 11.2917 18.5294ZM12.1195 9.725V16.2738L17.8361 13.0008L12.1195 9.725Z"
        fill="white"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: "AppSvgVideo"
};
</script>

<style lang="scss" scoped>
</style>
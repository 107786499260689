<template>
  <div>
    <svg width="11" height="18" viewBox="0 0 11 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <line x1="5.5" y1="0.5" x2="5.5" y2="16.5" stroke="#000C2E" stroke-linecap="round" />
      <line x1="10" y1="12.3345" x2="5.70711" y2="16.6274" stroke="#000C2E" stroke-linecap="round" />
      <line
        x1="0.5"
        y1="-0.5"
        x2="6.57107"
        y2="-0.5"
        transform="matrix(0.707107 0.707107 0.707107 -0.707107 1 11.6274)"
        stroke="#000C2E"
        stroke-linecap="round"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: "AppSvgArrowDown"
};
</script>

<style lang="scss" scoped>
</style>
<template>
  <nav id="nav" :class="{ 'is-editor': editor }">
    <ul class="nav" v-if="editor">
      <li
        class="mobile-only logo-kk-mobile"
        @click="
          navHandle();
          scrollToTop();
        "
      >
        <router-link to="/editor?path=site/" class>
          <img src="@/assets/logo_white.png" alt="KK" />
        </router-link>
      </li>
      <span class="nav-mobile-text" :class="{ navActive: navOpen }">
        <li class="desktop-only">
          <router-link to="/" class>
            <img src="@/assets/logo.png" alt="KK" />
          </router-link>
        </li>

        <li class="nav-item" v-for="item in nav" :key="item.id">
          <div
            @click="
              navHandle();
              scrollToTop();
            "
          >
            <router-link :to="'/editor?path=' + item.link" class="nav-link">{{ item.text }}</router-link>
          </div>
        </li>
      </span>
    </ul>

    <ul class="nav" :class="{ navActive: navOpen }" v-else>
      <li
        class="mobile-only logo-kk-mobile"
        @click="
          navHandle();
          scrollToTop();
        "
      >
        <router-link to="/" class>
          <img src="@/assets/logo_white.png" alt="KK" />
        </router-link>
      </li>
      <span class="nav-mobile-text" :class="{ navActive: navOpen }">
        <li class="desktop-only" @click="highlightAnim('logo')">
          <router-link to="/" class>
            <img src="@/assets/logo.png" alt="KK" />
          </router-link>
        </li>

        <li class="nav-item" v-for="item in nav" :key="item.id">
          <div
            @click="
              navHandle();
              scrollToTop(), highlightAnim('el');
            "
          >
            <router-link :to="item.link" class="nav-link">{{ item.text }}</router-link>
          </div>
        </li>
        <div class="hightlightLine"></div>
      </span>
    </ul>
    <div class="logo-mobile">
      <router-link to="/" class>
        <img src="@/assets/logo.png" alt="KK" />
      </router-link>
    </div>

    <div class="mobile-fake-animation" :class="{ navActive: navOpen }"></div>

    <div class="burger-mobile" @click="navHandle" :class="{ navActive: navOpen }">
      <DefaultSvgNav class="nav-mobile-handle" id="nav-burger" />
      <DefaultSvgClose class="nav-mobile-handle" id="nav-cross" />
    </div>
  </nav>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import DefaultSvgNav from "@/components/svg/DefaultSvgNav.vue";
import DefaultSvgClose from "@/components/svg/DefaultSvgClose.vue";
import { mapState } from "vuex";
export default {
  name: "Navigation",
  data() {
    return {
      editor: false,
      navOpen: false,
    };
  },
  components: {
    DefaultSvgNav,
    DefaultSvgClose,
  },
  methods: {
    navHandle() {
      if (window.innerWidth < 1024) {
        var handler = (this.navOpen = !this.navOpen);
        if (this.navOpen) {
          document.querySelector("body").style.overflow = "hidden";
        } else {
          document.querySelector("body").style.overflow = "auto";
        }
        return handler;
      } else {
        document.querySelector("body").style.overflow = "auto";
        return;
      }
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    highlightAnim(item) {
      var el = document.querySelector(".router-link-active");
      /*
      if (item == "logo") {
        if (window.innerWidth >= 1024) {
          document.querySelector(".hightlightLine").style.width = "0px";
          document.querySelector(".hightlightLine").style.right = "0px";
        }
      } else {
        if (window.innerWidth >= 1024) {
          var width = el.offsetWidth;
          var getLeft = el.offsetLeft;
          var calcRight = window.innerWidth - getLeft - width;
          document.querySelector(".hightlightLine").style.width = width + "px";
          document.querySelector(".hightlightLine").style.right =
            calcRight + "px";
        }
      }*/
    },
  },
  computed: {
    ...mapState({
      nav: (state) => state.pageNavigation,
    }),
  },
  mounted() {
    setTimeout(() => {
      if (this.$route.query.path) {
        this.editor = true;
      }
      this.highlightAnim();
    }, 200);
  },
};
</script>

<style lang="scss">
#nav {
  position: relative;
}

.logo-mobile,
.burger-mobile {
  display: none;
}

.nav {
  & span {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #ffffff;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 110px;
    box-sizing: border-box;
    padding: 15px 30px;
    border-bottom: 1px solid rgba(0, 12, 46, 0.2);

    & li {
      & a {
        padding: 38px 20px;
        margin: 0px 0px;
        text-decoration: none;
        font-weight: bold;
        color: var(--link-color);
        border-bottom: 0px;
        &.router-link-exact-active.nav-link {
          color: var(--link-active-color);
          border-bottom: 8px solid var(--link-active-color);
          mix-blend-mode: multiply;
          &:hover {
            color: var(--link-active-color);
            border-bottom: 8px solid var(--link-active-color);
            mix-blend-mode: multiply;
            padding: 38px 20px;
          }
        }

        &.nav-link:hover {
          padding: 38px 20px;
          color: var(--link-color);
          border-bottom: 8px solid var(--link-color);
          mix-blend-mode: multiply;
        }
      }

      &:first-child {
        margin-right: auto;
        width: 70px;
        & a {
          padding: 0;
          & img {
            width: 100%;
          }
        }
      }
    }
  }
  .hightlightLine {
    height: 8px;
    width: 0px;
    background: var(--link-active-color);
    bottom: 0px;
    right: 0%;
    position: absolute;
    z-index: 1;
    transition: all 0.5s ease-out;
    display: block;
  }
}

.desktop-only {
  display: block;
}

.mobile-only {
  display: none;
}

@media screen and (max-width: 1023px) {
  .nav-item {
    font-size: var(--font);
  }
  .desktop-only {
    display: none !important;
  }

  .mobile-only {
    display: block !important;
    &.logo-kk-mobile {
      position: absolute;
      left: var(--spacing-md-2);
      top: var(--spacing-md-2);
      width: 60px;
      & img {
        width: 100%;
      }
    }
  }

  .hightlightLine {
    display: none !important;
  }

  .nav {
    background: transparent;
    position: fixed;
    display: block;
    top: 0px;
    left: unset;
    right: 0px;
    width: 0%;
    height: 100%;
    z-index: 1999;

    & .nav-mobile-text {
      opacity: 0;
      height: auto;

      &.navActive {
        opacity: 1;
        transition: opacity 0.4s ease-in-out;
      }
    }

    &.navActive {
      width: 100%;
    }
    & span {
      padding: 0px;
      display: block;
      background: transparent;
      top: 50%;
      transform: translateY(-50%);
      position: relative;
      border-bottom: 0px solid rgba(0, 12, 46, 0.2);
      padding: 0px var(--spacing-md-3);
      font-size: var(--font-h2);

      & li {
        display: block;
        & div {
          display: inline-block;
        }
        & a {
          padding: 10px 10px;
          margin: 0px 10px;
          text-decoration: none;
          font-weight: bold;
          display: inline-block;
          color: #fff;
          border-bottom: 0px;
          &.router-link-exact-active.nav-link {
            color: var(--link-active-color);
            border-bottom: 0px solid var(--link-active-color);
            mix-blend-mode: unset;
            &:hover {
              color: var(--link-active-color);
              border-bottom: 0px solid var(--link-active-color);
              mix-blend-mode: unset;
              padding: 10px 10px;
            }
          }

          &.nav-link:hover {
            padding: 10px 10px;
            border-bottom: 0px solid var(--link-color);
            mix-blend-mode: unset;
            color: #ffffff;
          }
        }

        &:first-child {
          display: none;
        }
      }
    }
  }

  .mobile-fake-animation {
    width: 0px;
    height: 0px;
    background: var(--blue-color);
    position: fixed;
    top: 0px;
    right: 0px;
    transform: translateX(50%) translateY(-50%);
    border-radius: 50%;
    z-index: 1990;
    transition: all 0.25s ease-out;

    &.navActive {
      width: 500vw;
      height: 500vw;
      transition: all 0.4s ease-out;
    }
  }
  .logo-mobile {
    display: block;
    position: absolute;
    width: 60px;
    z-index: 999;
    left: var(--spacing-md-2);
    top: var(--spacing-md-2);
    & img {
      width: 100%;
    }
  }
  .burger-mobile {
    position: fixed;
    display: block;
    right: var(--spacing-md-1);
    top: var(--spacing-md-1);
    width: 3rem;
    height: 3rem;
    background: #ffffff;
    border-radius: 50%;
    z-index: 1999;

    &.navActive {
      background: transparent;
    }

    & .nav-mobile-handle {
      position: absolute;
      left: 50%;
      top: 55%;
      transform: translateX(-50%) translateY(-50%);
      opacity: 1;
      & svg {
        width: 1.5rem;
      }

      &#nav-cross {
        opacity: 0;
        left: 51%;
        top: 57%;
        & svg {
          width: 1.25rem;
        }
      }
    }

    &.navActive {
      & #nav-cross {
        opacity: 1;
      }
      & #nav-burger {
        opacity: 0;
      }
    }
  }
}
@media screen and (max-width: 1220px) {
  .nav-item {
    font-size: var(--font-small);

    & div a {
      padding: 40px 20px !important;
    }
  }
}
@media screen and (max-width: 767px) {
  .nav {
    & span {
      font-size: 1.5rem;
      & li {
        display: block;
        & a {
          padding: 10px 0px !important;
          margin: 0px 0px;
        }
      }
    }
  }
}
</style>

<style lang="scss">
.burger-mobile {
  &.navActive {
    background: transparent;
    & #nav-cross {
      & path {
        stroke: #ffffff;
      }
    }
  }
}
</style>

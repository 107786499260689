<template>
  <section class="intro">
    <div class="intro-img-wrapper">
      <div class="intro-img-wrapper-gradient"></div>
      <AppImage
        :img="blok.image"
        class="img-fluid relative-hero-img"
        :altText="blok.alt"
        classes
        type="introImg"
        :width="1680"
        :height="350"
      ></AppImage>
    </div>
    <div>
      <div class="grid">
        <h1>
          <mark>{{ blok.title }}</mark>
        </h1>
        <app-rich-text class="row h4" v-if="blok.text" :text="blok.text" />
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "AppIntroImage",
  props: {
    blok: {
      type: Object,
      default: () => {}
    }
  }
};
</script>

<style lang="scss" scoped>
h1 {
  margin-top: -3.5rem;
  line-height: 2em;
  & mark {
    color: #ffffff;
    background-color: transparent;
    padding: 1rem 1.5rem 1rem 1.5rem;
    font-family: inherit;
    background-repeat: no-repeat;
    line-height: inherit;
    background-image: linear-gradient(
      var(--link-active-color),
      var(--link-active-color)
    );
    background-size: 100% 100%;
    background-position: 0 67%;
    box-decoration-break: clone;
    transition: background-size 1s ease-out;
  }
}

.intro-img-wrapper {
  position: relative;
  &-gradient {
    position: absolute;
    height: 50%;
    width: 100%;
    background: -moz-linear-gradient(
      top,
      rgba(255, 255, 255, 0.5) 0%,
      rgba(255, 255, 255, 0) 100%
    ); /* FF3.6-15 */
    background: -webkit-linear-gradient(
      top,
      rgba(255, 255, 255, 0.5) 0%,
      rgba(255, 255, 255, 0) 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.5) 0%,
      rgba(255, 255, 255, 0) 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    top: 0px;
    left: 0px;
    z-index: 2;
    display: none;
  }
}

.h4 {
  max-width: 80%;
  &.row {
    padding-bottom: 0px;
  }
}
@media screen and (max-width: 1023px) {
  .intro-img-wrapper-gradient {
    display: block;
  }
}

@media screen and (max-width: 767px) {
  .h4 {
    max-width: 100%;
  }
  h1 {
    margin-top: -2.5rem;
    & mark {
      margin-left: 0rem;
      padding: 0.5rem 1.5rem;
    }
  }
}

/*
Pageload animations
*/

.loaded {
  & mark {
    background-size: 100% 100%;
    transition: background-size 1s ease-out;
  }
}
</style>
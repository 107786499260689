<template>
  <div class="blue-box" :class="blok.bgColor">
    <div class="grid">
      <h1>{{ blok.title }}</h1>
      <app-rich-text class="text" v-if="blok.text" :text="blok.text" />
    </div>
  </div>
</template>

<script>
export default {
  name: "AppBlueBox",
  props: {
    blok: {
      type: Object,
      default: () => {}
    }
  }
};
</script>

<style lang="scss" scoped>
.blue-box {
  &.blue {
    background-color: var(--blue-color);
    color: white;
  }
  &.white {
    background-color: white;
    color: var(--blue-color);
  }
  padding: 6.3rem 0px;
  & h1 {
    margin-bottom: 2rem;
  }

  & .text {
    width: 75%;
  }
}

@media screen and (max-width: 1023px) {
  .blue-box {
    padding: calc(var(--spacing-md-3) * 2) 0px;
  }
}

@media screen and (max-width: 767px) {
  .blue-box {
    & .text {
      width: 100%;
    }
  }
}
</style>